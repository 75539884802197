import { FANTOM, OP, BASE, ARBITRUM } from "config/chains";
const kyberTokens = {
  [FANTOM]: [
    {
      chainId: 250,
      address: "0x448d59b4302ab5d2dadf9611bed9457491926c8e",
      symbol: "axlBTC",
      name: "Axelar BTC",
      decimals: 8,
      marketCap: 44043628.025240004,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/5b9c11fe-6ff5-40b7-afba-d2168cd4615d.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xfe7eda5f2c56160d406869a8aa4b2f365d544c7b",
      symbol: "axlETH",
      name: "Axelar ETH",
      decimals: 18,
      marketCap: 44043628.025240004,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/5b9c11fe-6ff5-40b7-afba-d2168cd4615d.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xd226392c23fb3476274ed6759d4a478db3197d82",
      symbol: "axlUSDT",
      name: "Axelar USDT",
      decimals: 6,
      marketCap: 44043628.025240004,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/5b9c11fe-6ff5-40b7-afba-d2168cd4615d.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      symbol: "FTM",
      name: "Fantom",
      decimals: 18,
      marketCap: 44043628.025240004,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/5b9c11fe-6ff5-40b7-afba-d2168cd4615d.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xcbe0ca46399af916784cadf5bcc3aed2052d6c45",
      symbol: "LSHARE",
      name: "LSHARE",
      decimals: 18,
      marketCap: 12448100,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/4b94b261-fcf7-4858-b22c-4b745bfb35a4.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1376,
      cmcRank: 8460,
    },
    {
      chainId: 250,
      address: "0x28a92dde19d9989f39a49905d7c9c2fac7799bdf",
      symbol: "USDC.e",
      name: "LayerZero USD Coin ",
      decimals: 6,
      marketCap: 9920024.000999998,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/caeceb33-b7d0-4c0f-8751-7c1cdd2e4ca0.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xeeeeeb57642040be42185f49c52f7e9b38f8eeee",
      symbol: "ELK",
      name: "Elk",
      decimals: 18,
      marketCap: 4978951.23064,
      logoURI: "https://assets.coingecko.com/coins/images/17813/small/elk.png?1629336971",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x986934d46fa75502ece6ce0015358237688adb614d46174ef50bc43a625afccf",
      cgkRank: 2115,
      cmcRank: 3499,
    },
    {
      chainId: 250,
      address: "0x1b6382dbdea11d97f24495c9a90b7c88469134a4",
      symbol: "axlUSDC",
      name: "Axelar Wrapped USDC",
      decimals: 6,
      marketCap: 3997062.8225,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/598220ce-7ede-42f6-b870-e5c01b724544.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x45114a6d536abaf6195276d2bb53d0ad2b0cd479ce5659824cbba8e088968cfd",
      cmcRank: 2273,
    },
    {
      chainId: 250,
      address: "0x3dc57b391262e3aae37a08d91241f9ba9d58b570",
      symbol: "YOSHI",
      name: "Yoshi.exchange",
      decimals: 18,
      marketCap: 3480668.52,
      logoURI: "https://assets.spookyswap.finance/tokens/YOSHI.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1218,
      cmcRank: 3792,
    },
    {
      chainId: 250,
      address: "0xc5e2b037d30a390e62180970b3aa4e91868764cd",
      symbol: "TAROT",
      name: "Tarot",
      decimals: 18,
      marketCap: 3004944,
      logoURI: "https://assets.spookyswap.finance/tokens/TAROT.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x4b6b3d425f82248996d77ecc3f3df1e500aac1db",
      symbol: "axlLqdr",
      name: "Axelar Wrapped Lqdr",
      decimals: 18,
      marketCap: 2536458.6471965,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/ad043c00-c220-483a-86e1-d43d9d91ff5c.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xad05726287622ac0dc809eac346e73a01a279478a6e0cca67ad16eecb4c55b45",
      cgkRank: 2028,
      cmcRank: 4896,
    },
    {
      chainId: 250,
      address: "0xe0654c8e6fd4d733349ac7e09f6f23da256bf475",
      symbol: "SCREAM",
      name: "Scream",
      decimals: 18,
      marketCap: 1891782,
      logoURI: "https://assets.spookyswap.finance/tokens/SCREAM.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2461,
      cmcRank: 3277,
    },
    {
      chainId: 250,
      address: "0x07bb65faac502d4996532f834a1b7ba5dc32ff96",
      symbol: "FVM",
      name: "FVM",
      decimals: 18,
      marketCap: 1631873.963217,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/c83ec066-2c63-4d01-a2e8-af3d3da2a789.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2712,
    },
    {
      chainId: 250,
      address: "0x260b3e40c714ce8196465ec824cd8bb915081812",
      symbol: "IronICE",
      name: "Iron Finance ICE Token",
      decimals: 18,
      marketCap: 1490366.196,
      logoURI: "https://assets.spookyswap.finance/tokens/IronICE.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 7983,
    },
    {
      chainId: 250,
      address: "0x695921034f0387eac4e11620ee91b1b15a6a09fe",
      symbol: "WETH",
      name: "Wrapped Ether",
      decimals: 18,
      marketCap: 840801.2995747,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/7e3f696d-d33a-49bc-938a-f2e30a00aea4.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xb90ccd563918ff900928dc529aa01046795ccb4a",
      symbol: "sfrxETH",
      name: "Staked Frax Ether",
      decimals: 18,
      marketCap: 740496.12,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/0b81ec6b-c8ab-415c-8fba-6d9d10f3f61a.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x871f3f321fae69183aaed466cc02278794eabd138cc2d6990cc1c089acac0ee7",
      cgkRank: 124,
      cmcRank: 2866,
    },
    {
      chainId: 250,
      address: "0x9e73f99ee061c8807f69f9c6ccc44ea3d8c373ee",
      symbol: "frxETH",
      name: "Frax Ether",
      decimals: 18,
      marketCap: 685834.52,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/a33edbf1-c112-4eff-8827-5fff016c52fd.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x3b639b4fe487cf81b6d31fcd65db0aeba489af3e60612d8ede17d92b312e1bb9",
      cgkRank: 98,
      cmcRank: 2395,
    },
    {
      chainId: 250,
      address: "0x501e3d716a72e11e1e22edcf0365556b357da0c9",
      symbol: "axlWMAI",
      name: "Axelar Wrapped WMAI",
      decimals: 18,
      marketCap: 292938.5982782226,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/96334c4c-d74f-4cd9-8b8a-e5bf52443344.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x36c4016636e11795e1f2d110e1de49ca3ba967e432d33b975b823731b28fb367",
    },
    {
      chainId: 250,
      address: "0xcfc785741dc0e98ad4c9f6394bb9d43cd1ef5179",
      symbol: "ankrFTM",
      name: "Ankr Staked FTM",
      decimals: 18,
      marketCap: 263147.1284364,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/375d58b7-8d44-439f-82cf-a0c87c6fc245.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 6402,
    },
    {
      chainId: 250,
      address: "0x9bd0611610a0f5133e4dd1bfdd71c5479ee77f37",
      symbol: "FTMO",
      name: "Fantom Oasis Token",
      decimals: 18,
      marketCap: 255955.99999999997,
      logoURI: "https://assets.spookyswap.finance/tokens/FTMO.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 7233,
    },
    {
      chainId: 250,
      address: "0xfbfae0dd49882e503982f8eb4b8b1e464eca0b91",
      symbol: "TCS",
      name: "Timechain Swap Token",
      decimals: 18,
      marketCap: 240161.80000000002,
      logoURI: "https://assets.spookyswap.finance/tokens/TCS.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 2080,
    },
    {
      chainId: 250,
      address: "0xcc1b99ddac1a33c201a742a1851662e87bc7f22c",
      symbol: "USDT",
      name: "LayerZero Tether USD",
      decimals: 6,
      marketCap: 176540.7168,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/3338a5d9-859f-4c64-96bc-5fc1cc210981.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x1d43697d67cb5d0436cc38d583ca473a1bfebc7a",
      symbol: "RIP",
      name: "Fantom Doge",
      decimals: 9,
      marketCap: 148411,
      logoURI: "https://assets.spookyswap.finance/tokens/RIP.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x477a9d5df9beda06f6b021136a2efe7be242fcc9",
      symbol: "TRAVA",
      name: "TravaFinance Token",
      decimals: 18,
      marketCap: 143382.5,
      logoURI: "https://assets.spookyswap.finance/tokens/TRAVA.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2143,
      cmcRank: 1451,
    },
    {
      chainId: 250,
      address: "0x195fe0c899434fb47cd6c1a09ba9da56a1cca12c",
      symbol: "MUNNY",
      name: "Munny",
      decimals: 18,
      marketCap: 136327.90234197417,
      logoURI: "https://assets.spookyswap.finance/tokens/MUNNY.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x22e2e616be1183419a90455da2bfd58279fbb333624e3d50c1760932a66872c8",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0xf1648c50d2863f780c57849d812b4b7686031a3d",
      symbol: "WBTC",
      name: "Wrapped BTC",
      decimals: 8,
      marketCap: 115331.8309045,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/e9d2ed50-730e-4037-8d5c-1fd4ed2d9597.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xeaf45b62d9d0bdc1d763baf306af5edd7c0d7e55",
      symbol: "Death",
      name: "Death",
      decimals: 9,
      marketCap: 98780,
      logoURI: "https://assets.spookyswap.finance/tokens/DEATH.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x91a40c733c97a6e1bf876eaf9ed8c08102eb491f",
      symbol: "DAI",
      name: "LayerZero Dai Stablecoin",
      decimals: 18,
      marketCap: 28423.335136174,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/f592ef27-d016-4307-9e6f-1070fcdd8e71.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x1bcf4dc879979c68fa255f731fe8dcf71970c9bc",
      symbol: "SYF",
      name: "Syfin",
      decimals: 18,
      marketCap: 11546.648107501593,
      logoURI: "https://assets.spookyswap.finance/tokens/SYF.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x42ae8468a1fddb965d420bd71368a87ec3a2b4b8",
      symbol: "Metti",
      name: "Metti Inu",
      decimals: 18,
      marketCap: 9951,
      logoURI: "https://assets.spookyswap.finance/tokens/Metti.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x496e1693a7b162c4f0cd6a1792679cc48ecbcc8d",
      symbol: "EYE",
      name: "The Eye of Truth",
      decimals: 18,
      marketCap: 5881.469408345276,
      logoURI: "https://assets.spookyswap.finance/tokens/EYE.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xfbc3c04845162f067a0b6f8934383e63899c3524",
      symbol: "FOO",
      name: "Fantums of Opera Token",
      decimals: 18,
      marketCap: 3038.8269094571465,
      logoURI: "https://assets.spookyswap.finance/tokens/FOO.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x34d33dc8ac6f1650d94a7e9a972b47044217600b",
      symbol: "SMART",
      name: "Smart Token",
      decimals: 18,
      marketCap: 365.6284538011583,
      logoURI: "https://assets.spookyswap.finance/tokens/SMART.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xaa798bf5ec09b6e3bc059ea4d36d4ca53e063ef7",
      symbol: "crvUSD",
      name: "Curve USD Stablecoin",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/a63c3bc2-1afb-4392-ac50-6561cd3fadb6.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x9b06f3c5de42d4623d7a2bd940ec735103c68a76",
      symbol: "Volta",
      name: "Volta Club",
      decimals: 18,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/03a8abe0-ba1d-45d3-80a7-902a9d8833f81693635070364.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 2633,
    },
    {
      chainId: 250,
      address: "0x0e249130b3545a2a287de9f27d805cab95f03db9",
      symbol: "FBA",
      name: "Firebird Aggregator",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/6c1fe132-450e-4677-95bd-2aea4f694ff8.webp",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 6724,
    },
    {
      chainId: 250,
      address: "0x2130d2a1e51112d349ccf78d2a1ee65843ba36e0",
      symbol: "UNIDX",
      name: "UniDex",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/63b8b3ed-14b6-403e-af7c-bae2bd0c314f.jpg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x00f225722edf265a4643424a092b10827571ca4e235f39099fc9127058642b65",
    },
    {
      chainId: 250,
      address: "0xdc301622e621166bd8e82f2ca0a26c13ad0be355",
      symbol: "FRAX",
      name: "Frax",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/82511b7c-dcb4-45a8-a8ae-ecdeaed2165a.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0xc97e3534de7bcbba31031c2794a422c4cc4a5ec34b98be74070530a7963c6c39",
      cgkRank: 106,
      cmcRank: 214,
    },
    {
      chainId: 250,
      address: "0x7d016eec9c25232b01f23ef992d98ca97fc2af5a",
      symbol: "FXS",
      name: "Frax Share",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/9159ac78-8f78-40c3-a179-67c0f4d670cc.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x9a3b3da6d0e37abb1bfd8c9e30a2fc93262ce6b4e713049a1b3058cd70697790",
    },
    {
      chainId: 250,
      address: "0xdf34be7f242ee338644d8d1a1f4d11a807b4da5f",
      symbol: "axlKNC",
      name: "Axelar Wrapped KNC",
      decimals: 18,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/1140dcb2-4050-44b5-81c5-90162560002b1704705608570.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
      domainSeparator: "0xa4a38860cb542242f61a0374e6f3da3c1b6515c66d6fd37574c5f08ba30f9205",
    },
    {
      chainId: 250,
      address: "0x82f8cb20c14f134fe6ebf7ac3b903b2117aafa62",
      symbol: "FXS",
      name: "Frax Share",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/FXS.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xf5c84efe97c61fb589ec1d7eea64ff89b059793b420036ae8bd6ab281626f47c",
      permitType: "EIP-2612",
      cgkRank: 103,
      cmcRank: 92,
    },
    {
      chainId: 250,
      address: "0xddcb3ffd12750b45d32e084887fdf1aabab34239",
      symbol: "ANY",
      name: "Anyswap",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/anyswap/Brand-assets/master/logo/c-128-color-2.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8",
      symbol: "LINK",
      name: "ChainLink",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xc6fe33b7b1785977c28ef845e6bd37f28b60984bd515af3fb998e1169fd5f140",
      permitType: "EIP-2612",
      cgkRank: 15,
      cmcRank: 14,
    },
    {
      chainId: 250,
      address: "0xbfaf328fe059c53d936876141f38089df0d1503d",
      symbol: "MM",
      name: "MMToken",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/MM.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x98fdf2bd32066257af0b58da5f84bd53524367c65269939d80e2ee09a7edfe08",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0xd6070ae98b8069de6b494332d1a1a81b6179d960",
      symbol: "BIFI",
      name: "Beefy.Finance",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/12704/large/token.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x077e2d8ea95b758d35561f6043f68889f87228aeb5e768476ed3fc999d33e49c",
      permitType: "EIP-2612",
      cgkRank: 736,
      cmcRank: 655,
    },
    {
      chainId: 250,
      address: "0x29b0da86e484e1c0029b56e817912d778ac0ec69",
      symbol: "YFI",
      name: "yearn.finance",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/11849/large/yfi-192x192.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xe5b86ef1e800fac7f035c910f158721abf75fa7b05b5406d0260038ed83111ca",
      permitType: "EIP-2612",
      cgkRank: 201,
      cmcRank: 180,
    },
    {
      chainId: 250,
      address: "0x74b23882a30290451a17c44f4f05243b6b58c76d",
      symbol: "ETH",
      name: "Ethereum",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/44b5b6af-0862-4b59-acdd-10a572feb86d.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x96ccc1d5e9d4daf3d83a43748af45b5edaf05cd5244ad805518ce39d4ed9f272",
      permitType: "EIP-2612",
      cmcRank: 2240,
    },
    {
      chainId: 250,
      address: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
      symbol: "multiUSDC",
      name: "Multi USD Coin",
      decimals: 6,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/75041c38-13d6-4fd5-91a7-5255407daa69.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0xe494f2ccea138d6d397eabffa1428dcd703fb26029e591e283732f2128f79e3d",
      permitType: "EIP-2612",
      cgkRank: 7,
      cmcRank: 7,
    },
    {
      chainId: 250,
      address: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
      symbol: "WFTM",
      name: "Wrapped Fantom",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/77211453-8757-4396-89c1-5c12e9d37b36.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 2376,
    },
    {
      chainId: 250,
      address: "0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc",
      symbol: "SUSHI",
      name: "Sushi",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/SUSHI.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x86be7fd6ebfd7d0453d2fadc877e8f096d207259ac0350c36a313568151b690f",
      permitType: "EIP-2612",
      cgkRank: 205,
      cmcRank: 165,
    },
    {
      chainId: 250,
      address: "0x321162cd933e2be498cd2267a90534a804051b11",
      symbol: "multiBTC",
      name: "Bitcoin",
      decimals: 8,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x24d7b5f6d7fb317c71d7818eabe586d41178469c68eea29d78654d6db5c97198",
      permitType: "EIP-2612",
      cgkRank: 17,
      cmcRank: 203,
    },
    {
      chainId: 250,
      address: "0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454",
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x5e3a6cb4cd4c1e030df9cfd41ee4cb3dccb1d0630e272b9aead458f39721951c",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0x09e145a1d53c0045f41aeef25d8ff982ae74dd56",
      symbol: "ZOO",
      name: "ZOO",
      decimals: 0,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/ZOO.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 7416,
    },
    {
      chainId: 250,
      address: "0xd0660cd418a64a1d44e9214ad8e459324d8157f1",
      symbol: "WOOFY",
      name: "Woofy",
      decimals: 12,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/WOOFY.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x45492261756113a80c794aa2ac879dfa0814e3a3d3a338c9e1f74876d0ab56da",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0x1e4f97b9f9f913c46f1632781732927b9019c68b",
      symbol: "CRV",
      name: "Curve DAO",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/12124/large/Curve.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x7e608eaf14238416ac5ce8f61681063811a6483e67fa855925723c94b8b864f2",
      permitType: "EIP-2612",
      cgkRank: 105,
      cmcRank: 94,
    },
    {
      chainId: 250,
      address: "0xf16e81dce15b08f326220742020379b855b87df9",
      symbol: "ICE",
      name: "IceToken",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://zapper.fi/images/ICE-icon.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x07ff9ccf87ff29f976a22164d85faa451419e86b2b1cdeaa311936e3af8218ac",
      permitType: "EIP-2612",
      cgkRank: 1968,
      cmcRank: 3747,
    },
    {
      chainId: 250,
      address: "0x753fbc5800a8c8e3fb6dc6415810d627a387dfc9",
      symbol: "BADGER",
      name: "Badger",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/13287/large/badger_dao_logo.jpg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xa7f1dca4595d23916e62e43fb85fc90693e741a8f79ad4b96ce35f2643220bd2",
      permitType: "EIP-2612",
      cgkRank: 440,
      cmcRank: 416,
    },
    {
      chainId: 250,
      address: "0x46e7628e8b4350b2716ab470ee0ba1fa9e76c6c5",
      symbol: "BAND",
      name: "Band",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/9545/large/band-protocol.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xa996d2f742bedd3f768d884ec61ffe00dde54f16fe4974df5aca43b8f7ddda27",
      permitType: "EIP-2612",
      cgkRank: 193,
      cmcRank: 161,
    },
    {
      chainId: 250,
      address: "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
      symbol: "multiDAI",
      name: "Multi Dai Stablecoin",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/bb637c89-ffa3-41a6-83a6-91ffc67093f6.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0xbb47180f779dc85a502779e05f76698889bb7087f131e3419c291f57fd0c7b6d",
      permitType: "EIP-2612",
      cgkRank: 22,
      cmcRank: 19,
    },
    {
      chainId: 250,
      address: "0x6a07a792ab2965c72a5b8088d3a069a7ac3a993b",
      symbol: "AAVE",
      name: "Aave",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/12645/large/AAVE.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xd0479f5ef73f0a17e5ae970f930f97979d024e7c8318a99479406b7e15c243e3",
      permitType: "EIP-2612",
      cgkRank: 53,
      cmcRank: 54,
    },
    {
      chainId: 250,
      address: "0x049d68029688eabf473097a2fc38ef61633a3c7a",
      symbol: "multiUSDT",
      name: "Multi Frapped USDT",
      decimals: 6,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0xd6457f90c7322de933ee335527c3de952e0ddc1d7210497eb0f37cba863faebb",
      permitType: "EIP-2612",
      cgkRank: 3,
      cmcRank: 3,
    },
    {
      chainId: 250,
      address: "0x657a1861c15a3ded9af0b6799a195a249ebdcbc6",
      symbol: "CREAM",
      name: "Cream",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/11976/large/Cream.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x259d61a1b57e066f3ccb3a89eee9cfd13c39f459356336a459393ee30060ee4d",
      permitType: "EIP-2612",
      cgkRank: 1052,
      cmcRank: 623,
    },
    {
      chainId: 250,
      address: "0x841fad6eae12c286d1fd18d1d525dffa75c7effe",
      symbol: "BOO",
      name: "SpookyToken",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/BOO.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xcc27ad22677ba285683b6280d1cfe565db67cf51aa7e0df71ee22433e933b000",
      permitType: "EIP-2612",
      cgkRank: 1083,
      cmcRank: 885,
    },
    {
      chainId: 250,
      address: "0xc1be9a4d5d45beeacae296a7bd5fadbfc14602c4",
      symbol: "GTON",
      name: "Graviton",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/GTON.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x51feacd7d8c8f629d5cb4fdaefd9f866f3bce295b4ecaeee572fac4180bbe980",
      permitType: "EIP-2612",
      cmcRank: 2136,
    },
    {
      chainId: 250,
      address: "0xb01e8419d842beebf1b70a7b5f7142abbaf7159d",
      symbol: "COVER",
      name: "Cover Protocol Governance",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/13563/large/1_eWBbDaqpxXqt7WYPSP4qSw.jpeg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xa0490fe07d4e6a8f6fb72ec68e7c08266aca66b33760f5136024e9b673d69fb9",
      permitType: "EIP-2612",
      cgkRank: 3570,
    },
    {
      chainId: 250,
      address: "0x3a3841f5fa9f2c283ea567d5aeea3af022dd2262",
      symbol: "SHADE",
      name: "ShadeToken",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/SHADE.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xf37bfb25e0a651abcd72574fa042c37588a4f6471d85ab84885c49dd9940ad60",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0xa48d959ae2e88f1daa7d5f611e01908106de7598",
      symbol: "xBOO",
      name: "Boo MirrorWorld",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/xBOO.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b",
      symbol: "ATRI",
      name: "AtariToken",
      decimals: 0,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/12992/large/atari.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x5f86995d6f60e91406a6badddceb62b0c608b173d908205fe76f31dfa060a8a0",
      permitType: "EIP-2612",
      cgkRank: 2091,
    },
    {
      chainId: 250,
      address: "0xad84341756bf337f5a0164515b1f6f993d194e1f",
      symbol: "FUSD",
      name: "Fantom USD",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/fUSD.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 5871,
    },
    {
      chainId: 250,
      address: "0x627524d78b4fc840c887ffec90563c7a42b671fd",
      symbol: "KEK",
      name: "Cryptokek.com",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/KEK.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x66b92360c7217f4f88d40d05c476d645a4ae30cda8ec03077049c24a5c53af5e",
      permitType: "EIP-2612",
      cmcRank: 7901,
    },
    {
      chainId: 250,
      address: "0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37",
      symbol: "TSHARE",
      name: "TSHARE",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/b6d1997e-c99d-4fc1-972f-c7271153e081.webp",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1703,
      cmcRank: 4281,
    },
    {
      chainId: 250,
      address: "0x6c021ae822bea943b2e66552bde1d2696a53fbb7",
      symbol: "TOMB",
      name: "TOMB",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/TOMB.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1697,
      cmcRank: 4416,
    },
    {
      chainId: 250,
      address: "0x82f0b8b456c1a451378467398982d4834b6829c1",
      symbol: "MIM",
      name: "Magic Internet Money",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/16786/large/mimlogopng.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0x2d963745b416af0dd4c59baa7c671dd21a6f174d2ba3cc8dfa1d73c9b558709f",
      permitType: "EIP-2612",
      cgkRank: 569,
      cmcRank: 2614,
    },
    {
      chainId: 250,
      address: "0x8503eb4a136bdbeb323e37aa6e0fa0c772228378",
      symbol: "BOMB",
      name: "BOMB",
      decimals: 0,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/BOMB.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x90e892fed501ae00596448aecf998c88816e5c0f",
      symbol: "DMD",
      name: "DarkMatter",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/DMD.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 7164,
    },
    {
      chainId: 250,
      address: "0x3129662808bec728a27ab6a6b9afd3cbaca8a43c",
      symbol: "DOLA",
      name: "Dola USD Stablecoin",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/DOLA.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xe12d74eca89a64afad884637a5562efbdd3ee29e3d0a91427a50abfaddcc119f",
      permitType: "EIP-2612",
      cgkRank: 706,
      cmcRank: 3349,
    },
    {
      chainId: 250,
      address: "0x6a545f9c64d8f7b957d8d2e6410b52095a9e6c29",
      symbol: "CFi",
      name: "CyberFi Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/CFi.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x9a842ed789a35971870cc230a1caa392cad68409280d0c2ea477384e7c70275e",
      permitType: "EIP-2612",
      cgkRank: 2944,
      cmcRank: 1708,
    },
    {
      chainId: 250,
      address: "0x53d831e1db0947c74e8a52618f662209ec5de0ce",
      symbol: "SING",
      name: "Sing Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/SING.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xbbc4a8d076f4b1888fec42581b6fc58d242cf2d5",
      symbol: "FONT",
      name: "Font",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/FONT.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xb0592eb510d095a52399c7255cd116b9e3eddf3b6353f36925b419d75edcfd60",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0x468003b688943977e6130f4f68f23aad939a1040",
      symbol: "SPELL",
      name: "Spell Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/SPELL.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x1baacc634cefe721a512b539eb0d80833bbacfc350fd3051ccc4df7a65898cac",
      permitType: "EIP-2612",
      cgkRank: 429,
      cmcRank: 449,
    },
    {
      chainId: 250,
      address: "0x5a2e451fb1b46fde7718315661013ae1ae68e28c",
      symbol: "CGS",
      name: "Cougar Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/CGS.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 8107,
    },
    {
      chainId: 250,
      address: "0x4d9361a86d038c8ada3db2457608e2275b3e08d4",
      symbol: "MESO",
      name: "FTM-Meso",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/MESO.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x0def844ed26409c5c46dda124ec28fb064d90d27",
      symbol: "CoUSD",
      name: "CoffinDollar",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/CoUSD.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xb84527d59b6ecb96f433029ecc890d4492c5dce1",
      symbol: "INV",
      name: "Inverse DAO",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/INV.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x604b2d98b54a8588cc70daa62d3eb6443e206068f3a66017c45a3dad075f5d0f",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0xfa1fbb8ef55a4855e5688c0ee13ac3f202486286",
      symbol: "FHM",
      name: "Fantohm",
      decimals: 9,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/FHM.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x04e36c032d5c7dc7c075c52e366d22fc85332fc817df468027038348d29f81e9",
      permitType: "EIP-2612",
      cgkRank: 2745,
      cmcRank: 6645,
    },
    {
      chainId: 250,
      address: "0xb66b5d38e183de42f21e92abcaf3c712dd5d6286",
      symbol: "PILLS",
      name: "PILLS Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ftmscan.com/token/images/morpheusfinftm_32.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x2bd0fc5ee1b87710725df6f52754d0671b02a1581f83d3753a568dba42dcca54",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0x5c4fdfc5233f935f20d2adba572f770c2e377ab0",
      symbol: "HEC",
      name: "Hector",
      decimals: 9,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/HEC.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xd35b7b69aee96efeb5d263607b3155fdd09c0e3b0725264d7029b8fe89299d29",
      permitType: "EIP-2612",
      cgkRank: 1219,
      cmcRank: 6119,
    },
    {
      chainId: 250,
      address: "0xfb98b335551a418cd0737375a2ea0ded62ea213b",
      symbol: "MAI",
      name: "MAI",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/730c256d-3e0d-4175-afaf-63a878794fae.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0x49fbefa726511f603797d1ea01603a467c78e480803762e6b62a12015d47d01e",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0x9f47f313acfd4bdc52f4373b493eae7d5ac5b765",
      symbol: "JOE",
      name: "JoeToken",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/JOE.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x5207886bf74bc15b26de1e1be55adefc7d989ae943e3fb8c70b67740da5cdab9",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0x6496994241804d7fe2b032901931e03bcd82301f",
      symbol: "MODA",
      name: "moda",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/MODA.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x1e9946de1fc39485c6aee8f0729596ee6de1c775a8c00f24e7c871ee73693c2a",
      permitType: "EIP-2612",
      cgkRank: 2130,
      cmcRank: 3861,
    },
    {
      chainId: 250,
      address: "0x175cbf2809acfd7521fdd387d65aac523fe4076f",
      symbol: "RAVEN",
      name: "Raven",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/RAVEN.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xe2fb177009ff39f52c0134e8007fa0e4baacbd07",
      symbol: "SOUL",
      name: "SoulPower",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/SOUL.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2656,
      cmcRank: 4993,
    },
    {
      chainId: 250,
      address: "0x85dec8c4b2680793661bca91a8f129607571863d",
      symbol: "BRUSH",
      name: "PaintSwap Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/13229.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1394,
      cmcRank: 3596,
    },
    {
      chainId: 250,
      address: "0x9879abdea01a879644185341f7af7d8343556b7a",
      symbol: "TUSD",
      name: "TrueUSD",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/TUSD.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xc59aa7858e0502279ce77dd2e40bf10ee88012d29cc4bb2244297a97b0e6b39c",
      permitType: "EIP-2612",
      cgkRank: 41,
      cmcRank: 40,
    },
    {
      chainId: 250,
      address: "0x8f9bccb6dd999148da1808ac290f2274b13d7994",
      symbol: "SUMMIT",
      name: "summitdefi.com",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/SUMMIT.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x5d5530eb3147152fe78d5c4bfeede054c8d1442a",
      symbol: "FEED",
      name: "Feeder.finance",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/FEED.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x0580519dbf84f7b7f90b446d50aad1bc493e6b53d176ab5dbda767754f33d47f",
      permitType: "EIP-2612",
      cgkRank: 3604,
      cmcRank: 6104,
    },
    {
      chainId: 250,
      address: "0x3b57f3feaaf1e8254ec680275ee6e7727c7413c7",
      symbol: "EXOD",
      name: "Exodia",
      decimals: 9,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/EXOD.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x589db4ecb76b0f10b3626a07816b680b03b9a5e635ef4237f306d707ec1d870a",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0xc758295cd1a564cdb020a78a681a838cf8e0627d",
      symbol: "FS",
      name: "FantomStarter",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/FS.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x46e1ee17f51c52661d04238f1700c547de3b84a1",
      symbol: "SCARE",
      name: "ScareCrow",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/SCARE.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x37f70aa9fefc8971117bd53a1ddc2372aa7eec41",
      symbol: "BOUJE",
      name: "Bouje Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/BOUJE.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xeff6fcfbc2383857dd66ddf57efffc00d58b7d9d",
      symbol: "JulD",
      name: "JulSwap",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/JulD.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x9b684970bdee6d97c9a3e1de48a1be8beca98eed7a4394e45fa82d82e5af26ac",
      permitType: "EIP-2612",
      cgkRank: 1795,
      cmcRank: 1261,
    },
    {
      chainId: 250,
      address: "0xf43cc235e686d7bc513f53fbffb61f760c3a1882",
      symbol: "ELITE",
      name: "ftm.guru",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/ELITE.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 5300,
    },
    {
      chainId: 250,
      address: "0xd8321aa83fb0a4ecd6348d4577431310a6e0814d",
      symbol: "GEIST",
      name: "Geist.Finance Protocol Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/GEIST.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 7356,
    },
    {
      chainId: 250,
      address: "0x31a37aedc0c18aa139e120e1cdc673bbb2063e6f",
      symbol: "TOTEM",
      name: "Totem Finance",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/TOTEM.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x0789ff5ba37f72abc4d561d00648acadc897b32d",
      symbol: "MORPH",
      name: "Morpheus Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/MORPH.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 7156,
    },
    {
      chainId: 250,
      address: "0x23cbc7c95a13071562af2c4fb1efa7a284d0543a",
      symbol: "fSWAMP",
      name: "fSWAMP Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/fSWAMP.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x6626c47c00f1d87902fc13eecfac3ed06d5e8d8a",
      symbol: "WOO",
      name: "Wootrade Network",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/WOO.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x2e5a8c08acca91922e64357ccf462195e4a8cabd572d94b75d4cc0d9bb299e63",
      permitType: "EIP-2612",
      cgkRank: 92,
      cmcRank: 88,
    },
    {
      chainId: 250,
      address: "0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9",
      symbol: "LQDR",
      name: "Liquid Driver",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/10581faf-3e08-45a2-8ddb-e7802e2b0084.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x49894fcc07233957c35462cfc3418ef0cc26129f",
      symbol: "FANG",
      name: "FANG Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/FANG.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 6405,
    },
    {
      chainId: 250,
      address: "0xc60d7067dfbc6f2caf30523a064f416a5af52963",
      symbol: "TREEB",
      name: "Treeb",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/TREEB.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0x05848b832e872d9edd84ac5718d58f21fd9c9649",
      symbol: "STEAK",
      name: "SteakToken",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/STEAK.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x9bb7a26038712af445493cf456a884d2732306aa68e09eedb0cb894ac3483457",
      permitType: "EIP-2612",
      cmcRank: 8041,
    },
    {
      chainId: 250,
      address: "0x47d0d625638b56084e76b8720475d175d171af9a",
      symbol: "PENNI",
      name: "PenniToken",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/PENNI.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x085a12c866668453aa43cb6dbc30137dae42363a023c2ce1e2a16b075441657e",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0xd3b71117e6c1558c1553305b44988cd944e97300",
      symbol: "YEL",
      name: "YEL Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/YEL.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2953,
      cmcRank: 7044,
    },
    {
      chainId: 250,
      address: "0x9fc071ce771c7b27b7d9a57c32c0a84c18200f8a",
      symbol: "iFUSD",
      name: "iFUSD",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/iFUSD.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x1d7ea54537eba36135ee736a42fedb1184253a45523cbe19693c7a85a9887b61",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0x593ab53baffaf1e821845cf7080428366f030a9c",
      symbol: "COFFIN",
      name: "CoffinToken",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.spookyswap.finance/tokens/COFFIN.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 250,
      address: "0xddc0385169797937066bbd8ef409b5b3c0dfeb52",
      symbol: "wMEMO",
      name: "Wrapped MEMO",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/56e18328-3aef-4912-917a-51df14e0aeb9.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x2b13fe6cde4272101100aaa16a4e41d48b024c78f5e81c66d7dad179037ba40a",
      permitType: "EIP-2612",
    },
    {
      chainId: 250,
      address: "0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979",
      symbol: "fBOMB",
      name: "Fantom Bomb",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/24109/small/logo-blue.png?1646376874",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 4541,
    },
    {
      chainId: 250,
      address: "0x66eed5ff1701e6ed8470dc391f05e27b1d0657eb",
      symbol: "MPX",
      name: "MPX",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/28965/small/mpx_logo_256.png?1675744910",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2171,
      cmcRank: 5727,
    },
    {
      chainId: 250,
      address: "0x7381ed41f6de418dde5e84b55590422a57917886",
      symbol: "beFTM",
      name: "Beefy Escrowed Fantom",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/0e80843e-d6c3-4d7a-bf6f-22727e30f32a.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 4398,
    },
  ],
  [OP]: [
    {
      chainId: 10,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      symbol: "ETH",
      name: "Ethereum",
      decimals: 18,
      marketCap: 99554838.23823,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/48c2031f-6696-4dcf-b253-391293b04813.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x9560e827af36c94d2ac33a39bce1fe78631088db",
      symbol: "VELO",
      name: "VelodromeV2",
      decimals: 18,
      marketCap: 85469023.6839,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/ecc965a9-27b6-4108-a84c-b582a23d43d4.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x1c9f1825cfdf5f6f5983a86d7463991bdf13741d024be66a61a43b424b760c5c",
      cgkRank: 772,
      cmcRank: 2342,
    },
    {
      chainId: 10,
      address: "0x0b2c639c533813f4aa9d7837caf62653d097ff85",
      symbol: "USDC.e",
      name: "USD Coin",
      decimals: 6,
      marketCap: 39724088.404,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/c905adfa-cf1e-472f-b455-9278157fc5c91693939579378.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x26d9c34bb1a1c312f69c53b2d93b8be20faafba63af2438c6811713c9b1f933f",
    },
    {
      chainId: 10,
      address: "0x9a601c5bb360811d96a23689066af316a30c3027",
      symbol: "PIKA",
      name: "Pika Protocol",
      decimals: 18,
      marketCap: 37314300,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/5562f858-8463-4482-9123-0f3c33a3e531.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1360,
      cmcRank: 5441,
    },
    {
      chainId: 10,
      address: "0x2dad3a13ef0c6366220f989157009e501e7938f8",
      symbol: "EXTRA",
      name: "Extra Finance",
      decimals: 18,
      marketCap: 19157220,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/0459d438-b8ef-4234-bc30-3a3233689af51694429578983.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1573,
      cmcRank: 4600,
    },
    {
      chainId: 10,
      address: "0xdfa46478f9e5ea86d57387849598dbfb2e964b02",
      symbol: "MAI",
      name: "MAI",
      decimals: 18,
      marketCap: 17728854.357612,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/de37ffe7-6eb2-4d7f-944f-7f2a0c2ea6d9.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 841,
      cmcRank: 3386,
    },
    {
      chainId: 10,
      address: "0x6806411765af15bddd26f8f544a34cc40cb9838b",
      symbol: "frxETH",
      name: "Frax Ether",
      decimals: 18,
      marketCap: 11544880.92,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/4652b3b8-cbd3-4a0c-81df-daa37952f1bf.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x05d3fae8080e4faac23b60bcba8cb1e2fafff7472b29076c30f7a6c84dda3f25",
      cgkRank: 98,
      cmcRank: 2395,
    },
    {
      chainId: 10,
      address: "0xc55e93c62874d8100dbd2dfe307edc1036ad5434",
      symbol: "mooBIFI",
      name: "Moo BIFI",
      decimals: 18,
      marketCap: 5439152.26816,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/6281986a-7d4f-4e11-b103-0fe078cc2f811698233452459.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x851490caa5c880f935c58a5bdc37014a7b5ec4946b808564d9af66b67668380d",
    },
    {
      chainId: 10,
      address: "0x58b9cb810a68a7f3e1e4f8cb45d1b9b3c79705e8",
      symbol: "NEXT",
      name: "Connext",
      decimals: 18,
      marketCap: 4809218.82872,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/4aa25048-fb69-427b-bf75-1781a8a4656f1693937058635.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x5068fca98f4e68f6d8f7403fde1f7450f9e469861325335845d7322151cb6676",
      cgkRank: 942,
      cmcRank: 2257,
    },
    {
      chainId: 10,
      address: "0x484c2d6e3cdd945a8b2df735e079178c1036578c",
      symbol: "sfrxETH",
      name: "Staked Frax Ether",
      decimals: 18,
      marketCap: 3830568.8000000003,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/89253c72-502b-470b-913d-4d6c66840cb4.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x811dc6b72ab1d48542840b507a73e1352620b0b103c9130e6ed58bc7a276dc8c",
      cgkRank: 124,
      cmcRank: 2866,
    },
    {
      chainId: 10,
      address: "0xaddb6a0412de1ba0f936dcaeb8aaa24578dcf3b2",
      symbol: "cbETH",
      name: "Coinbase Wrapped Staked ETH",
      decimals: 18,
      marketCap: 3522849.356063,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/4eed248f-2d4b-42ec-8d2f-7cc08c86e7f5.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 131,
      cmcRank: 218,
    },
    {
      chainId: 10,
      address: "0xeb466342c4d449bc9f53a865d5cb90586f405215",
      symbol: "axlUSDC",
      name: "Axelar Wrapped USDC",
      decimals: 6,
      marketCap: 134053.89998,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/752f9d01-3f3d-4759-aa34-5ee385fbad55.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xf573ab3481811211b13df6bf9e1ee802424f5cee9e05e166e8eb78f6b390f24f",
      cmcRank: 2273,
    },
    {
      chainId: 10,
      address: "0x9cfb13e6c11054ac9fcb92ba89644f30775436e4",
      symbol: "axl.wstETH",
      name: "Axelar Wrapped wstETH",
      decimals: 18,
      marketCap: 51632.775108,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/39b8a17b-4006-4a91-8b74-6648a5c66d201693508491859.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xd147b7f8606af7ec182758216644baae997e678a5d630d830c243ba2bb1c8186",
    },
    {
      chainId: 10,
      address: "0xb448ec505c924944ca8b2c55ef05c299ee0781df",
      symbol: "axlKNC",
      name: "Axelar Wrapped KNC",
      decimals: 18,
      marketCap: 20843.757170011548,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/538cbc06-ef8a-4acc-ac38-7f41d75f1666.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xfb2b7bec8b0a03bffbe4884f1ea6fa8c03a4a17633cb3d2acece80450607514f",
    },
    {
      chainId: 10,
      address: "0x23ee2343b892b1bb63503a4fabc840e0e2c6810f",
      symbol: "AXL",
      name: "Axelar",
      decimals: 6,
      marketCap: 20580.58414128,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/1861abc8-357d-434b-be73-f154d7e07add.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x848d88df672aceac322dee8ecb0c8b1904de6b1e27a6d87aaf871acda5ea676f",
      cgkRank: 107,
      cmcRank: 98,
    },
    {
      chainId: 10,
      address: "0x7b0bcc23851bbf7601efc9e9fe532bf5284f65d3",
      symbol: "EST",
      name: "Erica Social Token",
      decimals: 18,
      marketCap: 4065.286,
      logoURI: "https://ethereum-optimism.github.io/data/EST/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x55a89168b92488433dd39922661f63fe9e8dcd4396b4dada62e40cf3631a5801",
    },
    {
      chainId: 10,
      address: "0x9046d36440290ffde54fe0dd84db8b1cfee9107b",
      symbol: "yfi",
      name: "yearn.finance",
      decimals: 18,
      marketCap: 233.75332193164002,
      logoURI: "https://assets.coingecko.com/coins/images/11849/large/yearn.jpg?1687142705",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 201,
      cmcRank: 180,
    },
    {
      chainId: 10,
      address: "0x7c6b91d9be155a6db01f749217d76ff02a7227f2",
      symbol: "SARCO",
      name: "Sarcophagus",
      decimals: 18,
      marketCap: 3.1209320023001177,
      logoURI: "https://ethereum-optimism.github.io/data/SARCO/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x88dfaaabaf06f3a41d2606ea98bc8eda109abebb",
      symbol: "axlWMAI",
      name: "Axelar Wrapped WMAI",
      decimals: 18,
      marketCap: 2.8650906e-317,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/f43c5fec-ef02-47e2-a1fc-cf79bb5ea1b2.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0x6d5683fb02645aa043df55599df64cd99ac9e1041d984f7e8d0bd7ff257e7b68",
    },
    {
      chainId: 10,
      address: "0x3390108e913824b8ead638444cc52b9abdf63798",
      symbol: "MASK",
      name: "Mask Network",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/MASK/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0xc22885e06cd8507c5c74a948c59af853aed1ea5c",
      symbol: "USDD",
      name: "Decentralized USD",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/USDD.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x3bb4445d30ac020a84c1b5a8a2c6248ebc9779d0",
      symbol: "LIZ",
      name: "Theranos Coin",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/LIZ/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x41b94c5867f7f6217c9a30520cb3e793b1ee1b97",
      symbol: "TIA",
      name: "Axelar Wrapped TIA",
      decimals: 6,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/b3a55fcb-ccec-4c0f-8522-aa271b6774371698927282857.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xb407bba633671174817b52f269fff1f1920ab525f84ab1e3d19c385b45ffd398",
    },
    {
      chainId: 10,
      address: "0x894134a25a5fac1c2c26f1d8fbf05111a3cb9487",
      symbol: "GRAI",
      name: "Gravita Debt Token",
      decimals: 18,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/fac45641-d943-4b4e-b890-cb51df1607611700081689175.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x65559aa14915a70190438ef90104769e5e890a00",
      symbol: "ENS",
      name: "Ethereum Name Service",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/ENS/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58",
      symbol: "USDT",
      name: "Tether USD",
      decimals: 6,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/ba682741-f6a6-4542-b716-8f657a64e343.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      cgkRank: 3,
      cmcRank: 3,
    },
    {
      chainId: 10,
      address: "0xe0bb0d3de8c10976511e5030ca403dbf4c25165b",
      symbol: "0xBTC",
      name: "0xBitcoin",
      decimals: 8,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/0xBTC/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2362,
      cmcRank: 1565,
    },
    {
      chainId: 10,
      address: "0x68f180fcce6836688e9084f035309e29bf0a2095",
      symbol: "WBTC",
      name: "Wrapped BTC",
      decimals: 8,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/70e8470d-9647-42bd-833e-999b36346de0.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 17,
      cmcRank: 203,
    },
    {
      chainId: 10,
      address: "0x8700daec35af8ff88c16bdf0418774cb3d7599b4",
      symbol: "SNX",
      name: "Synthetix",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/SNX.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 64,
      cmcRank: 62,
    },
    {
      chainId: 10,
      address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
      symbol: "DAI",
      name: "Dai Stable Coin",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/DAI/logo.svg",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0xc7bbf40a5fb081e6759d5d0ce2447e84427793536887332b932877b94ce51bd6",
      cgkRank: 22,
      cmcRank: 19,
    },
    {
      chainId: 10,
      address: "0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6",
      symbol: "LINK",
      name: "ChainLink Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/84f27e9a-a21a-4789-94dc-62fbc75d67ad.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 15,
      cmcRank: 14,
    },
    {
      chainId: 10,
      address: "0xfe8b128ba8c78aabc59d4c64cee7ff28e9379921",
      symbol: "BAL",
      name: "Balancer",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/BAL/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x8ae125e8653821e851f12a49f7765db9a9ce7384",
      symbol: "DOLA",
      name: "Dola USD Stablecoin",
      decimals: 18,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/b80bdd71-a1ef-4a18-b642-7542694ba03d1693940093678.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 706,
      cmcRank: 3349,
    },
    {
      chainId: 10,
      address: "0x67ccea5bb16181e7b4109c9c2143c24a1c2205be",
      symbol: "FXS",
      name: "Frax Share",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/3ae92ad8-2f14-4d2b-b6ab-a2add294cf0e.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x93c56da7302f5ca534469719078a368b4ed5c5f8df3b1eda9c886c01012e811d",
      permitType: "EIP-2612",
    },
    {
      chainId: 10,
      address: "0x1eba7a6a72c894026cd654ac5cdcf83a46445b08",
      symbol: "GTC",
      name: "Gitcoin",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/GTC/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x2e3d870790dc77a83dd1d18184acc7439a53f475",
      symbol: "FRAX",
      name: "Frax",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/62da2c7e-094a-4349-8c80-03007eb4dc78.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0x771df1a8cfb12698014aecdef7375a7f166f5a47f214ccae4e363a6a794daddb",
      cgkRank: 106,
      cmcRank: 214,
    },
    {
      chainId: 10,
      address: "0x3c8b650257cfb5f272f799f5e2b4e65093a11a05",
      symbol: "VELO",
      name: "Velodrome",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/20f4ca0a-a004-4d82-9f03-0cefdc769f02.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x4200000000000000000000000000000000000042",
      symbol: "OP",
      name: "Optimism",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/7dd69eb0-bbff-4fbc-a581-1562070eff66.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xd423c9c6b838296762a6bbf9663d4609148ea6bc83b5e94ce2c05615edc69072",
      permitType: "EIP-2612",
      cgkRank: 29,
      cmcRank: 27,
    },
    {
      chainId: 10,
      address: "0xb12c13e66ade1f72f71834f2fc5082db8c091358",
      symbol: "ROOBEE",
      name: "ROOBEE",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/ROOBEE.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x5fc6d714895cbb47a9118fb22b61bbace12f5fea745061fc94fdfebb85308797",
      cgkRank: 2078,
      cmcRank: 1124,
    },
    {
      chainId: 10,
      address: "0x117cfd9060525452db4a34d51c0b3b7599087f05",
      symbol: "GYSR",
      name: "Geyser",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/GYSR/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0xee9801669c6138e84bd50deb500827b776777d28",
      symbol: "O3",
      name: "O3 Swap Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/O3.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2092,
      cmcRank: 1313,
    },
    {
      chainId: 10,
      address: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
      symbol: "USDC.e",
      name: "USD Coin",
      decimals: 6,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/f83406de-fba3-48eb-94cd-882b28a9cdd91698250531131.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      cgkRank: 7,
      cmcRank: 7,
    },
    {
      chainId: 10,
      address: "0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9",
      symbol: "sUSD",
      name: "Synthetix USD",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/8c752201-235a-4c13-a67e-9d50a14f21ef.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      cgkRank: 534,
      cmcRank: 491,
    },
    {
      chainId: 10,
      address: "0xe405de8f52ba7559f9df3c368500b6e6ae6cee49",
      symbol: "sETH",
      name: "Synthetic Ether",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/sETH/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 729,
      cmcRank: 4682,
    },
    {
      chainId: 10,
      address: "0x298b9b95708152ff6968aafd889c6586e9169f1d",
      symbol: "sBTC",
      name: "Synth sBTC",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/sBTC/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x6fd9d7ad17242c41f7131d257212c54a0e816691",
      symbol: "UNI",
      name: "Uniswap",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/UNI/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 20,
      cmcRank: 21,
    },
    {
      chainId: 10,
      address: "0xc5db22719a06418028a40a9b5e9a7c02959d0d08",
      symbol: "sLINK",
      name: "Synthetic Chainlink",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/sLINK/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0xb548f63d4405466b36c0c0ac3318a22fdcec711a",
      symbol: "RGT",
      name: "Rari Governance Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/RGT/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x00f932f0fe257456b32deda4758922e56a4f4b42",
      symbol: "PAPER",
      name: "Paper",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/PAPER/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1956,
      cmcRank: 4773,
    },
    {
      chainId: 10,
      address: "0x7fb688ccf682d58f86d7e38e03f9d22e7705448b",
      symbol: "RAI",
      name: "Rai Reflex Index",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/RAI/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1598,
      cmcRank: 1177,
    },
    {
      chainId: 10,
      address: "0xc98b98d17435aa00830c87ea02474c5007e1f272",
      symbol: "BitBTC",
      name: "BitBTC",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/BitBTC/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x5029c236320b8f15ef0a657054b84d90bfbeded3",
      symbol: "BitANT",
      name: "BitANT",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/BitANT/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x53bc913f50e3230714637b1309e2234bcc90ca496f60eda51c79072b21101d2c",
    },
    {
      chainId: 10,
      address: "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
      symbol: "LYRA",
      name: "Lyra",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/LYRA/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 474,
      cmcRank: 448,
    },
    {
      chainId: 10,
      address: "0xe7798f023fc62146e8aa1b36da45fb70855a77ea",
      symbol: "UMA",
      name: "UMA Voting Token v1",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/UMA/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x9e5aac1ba1a2e6aed6b32689dfcf62a509ca96f3",
      symbol: "DF",
      name: "dForce",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/DF/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0xbfd291da8a403daaf7e5e9dc1ec0aceacd4848b9",
      symbol: "USX",
      name: "dForce USD",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/USX/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xf4fe38d00944a0198c796778bb84744210b765426fc68d5e297e60a6aeab4f3a",
      cgkRank: 754,
      cmcRank: 8106,
    },
    {
      chainId: 10,
      address: "0x9e1028f5f1d5ede59748ffcee5532509976840e0",
      symbol: "PERP",
      name: "Perpetual",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/PERP/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 383,
      cmcRank: 338,
    },
    {
      chainId: 10,
      address: "0x3e7ef8f50246f725885102e8238cbba33f276747",
      symbol: "BOND",
      name: "BarnBridge Governance Token (Optimism)",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/BOND/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 637,
      cmcRank: 614,
    },
    {
      chainId: 10,
      address: "0x4200000000000000000000000000000000000006",
      symbol: "WETH",
      name: "Wrapped Ether",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/f1e0a398-4bb2-4910-9249-a6fcd3944c84.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 2240,
    },
    {
      chainId: 10,
      address: "0x1da650c3b2daa8aa9ff6f661d4156ce24d08a062",
      symbol: "DCN",
      name: "Dentacoin",
      decimals: 0,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/DCN/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2335,
      cmcRank: 1497,
    },
    {
      chainId: 10,
      address: "0x217d47011b23bb961eb6d93ca9945b7501a5bb11",
      symbol: "THALES",
      name: "Thales DAO Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/THALES/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 929,
      cmcRank: 2828,
    },
    {
      chainId: 10,
      address: "0xe4f27b04cc7729901876b44f4eaa5102ec150265",
      symbol: "XCHF",
      name: "CryptoFranc",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/XCHF/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x76fb31fb4af56892a25e32cfc43de717950c9278",
      symbol: "AAVE",
      name: "Aave Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/AAVE/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x17b074fd049541578d029dfa94d1389a7db759e0497e5e30d2b6f7d75413e140",
      cgkRank: 53,
      cmcRank: 54,
    },
    {
      chainId: 10,
      address: "0xcfd1d50ce23c46d3cf6407487b2f8934e96dc8f9",
      symbol: "SPANK",
      name: "SPANK",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/SPANK/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0xaf9fe3b5ccdae78188b1f8b9a49da7ae9510f151",
      symbol: "DHT",
      name: "dHEDGE DAO Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/DHT/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1498,
      cmcRank: 1105,
    },
    {
      chainId: 10,
      address: "0xba28feb4b6a6b81e3f26f08b83a19e715c4294fd",
      symbol: "UST",
      name: "UST (Wormhole)",
      decimals: 6,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/UST/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x0994206dfe8de6ec6920ff4d779b0d950605fb53",
      symbol: "CRV",
      name: "Curve DAO Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/CRV/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 105,
      cmcRank: 94,
    },
    {
      chainId: 10,
      address: "0x81ab7e0d570b01411fcc4afd3d50ec8c241cb74b",
      symbol: "EQZ",
      name: "Equalizer",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/EQZ/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 3914,
      cmcRank: 1449,
    },
    {
      chainId: 10,
      address: "0xfeaa9194f9f8c1b65429e31341a103071464907e",
      symbol: "LRC",
      name: "LoopringCoin V2",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/LRC/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0xf98dcd95217e15e05d8638da4c91125e59590b07",
      symbol: "KROM",
      name: "Kromatika",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/KROM/logo.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1846,
      cmcRank: 1312,
    },
    {
      chainId: 10,
      address: "0xc40f949f8a4e094d1b49a23ea9241d289b7b2819",
      symbol: "LUSD",
      name: "LUSD Stablecoin",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://ethereum-optimism.github.io/data/LUSD/logo.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0xab7badef82e9fe11f6f33f87bc9bc2aa27f2fcb5",
      symbol: "MKR",
      name: "Maker",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/MKR.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0xa00e3a3511aac35ca78530c85007afcd31753819",
      symbol: "KNC",
      name: "Kyber Network Crystal v2",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/ce1e0193-da6c-45ba-ad7d-b72b0c3ab4e7.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 337,
      cmcRank: 330,
    },
    {
      chainId: 10,
      address: "0x4518231a8fdf6ac553b9bbd51bbb86825b583263",
      symbol: "mKNC",
      name: "Multichain Kyber Network Crystal v2",
      decimals: 18,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/d0de81a5-0679-40b8-85da-046fe743afbd1692962756267.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
      symbol: "BOB",
      name: "BOB",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/1f49c3a1-952e-44fd-b234-d178e9d0edca.svg",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0xb23bf3c5aee6b73a93c5f441104927f0b7cd5fc945e7a1142b91792e9f9b72f7",
      cgkRank: 2932,
      cmcRank: 5272,
    },
    {
      chainId: 10,
      address: "0x9485aca5bbbe1667ad97c7fe7c4531a624c8b1ed",
      symbol: "agEUR",
      name: "agEUR",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/1b7cebe7-27a2-4e2b-9138-8dbef9515843.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x0576bdf5d6ffcc6230d080dd3cdf65d961781b737494bc057e700e549a5026ed",
    },
    {
      chainId: 10,
      address: "0xeeeeeb57642040be42185f49c52f7e9b38f8eeee",
      symbol: "ELK",
      name: "Elk",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/9a8687ec-9733-4b76-aaa3-015b2435f587.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x30b18a1100aa36ee8aa67ef291e12decca3ddc3e2a4b142d014e4d79e17f6475",
    },
    {
      chainId: 10,
      address: "0x920cf626a271321c151d027030d5d08af699456b",
      symbol: "KWENTA",
      name: "Kwenta",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/d7c5dcd7-d271-4732-887c-1260d9492dab.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 523,
      cmcRank: 564,
    },
    {
      chainId: 10,
      address: "0xfdb794692724153d1488ccdbe0c56c252596735f",
      symbol: "LDO",
      name: "Lido DAO Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/da9720a3-e4de-4890-97e3-f62d75e50e33.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 39,
      cmcRank: 35,
    },
    {
      chainId: 10,
      address: "0x1f32b1c2345538c0c6f582fcb022739c4a194ebb",
      symbol: "wstETH",
      name: "Lido Wrapped Staked ETH",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/056b85aa-e28e-4fc2-a08f-3635e5c514e7.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 2248,
    },
    {
      chainId: 10,
      address: "0xfdeffc7ad816bf7867c642df7ebc2cc5554ec265",
      symbol: "BeVELO",
      name: "Beefy Velo",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/1fcb0066-7b02-4180-b4c2-00931f05f0f2.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 10,
      address: "0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979",
      symbol: "fBOMB",
      name: "Fantom Bomb",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/24109/small/logo-blue.png?1646376874",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 4541,
    },
  ],
  [BASE]: [
    {
      chainId: 8453,
      address: "0x9cfb13e6c11054ac9fcb92ba89644f30775436e4",
      symbol: "axl.wstETH",
      name: "Axelar Wrapped wstETH",
      decimals: 18,
      marketCap: 19820587741.025173,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/556f49f3-169c-4789-b125-563fb30080c51699952884779.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x40899e93fa4d9b107208f2d83956ad72700ec744a80fa78200ee6eec4f15e58e",
    },
    {
      chainId: 8453,
      address: "0x4a3a6dd60a34bb2aba60d73b4c88315e9ceb6a3d",
      symbol: "MIM",
      name: "MIM",
      decimals: 18,
      marketCap: 19820587741.025173,
      logoURI: "https://assets.coingecko.com/coins/images/16786/large/mimlogopng.png?1696516358",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x40899e93fa4d9b107208f2d83956ad72700ec744a80fa78200ee6eec4f15e58e",
    },
    {
      chainId: 8453,
      address: "0x4200000000000000000000000000000000000006",
      symbol: "WETH",
      name: "Wrapped Ether",
      decimals: 18,
      marketCap: 117938234.15933998,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/2c73c292-344c-4daf-9a82-0e081b0f916b1692777707948.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 2240,
    },
    {
      chainId: 8453,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      symbol: "ETH",
      name: "Ethereum",
      decimals: 18,
      marketCap: 117938234.15933998,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/9412b9e7-161f-472e-94b2-a62d2c386ab7.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca",
      symbol: "USDbC",
      name: "USD Base Coin",
      decimals: 6,
      marketCap: 105511385.37468,
      logoURI: "https://basescan.org/token/images/usdbc_ofc_32.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      cmcRank: 2264,
    },
    {
      chainId: 8453,
      address: "0x940181a94a35a4569e4529a3cdfb74e38fd98631",
      symbol: "AERO",
      name: "Aerodrome",
      decimals: 18,
      marketCap: 70363916.3818,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/fb512643-cd38-47e7-b1a4-3d9d0bc42e1b1693380383389.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x853ee41882d37496b330318f7075c95307704538c45e32745f9e8135cb142283",
      cgkRank: 1316,
    },
    {
      chainId: 8453,
      address: "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
      symbol: "USDC.e",
      name: "USD Coin",
      decimals: 6,
      marketCap: 53899251.336,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/90bc5afa-3ea2-4cb3-8e76-9d4dff085b761693939652735.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x02fa7265e7c5d81118673727957699e4d68f74cd74b7db77da710fe8a2c7834f",
      cgkRank: 7,
      cmcRank: 7,
    },
    {
      chainId: 8453,
      address: "0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22",
      symbol: "cbETH",
      name: "Coinbase Wrapped Staked ETH",
      decimals: 18,
      marketCap: 48070656.04276,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/55f87238-fa59-42b3-8d27-edc4159e53281693005558215.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 131,
      cmcRank: 218,
    },
    {
      chainId: 8453,
      address: "0x6653dd4b92a0e5bf8ae570a98906d9d6fd2eec09",
      symbol: "RCKT",
      name: "RocketSwap",
      decimals: 18,
      marketCap: 17647408.05432,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/ad6634e2-db69-4c0f-a40d-0f4b03a966c51693636017157.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 5743,
    },
    {
      chainId: 8453,
      address: "0x27d2decb4bfc9c76f0309b8e88dec3a601fe25a8",
      symbol: "BALD",
      name: "Bald",
      decimals: 18,
      marketCap: 15040040,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/bba83106-836c-4878-a85b-20a7e6b8fe2d1693635264537.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xf26bbc67b99762d08ccf5d035997b03de8e78b04c955f7ee5c47c3c26cb9e0ce",
      cmcRank: 4376,
    },
    {
      chainId: 8453,
      address: "0x4621b7a9c75199271f773ebd9a499dbd165c3191",
      symbol: "DOLA",
      name: "Dola USD Stablecoin",
      decimals: 18,
      marketCap: 13114399.446,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/32b8ea20-0803-48ac-aa9b-b391c281d2681693985276493.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 706,
      cmcRank: 3349,
    },
    {
      chainId: 8453,
      address: "0xde5ed76e7c05ec5e4572cfc88d1acea165109e44",
      symbol: "DEUS",
      name: "DEUS",
      decimals: 18,
      marketCap: 10036000,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/d7a96415-ef02-400f-bfe4-68053c7af91f1693985235671.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xf6386798e11592e24b320a6e8e31949108a252da3b4d53540e70630e54987a60",
    },
    {
      chainId: 8453,
      address: "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
      symbol: "DAI",
      name: "Dai Stablecoin",
      decimals: 18,
      marketCap: 9399963,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/b1fbd5ca-2a9a-48cf-b35c-7d2069ce6fc31693005268649.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      cgkRank: 22,
      cmcRank: 19,
    },
    {
      chainId: 8453,
      address: "0x548f93779fbc992010c07467cbaf329dd5f059b7",
      symbol: "bmx",
      name: "BMX",
      decimals: 18,
      marketCap: 7899929.999999999,
      logoURI: "https://assets.coingecko.com/coins/images/31699/large/bmx_white.png?1694511309",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2564,
    },
    {
      chainId: 8453,
      address: "0x6c47669ce25f01e64cef604e43d8fa8c42938fb1",
      symbol: "SHIA",
      name: "SHIA",
      decimals: 18,
      marketCap: 6970207.716171388,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/c6573721-c364-4f91-9c5f-79b5ff5e792e1692800003476.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0xb79dd08ea68a908a97220c76d19a6aa9cbde4376",
      symbol: "USD+",
      name: "USD+",
      decimals: 6,
      marketCap: 6629722.694999999,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/6159e1ea-4263-4b31-9f01-201fec295efb1693635728057.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 989,
      cmcRank: 2299,
    },
    {
      chainId: 8453,
      address: "0xc1cba3fcea344f92d9239c08c0568f6f2f0ee452",
      symbol: "wstETH",
      name: "Wrapped liquid staked Ether 2.0",
      decimals: 18,
      marketCap: 5283102.376283313,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/71020f17-30dd-487f-b385-8056f017d6c41699810100901.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0x65a2508c429a6078a7bc2f7df81ab575bd9d9275",
      symbol: "DAI+",
      name: "DAI+",
      decimals: 18,
      marketCap: 3829313.9884999995,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/b25bcc5c-3efe-4e77-a09b-4249d44c22b21693985070822.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 5932,
    },
    {
      chainId: 8453,
      address: "0x9483ab65847a447e36d21af1cab8c87e9712ff93",
      symbol: "wUSDR",
      name: "Wrapped USDR",
      decimals: 9,
      marketCap: 3715427.132383686,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/3f7df6c8-9f33-4a90-8b8c-7a9db950802a1693994837395.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 4544,
    },
    {
      chainId: 8453,
      address: "0x417ac0e078398c154edfadd9ef675d30be60af93",
      symbol: "crvUSD",
      name: "Curve.Fi USD Stablecoin",
      decimals: 18,
      marketCap: 2513436.4517389126,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/42ed89d5-6f35-46bc-9539-32bcb6aed74e1694661394069.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0xbf1aea8670d2528e08334083616dd9c5f3b087ae",
      symbol: "MAI",
      name: "Mai Stablecoin",
      decimals: 18,
      marketCap: 2329245.776858,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/4ac247da-c433-41ec-9a71-d0531562a50f1693722343904.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0xfd4330b0312fdeec6d4225075b82e00493ff2e3f",
      symbol: "SDEX",
      name: "SmarDex Token",
      decimals: 18,
      marketCap: 2259176.9535714,
      logoURI:
        "https://assets.coingecko.com/coins/images/29470/large/SDEX_logo_transparent_outside_240x240.png?1696930070",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
      domainSeparator: "0xeb9923917b451b0f68590db1ead1d9ac27756c9594d65f193cca4999e0e434d9",
      cgkRank: 325,
      cmcRank: 333,
    },
    {
      chainId: 8453,
      address: "0x54016a4848a38f257b6e96331f7404073fd9c32c",
      symbol: "SCALE",
      name: "Scale",
      decimals: 18,
      marketCap: 2068298.005712,
      logoURI: "https://assets.coingecko.com/coins/images/32221/large/SCALE_icon_200x200.png?1696835640",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
    },
    {
      chainId: 8453,
      address: "0x236aa50979d5f3de3bd1eeb40e81137f22ab794b",
      symbol: "tBTC",
      name: "Base tBTC v2",
      decimals: 18,
      marketCap: 1892701.816818,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/8aada8b3-d065-4594-a4a9-24df505c1a3a1694661531184.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x506032db836c9a947f1afc2a33bff1c0d34372c45ebded52ca8a8ccb45b36b46",
      cgkRank: 548,
      cmcRank: 2294,
    },
    {
      chainId: 8453,
      address: "0xeb466342c4d449bc9f53a865d5cb90586f405215",
      symbol: "axlUSDC",
      name: "Axelar Wrapped USDC",
      decimals: 6,
      marketCap: 1616667.0947999998,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/398ee553-1929-4e2d-b5e4-f5534bbd2ed71693005326820.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x0004c43ad6dfda1dbc91908af2e9fce1d16f5982a04b2068519c74ce6cfaeae2",
      cmcRank: 2273,
    },
    {
      chainId: 8453,
      address: "0x8901cb2e82cc95c01e42206f8d1f417fe53e7af0",
      symbol: "YFX",
      name: "YieldFarming Index",
      decimals: 18,
      marketCap: 1185666.536434621,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/60317a31-daf3-44b1-a150-b32d18403c601693991177021.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0x78a087d713be963bf307b18f2ff8122ef9a63ae9",
      symbol: "BSWAP",
      name: "Baseswap Token",
      decimals: 18,
      marketCap: 1090749.478062,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/f8fccf89-0cf3-42df-996e-a3a2cd5f35ed1693634347023.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 4060,
    },
    {
      chainId: 8453,
      address: "0xbd2dbb8ecea9743ca5b16423b4eaa26bdcfe5ed2",
      symbol: "SYNTH",
      name: "Synth Token",
      decimals: 18,
      marketCap: 738530.4372,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/6d030d67-99f3-4960-8676-81ccb1fbeb9c1693636119373.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x1aa7bb4b32ddf9fad4e106faa06f9cb4b9793dcf5a7d6412cecb721cc36c0197",
      cgkRank: 2670,
      cmcRank: 4657,
    },
    {
      chainId: 8453,
      address: "0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979",
      symbol: "fBOMB",
      name: "Fantom Bomb",
      decimals: 18,
      marketCap: 686679.1077984001,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/6c7ec586-c337-4628-a271-1e6425b45fd41694429447358.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 4541,
    },
    {
      chainId: 8453,
      address: "0x4e74d4db6c0726ccded4656d0bce448876bb4c7a",
      symbol: "wblt",
      name: "Wrapped BMX Liquidity Token",
      decimals: 18,
      marketCap: 672116.9979999999,
      logoURI: "https://assets.coingecko.com/coins/images/31532/large/wBLT_200x200.png?1693786810",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x7ef856bfc7ca742d9c824bad87e40acb564f965deaaf950d25bc78bc33a18484",
      cgkRank: 2641,
    },
    {
      chainId: 8453,
      address: "0x2dad3a13ef0c6366220f989157009e501e7938f8",
      symbol: "EXTRA",
      name: "Extra Finance",
      decimals: 18,
      marketCap: 510877.30995863996,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/1caeab70-2464-4cfd-bd16-c016a1cfaf251694429562751.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1573,
      cmcRank: 4600,
    },
    {
      chainId: 8453,
      address: "0xd386a121991e51eab5e3433bf5b1cf4c8884b47a",
      symbol: "BVM",
      name: "BasedVelocimeter",
      decimals: 18,
      marketCap: 454302.82027908,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/f525748a-4a1a-41a8-b4e7-ff3a8606b8921693991475362.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 3411,
      cmcRank: 4856,
    },
    {
      chainId: 8453,
      address: "0x8ee73c484a26e0a5df2ee2a4960b789967dd0415",
      symbol: "CRV",
      name: "Curve DAO Token",
      decimals: 18,
      marketCap: 406001.4390576694,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/6ed7864e-1e2d-4af6-a1f2-ad1b401e57ea1694661741014.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0x9e1028f5f1d5ede59748ffcee5532509976840e0",
      symbol: "COMP",
      name: "Compound",
      decimals: 18,
      marketCap: 397911.30711307115,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/83272afa-ee77-4975-a395-09dd3846ec721693636665263.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0x22a2488fe295047ba13bd8cccdbc8361dbd8cf7c",
      symbol: "SONNE",
      name: "Sonne",
      decimals: 18,
      marketCap: 345717.7729776795,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/e27d85ee-1131-4006-a299-867002697beb1694429672142.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x41e5cd1bded46ecbebd82945c0d59f783a46cff093986620287dced1d8afa291",
    },
    {
      chainId: 8453,
      address: "0x1dd2d631c92b1acdfcdd51a0f7145a50130050c4",
      symbol: "ALB",
      name: "AlienBase Token",
      decimals: 18,
      marketCap: 176382.84648,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/8226867d-cb9b-4631-b96a-db26c39701de1693005396291.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x17308a01bfc3d0e2f1ea4af8811e9d8f65209c5f7b480a0a6f793b29bffd1ae1",
      cgkRank: 3019,
    },
    {
      chainId: 8453,
      address: "0x1a35ee4640b0a3b87705b0a4b45d227ba60ca2ad",
      symbol: "axlWBTC",
      name: "Axelar Wrapped WBTC",
      decimals: 8,
      marketCap: 146648.06812440002,
      logoURI: "https://assets.coingecko.com/coins/images/28173/large/wbtc-satoshi_D_3x.png?1696986732",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
      domainSeparator: "0x830918e5f8247379263e20394c701bfe6463cdb29976a859cb38416fdb8be434",
    },
    {
      chainId: 8453,
      address: "0x23ee2343b892b1bb63503a4fabc840e0e2c6810f",
      symbol: "AXL",
      name: "Axelar",
      decimals: 6,
      marketCap: 133586.37553755,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/4753a80c-389f-4cec-b988-c3cf7f8bbd231699811110983.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x686cf8d776381b7d174f434265c8a23fc02ac160eb2a80b1919675e7a5a4f27d",
      cgkRank: 107,
      cmcRank: 98,
    },
    {
      chainId: 8453,
      address: "0x9eaf8c1e34f05a589eda6bafdf391cf6ad3cb239",
      symbol: "yfi",
      name: "yearn.finance",
      decimals: 18,
      marketCap: 86903.96694015,
      logoURI: "https://assets.coingecko.com/coins/images/11849/large/yearn.jpg?1687142705",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 201,
      cmcRank: 180,
    },
    {
      chainId: 8453,
      address: "0xf7a0dd3317535ec4f4d29adf9d620b3d8d5d5069",
      symbol: "stERN",
      name: "Staked ERN Vault",
      decimals: 18,
      marketCap: 52408.766709999996,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/13f7b55f-a3ab-4116-9705-e85f7baa43bb1696253059794.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x6b3b9ad02cb3c5adf38ccf31837e756788cca6d6ea70ca6a2e5686a550ac434c",
    },
    {
      chainId: 8453,
      address: "0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc",
      symbol: "HOP",
      name: "Hop",
      decimals: 18,
      marketCap: 41313.827294304,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/2c2dad40-c4e9-41b4-95de-c3f1e82e263e1693985693950.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xb0e2a84acc31a98ed317b15ce31c35801a4e8b586eebee30b2a36415f7ef40df",
      cgkRank: 1581,
      cmcRank: 3296,
    },
    {
      chainId: 8453,
      address: "0xa334884bf6b0a066d553d19e507315e839409e62",
      symbol: "ERN",
      name: "Ethos Reserve Note",
      decimals: 18,
      marketCap: 25108.11462936,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/d4f6bdef-b616-4a28-9244-fbb29012e9131693985648123.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x7d39f9d9f2c9a02cc11f4cc75b96f350f9a23777a82c2b8c39bbe875f7b3d7c1",
      cgkRank: 1715,
      cmcRank: 2800,
    },
    {
      chainId: 8453,
      address: "0xe428c32fe2dfde4e25e9bb37c690ce22229f21ac",
      symbol: "wsFTW",
      name: "Wrapped sFTW",
      decimals: 18,
      marketCap: 11069.992007800001,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/69cbbbda-feef-49bb-bbc4-c811ac5f35fd1696253163812.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0x28fe69ff6864c1c218878bdca01482d36b9d57b1",
      symbol: "KNC",
      name: "Kyber Network Crystal v2",
      decimals: 18,
      marketCap: 8421.631616229568,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/72f65ba0-66c0-45ec-b46b-7b7ec80ca5051696326694583.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0x37defbc399e5737d53dfb5533d9954572f5b19bf",
      symbol: "BLAZE",
      name: "BLAZE",
      decimals: 9,
      marketCap: 6754.3,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/87b174c9-248c-4c9d-ad48-c0786a685b5e1695206456383.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0x41b94c5867f7f6217c9a30520cb3e793b1ee1b97",
      symbol: "TIA",
      name: "Axelar Wrapped TIA",
      decimals: 6,
      marketCap: 32.09766294445391,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/5c26b844-6021-408b-8ae5-eb37410d69751698927379106.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xe59f5317903c4cdaf48033e5959949134e2e81b0d1c0dbac145e1c1f373cc4bf",
    },
    {
      chainId: 8453,
      address: "0x34ccb1fec9e6af1b3aa6ffd737392dfa2f29843a",
      symbol: "Fren Pet",
      name: "Fren Pet",
      decimals: 18,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/922d1a89-5d0f-48f3-8a55-41ad080dc17e1693634737491.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 8453,
      address: "0x60d01ec2d5e98ac51c8b4cf84dfcce98d527c747",
      symbol: "iZi",
      name: "izumi Token",
      decimals: 18,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/7b6aea3f-15b8-4dbb-882b-85b80370c5491696935518118.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
  ],
  [ARBITRUM]: [
    {
      chainId: 42161,
      address: "0x912ce59144191c1204e64559fe8253a0e49e6548",
      symbol: "ARB",
      name: "Arbitrum",
      decimals: 18,
      marketCap: 18300000000,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/061620bb-15ab-4877-ae14-ea615e07a5291697781498049.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x81e28e6f37e32d4756bbeaf3c057b82178ac53398af819adfc680fad31b2ecd5",
      cgkRank: 44,
      cmcRank: 41,
    },
    {
      chainId: 42161,
      address: "0x60d01ec2d5e98ac51c8b4cf84dfcce98d527c747",
      symbol: "iZi",
      name: "izumi Token",
      decimals: 18,
      marketCap: 1012574810.3791153,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/ab57ca71-e7ec-47d4-95b4-01a56715a5541696935480076.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      symbol: "ETH",
      name: "Ethereum",
      decimals: 18,
      marketCap: 590187076.86,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/108fc11d-014b-4d9a-99e2-0b5ee1e44ed1.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
      symbol: "USDC.e",
      name: "USD Coin",
      decimals: 6,
      marketCap: 474872749.8,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/e6265034-bfad-4e1f-aac0-20afa09fec29.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0x08d11903f8419e68b1b8721bcbe2e9fc68569122a77ef18c216f10b3b5112c78",
    },
    {
      chainId: 42161,
      address: "0x3082cc23568ea640225c2467653db90e9250aaa0",
      symbol: "RDNT",
      name: "Radiant",
      decimals: 18,
      marketCap: 247161905.91120002,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/2d999546-78da-44a4-bf29-b1b6778ac3cf.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 312,
      cmcRank: 308,
    },
    {
      chainId: 42161,
      address: "0x09e18590e8f76b6cf471b3cd75fe1a1a9d2b2c2b",
      symbol: "AIDOGE",
      name: "ArbDoge AI",
      decimals: 6,
      marketCap: 31113390,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/5f8933f6-b5f2-445e-83b3-eb363f4334e6.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 680,
      cmcRank: 616,
    },
    {
      chainId: 42161,
      address: "0x58b9cb810a68a7f3e1e4f8cb45d1b9b3c79705e8",
      symbol: "NEXT",
      name: "Connext",
      decimals: 18,
      marketCap: 28582124.733450003,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/9799b0c8-d7e7-47ba-9483-6422927727e31693937075936.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x1a5cc4044f5fcc5e2c53ba82bd251e87f2a77e8a73e591d6fca9e5377f015141",
      cgkRank: 942,
      cmcRank: 2257,
    },
    {
      chainId: 42161,
      address: "0xaaa6c1e32c55a7bfa8066a6fae9b42650f262418",
      symbol: "RAM",
      name: "Ramses",
      decimals: 18,
      marketCap: 18078116.419448003,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/2819fc1b-55ed-4371-9a63-a181600a9a0d.webp",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2008,
      cmcRank: 4519,
    },
    {
      chainId: 42161,
      address: "0x178412e79c25968a32e89b11f63b33f733770c2a",
      symbol: "frxETH",
      name: "Frax Ether",
      decimals: 18,
      marketCap: 12046674.2,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/68aaeca7-0859-46e6-bf6d-a2fc47433116.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x2cb85de576f50ca879ec3b236c3789df95a4b71a85a4207d8ca28dc8890b6a5f",
      cgkRank: 98,
      cmcRank: 2395,
    },
    {
      chainId: 42161,
      address: "0x2bcc6d6cdbbdc0a4071e48bb3b969b06b3330c07",
      symbol: "SOL",
      name: "Wrapped SOL",
      decimals: 9,
      marketCap: 11981961.15821959,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/e0347f67-396b-478d-a874-e95d5a59dfa21700053525024.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xe496a794b4d65b8b550e61f40b25bc2a9c7648b07fd5cd45a2714554d54b64ad",
    },
    {
      chainId: 42161,
      address: "0x3f56e0c36d275367b8c502090edf38289b3dea0d",
      symbol: "MAI",
      name: "MAI",
      decimals: 18,
      marketCap: 11042082.494065,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/a80a88d3-6bd8-4588-9948-59ccebabfea1.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 841,
      cmcRank: 3386,
    },
    {
      chainId: 42161,
      address: "0x95ab45875cffdba1e5f451b950bc2e42c0053f39",
      symbol: "sfrxETH",
      name: "Staked Frax Ether",
      decimals: 18,
      marketCap: 6872689.5600000005,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/e0ef5b95-84cb-4d19-96be-cd5f75f74c8a.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xe60fdc623d14b38cf30b8ccace82d7c3fb2647a20a31a8487601189de3d19300",
      cgkRank: 124,
      cmcRank: 2866,
    },
    {
      chainId: 42161,
      address: "0xabd587f2607542723b17f14d00d99b987c29b074",
      symbol: "SDEX",
      name: "SmarDex Token",
      decimals: 18,
      marketCap: 5753256.048115199,
      logoURI:
        "https://assets.coingecko.com/coins/images/29470/large/SDEX_logo_transparent_outside_240x240.png?1696930070",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
      domainSeparator: "0xeb27a89f69acaf270c9ad332928baef3d8ed6c356a88b81bbfc36eb2017e277b",
      cgkRank: 325,
      cmcRank: 333,
    },
    {
      chainId: 42161,
      address: "0x1622bf67e6e5747b81866fe0b85178a93c7f86e3",
      symbol: "UMAMI",
      name: "Umami",
      decimals: 9,
      marketCap: 5070000,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/09de5273-356b-48f5-846c-c338fd52a7c3.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x00da32098b5ddf0d199820c9695703bfdb5326e1bc1250a2ed112f7c3cb6e3fb",
      cgkRank: 1941,
      cmcRank: 4610,
    },
    {
      chainId: 42161,
      address: "0x1debd73e752beaf79865fd6446b0c970eae7732f",
      symbol: "CBETH",
      name: "Coinbase Wrapped Staked ETH",
      decimals: 18,
      marketCap: 3818622.3567749998,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/af1c3c1a-2115-49f3-94f4-51522f5a100d.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x7126f5ba5fed3ace1c55def939e8a0644c20171ef4d745d52ce532c33d32a869",
      cgkRank: 131,
      cmcRank: 218,
    },
    {
      chainId: 42161,
      address: "0x894134a25a5fac1c2c26f1d8fbf05111a3cb9487",
      symbol: "GRAI",
      name: "Gravita Debt Token",
      decimals: 18,
      marketCap: 1691739.8550415998,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/43568a56-aec0-4414-a3fe-0d4f3909d753.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0xc19669a405067927865b40ea045a2baabbbe57f5",
      symbol: "STAR",
      name: "STAR",
      decimals: 18,
      marketCap: 1189466.0745760002,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/6bbf42d2-6ee4-46c7-befe-e814b53d9e9e1692894466739.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2103,
    },
    {
      chainId: 42161,
      address: "0x1b01514a2b3cdef16fd3c680a818a0ab97da8a09",
      symbol: "FPI",
      name: "Frax Price Index",
      decimals: 18,
      marketCap: 1092000,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/9039c3ac-c5cb-40a4-8ac8-8e36d2f748be.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x2b0fcb0fe46f72012d5cd4cb1668d1a093d0209e07e1437b9a401f0c741138c3",
      cmcRank: 8400,
    },
    {
      chainId: 42161,
      address: "0x15a808ed3846d25e88ae868de79f1bcb1ac382b5",
      symbol: "MVD",
      name: "Metavault DAO",
      decimals: 9,
      marketCap: 856160.096,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/0b695f53-d4df-4635-8362-37f29aa60e37.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x60c7604e22e2ed2266d507250af2ac822883c377bdd9954b52d755865c15f75f",
      cgkRank: 2361,
      cmcRank: 5562,
    },
    {
      chainId: 42161,
      address: "0x43c25f828390de5a3648864eb485cc523e039e67",
      symbol: "PET",
      name: "Pet Token",
      decimals: 18,
      marketCap: 572090.08209,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/9d7d1761-53a5-41c4-a0d8-0f1e13ef0ee4.jpeg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xb82e8c952e9387418c7dda707a249b3ca0aac524456dfd1606b43dc2cd58616d",
      cgkRank: 1922,
      cmcRank: 2754,
    },
    {
      chainId: 42161,
      address: "0x23ee2343b892b1bb63503a4fabc840e0e2c6810f",
      symbol: "AXL",
      name: "Axelar",
      decimals: 6,
      marketCap: 512794.19519999996,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/f25e0d05-64f2-47b9-88fc-632e795813c3.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x857e302637446c4286aaf628122ed8d70bbcdd206127d5e080509178d518e9bc",
      cgkRank: 107,
      cmcRank: 98,
    },
    {
      chainId: 42161,
      address: "0xe2105bea3819d3131b5b2306d44eb3271bfcfe0e",
      symbol: "FIEF",
      name: "Fief",
      decimals: 18,
      marketCap: 452971.66401584,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/404aefe1-2022-4066-9256-e70048b5d609.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xfc15193f745bcf7e5ad08f0603cc235daf9ac42ac6ed5ffa381342069eafe7ef",
    },
    {
      chainId: 42161,
      address: "0xb9c8f0d3254007ee4b98970b94544e473cd610ec",
      symbol: "QI",
      name: "QiDao",
      decimals: 18,
      marketCap: 419966.83428613306,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/c95f282a-1cb3-4561-bc52-ae9ae712d84a.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0x15b2fb8f08e4ac1ce019eadae02ee92aedf06851",
      symbol: "CHR",
      name: "CHRONOS",
      decimals: 18,
      marketCap: 346500.01631224,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/34e10246-6651-4728-80e1-3b459439f5d6.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 5487,
    },
    {
      chainId: 42161,
      address: "0xf50874f8246776ca4b89eef471e718f70f38458f",
      symbol: "arbs",
      name: "Arbswap",
      decimals: 18,
      marketCap: 284557.921186,
      logoURI: "https://assets.coingecko.com/coins/images/30565/large/Arb-Logo_Circle_DARK_LOGO_ONLY.png?1696529436",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
      cmcRank: 3015,
    },
    {
      chainId: 42161,
      address: "0xbc011a12da28e8f0f528d9ee5e7039e22f91cf18",
      symbol: "swETH",
      name: "swETH",
      decimals: 18,
      marketCap: 229043.72805269997,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/aab56d67-5557-4f19-952e-ffebb243c2921693940515302.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 182,
      cmcRank: 2375,
    },
    {
      chainId: 42161,
      address: "0x88dfaaabaf06f3a41d2606ea98bc8eda109abebb",
      symbol: "axlWMAI",
      name: "Axelar Wrapped WMAI",
      decimals: 18,
      marketCap: 174219.40412355892,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/ee545533-f800-425d-90f4-ac8a74d97459.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x1847f0ce23c229cd7add1e1a93eb5fef5f056efa9252c48b4e1d034b7f67cc9b",
    },
    {
      chainId: 42161,
      address: "0x9dca587dc65ac0a043828b0acd946d71eb8d46c1",
      symbol: "iFARM",
      name: "iFARM",
      decimals: 18,
      marketCap: 94103.2855,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/46408c8d-520d-4c97-b95b-ce090c1e0ec0.jpeg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x6d97f23b08b6053f2f30c3ab8f863385f87aa8740aad2d89d5c9143d6e9c97f7",
    },
    {
      chainId: 42161,
      address: "0xd5954c3084a1ccd70b4da011e67760b8e78aee84",
      symbol: "ARX",
      name: "ArbiDex Token",
      decimals: 18,
      marketCap: 83582.149678695,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/331774d1-9a5e-45f4-9f2e-0ac32e7b0d6d1695297222124.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 6034,
    },
    {
      chainId: 42161,
      address: "0x9cfb13e6c11054ac9fcb92ba89644f30775436e4",
      symbol: "axl.wstETH",
      name: "Axelar Wrapped wstETH",
      decimals: 18,
      marketCap: 39565.9294245,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/986776b7-b544-4429-831b-a8b488eac17a1693507416091.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xaf4be73354b75009da49170673bda7bb02df816fd4324d2731d3b905ec87c01a",
    },
    {
      chainId: 42161,
      address: "0x9b06f3c5de42d4623d7a2bd940ec735103c68a76",
      symbol: "Volta",
      name: "Volta Club",
      decimals: 18,
      marketCap: 21455.2027557,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/5f876d50-6f59-4aa0-95c3-f9916d19c1381693635052532.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 2633,
    },
    {
      chainId: 42161,
      address: "0xb448ec505c924944ca8b2c55ef05c299ee0781df",
      symbol: "axlKNC",
      name: "Axelar Wrapped KNC",
      decimals: 18,
      marketCap: 16565.41135232894,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/b1014750-397d-4ece-b54d-08c106da054b.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x70b888c0a1204528695141e49066cc3f489fe4b71b8970856abc2e499d502d33",
    },
    {
      chainId: 42161,
      address: "0x81de4945807bb31425362f8f7109c18e3dc4f8f0",
      symbol: "BLID",
      name: "Bolide",
      decimals: 18,
      marketCap: 10077.384540000001,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/3f01c78a-a604-43e9-8ed4-01ff444de1bf.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x6b75cb68c2c037c73faa0232851c05051115a038bfb456b25f324dac6ec81773",
    },
    {
      chainId: 42161,
      address: "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
      symbol: "BOB",
      name: "BOB",
      decimals: 18,
      marketCap: 10000.552,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/3a323481-0460-4902-80a1-bb00ee006179.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x60381d6ec544f8cd4f93f59eb45b01c5ed5c769f39f908d1632f786a0168dfe0",
      cgkRank: 2932,
      cmcRank: 5272,
    },
    {
      chainId: 42161,
      address: "0xc608dfb90a430df79a8a1edbc8be7f1a0eb4e763",
      symbol: "fETH",
      name: "Fractional ETH",
      decimals: 18,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/78da6000-73f1-4c93-b1ba-5a826aa3a20e1699565304526.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0x55380fe7a1910dff29a47b622057ab4139da42c5",
      symbol: "xETH",
      name: "Leveraged ETH",
      decimals: 18,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/20f488fe-c94c-4dad-a7a7-135d501f45b61699565374817.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0x8a011ef14a92aa37ce82a4c95004a70730b6ac38",
      symbol: "EsHMX",
      name: "Escrowed HMX",
      decimals: 18,
      marketCap: 0,
      logoURI: "",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0xfa5ed56a203466cbbc2430a43c66b9d8723528e7",
      symbol: "agEUR",
      name: "agEUR",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/f1606977-d481-49ed-8539-1f650f4cdc30.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x113b07a53197177b838e31b394805c93ece5d3a1980b84925f25dbdff58c7380",
    },
    {
      chainId: 42161,
      address: "0x93b346b6bc2548da6a1e7d98e9a421b42541425b",
      symbol: "LUSD",
      name: "Liquity USD",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/14666/large/Group_3.png?1696514341",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
      domainSeparator: "0xe8c9f0a6a4a13761b98d5003db88c57f851f40dfdc00cba66e682a27da797501",
      cgkRank: 254,
      cmcRank: 261,
    },
    {
      chainId: 42161,
      address: "0xe3b3fe7bca19ca77ad877a5bebab186becfad906",
      symbol: "sFRAX",
      name: "Staked FRAX",
      decimals: 18,
      marketCap: 0,
      logoURI:
        "https://storage.googleapis.com/ks-setting-1d682dca/c02b437e-211a-4cf4-9414-84d21d5c9d4c1703955960083.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
      domainSeparator: "0xcdd476fbdba6f65c2401ff8e1ea9e87f8d16225698e7068e17907dbcc66936c2",
    },
    {
      chainId: 42161,
      address: "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
      symbol: "MIM",
      name: "Magic Internet Money",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/784d4ce5-44ef-4cf3-88ee-1ad4f776f786.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0x5f3b7300686183f6f8df3013c5d37ebea182596964b35908b266ead50015be8b",
    },
    {
      chainId: 42161,
      address: "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
      symbol: "WBTC",
      name: "Wrapped BTC",
      decimals: 8,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/2d0f304e-0b0b-4c8b-9fc5-7d62bd5ee722.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x5a8dcb3325ce248306e60935ba6afde41646fd4e466b684babc515bce77ce7af",
      permitType: "EIP-2612",
      cgkRank: 17,
      cmcRank: 203,
    },
    {
      chainId: 42161,
      address: "0x319f865b287fcc10b30d8ce6144e8b6d1b476999",
      symbol: "CTSI",
      name: "Cartesi Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/CTSI.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xc7aa5348f205dacbc34c9a409326b3d99c88239e8c160b4e48e11abd3be44577",
      permitType: "EIP-2612",
      cgkRank: 260,
      cmcRank: 273,
    },
    {
      chainId: 42161,
      address: "0x6694340fc020c5e6b96567843da2df01b2ce1eb6",
      symbol: "STG",
      name: "StargateToken",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/STG.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0x99c409e5f62e4bd2ac142f17cafb6810b8f0baae",
      symbol: "BIFI",
      name: "beefy.finance",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/BIFI.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x1e38ca635b176feb44f746c4329a5a4bcd8e4141a357c9b2985d0b679d5dafd7",
      permitType: "EIP-2612",
      cgkRank: 736,
      cmcRank: 655,
    },
    {
      chainId: 42161,
      address: "0x68ead55c258d6fa5e46d67fc90f53211eab885be",
      symbol: "POP",
      name: "Popcorn",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/POP.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x2b43dfbc3cb53e8c75428a4ec7c2d8b0a7c0d74bb35a8f1442021a8c57ee92b5",
      permitType: "EIP-2612",
      cgkRank: 1435,
      cmcRank: 5519,
    },
    {
      chainId: 42161,
      address: "0xd74f5255d557944cf7dd0e45ff521520002d5748",
      symbol: "USDs",
      name: "Sperax USD",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/USDS.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xb867823257323749c326b07d00f5e52563d468947e16373b65004ded60afe2eb",
      permitType: "EIP-2612",
      cgkRank: 2324,
      cmcRank: 3741,
    },
    {
      chainId: 42161,
      address: "0xee9801669c6138e84bd50deb500827b776777d28",
      symbol: "O3",
      name: "O3 Swap Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/O3.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2092,
      cmcRank: 1313,
    },
    {
      chainId: 42161,
      address: "0x21e60ee73f17ac0a411ae5d690f908c3ed66fe12",
      symbol: "DERI",
      name: "Deri",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/DERI.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x68fbbdef15a70b82e31c7e8b9d7ef121f689fe620d6cf33c67aea1189d45e114",
      permitType: "EIP-2612",
      cgkRank: 1835,
      cmcRank: 1317,
    },
    {
      chainId: 42161,
      address: "0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
      symbol: "NAVI",
      name: "NAVI",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/NAVI.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 119,
      cmcRank: 104,
    },
    {
      chainId: 42161,
      address: "0x9d2f299715d94d8a7e6f5eaa8e654e8c74a988a7",
      symbol: "FXS",
      name: "Frax Share",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/bf3022e5-d7ef-44be-9726-b922dbf01001.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xc3e042a50b3cdcaf4b7f61df755a870ceae1b2896001cbeb8b3f6808e9c63864",
      permitType: "EIP-2612",
      cgkRank: 103,
      cmcRank: 92,
    },
    {
      chainId: 42161,
      address: "0x080f6aed32fc474dd5717105dba5ea57268f46eb",
      symbol: "SYN",
      name: "Synapse",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/SYN.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x63eb0b7471bfe69b3567ef2739d13e4b5f4501988ce98708e014fba6bb2cf677",
      permitType: "EIP-2612",
      cgkRank: 412,
      cmcRank: 461,
    },
    {
      chainId: 42161,
      address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
      symbol: "WETH",
      name: "Wrapped Ether",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/1eebc9df-2870-41bd-a78c-5cce81a74ffa.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xac33225466df015cad7b0e1bf31afc69a49f20d95501a98d9644ad21163469a0",
      permitType: "EIP-2612",
      cmcRank: 2240,
    },
    {
      chainId: 42161,
      address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
      symbol: "DAI",
      name: "Dai Stablecoin",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/8278aa07-a39f-47a9-a4a1-0556876bf8ee.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0x8990f9dbb6294602194fe5f05549dd56bac20e2291d6034e1ba5746495dc4d37",
      cgkRank: 22,
      cmcRank: 19,
    },
    {
      chainId: 42161,
      address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
      symbol: "USDT",
      name: "Tether USD",
      decimals: 6,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/c5cbcd57-0597-4c7b-8433-6fa98c90c5f9.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0xac9d14034394f4b1d4bb6a20191a30c20faf508b6c4670e931b954eb281b8a33",
      permitType: "EIP-2612",
    },
    {
      chainId: 42161,
      address: "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
      symbol: "MULTI",
      name: "Multichain",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://raw.githubusercontent.com/KyberNetwork/ks-assets/main/img/token/MULTI.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xa72a83a9781b45a54d6357651f83ceda76edde8abad724e595865f57f493efbf",
      cgkRank: 683,
      cmcRank: 688,
    },
    {
      chainId: 42161,
      address: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
      symbol: "USDC.e",
      name: "USD Coin ",
      decimals: 6,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0xa074269f06a6961e917f3c53d7204a31a08aec9a5f4a5801e8a8f837483b62a0",
      permitType: "EIP-2612",
    },
    {
      chainId: 42161,
      address: "0x539bde0d7dbd336b79148aa742883198bbf60342",
      symbol: "MAGIC",
      name: "MAGIC",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/18623/large/magic.png?1696518095",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
      domainSeparator: "0xaeb5495f10bc7e5c8deaa07660bbe8c30ed27b8a64be945a589bb21e3531ae18",
      cgkRank: 192,
      cmcRank: 170,
    },
    {
      chainId: 42161,
      address: "0xd4d42f0b6def4ce0383636770ef773390d85c61a",
      symbol: "SUSHI",
      name: "SushiSwap",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png?1696512101",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
      domainSeparator: "0x4ef115fb229fd2eb09f243af11960e76ca878d8aba45e22b0e00acb76bbeba05",
      cgkRank: 205,
      cmcRank: 165,
    },
    {
      chainId: 42161,
      address: "0x316772cfec9a3e976fde42c3ba21f5a13aaaff12",
      symbol: "mKNC",
      name: "Multichain Kyber Network Crystal v2",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/f67581b8-3ae4-41c6-83ce-dab18766f527.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0xe4dddfe67e7164b0fe14e218d80dc4c08edc01cb",
      symbol: "KNC",
      name: "Kyber Network Crystal v2",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/8ece55cf-ed9f-48dd-9b5c-32d96339034a.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x91776f0aa1fa00cc9b4ec5e84d9422d69b147c7a3e45822b61ee9e35f72965b2",
    },
    {
      chainId: 42161,
      address: "0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
      symbol: "LINK",
      name: "ChainLink Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/d5dcd1dd-7222-479b-89dd-6cce8487ea12.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x42684971c6f486437d3de866e7dafff06b9b8f570323c8f2dcc519e998333f97",
      cgkRank: 15,
      cmcRank: 14,
    },
    {
      chainId: 42161,
      address: "0x0c4681e6c0235179ec3d4f4fc4df3d14fdd96017",
      symbol: "RDNT",
      name: "Radiant",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/3bfe2b40-9b70-408e-8cf8-484fad4ced76.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
      symbol: "FRAX",
      name: "Frax",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/2b6f83d3-34c6-4758-947c-ee13077cc887.png",
      isWhitelisted: true,
      isStable: true,
      isStandardERC20: true,
      domainSeparator: "0x66bf3ec29abafbf17148297fbefdd3632a17654c85419b3505decc968d3aad8a",
      cgkRank: 106,
      cmcRank: 214,
    },
    {
      chainId: 42161,
      address: "0x8616e8ea83f048ab9a5ec513c9412dd2993bce3f",
      symbol: "fxUSD",
      name: "handleUSD",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/d9c82dea-152f-489a-9003-38c0e56cfb76.jpeg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0x3d9907f9a368ad0a51be60f7da3b97cf940982d8",
      symbol: "GRAIL",
      name: "Camelot Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://arbiscan.io/token/images/camelotexchange_32.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 763,
      cmcRank: 2400,
    },
    {
      chainId: 42161,
      address: "0x656b80b667a46869144047e6e6c0000c81610253",
      symbol: "ANGLE",
      name: "ANGLE_arbitrum",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/7fe8fb0d-eb70-4727-bfa7-39500a32b0eb.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xd2fda6749bbdc982bdfdefc129319974798dddd4374f9f3faf3915df994fb1ff",
    },
    {
      chainId: 42161,
      address: "0x5979d7b546e38e414f7e9822514be443a4800529",
      symbol: "wstETH",
      name: "Wrapped liquid staked Ether 2.0",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/eefe31b6-7d9c-4b31-9a3f-7e1bfe2264e2.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 2248,
    },
    {
      chainId: 42161,
      address: "0x13ad51ed4f1b7e9dc168d8a00cb3f4ddd85efa60",
      symbol: "LDO",
      name: "Lido DAO Token",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/aeab462b-5c15-44a9-b2e9-7ab937c30b2d.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x81b0c96c44a52f3c065233fbbd5b2ef65b4900f707b327e6c0385b9251b52af6",
      cgkRank: 39,
      cmcRank: 35,
    },
    {
      chainId: 42161,
      address: "0xf763fa322dc58dee588252fafee5f448e863b633",
      symbol: "SWTH",
      name: "Carbon Token",
      decimals: 8,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/4227c7e5-4d2d-4774-ad37-db22866e7d9c.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 1162,
    },
    {
      chainId: 42161,
      address: "0x18c11fd286c5ec11c3b683caa813b77f5163a122",
      symbol: "GNS",
      name: "Gains Network",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/defa480e-a84f-4c68-88e2-c654719432a4.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 264,
      cmcRank: 257,
    },
    {
      chainId: 42161,
      address: "0xeb466342c4d449bc9f53a865d5cb90586f405215",
      symbol: "axlUSDC",
      name: "Axelar Wrapped USDC",
      decimals: 6,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/a326f5d1-8fb6-4889-b3bc-c00c26e9c62c.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x6d3826532b7502fc544874e82f93738a4a4660589ef9d12a74659907c3feefbf",
      cmcRank: 2273,
    },
    {
      chainId: 42161,
      address: "0xf7728582002ef82908c8242cf552e969ba863ffa",
      symbol: "svETH",
      name: "Savvy ETH",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/31005/large/logo_%288%29.png?1696529842",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
    },
    {
      chainId: 42161,
      address: "0xeee18334c414a47fb886a7317e1885b2bfb8c2a6",
      symbol: "svBTC",
      name: "Savvy BTC",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/31004/large/logo_%287%29.png?1696529841",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
    },
    {
      chainId: 42161,
      address: "0x8ffd9f31980f26fbf4ac693678db700f1d8c51f6",
      symbol: "LSETH",
      name: "Liquid Staked ETH",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/6ebc4543-fcd6-4166-bd73-c73bb341672b.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x3bfd0a27b07c98fd8cbe25aa6880825e19106d73ac73bf13f7e4c140061beafa",
    },
    {
      chainId: 42161,
      address: "0x1ad1db091c14c4f5a988b58ac8a34fb01f672a8a",
      symbol: "CPI",
      name: "Consumer Price Index",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/d25b326f-81e4-4f84-9cb3-4449c0e3479e.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x92d41a7a00361ddd081bbc2037ca4e57757f8f1202309de041e4c32aa31fd486",
    },
    {
      chainId: 42161,
      address: "0xafdefd913286a1cec75aca503938c6bba01a5445",
      symbol: "JPG",
      name: "JPG NFT Index",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/fac1abd3-5218-46c4-af4b-01efa1f82f01.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xaba1edc483858c9d59a7b1998af7bf9c4b544de82eb4e4cf431806297ef0a80e",
      cgkRank: 3523,
    },
    {
      chainId: 42161,
      address: "0x43ab8f7d2a8dd4102ccea6b438f6d747b1b9f034",
      symbol: "SVY",
      name: "Savvy",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/31253/large/SAVVY.png?1696530077",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
      cgkRank: 2664,
      cmcRank: 5669,
    },
    {
      chainId: 42161,
      address: "0xf202ab403cd7e90197ec0f010ee897e283037706",
      symbol: "svUSD",
      name: "Savvy USD",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/31006/large/logo_%289%29.png?1696529843",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
    },
    {
      chainId: 42161,
      address: "0xd85e038593d7a098614721eae955ec2022b9b91b",
      symbol: "gDAI",
      name: "Gains Network DAI",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/4a46f42c-ab50-491e-9936-dd9a21e373ee.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
    },
    {
      chainId: 42161,
      address: "0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979",
      symbol: "fBOMB",
      name: "Fantom Bomb",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/24109/small/logo-blue.png?1646376874",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cmcRank: 4541,
    },
    {
      chainId: 42161,
      address: "0x9e758b8a98a42d612b3d38b66a22074dc03d7370",
      symbol: "SIS",
      name: "Symbiosis",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/8fa6beb8-b465-4816-94b3-cca3dc0d75d7.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x369d18990135570599c58ea4a257338ef8e85a3b1234e25d80dfb3b74ff0abe8",
      cgkRank: 950,
      cmcRank: 800,
    },
    {
      chainId: 42161,
      address: "0x4cfa50b7ce747e2d61724fcac57f24b748ff2b2a",
      symbol: "fUSDC",
      name: "Fluid USDC",
      decimals: 6,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/48773afd-ad36-46f0-9dfa-16ca91d4c6af.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0xe936828fd531a3eeef53ff40cc5fb0ba30d0e32210dae17d82826fa37fab7322",
      cmcRank: 3227,
    },
    {
      chainId: 42161,
      address: "0x0c880f6761f1af8d9aa9c466984b80dab9a8c9e8",
      symbol: "PENDLE",
      name: "Pendle",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/baff3af0-f9a7-4d4f-bd2b-87ca3d031f03.png",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x51c881379d502b27709ba0ee4b7c17e4aa2ed1cea04d32666e9c7a0665a40bf5",
      cgkRank: 338,
      cmcRank: 169,
    },
    {
      chainId: 42161,
      address: "0xeeeeeb57642040be42185f49c52f7e9b38f8eeee",
      symbol: "ELK",
      name: "Elk",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/ec4d199b-a4df-4609-b89d-7be1685dff97.svg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      domainSeparator: "0x809307dda4bbb79edac338daa73286edcbcfcf209da442f7dc6bc875c5b2a90c",
      cgkRank: 2115,
      cmcRank: 3499,
    },
    {
      chainId: 42161,
      address: "0xdb298285fe4c5410b05390ca80e8fbe9de1f259b",
      symbol: "FOREX",
      name: "handleFOREX",
      decimals: 18,
      marketCap: 0,
      logoURI: "https://storage.googleapis.com/ks-setting-1d682dca/e822f60f-5c2d-4334-8413-d37279919774.jpeg",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: true,
      cgkRank: 2311,
      cmcRank: 3977,
    },
    {
      chainId: 42161,
      address: "0x323665443cef804a3b5206103304bd4872ea4253",
      symbol: "USDV",
      name: "USDV",
      decimals: 6,
      marketCap: 0,
      logoURI: "https://assets.coingecko.com/coins/images/32948/large/usdv_%281%29.png?1699933314",
      isWhitelisted: true,
      isStable: false,
      isStandardERC20: false,
      domainSeparator: "0xc57992fc1143727981085c9708ab96a5f7dc734b4b938911779e644812eca6f6",
      cmcRank: 813,
    },
  ],
};
export default kyberTokens;
