import icbethoven from "img/dex/beethoven.png";
import icBomb from "img/dex/bombswap.png";
import icCurve from "img/dex/curve.png";
import icDeus from "img/dex/deus.png";
import icE3 from "img/dex/e3.png";
import icEqualizer from "img/dex/equalizer.png";
import icQquity from "img/dex/equity.png";
import icExcalibur from "img/dex/excalibur.png";
import icFirebird from "img/dex/firebird.png";
import icFrax from "img/dex/frax.png";
import icFusd from "img/dex/fusd.png";
import icJet from "img/dex/jetswap.png";
import icLif3 from "img/dex/lif3.png";
import icMorpheus from "img/dex/morpheus.png";
import icNavigator from "img/dex/navi.svg";
import icPaint from "img/dex/paint.png";
import icProtofi from "img/dex/protofi.png";
import icSaddle from "img/dex/saddle.png";
import icSkeleton from "img/dex/skeleton.png";
import icSkull from "img/dex/skull.png";
import icsolidly from "img/dex/solidly.png";
import icSolidlyV3 from "img/dex/solidlyv3.png";
import icSoul from "img/dex/soul.png";
import icSpartacus from "img/dex/spartacus.png";
import icSpirit from "img/dex/spirit.png";
import icSpokky from "img/dex/spooky.png";
import icSushi from "img/dex/sushi.png";
import icSynapse from "img/dex/synapse.png";
import icTomb from "img/dex/tomb.png";
import icVelocimeter from "img/dex/velocimeter.png";
import icVoodoo from "img/dex/voodoo.png";
import icWigo from "img/dex/wigo.png";
import icWoo from "img/dex/woo.png";
import icYoshi from "img/dex/yoshi.png";
import icDodo from "img/dex/dodo.png";
import icUniswap from "img/dex/uniswap.png";
import icWombat from "img/dex/wombat.png";
import icTraderjoev2 from "img/dex/traderjoe.png";
import icRamses from "img/dex/ramses.png";
import icZyber from "img/dex/zyber.png";
import icArbi from "img/dex/arbi.png";
import icBalancer from "img/dex/balancer.png";
import icChronos from "img/dex/chronos.png";
import icCamelot from "img/dex/camelot.png";
import icSolidLizard from "img/dex/solidlizard.png";
import icMorphex from "img/dex/morphex.png";
import icOPX from "img/dex/opx.png";
import icVelodrome from "img/dex/velodrome.png";
import icZip from "img/dex/zip.png";
import icNAVI from "img/dex/navi.png";
import icSolunea from "img/dex/solunea.png";
import icSterling from "img/dex/sterling.png";
import icShekel from "img/dex/shekel.jpeg";
import icDefault from "img/dex/default-dex.svg";

import icBaldex from "img/dex/baldex.png";
import icBase from "img/dex/base.png";
import icBaso from "img/dex/baso.png";
import icDackie from "img/dex/dackie.png";
import icPartical from "img/dex/partical.png";
import icRocket from "img/dex/rocket.png";
import icSwapbased from "img/dex/swapbased.png";
import icSynth from "img/dex/synth.png";
import icThrone from "img/dex/throne.png";
import icXena from "img/dex/xena.png";
import icSusd from "img/dex/susd.png";
import icAgeur from "img/dex/ageur.png";
import icGrai from "img/dex/grai.png";
import icFrxEth from "img/dex/frxeth.png";
import icMai from "img/dex/mai.png";
import icAxlWmai from "img/dex/axlwmai.png";
import icWsteth from "img/dex/wsteth.png";
import icMim from "img/dex/mim.png";
import icSeth from "img/dex/seth.svg";

import icUSDC from "img/ic_usdc_24.svg";
import icETH from "img/ic_eth_24.svg";
import { FANTOM, SONIC_TESTNET } from "./chains";
import navi from "img/dex/navi.svg";

export const TOKEN_IMG = {
  usdc: icUSDC,
  cbeth: icETH,
  frax: icFrax,
  susd: icSusd,
  ageur: icAgeur,
  grai: icGrai,
  frxeth: icFrxEth,
  mai: icMai,
  axlwmai: icAxlWmai,
  wsteth: icWsteth,
  mim: icMim,
  seth: icSeth,
};

export const DEX_IMG = {
  //FTM chain
  basedfinance: "https://next-gen.basedfinance.io/blogo.svg",
  beethovenx: icbethoven,
  bombswap: icBomb,
  curve: icCurve,
  deus: icDeus,
  deusstable: icDeus,
  e3dex: icE3,
  equalizer: icEqualizer,
  equity: icQquity,
  lif3: icLif3,
  jetswap: icJet,
  fusd: icFusd,
  fraxswap: icFrax,
  firebird: icFirebird,
  excalibur: icExcalibur,
  morpheusswap: icMorpheus,
  morphex: icMorphex,
  navigator: icNavigator,
  solidlyv3: icSolidlyV3,
  solidly: icsolidly,
  skullswap: icSkull,
  skullswapv3: icSkull,
  skeleton: icSkeleton,
  saddle: icSaddle,
  protofi: icProtofi,
  paintswap: icPaint,
  soulswap: icSoul,
  spartacusswap: icSpartacus,
  spartacus: icSpartacus,
  synapse: icSynapse,
  velocimeter: icVelocimeter,
  tombswap: icTomb,
  woofiv2: icWoo,
  woofi: icWoo,
  yoshiExchange: icYoshi,
  voodoo: icVoodoo,
  wigoswap: icWigo,

  //Abitrum chain
  arbidexv3: icArbi,
  arbidex: icArbi,
  camelot: icCamelot,
  balancer: icBalancer,
  chronos: icChronos,
  dodo: icDodo,
  navi: icNAVI,
  kyberswapelastic: "https://kyberswap.com/favicon.ico",
  level: "https://app.level.finance/logo/favicon-96x96.png",
  mmfinance:
    "https://raw.githubusercontent.com/firebird-finance/firebird-assets/master/blockchains/cronos/assets/0x97749c9B61F878a880DfE312d2594AE07AEd7656/logo.png",
  ramses: icRamses,
  ramsescl: icRamses,
  shekel: icShekel,
  solidlizard: icSolidLizard,
  solunea: icSolunea,
  sterling: icSterling,
  swapfish: "https://swapfish.fi/favicon.ico",
  uniswapv3: icUniswap,
  traderjoev2: icTraderjoev2,
  traderjoev21: icTraderjoev2,
  wombat: icWombat,
  zyberswapv3: icZyber,
  zyberswap: icZyber,
  zyberstable: icZyber,

  //Op chain
  opxfinance: icOPX,
  velodrome: icVelodrome,
  velodromev2: icVelodrome,
  zipswap: icZip,

  //Base chain
  aerodrome:
    "https://raw.githubusercontent.com/firebird-finance/firebird-assets/master/blockchains/base/assets/0x940181a94A35A4569E4529A3CDfB74e38FD98631/logo.png",
  alienbase: "https://app.alienbase.xyz/favicon.ico",
  baldex: icBaldex,
  baseswap: icBase,
  baseswapv3: icBase,
  basofinance: icBaso,
  dackieswapv3: icDackie,
  dackieswap: icDackie,
  netherfi: "https://nether.fi/favicon/favicon-96x96.png",
  particalesmoney: icPartical,
  rocketswap: icRocket,
  sushiswap: icSushi,
  sushiswapv3: icSushi,
  swapbased: icSwapbased,
  swapbasedv3: icSwapbased,
  swapbasedperps: icSwapbased,
  synthswap: icSynth,
  synthswapv3: icSynth,
  synthswapperp: icSynth,
  throne: icThrone,
  thronev2: icThrone,
  uniswapv2: icUniswap,
  xena: icXena,
  spookyswap: icSpokky,
  spiritswapv2: icSpirit,
  spiritswap: icSpirit,
  wooswap: icWoo,
  yoshiswap: icYoshi,
  bomb: icBomb,
  ramsesexchange: icRamses,
  wagmi: "https://dd.dexscreener.com/ds-data/dexes/wagmi.png",
  spookyswapv3: "https://dd.dexscreener.com/ds-data/dexes/spookyswap.png",
  dyorswap: "https://dd.dexscreener.com/ds-data/dexes/dyorswap.png",
  metropolis: "https://dd.dexscreener.com/ds-data/dexes/metropolis.png",
  mobius: "https://dd.dexscreener.com/ds-data/tokens/sonic/0xf3969639ae1f2716568edbd0420153a6709f6e7b.png?size=lg&key=606d32",
  silverswap: "https://dd.dexscreener.com/ds-data/dexes/silverswap.png",
  default: icDefault,
};
export const getDexIcon = (dexId) => {
  return DEX_IMG?.[dexId];
};
export enum PoolSource {
  //fantom
  Spookyswap = "spookyswap",
  Spiritswap = "spiritswap",
  Jetswap = "jetswap",
  Paintswap = "paintswap",
  Beethovenx = "beethovenx",
  Synapse = "synapse",
  Soulswap = "soulswap",
  Knightswap = "knightswap",
  Yoshiexchange = "yoshiexchange",
  Morpheusswap = "morpheusswap",
  Protofi = "protofi",
  Sushiswap = "sushiswap",
  Wigoswap = "wigoswap",
  Solidly = "solidly",
  Tombswap = "tombswap",
  Excalibur = "excalibur",
  Saddle = "saddle",
  Fusd = "fusd",
  Spartacus = "spartacus",
  Spiritswapv2 = "spiritswapv2",
  Spiritswapv3 = "spiritswapv3",
  Basedfinance = "basedfinance",
  Equalizer = "equalizer",
  Deusstable = "deusstable",
  Navigator = "navigator",
  Bombswap = "bombswap",
  Woofiv2 = "woofiv2",
  Skullswap = "skullswap",
  Cranium = "cranium",
  Lif3 = "lif3",
  Skullswapv3 = "skullswapv3",
  Morphex = "morphex",
  Voodoo = "voodoo",
  Equity = "equity",
  Velocimeter = "velocimeter",
  Skeleton = "skeleton",
  E3dex = "e3dex",
  Lif3v3 = "lif3v3",
  Solidlyv3 = "solidlyv3",
  Equalizerv3 = "equalizerv3",
  SilverSwap = "silverswap",
}
export const MICROSWAP_DEXES = {
  [SONIC_TESTNET]: [
    {
      name: "SpookySwap",
      icon: "https://dd.dexscreener.com/ds-data/dexes/spookyswap.png",
      id: "spookyswap",
    },
    {
      name: "SpookySwap V3",
      icon: "https://dd.dexscreener.com/ds-data/dexes/spookyswap.png",
      id: "spookyswapv3",
    },
    {
      name: "DYORSwap",
      icon: "https://dd.dexscreener.com/ds-data/dexes/dyorswap.png",
      id: "dyorswap",
    },
    {
      name: "Beethovenx",
      icon: "https://dd.dexscreener.com/ds-data/dexes/beethovenx.png",
      id: "beethovenx",
    },
    {
      name: "Sushi Swap",
      icon: "https://dd.dexscreener.com/ds-data/dexes/sushiswap.png",
      id: "sushiswap",
    },
    {
      name: "Sushi Swap V3",
      icon: "https://dd.dexscreener.com/ds-data/dexes/sushiswap.png",
      id: "sushiswapv3",
    },
    {
      name: "Equalizer",
      icon: "https://dd.dexscreener.com/ds-data/dexes/equalizer.png",
      id: "equalizer",
    },
    {
      name: "Solidly V3",
      icon: "https://dd.dexscreener.com/ds-data/dexes/solidly.png",
      id: "solidlyv3",
    },

    {
      name: "Mobius",
      icon: "https://dd.dexscreener.com/ds-data/tokens/sonic/0xf3969639ae1f2716568edbd0420153a6709f6e7b.png?size=lg&key=606d32",
      id: "mobius",
    },
    {
      name: "Metropolis",
      icon: "https://dd.dexscreener.com/ds-data/dexes/metropolis.png",
      id: "metropolis",
    },
    {
      name: "SilverSwap",
      icon: "https://dd.dexscreener.com/ds-data/dexes/silverswap.png",
      id: "silverswap",
    },
    {
      name: "Wagmi",
      icon: "https://dd.dexscreener.com/ds-data/dexes/wagmi.png",
      id: "wagmi",
    },
    {
      name: "Navigator",
      icon: navi,
      id: "navigator",
    },
    // {
    //   name: "Spirit Swap",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/spiritswap.png",
    //   id: "spiritswap",
    // },
    // {
    //   name: "Jet Swap",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/jetswap.png",
    //   id: "jetswap",
    // },
    // {
    //   name: "Paint Swap",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/paintswap.png",
    //   id: "paintswap",
    // },

    // {
    //   name: "Soul Swap",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/soulswap.png",
    //   id: "soulswap",
    // },
    // {
    //   name: "Yoshi Exchange",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/yoshiexchange.png",
    //   id: "yoshiexchange",
    // },
    // {
    //   name: "Morpheus Swap",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/morpheusswap.png",
    //   id: "morpheusswap",
    // },
    // {
    //   name: "Protofi",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/protofi.png",
    //   id: "protofi",
    // },

    // {
    //   name: "Wigo Swap",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/wigoswap.png",
    //   id: "wigoswap",
    // },
    // {
    //   name: "Solidly",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/solidly.png",
    //   id: "solidly",
    // },
    // {
    //   name: "Tomb Swap",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/tombswap.png",
    //   id: "tombswap",
    // },
    // {
    //   name: "Excalibur",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/excalibur.png",
    //   id: "excalibur",
    // },
    // {
    //   name: "Funi.Exchange",
    //   icon: getDexIcon(PoolSource.Fusd),
    //   id: "fusd",
    // },
    // {
    //   name: "Spartacus",
    //   icon: getDexIcon(PoolSource.Spartacus),
    //   id: "spartacus",
    // },
    // {
    //   name: "Spiritswap V2",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/spiritswap.png",
    //   id: "spiritswapv2",
    // },
    // {
    //   name: "Spiritswap V3",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/spiritswap.png",
    //   id: "spiritswapv3",
    // },

    // {
    //   name: "Deus Stable",
    //   icon: getDexIcon(PoolSource.Deusstable),
    //   id: "deusstable",
    // },
    // {
    //   name: "Navigator Exchange",
    //   icon: getDexIcon(PoolSource.Navigator),
    //   id: "navigator",
    // },
    // {
    //   name: "Bomb Swap",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/bombswap.png",
    //   id: "bombswap",
    // },

    // {
    //   name: "Skull Swap",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/skullswap.png",
    //   id: "skullswap",
    // },
    // {
    //   name: "Cranium",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/cranium.png",
    //   id: "cranium",
    // },
    // {
    //   name: "Lif3",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/lif3.png",
    //   id: "lif3",
    // },
    // {
    //   name: "Skullswap V3",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/skullswap.png",
    //   id: "skullswapv3",
    // },
    // {
    //   name: "Morphex",
    //   icon: getDexIcon(PoolSource.Morphex),
    //   id: "morphex",
    // },
    // {
    //   name: "Voodoo",
    //   icon: getDexIcon(PoolSource.Voodoo),
    //   id: "voodoo",
    // },
    // {
    //   name: "Equity",
    //   icon: getDexIcon(PoolSource.Equity),
    //   id: "equity",
    // },
    // {
    //   name: "Velocimeter",
    //   icon: "https://dd.dexscreener.com/ds-data/dexes/velocimeter.png",
    //   id: "velocimeter",
    // },
    // {
    //   name: "Eliteness E3",
    //   icon: getDexIcon(PoolSource.E3dex),
    //   id: "e3dex",
    // },

    // {
    //   name: "Thick",
    //   icon: "https://ftm.guru/icons/thick.png",
    //   id: "equalizerv3",
    // },
  ].sort((a, b) => a.name.localeCompare(b.name)),
};
export const DEFAULT_MICROSWAP_SELECTED_LIQ_SOURCE = {
  [SONIC_TESTNET]:
    "navigator,wagmi,beethovenx,equalizer,solidlyv3,spookyswap,spookyswapv3,sushiswap,dyorswap,sushiswap,sushiswapv3,mobius,silverswap,metropolis,",
};
