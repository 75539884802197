import TooltipComponent from "components/Tooltip/Tooltip";

import MintIc from "img/home/ic-mint.svg";
import MintIcLight from "img/home/ic-mintLight.svg";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import styled from "styled-components";
import MintModal from "./MintModal";
import bg from "img/nft/Display.png";
import { useChainId } from "lib/chains";
import { useLocation } from "react-router-dom";
import { TXT } from "../multichainTxt";

import { useWeb3React } from "@web3-react/core";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";

import NlpManager from "abis/NlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";

import { ARBITRUM, BASE, FANTOM, MUMBAI, OP } from "config/chains";
import { getContract } from "config/contracts";
import { useNaviPrice } from "domain/legacy";
import { ethers } from "ethers";
import { NLP_DECIMALS, USD_DECIMALS, getDepositBalanceData, getStakingData } from "lib/legacy";
import { switchNetwork } from "lib/wallets";
import { useThemeContext } from "contexts/ThemeProvider";
const { AddressZero } = ethers.constants;

function Banner({ isVisible, setIsVisible, connectWallet, setPendingTxns, pendingTxns }) {
  // const [isVisible, setIsVisible] = useState(false);
  const { isLightTheme, lightThemeClassName } = useThemeContext();
  const { chainId } = useChainId();
  const location = useLocation();
  const { active, library } = useWeb3React();
  const account = "0xCe52c2E8e54CC717D1B35ac730406141DDCCb47d";
  const readerAddress = getContract(chainId, "Reader");
  const nlpManagerAddress = getContract(chainId, "NlpManager");
  const banner = TXT[chainId]?.bannerImg;
  // useEffect(() => {
  //   if (isShow) {
  //     setIsVisible(true);
  //   }
  // }, [isShow]);

  const nlpAddress = getContract(chainId, "NLP");
  const usdgAddress = getContract(chainId, "USDN");
  const naviAddress = getContract(chainId, "NAVI");
  const esNaviAddress = getContract(chainId, "ES_NAVI");
  const bnNaviAddress = getContract(chainId, "BN_NAVI");
  const tokensForSupplyQuery = [naviAddress, nlpAddress, usdgAddress];
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");
  const bonusNaviTrackerAddress = getContract(chainId, "BonusNaviTracker");
  const depositTokens = [
    naviAddress,
    esNaviAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    bnNaviAddress,
    nlpAddress,
  ];

  const feeNaviTrackerAddress = getContract(chainId, "FeeNaviTracker");

  const feeNlpTrackerAddress = getContract(chainId, "FeeNlpTracker");
  const stakedNlpTrackerAddress = getContract(chainId, "StakedNlpTracker");

  const rewardTrackersForDepositBalances = [
    stakedNaviTrackerAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNlpTrackerAddress,
  ];

  const rewardTrackersForStakingInfo = [
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    stakedNlpTrackerAddress,
    feeNlpTrackerAddress,
  ];

  const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, nlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, NlpManager),
  });

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );

  const { data: depositBalances } = useSWR(
    [`StakeV2:depositBalances:${active}`, chainId, rewardReaderAddress, "getDepositBalances", account],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );
  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );
  let aum = bigNumberify(0);
  if (aums) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let nlpPrice;
  let nlpSupply;
  if (aum && totalSupplies && totalSupplies[3]) {
    nlpSupply = totalSupplies[3];
    nlpPrice =
      aum && aum.gt(0) && nlpSupply.gt(0)
        ? aum.mul(expandDecimals(1, NLP_DECIMALS)).div(nlpSupply)
        : expandDecimals(1, USD_DECIMALS);
  }
  const { naviPrice } = useNaviPrice(chainId, {}, active);

  const depositBalanceData = getDepositBalanceData(depositBalances);

  const esNaviInStakedNavi =
    depositBalanceData &&
    naviPrice &&
    depositBalanceData["esNaviInStakedNavi"].mul(naviPrice).div(expandDecimals(1, NLP_DECIMALS));
  const nlpInStakedNlp =
    depositBalanceData &&
    nlpPrice &&
    depositBalanceData["nlpInStakedNlp"].mul(nlpPrice).div(expandDecimals(1, NLP_DECIMALS));
  const totalStaked = nlpInStakedNlp && esNaviInStakedNavi && nlpInStakedNlp.add(esNaviInStakedNavi);

  const stakingData = getStakingData(stakingInfo);

  const stakedNaviTrackerRewards = stakingData && stakingData["stakedNaviTracker"].claimable;
  const feeNlpTrackerRewards = stakingData && stakingData["feeNlpTracker"].claimable;
  const stakedNlpTrackerRewards = stakingData && stakingData["stakedNlpTracker"].claimable;
  return (
    <StyledBanner className={lightThemeClassName}>
      {/* <MintModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        connectWallet={connectWallet}
        setPendingTxns={setPendingTxns}
        pendingTxns={pendingTxns}
      /> */}
      <div className="container">
        <div className="txt-banner">
          <div className="txt-intro">Effortless trading, continuous liquidity. Across Crypto, Forex, and beyond.</div>
          <div className="txt-desc">
            We ensure continuous liquidity, allowing users to engage with their assets confidently, knowing that there
            are always opportunities to transact.
          </div>
          {/* <a href="/#" className="border-btn">
            Learn More
          </a> */}
        </div>
        <div className="img-banner">
          <img alt="avt" src={TXT[chainId]?.avt} className="avt-img" />
          {/* {(chainId === ARBITRUM || chainId === BASE) && (
            <div className="nft-notavailable">
              <div className="title">NFT is only available on Fantom & Optimism</div>
              <div className="button-group">
                <button className="default-btn" onClick={() => switchNetwork(FANTOM, active)}>
                  Switch to Fantom
                </button>
                <button className="default-btn" onClick={() => switchNetwork(OP, active)}>
                  Switch to Optimism
                </button>
              </div>
            </div>
          )} */}
          {/* {chainId !== ARBITRUM && chainId !== BASE && ( */}
          <div className="avt-content">
            {" "}
            {/* <img alt="mint" className="deep-banner" src={TXT[chainId]?.bannerChain} /> */}
            <div className="title">
              Navigator Club
              {/* <img src={TXT[chainId]?.icon} alt="img" className="token" /> */}
            </div>
            <div className="desc">
              {" "}
              <img alt="avt" src={TXT[chainId]?.avt} className="avt-img-mobile" />A collection of{" "}
              {chainId === FANTOM || chainId === MUMBAI || chainId === OP ? "10,000" : "5,000"} Navigator NFT on{" "}
              {TXT[chainId]?.chainName}, creating a community centered around strong identity, values, and rewards
            </div>
            {/* <div className="actions">
              {
                chainId === OP || chainId === FANTOM || chainId === MUMBAI ? (
                  <button
                    className="btn-primary"
                    onClick={() => {
                      setIsVisible(true);
                    }}
                  >
                    {" "}
                    <img alt="mint" src={isLightTheme ? MintIcLight : MintIc} /> Mint NFT
                  </button>
                ) : null

              }
            </div> */}
            {/* {(chainId === OP || chainId === MUMBAI || chainId === FANTOM) && ( */}
            <div className="total-treasury">
              <div>
                <div className="total-title">Total treasury value</div>
                <div className="value">
                  {" "}
                  {totalStaked ? (
                    <div>{"$" + formatAmount(totalStaked, 30, 2, true)}</div>
                  ) : (
                    // <TooltipComponent
                    //   handle={`$${formatAmount(totalStaked, 30, 2, true)}`}
                    //   position="left-bottom"
                    //   className="nowrap"
                    //   renderContent={() => {
                    //     return (
                    //       <>
                    //         <StatsTooltipRow
                    //           label={`Staked NLP`}
                    //           value={formatAmount(nlpInStakedNlp, 30, 2, true)}
                    //           showDollar={true}
                    //         />
                    //         <StatsTooltipRow
                    //           label={`Staked EsNAVI`}
                    //           value={formatAmount(esNaviInStakedNavi, 30, 2, true)}
                    //           showDollar={true}
                    //         />
                    //         <div className="Tooltip-divider" />
                    //         <StatsTooltipRow
                    //           label={`Compounding Reward`}
                    //           value={formatAmount(stakedNaviTrackerRewards, 18, 2, true) + " EsNAVI"}
                    //           showDollar={false}
                    //         />
                    //         <StatsTooltipRow
                    //           label={`Pending Reward`}
                    //           value={formatAmount(stakedNlpTrackerRewards, 18, 2, true) + " EsNAVI"}
                    //           showDollar={false}
                    //         />

                    //         <StatsTooltipRow
                    //           label={``}
                    //           value={
                    //             formatAmount(feeNlpTrackerRewards, 18, 2, true) +
                    //             (chainId === FANTOM ? " WFTM" : " WETH")
                    //           }
                    //           showDollar={false}
                    //           showTwoDots={false}
                    //         />
                    //       </>
                    //     );
                    //   }}
                    // />
                    <span className="skeleton-box" style={{ width: "80px", height: "25px" }} />
                  )}
                </div>
              </div>
              {/* <button className="btn-outline">Details</button> */}
            </div>
          </div>
        </div>
      </div>
    </StyledBanner>
  );
}

const StyledBanner = styled.section`
  align-items: center;
  position: relative;
  background: url(${bg}) no-repeat 0 center;
  padding: 48px 0;
  margin-bottom: 120px;
  margin-top: -2px;
  @media screen and (max-width: 991px) {
    background-position: center 0;
    margin-bottom: 48px;
    background-size: cover;
  }
  @media screen and (max-width: 700px) {
    /* padding: 24px 0; */
    margin-bottom: 48px;
  }

  .txt-banner {
    @media screen and (max-width: 991px) {
      margin-right: 24px;
    }
    @media screen and (max-width: 700px) {
      margin-right: 0;
    }
    .border-btn {
      width: 200px;
      @media screen and (max-width: 700px) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .img-banner {
    flex-shrink: 0;
    min-width: 400px;
    width: 400px;
    padding: 32px 24px;
    border-radius: 16px;
    border: 2px solid var(--Border, rgba(55, 63, 92, 0.5));
    background: rgba(255, 255, 255, 0.13);
    backdrop-filter: blur(12px);

    .avt-img {
      width: 186px;
      height: 186px;
      margin: 0 auto 12px auto;
      display: block;
    }
    .title {
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      .token {
        width: 40px;
        height: 40px;
      }
      margin-bottom: 12px;
    }
    .desc {
      color: #828899;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      margin-bottom: 12px;
    }
    .total-treasury {
      border-radius: 16px;
      border: 1px solid var(--Border, rgba(55, 63, 92, 0.5));
      background: rgba(0, 0, 0, 0.2);
      padding: 12px;
      /* backdrop-filter: blur(35px); */
      .total-title {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 4px;
      }
      .value {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
    }
    @media screen and (max-width: 991px) {
      min-width: 255px;
      width: 255px;
      padding: 12px;
      .title {
        font-size: 16px;
        margin-bottom: 8px;
        .token {
          width: 24px;
          height: 24px;
        }
        justify-content: center;
      }
      .avt-img {
        width: 100px;
        height: 100px;
        margin-bottom: 8px;
      }
      .desc {
        font-size: 12px;

        line-height: 140%;
      }
      .total-treasury {
        padding: 8px 4px;
        text-align: center;
        .value {
          font-size: 16px;
        }
      }
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      padding: 16px;
      .desc {
        font-weight: 500;
        display: flex;
        gap: 16px;
        align-items: center;
      }
      .avt-img {
        display: none;
      }
    }
  }
  .avt-img-mobile {
    display: none;
    @media screen and (max-width: 700px) {
      display: block;
      width: 100px;
      height: 100px;
    }
  }
  .txt-intro {
    font-size: var(--Text-Display, 40px);
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 8px;
    color: #90fb75;
    @media screen and (max-width: 991px) {
      font-size: 24px;
    }
    @media screen and (max-width: 700px) {
      text-align: center;
    }
  }

  .txt-desc {
    font-size: var(--Text-Body, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 16px;
    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
    @media screen and (max-width: 700px) {
      font-size: 12px;

      text-align: center;
    }
  }
  .container {
    width: 1368px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    max-width: 100%;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      gap: 16px;
      padding: 0 16px;
    }
  }
`;

export default Banner;
