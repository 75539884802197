import { getContract } from "config/contracts";
import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount } from "lib/numbers";
import { getTokenInfo, useInfoTokens } from "domain/tokens";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDN_AMOUNT,
  getBuyNlpFromAmount,
  getSellNlpToAmount,
  importImage,
  LONG,
  PLACEHOLDER_ACCOUNT,
  SWAP,
  USD_DECIMALS,
  USDN_DECIMALS,
} from "lib/legacy";
import { getTokens, getWhitelistedTokens } from "config/tokens";
import ReaderV2 from "abis/ReaderV2.json";
import styled from "styled-components";
import { contractFetcher } from "lib/contracts";
import { DataTable, TableRow } from "components/Table";
import AssetDropdown from "pages/Dashboard/AssetDropdown";
import { useChainIdWithDefault } from "lib/chains";
import { SUPPORTED_CHAIN_ID_NSLP } from "config/chains";
import Tooltip from "components/Tooltip/Tooltip";
import ExternalLink from "components/ExternalLink/ExternalLink";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { Link } from "react-router-dom";
import { useLocalStorageByChainId } from "lib/localStorage";

const PoolCompositionNlp = ({ isBuying, nlpAmount, nlpPrice, usdgSupply, totalTokenWeights }) => {
  const { account, active, library } = useWeb3React();
  const chainId = useChainIdWithDefault({
    chains: SUPPORTED_CHAIN_ID_NSLP,
    isUseDefaultChain: false,
  });
  const [, setSwapOption] = useLocalStorageByChainId(chainId, "Swap-option-v2", LONG);

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);

  const readerAddress = getContract(chainId, "Reader");

  const tokens = getTokens(chainId);
  const tokenAddresses = tokens.map((token) => token.address);

  const { data: tokenBalances } = useSWR(
    [`NlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 5000,
    }
  );

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }
  const getWeightText = (tokenInfo) => {
    if (
      !tokenInfo.weight ||
      !tokenInfo.usdgAmount ||
      !adjustedUsdgSupply ||
      adjustedUsdgSupply.eq(0) ||
      !totalTokenWeights
    ) {
      return "...";
    }

    const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
    // use add(1).div(10).mul(10) to round numbers up
    const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10);

    const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
      targetWeightBps,
      2,
      2,
      false
    )}%`;

    return (
      <Tooltip
        handle={weightText}
        className="buy-tooltip excep"
        position="right-bottom"
        renderContent={() => {
          return (
            <>
              <StatsTooltipRow
                label={`Current Weight`}
                value={`${formatAmount(currentWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <StatsTooltipRow
                label={`Target Weight`}
                value={`${formatAmount(targetWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <div style={{ marginTop: "8px" }} />
              {currentWeightBps.lt(targetWeightBps) && (
                <div className="text-white">
                  {tokenInfo.symbol} is below its target weight.
                  <div style={{ marginTop: "8px" }} />
                  Get lower fees to buy NLP with {tokenInfo.symbol},&nbsp; and to{" "}
                  <Link
                    to="/swap"
                    onClick={() => {
                      setSwapOption(SWAP);
                    }}
                  >
                    swap
                  </Link>{" "}
                  {tokenInfo.symbol} for other tokens.
                </div>
              )}
              {currentWeightBps.gt(targetWeightBps) && (
                <div className="text-white">
                  <div>
                    {tokenInfo.symbol} is above its target weight.
                    <div style={{ marginTop: "8px" }} />
                    Get lower fees to <Link to="/swap">swap</Link> tokens for {tokenInfo.symbol}.
                  </div>
                </div>
              )}
              {/* <div style={{ marginTop: "8px" }} />
              <div>
                <ExternalLink href="https://docs.navigator.exchange/nlp">More Info</ExternalLink>
              </div> */}
            </>
          );
        }}
      />
    );
  };

  return (
    <Wrapper className="Pool-Paper Pool-Composition">
      <h3>Pool Composition</h3>
      <DataTable $noIndex $rowHeightSkeletonMobile="146.38px">
        <div className="head">
          <div className="col-name">Token</div>
          <div className="col-name">Pool</div>
          <div
            className="col-name"
            style={{
              minWidth: "160px",
            }}
          >
            Weight
          </div>
          <div className="col-name">Utilization</div>
          <div className="col-name">Available</div>
          <div className="col-name">Fees</div>
        </div>
        <div className="body">
          {visibleTokens.map((token) => {
            let tokenFeeBps;
            if (isBuying) {
              const { feeBasisPoints: feeBps } = getBuyNlpFromAmount(
                nlpAmount,
                token.address,
                infoTokens,
                nlpPrice,
                usdgSupply,
                totalTokenWeights
              );
              tokenFeeBps = feeBps;
            } else {
              const { feeBasisPoints: feeBps } = getSellNlpToAmount(
                nlpAmount,
                token.address,
                infoTokens,
                nlpPrice,
                usdgSupply,
                totalTokenWeights
              );
              tokenFeeBps = feeBps;
            }
            const tokenInfo = getTokenInfo(infoTokens, token.address);
            let managedUsd;
            if (tokenInfo && tokenInfo.managedUsd) {
              managedUsd = tokenInfo.managedUsd;
            }
            let availableAmountUsd;
            if (tokenInfo && tokenInfo.minPrice && tokenInfo.availableAmount) {
              availableAmountUsd = tokenInfo.availableAmount
                .mul(tokenInfo.minPrice)
                .div(expandDecimals(1, token.decimals));
            }
            // let balanceUsd;
            // if (tokenInfo && tokenInfo.minPrice && tokenInfo.balance) {
            //   balanceUsd = tokenInfo.balance.mul(tokenInfo.minPrice).div(expandDecimals(1, token.decimals));
            // }
            let isCapReached = tokenInfo.managedAmount?.gt(tokenInfo.maxUsdgAmount);

            let amountLeftToDeposit = bigNumberify(0);
            if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
              amountLeftToDeposit = tokenInfo.maxUsdgAmount
                .sub(tokenInfo.usdgAmount)
                .mul(expandDecimals(1, USD_DECIMALS))
                .div(expandDecimals(1, USDN_DECIMALS));
            }
            if (amountLeftToDeposit.lt(0)) {
              amountLeftToDeposit = bigNumberify(0);
            }

            let utilization = bigNumberify(0);
            if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
              utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
            }
            let maxUsdgAmount = DEFAULT_MAX_USDN_AMOUNT;
            if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
              maxUsdgAmount = tokenInfo.maxUsdgAmount;
            }
            const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

            function renderFees() {
              const swapUrl = `https://app.1inch.io/#/${chainId}/swap/`;
              switch (true) {
                case (isBuying && isCapReached) || (!isBuying && managedUsd?.lt(1)):
                  return (
                    <Tooltip
                      handle="NA"
                      position="right-bottom"
                      renderContent={() => (
                        <div className="text-white">
                          <div>
                            Max pool capacity reached for {tokenInfo.symbol}
                            <div style={{ marginTop: "8px" }} />
                            Please mint NLP using another token
                          </div>
                          <div style={{ marginTop: "8px" }} />
                          <p>
                            <ExternalLink href={swapUrl}>
                              <span> Swap {tokenInfo.symbol} on 1inch</span>
                            </ExternalLink>
                          </p>
                        </div>
                      )}
                    />
                  );
                case (isBuying && !isCapReached) || (!isBuying && managedUsd?.gt(0)):
                  return `${formatAmount(tokenFeeBps, 2, 2, true, "-")}${
                    tokenFeeBps !== undefined && tokenFeeBps.toString().length > 0 ? "%" : ""
                  }`;
                default:
                  return "";
              }
            }

            return (
              <TableRow key={token.symbol} noScaleOnHover>
                <div
                  d-label="Token"
                  className="col-body"
                  style={{
                    minWidth: "160px",
                  }}
                >
                  <div className="token-symbol-wrapper">
                    <div className="App-card-title-info-icon">
                      <img src={tokenImage} alt="USDC.e" width="40px" />
                    </div>
                    <div className="App-card-title-info-text">
                      <div className="App-card-info-subtitle">
                        {token.symbol}{" "}
                        <div className="dropdown-clickable">
                          <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                        </div>
                      </div>
                      <div className="App-card-info-title">{token.name}</div>
                    </div>
                  </div>
                </div>
                <div d-label="Pool" className="col-body">
                  {tokenInfo?.managedUsd ? (
                    <Tooltip
                      handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              label={`Pool Amount`}
                              value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 4, true)} ${
                                token.symbol
                              }`}
                              showDollar={false}
                            />
                            <StatsTooltipRow
                              label={`Target Min Amount`}
                              value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 4, true)} ${
                                token.symbol
                              }`}
                              showDollar={false}
                            />
                            <StatsTooltipRow
                              label={`Max ${tokenInfo.symbol} Capacity`}
                              value={formatAmount(maxUsdgAmount, 18, 0, true)}
                              showDollar={true}
                            />
                          </>
                        );
                      }}
                    />
                  ) : (
                    <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  )}
                </div>
                <div
                  d-label="Weight"
                  className="col-body"
                  style={{
                    minWidth: "160px",
                  }}
                >
                  {getWeightText(tokenInfo) !== "..." ? (
                    getWeightText(tokenInfo)
                  ) : (
                    <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  )}
                </div>
                <div d-label="Utilization" className="col-body">
                  {formatAmount(utilization, 2, 2, false) + "%"}
                </div>
                <div d-label="Available" className="col-body">
                  {isBuying && (
                    <div>
                      {!amountLeftToDeposit ? (
                        <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                      ) : (
                        <Tooltip
                          handle={
                            amountLeftToDeposit && amountLeftToDeposit.lt(0)
                              ? "$0.00"
                              : `$${formatAmount(amountLeftToDeposit, USD_DECIMALS, 2, true)}`
                          }
                          position="right-bottom"
                          tooltipIconPosition="right"
                          renderContent={() => getTooltipContent(managedUsd, tokenInfo, token)}
                        />
                      )}
                    </div>
                  )}
                  {!isBuying && (
                    <div>
                      {formatAmount(availableAmountUsd, USD_DECIMALS, 2, true) === "--" ? (
                        <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                      ) : (
                        <Tooltip
                          handle={
                            availableAmountUsd && availableAmountUsd.lt(0)
                              ? "$0.00"
                              : `$${formatAmount(availableAmountUsd, USD_DECIMALS, 2, true)}`
                          }
                          position="right-bottom"
                          tooltipIconPosition="right"
                          renderContent={() => getTooltipContent(managedUsd, tokenInfo, token)}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div d-label="Fees" className="col-body">
                  {renderFees()}
                </div>
              </TableRow>
            );
          })}
        </div>
      </DataTable>
    </Wrapper>
  );
};

function getTooltipContent(managedUsd, tokenInfo, token) {
  return (
    <>
      <StatsTooltipRow
        label={`Current Pool Amount`}
        value={[
          `$${formatAmount(managedUsd, USD_DECIMALS, 0, true)}`,
          `(${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${token.symbol})`,
        ]}
      />
      <StatsTooltipRow label={`Max Pool Capacity`} value={formatAmount(tokenInfo.maxUsdgAmount, 18, 0, true)} />
    </>
  );
}
const Wrapper = styled.div`
  @media screen and (max-width: 1024px) {
    .body {
      .body-row {
        border-radius: 16px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .body {
      gap: 12px;
    }
  }
`;
export default PoolCompositionNlp;
