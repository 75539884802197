import { CRYPTO, FOREX } from "config/tokens";
import { getTokenSymbolFromString } from "domain/tokens";
import { Result } from "hooks/useOpenInterestStats";
import { sumBy } from "lodash";
import { FC, useMemo } from "react";
import styled from "styled-components";
import GenericChart from "../GenericChart";
import { FANTOM } from "config/chains";
import { ACTIVE_CHAIN_IDS_V2 } from "lib/legacy";

interface Props {
  chainId: number;
  data: Result | undefined;
  originData: any;
  isLightTheme?: boolean;
}

type ChartData = {
  title: string;
  data: {
    name: string;
    value: number;
    key: string;
  }[];
};

function getSumAsset(data: Result, key: "crypto" | "metals" | "forex") {
  return sumBy(
    Object.values(data).filter((d) => d.type === key),
    (o) => Number(o.value)
  );
}

const StatPieCharts: FC<Props> = ({ chainId, data, originData, isLightTheme }) => {
  const pieChartDatas = useMemo(() => {
    if (data) {
      //Open Interest Composition
      const composition: ChartData = {
        title: "Open Interest Composition",
        data: [],
      };

      composition.data = [
        {
          name: "Crypto",
          value: getSumAsset(data, "crypto"),
          key: "Crypto",
        },
        {
          name: "Metals",
          value: getSumAsset(data, "metals"),
          key: "Metals",
        },
        {
          name: "Forex",
          value: getSumAsset(data, "forex"),
          key: "Forex",
        },
      ];

      // Open Interest (Crypto)
      const openInterestCrypto: ChartData = {
        title: "Open Interest (Crypto)",
        data: [],
      };
      openInterestCrypto.data = Object.entries(originData)
        .filter(([k]) => k.includes("USD") && CRYPTO.includes(getTokenSymbolFromString(k)))
        .map(([key, value]) => ({
          name: key,
          value: Number(value),
          key,
        }));

      // Open Interest (Forex)
      const openInterestForex: ChartData = {
        title: "Open Interest (Forex)",
        data: [],
      };
      openInterestForex.data = Object.entries(originData)
        .filter(([k]) => k.includes("USD") && FOREX.includes(getTokenSymbolFromString(k)))
        .map(([key, value]) => ({
          name: key,
          value: Number(value),
          key,
        }));

      return {
        composition,
        openInterestCrypto,
        openInterestForex,
      };
    }
  }, [data, originData]);

  return (
    <CircleChartContainer className="chart-3-columns">
      {pieChartDatas &&
        Object.entries(pieChartDatas).map(([key, { data, title }]) => {
          return (
            <div className="cricle-chart-cell" key={key}>
              <GenericChart
                title={title}
                type="Pie"
                data={ACTIVE_CHAIN_IDS_V2.includes(chainId) ? data : []}
                isLightTheme={isLightTheme}
              />
            </div>
          );
        })}
    </CircleChartContainer>
  );
};

const CircleChartContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  .cricle-chart-cell {
    padding: 24px;
    background: #12141a;
    border-radius: 16px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 600px) {
    gap: 16px;
    /* .cricle-chart-cell {
      padding: 16px;
    } */
  }
`;

export default StatPieCharts;
