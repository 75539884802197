import React from "react";
import { Trans } from "@lingui/macro";
import Footer from "components/Footer/Footer";
import "./Buy.css";
// import TokenCard from "components/TokenCard/TokenCard";
// import buyNAVIIcon from "img/buy_navi.svg";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import TokenCards from "components/TokenCards/TokenCards";

export default function BuyNAVINLP({ chainId, active, library }) {
  return (
    <SEO title={getPageTitle("Buy NAVI or NLP")}>
      <div className="BuyNAVINLP default-container page-layout" style={{ paddingTop: 0 }}>
        <TokenCards chainId={chainId} active={active} library={library} />
        {/* <div className="BuyNAVINLP-container default-container">
          <div className="section-title-block">
            <div className="section-title-icon">
              <img src={buyNAVIIcon} alt="buyNAVIIcon" />
            </div>
            <div className="section-title-content" style={{ width: "100%" }}>
              <div className="Page-title">
                <span>Buy NAVI or NLP</span>
              </div>
            </div>
          </div>
          <TokenCard />
        </div> */}
        <Footer />
      </div>
    </SEO>
  );
}
