import React from "react";
import cx from "classnames";

import { DataTable, DateAndTime, PositionHead, TableRow } from "../../helper/table";

import { formatAmount, formatAmountInDollar } from "lib/numbers";

import icView from "img/ic-view.svg";
import { useThemeContext } from "contexts/ThemeProvider";
import { formatClosedPosition } from "../../helper";
import styled from "styled-components";
const ClosedPositions = ({ positions, chainId, assets, isEmpty }) => {
  const { lightThemeClassName } = useThemeContext();

  const formattedPositions = positions.map((position) => {
    return formatClosedPosition(position, assets);
  });

  return (
    <StyledDataTable $noIndex $autoHeight $noHeading={isEmpty} className={lightThemeClassName}>
      <div className="head">
        <div className="col-name">Position</div>
        <div className="col-name">Opened on</div>
        <div className="col-name">Closed on</div>
        <div className="col-name">Duration</div>
        <div className="col-name">PnL/ROI</div>
        <div className="col-name">Avg. Entry Price</div>
        <div className="col-name">Exit Price</div>
        <div className="col-name">Pos. Size</div>
      </div>
      <div className={`body ${formattedPositions?.length === 1 ? "body-single-row" : ""}`}>
        {formattedPositions.map((position, i) => {
          const isV2 = position.type === "V2";
          return (
            <TableRow key={position.key || i} to={`/analytics/position/${position.id}`}>
              <div d-label="Position" className="col-body">
                <PositionHead
                  position={position}
                  isV2={isV2}
                  asset={isV2 ? assets[position.indexToken.id] : undefined}
                  noFlag
                />
              </div>
              <div d-label="Opened on" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={position.openTime} />
                </div>
              </div>
              <div d-label="Closed on" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={position.closedTime} />
                </div>
              </div>
              <div d-label="Duration" className="col-body">
                {position.duration}
              </div>
              <div d-label="PnL/ROI" className="col-body">
                <div className="col-body-content">
                  <div
                    className={cx("", {
                      positive: !position.realisedPnl.lt(0),
                      negative: position.realisedPnl.lt(0),
                    })}
                  >
                    {formatAmountInDollar(position.realisedPnl, 30, 2, true, {
                      showPlus: true,
                    })}
                  </div>
                  <div className="muted fz-12">{position.roiText}</div>
                </div>
              </div>
              <div d-label="Avg. Entry Price" className="col-body">
                {formatAmountInDollar(position.averagePrice, 30, position.displayDecimals, true)}
              </div>
              <div d-label="Exit Price" className="col-body">
                {formatAmountInDollar(position.markPrice, 30, position.displayDecimals, true)}
              </div>
              <div d-label="Pos. Size" className="col-body">
                <div className="col-body-content">
                  <div> ${formatAmount(position.size, 30, 2, true)}</div>
                  <div className="muted fz-12">
                    {formatAmount(position.quantity, 30, 4, true)} {position.symbol}
                  </div>
                </div>
              </div>
              <img className="icon-view" src={icView} alt="view_ic" />
            </TableRow>
          );
        })}
      </div>
    </StyledDataTable>
  );
};
const StyledDataTable = styled(DataTable)`
  .muted.fz-12 {
    font-size: 12px;
    font-weight: 700 !important;
  }
  .body {
    @media screen and (max-width: 1023px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 8px;
      gap: 24px;
      &.body-single-row {
        grid-template-columns: 1fr;
      }
      .col-body-content {
        flex-direction: row !important;
        gap: 4px;
      }
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
      margin-top: 0;
      gap: 16px;
    }
  }
  .body .body-row {
    background: #1a1d26;
    padding: 10px 16px;
    @media screen and (max-width: 991px) {
      border-radius: 16px;
    }
    .position-head img {
      width: 32px;
      height: 32px;
    }
  }
  .col-body {
    font-weight: 500;
  }
`;

export default ClosedPositions;
