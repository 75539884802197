import { getContract } from "config/contracts";
import { ethers } from "ethers";
import { callContract, contractFetcher } from "lib/contracts";
import { useState } from "react";
import useSWR from "swr";
import Vester from "abis/Vester.json";
import MigrateSign from "abis/MigrateSign.json";
import RewardTracker from "abis/RewardTracker.json";
import Modal from "./Modal";
import styled, { css } from "styled-components";
import { ValidationRow } from "pages/BeginAccountTransfer/BeginAccountTransfer";
import { is } from "date-fns/locale";
import { importImage } from "lib/legacy";
const NaviMigrateDetailsModal = ({
  chainId,
  active,
  library,
  account,
  isVisible,
  setIsVisible,
  setPendingTxns,
  pendingTxns,
}) => {
  let parsedReceiver = ethers.constants.AddressZero;
  const [receiver, setReceiver] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAccountValid, setIsAccountValid] = useState(true);
  if (ethers.utils.isAddress(receiver)) {
    parsedReceiver = receiver;
  }
  const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");
  const { data: cumulativeNaviRewards } = useSWR(
    [
      `cumulativeNaviRewards:${[active, parsedReceiver]}`,
      chainId,
      stakedNaviTrackerAddress,
      "cumulativeRewards",
      parsedReceiver,
    ],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 10000,
    }
  );
  const { data: cumulativeNaviRewardsUser } = useSWR(
    [`cumulativeNaviRewardsUser:${[active, account]}`, chainId, stakedNaviTrackerAddress, "cumulativeRewards", account],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 10000,
    }
  );

  const stakedNlpTrackerAddress = getContract(chainId, "StakedNlpTracker");
  const MigrateSignAddress = getContract(chainId, "MigrateSign");
  const { data: cumulativeNlpRewards } = useSWR(
    [
      `cumulativeNlpRewards:${[active, parsedReceiver]}`,
      chainId,
      stakedNlpTrackerAddress,
      "cumulativeRewards",
      parsedReceiver,
    ],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 10000,
    }
  );
  const { data: cumulativeNlpRewardsUser } = useSWR(
    [`cumulativeNlpRewardsUser:${[active, account]}`, chainId, stakedNlpTrackerAddress, "cumulativeRewards", account],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 10000,
    }
  );

  const stakedNslpTrackerAddress = getContract(chainId, "StakedNslpTracker");
  const { data: cumulativeNslpRewards } = useSWR(
    [
      `cumulativeNslpRewards:${[active, parsedReceiver]}`,
      chainId,
      stakedNslpTrackerAddress,
      "cumulativeRewards",
      parsedReceiver,
    ],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 10000,
    }
  );
  const { data: cumulativeNslpRewardsUser } = useSWR(
    [`cumulativeNslpRewardsUser:${[active, account]}`, chainId, stakedNslpTrackerAddress, "cumulativeRewards", account],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 10000,
    }
  );
  const naviVesterAddress = getContract(chainId, "NaviVester");
  const nlpVesterAddress = getContract(chainId, "NlpVester");
  const nslpVesterAddress = getContract(chainId, "NslpVester");
  const { data: transferredCumulativeNaviRewards } = useSWR(
    [
      `transferredCumulativeNaviRewards:${[active, parsedReceiver]}`,
      chainId,
      naviVesterAddress,
      "transferredCumulativeRewards",
      parsedReceiver,
    ],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 10000,
    }
  );

  const { data: transferredCumulativeNlpRewards } = useSWR(
    [
      `transferredCumulativeNlpRewards:${[active, parsedReceiver]}`,
      chainId,
      nlpVesterAddress,
      "transferredCumulativeRewards",
      parsedReceiver,
    ],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 10000,
    }
  );

  const { data: transferredCumulativeNslpRewards } = useSWR(
    [
      `transferredCumulativeNslpRewards:${[active, parsedReceiver]}`,
      chainId,
      nslpVesterAddress,
      "transferredCumulativeRewards",
      parsedReceiver,
    ],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 10000,
    }
  );

  const { data: transferredCumulativeNaviRewardsUser } = useSWR(
    [
      `transferredCumulativeNaviRewardsUser:${[active, account]}`,
      chainId,
      naviVesterAddress,
      "transferredCumulativeRewards",
      account,
    ],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 10000,
    }
  );

  const { data: transferredCumulativeNlpRewardsUser } = useSWR(
    [
      `transferredCumulativeNlpRewards:${[active, account]}`,
      chainId,
      nlpVesterAddress,
      "transferredCumulativeRewards",
      account,
    ],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 10000,
    }
  );

  const { data: transferredCumulativeNslpRewardsUser } = useSWR(
    [
      `transferredCumulativeNslpRewards:${[active, account]}`,
      chainId,
      nslpVesterAddress,
      "transferredCumulativeRewards",
      account,
    ],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 10000,
    }
  );

  const hasStakedNavi =
    (cumulativeNaviRewards && cumulativeNaviRewards.gt(0)) ||
    (transferredCumulativeNaviRewards && transferredCumulativeNaviRewards.gt(0));
  const hasStakedNlp =
    (cumulativeNlpRewards && cumulativeNlpRewards.gt(0)) ||
    (transferredCumulativeNlpRewards && transferredCumulativeNlpRewards.gt(0));
  const hasStakedNslp =
    (cumulativeNslpRewards && cumulativeNslpRewards.gt(0)) ||
    (transferredCumulativeNslpRewards && transferredCumulativeNslpRewards.gt(0));
  const isReceiverValid = !hasStakedNavi && !hasStakedNlp && !hasStakedNslp;

  const hasStakedNaviUser =
    (cumulativeNaviRewardsUser && cumulativeNaviRewardsUser.gt(0)) ||
    (transferredCumulativeNaviRewardsUser && transferredCumulativeNaviRewardsUser.gt(0));
  const hasStakedNlpUser =
    (cumulativeNlpRewardsUser && cumulativeNlpRewardsUser.gt(0)) ||
    (transferredCumulativeNlpRewardsUser && transferredCumulativeNlpRewardsUser.gt(0));
  const hasStakedNslpUser =
    (cumulativeNslpRewardsUser && cumulativeNslpRewardsUser.gt(0)) ||
    (transferredCumulativeNslpRewardsUser && transferredCumulativeNslpRewardsUser.gt(0));
  const isReceiverUserValid = !hasStakedNaviUser && !hasStakedNlpUser && !hasStakedNslpUser;
  const handleMigrateData = async () => {
    let method = isReceiverUserValid ? "claim" : "signalTransfer";
    const contract = new ethers.Contract(MigrateSignAddress, MigrateSign.abi, library.getSigner());
    setLoading(true);
    callContract(chainId, contract, method, method === "claim" ? [] : [receiver], {
      successMsg: `Migrate completed`,
      sentMsg: `Migrate submitted!`,
      failMsg: `Migrate failed.`,
      setPendingTxns,
      pendingTxns,
    }).finally(() => {
      setLoading(false);
    });
  };
  const isButtonDisabled = () => {
    if (loading) return true;
    if (!isReceiverUserValid && receiver && !ethers.utils.isAddress(receiver)) return true;
    if (!isReceiverUserValid && receiver && !isReceiverUserValid) return true;
    return false;
  };
  const getPrimaryText = () => {
    if (loading) {
      return isReceiverUserValid ? "Claiming..." : "Requesting...";
    }
    return isReceiverUserValid ? "Claim" : "Request Transfer";
  };
  return (
    <ModalWrapper>
      <Modal label="EsNAVI Migration" isVisible={isVisible} setIsVisible={setIsVisible} className={`wrong-modal`}>
        <div>You have esNAVI tokens that migrated from Mummy.</div>
        <div className="tokens-container">
          <img alt="" src={importImage("ic_mmy_24.svg")} className="token" />
          <img alt="" src={importImage("migrate.svg")} className="arrow" />
          <img alt="" src={importImage("ic_navi_24.svg")} className="token" />
        </div>
        <ActionWrapper>
          <div className="btns-wrapper">
            {isReceiverUserValid ? (
              <></>
            ) : (
              <>
                <div className="input-container">
                  <input value={receiver} onChange={(e) => setReceiver(e.target.value)} placeholder="Receiver" />
                </div>
                {receiver && ethers.utils.isAddress(receiver) && (
                  <>
                    <div className="BeginAccountTransfer-validations">
                      <ValidationRow isValid={!hasStakedNavi}>
                        <span>Receiver has not staked NAVI tokens before</span>
                      </ValidationRow>
                      <ValidationRow isValid={!hasStakedNlp}>
                        <span>Receiver has not staked NLP tokens before</span>
                      </ValidationRow>
                      <ValidationRow isValid={!hasStakedNslp}>
                        <span>Receiver has not staked NSLP tokens before</span>
                      </ValidationRow>
                    </div>
                  </>
                )}
                {receiver && !ethers.utils.isAddress(receiver) && (
                  <>
                    <div className="BeginAccountTransfer-validations">
                      <ValidationRow isValid={false}>
                        <span>Receiver is not a valid address</span>
                      </ValidationRow>
                    </div>
                  </>
                )}
              </>
            )}
            <button className="default-btn full" onClick={handleMigrateData} disabled={isButtonDisabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </ActionWrapper>
      </Modal>
    </ModalWrapper>
  );
};

export default NaviMigrateDetailsModal;
const ModalWrapper = styled.div`
  font-size: 14px;
  .tokens-container {
    margin: 24px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 12px;
    .token {
      width: 60px;
      height: 60px;
    }
    .arrow {
      height: 24px;
    }
  }
  .input-container {
    width: 100%;
    padding: 16px;
    border-radius: 100px;
    background: var(--Nature-3, #11121b);
    input {
      padding: 0 !important;
      width: 100%;
      font-size: 14px !important;
    }
  }
  .BeginAccountTransfer-validations {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 0;
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  .btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: stretch;
  }

  & > img {
    height: 160px;
  }
`;
