import { ApolloClient, HttpLink, InMemoryCache, gql } from "@apollo/client";
import { FANTOM, OP } from "config/chains";
import {
  CRYPTO,
  CRYPTO_ANALYTICS,
  FOREX,
  METALS,
  TOKEN_SHOWS_DECIMALS,
  TOKEN_WHITE_LIST,
  TOKEN_WHITE_LIST_ANALYTICS,
} from "config/tokens";
import { getTokenSymbolFromString } from "domain/tokens";
import { formatNumber } from "lib/numbers";
import { chain, maxBy, minBy, sortBy, merge } from "lodash";
import { getAdditionalTokenByAddress, getTokenByAddress } from "./tokenHelper";
export const NOW_TS = parseInt(Date.now() / 1000);
export const FIRST_DATE_TS = 1734393600;
export const FIRST_DATE_FANTOM_V2 = 1734393600;
export const FIRST_DATE_OP_V2 = 1734393600;
export const MOVING_AVERAGE_DAYS = 7;
export const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS;
export const START_DATE_V2 = {
  [FANTOM]: FIRST_DATE_FANTOM_V2,
  [OP]: FIRST_DATE_OP_V2,
};
export const CHART_COLORS = [
  "#F99292", //0
  "#FBF693", //1
  "#F06292",
  "#D39CFB", //3
  "#52BCA3", //4
  "#2F8AC4", //5
  "#66C5CC", //6
  "#F70F0F", //7
  "#F89C74", //8
  "#F2B701", //9
  "#22C761", //10
  "#DCB0F2", //11
  "#96F1F5",
  "#909090", //12
  "#FEB13D", //13
  "#21EDA8", //15
  "#D84315",
  "#00ACC1",
  "#FF6D00",
  "#2962FF",
  "#00C853",
  "#D500F9",
  "#7C4DFF",
  "#009688",
  "#FF8A65",
  "#9FA8DA",
  "#764E9F", //2

  "#FFB74D",
];
function fillNa(arr) {
  const prevValues = {};
  let keys;
  if (arr.length > 0) {
    keys = Object.keys(arr[0]);
    delete keys.timestamp;
    delete keys.id;
  }

  for (const el of arr) {
    for (const key of keys) {
      if (!el[key]) {
        if (prevValues[key]) {
          el[key] = prevValues[key];
        }
      } else {
        prevValues[key] = el[key];
      }
    }
  }
  return arr;
}

function getChainSubgraph(chainName) {
  return chainName === "fantom"
    ? "navigator-fantom-stats"
    : chainName === "optimism"
    ? "navigator-op-stats"
    : chainName === "base"
    ? "navigator-base-stats"
    : chainName === "sonic"
    ? "navigator-sonic-stats"
    : "navigator-arb-stats";
}
export const getV1SubgraphData = ({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "sonic" } = {}) => {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const PROPS_HOURLY_FEE = "marginAndLiquidation swap mint burn".split(" ");
  const timestampProp =
    chainName === "fantom" ||
    chainName === "optimism" ||
    chainName === "arbitrum" ||
    chainName === "base" ||
    chainName === "sonic"
      ? "id"
      : "timestamp";
  const query = `{
      volumeStats(
        first: 1000,
        orderBy: ${timestampProp},
        orderDirection: desc
        where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
        subgraphError: allow
      ) {
        ${timestampProp}
        ${PROPS.join("\n")}
      }
      nlpStatsFantom: nlpStats(
        first: 1000
        orderBy: ${timestampProp}
        orderDirection: desc
        where: {
          period: daily
          ${timestampProp}_gte: ${from}
          ${timestampProp}_lte: ${to}
        }
        subgraphError: allow
      ) {
        ${timestampProp}
        aumInUsdn
        nlpSupply
        distributedUsd
        distributedEth
      }
      naviStats: naviStats(
        first: 1000
        orderBy: ${timestampProp}
        orderDirection: asc
        where: {
          period: daily
          ${timestampProp}_gte: ${from}
          ${timestampProp}_lte: ${to}
        }
        subgraphError: allow
      ) {
        ${timestampProp}
        stakedAmount
        period
      }
      nlpStatsAllTime: nlpStats(
        first: 1000
        orderBy: ${timestampProp}
        orderDirection: desc
        where: {
          period: daily
          ${timestampProp}_gte: ${from}
          ${timestampProp}_lte: ${to}
        }
        subgraphError: allow
      ) {
        ${timestampProp}
        aumInUsdn
        nlpSupply
        distributedUsd
        distributedEth
      }
      tradingStats(
        first: 1000
        orderBy: timestamp
        orderDirection: desc
        where: { period: daily, timestamp_gte: ${from}, timestamp_lte: ${to} }
        subgraphError: allow
      ) {
        timestamp
        profit
        loss
        profitCumulative
        lossCumulative
        longOpenInterest
        shortOpenInterest
      }
      userStats(
        first: 1000
        orderBy: timestamp
        orderDirection: desc
        where: { period: daily, timestamp_gte: ${from - 86400}, timestamp_lte: ${to} }
        subgraphError: allow
      ) {
        uniqueCount
        uniqueSwapCount
        uniqueMarginCount
        uniqueMintBurnCount
        uniqueCountCumulative
        uniqueSwapCountCumulative
        uniqueMarginCountCumulative
        uniqueMintBurnCountCumulative
        actionCount
        actionSwapCount
        actionMarginCount
        actionMintBurnCount
        timestamp
      }
      userTradesStats: userStats(
        where: {period: daily, timestamp_gte: ${from}, timestamp_lte: ${to}}
        orderBy: id
        orderDirection: desc
        ) {
        period
        timestamp
        id
        actionCount
        actionMarginCount
      }
      userTradesStats24h:
      userStats(
        where: {period: daily, timestamp_gte: ${from}, timestamp_lte: ${to}}
        orderBy: id
        orderDirection: desc
        ) {
        period
        timestamp
        actionMarginCount
        id
        uniqueMarginCountCumulative
        uniqueMintBurnCountCumulative
        uniqueSwapCountCumulative
      }
      tokenStats(
        orderBy: timestamp
        orderDirection: asc
        where: {period: daily, timestamp_gte: ${from}, timestamp_lte: ${to}}
        skip: 0
        first: 1000
      ) {
        poolAmountUsd
        timestamp
        token
      }

    }`;
  // const subgraph = getChainSubgraph(chainName);
  const subgraphUrl = `https://api.navigator.exchange/subgraph/sonic/query/stats`;
  const querySource = gql(query);
  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraphUrl, fetch }),
    cache: new InMemoryCache(),
  });
  return client.query({ query: querySource });
};
export const getV2SubgraphData = ({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "sonic" } = {}) => {
  const query = `{
       dailyInfos(where: {timestamp_gte: ${from}, timestamp_lte: ${to}}) {
        volumes
        users
        tradingFees
        trades
        totalVolume
        totalFees
        timestamp
        shortOI
        pnls
        newUsers
        mintVolume
        mintFees
        marginTradingVolume
        longOI
        liquidations
        fees
        burnVolume
        id
        burnFees
        lossPnl
        winPnl
      }
    }`;
  // const subgraph = getChainSubgraph(chainName);
  const subgraphUrl = `https://api.studio.thegraph.com/query/98833/sonic-exchange-core/version/latest`;
  const querySource = gql(query);
  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraphUrl, fetch }),
    cache: new InMemoryCache(),
  });
  return client.query({ query: querySource });
};
export const getV1VolumeStats = (graphData, chainName) => {
  if (!graphData) {
    return null;
  }
  const timestampProp =
    chainName === "fantom" ||
    chainName === "optimism" ||
    chainName === "arbitrum" ||
    chainName === "base" ||
    chainName === "sonic"
      ? "id"
      : "timestamp";
  const PROPS = "margin liquidation swap mint burn".split(" ");
  let ret = sortBy(graphData.volumeStats, timestampProp).map((item) => {
    const ret = { timestamp: Number(item[timestampProp]) };
    let all = 0;
    PROPS.forEach((prop) => {
      ret[prop] = item[prop] / 1e30;
      all += ret[prop];
    });
    ret.all = all;
    return ret;
  });

  let cumulative = 0;
  const cumulativeByTs = {};
  return ret.map((item) => {
    cumulative += item.all;

    let movingAverageAll;
    const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD;
    if (movingAverageTs in cumulativeByTs) {
      movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
    }

    return {
      movingAverageAll,
      cumulative,
      ...item,
    };
  });
};
export const getFee24hDataV1 = (graphData, chainName) => {
  const PROPS = "marginAndLiquidation swap mint burn".split(" ");
  const timestampProp =
    chainName === "fantom" || chainName === "optimism" || chainName === "arbitrum" || chainName === "base"
      ? "id"
      : "timestamp";
  if (!graphData) {
    return null;
  }
  let ret = sortBy(graphData.hourlyFees, timestampProp).map((item) => {
    const ret = { timestamp: item[timestampProp] };
    let all = 0;
    PROPS.forEach((prop) => {
      ret[prop] = item[prop] / 1e30;
      all += ret[prop];
    });
    ret.all = all;
    return ret;
  });
  let cumulative = 0;
  ret.forEach((item) => {
    cumulative += item.all;
  });
  return cumulative;
};
export const getVolume24hDataV1 = (graphData, chainName) => {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const timestampProp =
    chainName === "fantom" || chainName === "optimism" || chainName === "arbitrum" || chainName === "base"
      ? "id"
      : "timestamp";
  if (!graphData) {
    return null;
  }
  let ret = sortBy(graphData.hourlyVolumes, timestampProp).map((item) => {
    const ret = { timestamp: item[timestampProp] };
    let all = 0;
    PROPS.forEach((prop) => {
      ret[prop] = item[prop] / 1e30;
      all += ret[prop];
    });
    ret.all = all;
    return ret;
  });

  let cumulative = 0;
  ret.forEach((item) => {
    cumulative += item.all;
  });
  return cumulative;
};
export const getFeeDataV1 = (totalFeesData) => {
  let fees = { total: 0, delta: 0 };
  if (totalFeesData) {
    const total = totalFeesData?.[totalFeesData.length - 1]?.cumulative || 0;
    const delta = totalFeesData[totalFeesData.length - 1]?.all;
    fees = {
      total: total,
      delta: delta,
    };
  }
  return fees;
};
export const getVolumeDataV1 = (totalVolumeData) => {
  let data = { total: 0, delta: 0 };
  if (totalVolumeData) {
    const total = totalVolumeData[totalVolumeData.length - 1]?.cumulative;
    const delta =
      totalVolumeData[totalVolumeData.length - 1]?.cumulative - totalVolumeData[totalVolumeData.length - 2]?.cumulative;
    data = {
      total: total,
      delta: delta,
    };
  }
  return data;
};
export const getFullUserDataCount = (usersData, isRecordZero = false) => {
  if (!usersData) {
    return { total: 0, delta: 0 };
  }
  const last = usersData[usersData.length - 1]?.uniqueCountCumulative;
  const total = isRecordZero
    ? last
    : usersData[usersData.length - 1]?.uniqueCountCumulative - usersData[0]?.uniqueCountCumulative;
  const prevTotal = usersData[usersData.length - 2]?.uniqueCountCumulative;
  const delta = total && prevTotal ? last - prevTotal : null;
  return { total: last, delta };
};
export const getOpenInterestDataV1 = (totalVolumeData, volume24HData, chainId) => {
  let data = { total: 0, delta: 0 };
  if (totalVolumeData) {
    const total = totalVolumeData[totalVolumeData.length - 1]?.cumulative;
    const delta = chainId === FANTOM ? volume24HData : total - totalVolumeData[totalVolumeData.length - 2]?.cumulative;
    data = {
      total: total,
      delta: delta,
    };
  }
  return data;
};
export const getUserDatav1 = (graphData) => {
  const prevUniqueCountCumulative = {};
  let cumulativeNewUserCount = 0;
  const data = graphData
    ? sortBy(graphData.userStats, "timestamp").map((item) => {
        const newCountData = ["", "Swap", "Margin", "MintBurn"].reduce((memo, type) => {
          memo[`new${type}Count`] = prevUniqueCountCumulative[type]
            ? item[`unique${type}CountCumulative`] - prevUniqueCountCumulative[type]
            : item[`unique${type}Count`];
          prevUniqueCountCumulative[type] = item[`unique${type}CountCumulative`];
          return memo;
        }, {});
        cumulativeNewUserCount += newCountData.newCount;
        const oldCount = item.uniqueCount - newCountData.newCount;
        const oldPercent = ((oldCount / item.uniqueCount) * 100).toFixed(1);
        return {
          all: item.uniqueCount,
          uniqueSum: item.uniqueSwapCount + item.uniqueMarginCount + item.uniqueMintBurnCount,
          oldCount,
          oldPercent,
          cumulativeNewUserCount,
          ...newCountData,
          ...item,
        };
      })
    : null;

  return data;
};
export const getAmunData = (data, chainName) => {
  const timestampProp =
    chainName === "fantom" ||
    chainName === "optimism" ||
    chainName === "arbitrum" ||
    chainName === "base" ||
    chainName === "sonic"
      ? "id"
      : "timestamp";
  if (!data) {
    return null;
  }
  let cumulativeDistributedUsdPerNlp = 0;
  let cumulativeDistributedEthPerNlp = 0;
  let prevNlpSupply;
  let prevAum;

  if (!data.length) return [];
  let ret = sortBy(data, (item) => item[timestampProp])
    // .filter((item) => item[timestampProp] % 86400 === 0)
    .reduce((memo, item) => {
      const last = memo[memo.length - 1];

      const aum = Number(item.aumInUsdn) / 1e18;
      const nlpSupply = Number(item.nlpSupply) / 1e18;

      const distributedUsd = Number(item.distributedUsd) / 1e30;
      const distributedUsdPerNlp = nlpSupply !== 0 ? distributedUsd / nlpSupply || 0 : 0;
      cumulativeDistributedUsdPerNlp += distributedUsdPerNlp;

      const distributedEth = Number(item.distributedEth) / 1e18;
      const distributedEthPerNlp = nlpSupply !== 0 ? distributedEth / nlpSupply || 0 : 0;
      cumulativeDistributedEthPerNlp += distributedEthPerNlp;

      const nlpPrice = aum / nlpSupply;
      const timestamp = parseInt(item[timestampProp]);

      const newItem = {
        timestamp,
        aum,
        nlpSupply,
        nlpPrice,
        cumulativeDistributedEthPerNlp,
        cumulativeDistributedUsdPerNlp,
        distributedUsdPerNlp,
        distributedEthPerNlp,
      };

      if (last && last.timestamp === timestamp) {
        memo[memo.length - 1] = newItem;
      } else {
        memo.push(newItem);
      }

      return memo;
    }, [])
    .map((item) => {
      let { nlpSupply, aum } = item;
      if (!nlpSupply) {
        nlpSupply = prevNlpSupply;
      }
      if (!aum) {
        aum = prevAum;
      }
      item.nlpSupplyChange = prevNlpSupply ? ((nlpSupply - prevNlpSupply) / prevNlpSupply) * 100 : 0;
      if (item.nlpSupplyChange > 1000) {
        item.nlpSupplyChange = 0;
      }
      item.aumChange = prevAum ? ((aum - prevAum) / prevAum) * 100 : 0;
      if (item.aumChange > 1000) {
        item.aumChange = 0;
      }
      prevNlpSupply = nlpSupply;
      prevAum = aum;
      return item;
    });

  ret = fillNa(ret);
  return ret;
};
export const getTotalNlpDataV1 = (nlpStatsFantom, chainId) => {
  let data = { total: 0, delta: 0 };
  if (nlpStatsFantom) {
    const total =
      chainId === FANTOM
        ? nlpStatsFantom[nlpStatsFantom.length - 1]?.aum
        : nlpStatsFantom[nlpStatsFantom.length - 1]?.aum;
    const delta =
      chainId === FANTOM
        ? total - nlpStatsFantom[nlpStatsFantom.length - 2]?.aum
        : total - nlpStatsFantom[nlpStatsFantom.length - 2]?.aum;
    data = {
      total: total,
      delta: delta,
    };
  }
  return data;
};
export const getTradesData = (closedPositionsData) => {
  let ret = null;
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;
  const dataSort = closedPositionsData ? sortBy(closedPositionsData.tradingStats, (i) => i.timestamp) : [];
  const data = dataSort.map((dataItem, index) => {
    const longOpenInterest = dataItem.longOpenInterest / 1e30;
    const shortOpenInterest = dataItem.shortOpenInterest / 1e30;
    const openInterest = longOpenInterest + shortOpenInterest;

    // const fees = (marginFeesByTs[dataItem.timestamp]?.fees || 0)
    // const feesCumulative = (marginFeesByTs[dataItem.timestamp]?.feesCumulative || 0)

    const profit = dataItem.profit / 1e30;
    const loss = dataItem.loss / 1e30;
    const profitCumulative =
      index === 0 ? profit : dataItem.profitCumulative / 1e30 - dataSort[0].profitCumulative / 1e30;
    const lossCumulative = index === 0 ? loss : dataItem.lossCumulative / 1e30 - dataSort[0].lossCumulative / 1e30;
    const pnlCumulative = profitCumulative - lossCumulative;
    const pnl = profit - loss;
    currentProfitCumulative += profit;
    currentLossCumulative -= loss;
    currentPnlCumulative += pnl;
    return {
      longOpenInterest,
      shortOpenInterest,
      openInterest,
      profit,
      loss: -loss,
      profitCumulative,
      lossCumulative: -lossCumulative,
      pnl,
      pnlCumulative,
      timestamp: dataItem.timestamp,
      currentPnlCumulative,
      currentLossCumulative,
      currentProfitCumulative,
    };
  });
  if (data && data.length) {
    const maxProfit = maxBy(data, (item) => item.profit).profit;
    const maxLoss = minBy(data, (item) => item.loss).loss;
    const maxProfitLoss = Math.max(maxProfit, -maxLoss);

    const maxPnl = maxBy(data, (item) => item.pnl).pnl;
    const minPnl = minBy(data, (item) => item.pnl).pnl;
    const maxCurrentCumulativePnl = maxBy(data, (item) => item.currentPnlCumulative).currentPnlCumulative;
    const minCurrentCumulativePnl = minBy(data, (item) => item.currentPnlCumulative).currentPnlCumulative;

    const currentProfitCumulative = data[data.length - 1].currentProfitCumulative;
    const currentLossCumulative = data[data.length - 1].currentLossCumulative;
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(currentProfitCumulative, -currentLossCumulative),

      maxAbsPnl: Math.max(Math.abs(maxPnl), Math.abs(minPnl)),
      maxAbsCumulativePnl: Math.max(Math.abs(maxCurrentCumulativePnl), Math.abs(minCurrentCumulativePnl)),
    };

    ret = {
      data,
      stats,
    };
    return ret;
  }
};
export const getOpenInterestV1 = (tradersData) => {
  let data = { total: 0, delta: 0 };
  if (tradersData) {
    const total = tradersData.data[tradersData.data.length - 1]?.openInterest;
    const delta = total - tradersData.data[tradersData.data.length - 2]?.openInterest;
    data = {
      total: total,
      delta: delta,
    };
  }
  return data;
};
export const getOpenInteresDataDetailstV1 = (tradersData) => {
  let data = { long: 0, short: 0, total: 0 };
  if (tradersData) {
    const totalLong =
      tradersData.data?.reduce(
        (val, currentValue) => {
          val.long += parseNumberOrDefault(currentValue.longOpenInterest, 0);
          return val;
        },
        { long: 0 }
      ).long || 0;
    const totalShort =
      tradersData.data?.reduce(
        (val, currentValue) => {
          val.short += parseNumberOrDefault(currentValue.shortOpenInterest, 0);
          return val;
        },
        { short: 0 }
      ).short || 0;
    const total = totalLong + totalShort;
    data = {
      long: totalLong,
      short: totalShort,
      total: total,
    };
  }
  return data;
};
const getDailyV2PnlData = (data) => {
  let cumulative = 0;
  let cumulativeProfit = 0;
  let cumulativeLoss = 0;
  const newArray = data.map((info) => {
    const winPnl = Number(info.winPnl);
    const lossPnl = Number(info.lossPnl);
    const currentCumulative = winPnl + lossPnl + cumulative;
    const currentCumulativeProfit = winPnl + cumulativeProfit;
    const currentCumulativeLoss = lossPnl + cumulativeLoss;
    cumulative = currentCumulative;
    cumulativeProfit = currentCumulativeProfit;
    cumulativeLoss = currentCumulativeLoss;
    return {
      ...info,
      cumulativePnl: currentCumulative,
      profitCumulative: currentCumulativeProfit,
      lossCumulative: currentCumulativeLoss,
    };
  });
  return newArray;
};
export const getUsertsDailyStats = (data, graphData, dailyInfo) => {
  const userDataV1 = getUserDatav1(graphData);
  let cumulativeNew = 0;
  let cumulativeOld = 0;
  let cumulativeAction = 0;
  let cumulativeBreakdown = 0;
  const newArray = data.map((info, index) => {
    const currentCumulativeNew = info.newCount + cumulativeNew;
    const currentCumulativeOld = info.oldCount + cumulativeOld;
    const currentCumulativeAction = info.actionCount + cumulativeAction;
    const currentCumulativeBreakdown = info.uniqueSum + cumulativeBreakdown;
    cumulativeNew = currentCumulativeNew;
    cumulativeOld = currentCumulativeOld;
    cumulativeAction = currentCumulativeAction;
    cumulativeBreakdown = currentCumulativeBreakdown;
    const mintBurnV1 = userDataV1.find((x) => x.timestamp === info.timestamp)?.uniqueMintBurnCount || 0;
    const mintBurnNSLP = dailyInfo.find((x) => x.timestamp === info.timestamp)?.uniqueMintBurnCount || 0;

    return {
      ...info,
      cumulativeNew: info.cumulativeNewUserCount,
      cumulativeOld: currentCumulativeOld,
      cumulativeAction: currentCumulativeAction,
      cumulativeBreakdown: currentCumulativeBreakdown,
      mintBurnNLP: mintBurnV1,
      mintBurnNSLP: mintBurnNSLP,
      allBreakDown: mintBurnV1 + mintBurnNSLP + info.uniqueSwapCount + info.uniqueMarginCount,
    };
  });
  return newArray;
};
export const getDailyInfoAnalyticsV2 = (dailyInfoData) => {
  let totalVolumeValue;
  let totalTradesValue;
  let usersValue;
  let totalOpenInterest;
  let totalLongOpenInterest;
  let totalShortOpenInterest;
  // console.log("????", dailyInfoData);
  if (dailyInfoData) {
    totalVolumeValue =
      dailyInfoData?.reduce(
        (val, currentValue) => {
          val.volume += parseNumberOrDefault(currentValue.totalVolume, 0);
          return val;
        },
        { volume: 0 }
      ).volume || 0;
    totalOpenInterest =
      dailyInfoData?.reduce(
        (val, currentValue) => {
          val.openInterest += parseNumberOrDefault(currentValue.longOpenInterest + currentValue.shortOpenInterest, 0);
          return val;
        },
        { openInterest: 0 }
      ).openInterest || 0;
    totalLongOpenInterest =
      dailyInfoData?.reduce(
        (val, currentValue) => {
          val.longOpenInterest += parseNumberOrDefault(currentValue.longOpenInterest);
          return val;
        },
        { longOpenInterest: 0 }
      ).longOpenInterest || 0;
    totalShortOpenInterest =
      dailyInfoData?.reduce(
        (val, currentValue) => {
          val.shortOpenInterest += parseNumberOrDefault(currentValue.shortOpenInterest);
          return val;
        },
        { shortOpenInterest: 0 }
      ).shortOpenInterest || 0;
    usersValue =
      dailyInfoData?.reduce(
        (val, currentValue) => {
          val.newUsers += parseNumberOrDefault(currentValue.newCount, 0);
          return val;
        },
        { newUsers: 0 }
      ).newUsers || 0;
    const totalNetPnl =
      dailyInfoData?.reduce(
        (val, currentValue) => {
          val.netPnl += Number(currentValue.winPnl) + Number(currentValue.lossPnl);
          return val;
        },
        { netPnl: 0 }
      ).netPnl || 0;
    totalTradesValue =
      dailyInfoData?.reduce(
        (val, currentValue) => {
          val.trades += parseNumberOrDefault(currentValue.actionMarginCount, 0);
          return val;
        },
        { trades: 0 }
      ).trades || 0;
    const result = {
      volume: {
        total: totalVolumeValue,
        delta: Number(dailyInfoData?.[dailyInfoData.length - 1]?.totalVolume || 0),
      },
      pnl: {
        total: totalNetPnl,
        dailyData: getDailyV2PnlData(dailyInfoData),
      },
      openInterest: {
        total: totalOpenInterest || 0,
        long: totalLongOpenInterest || 0,
        short: totalShortOpenInterest || 0,
        delta:
          Number(dailyInfoData?.[dailyInfoData.length - 1]?.longOpenInterest || 0) +
          Number(dailyInfoData?.[dailyInfoData.length - 1]?.shortOpenInterest || 0) -
          (Number(dailyInfoData?.[dailyInfoData.length - 2]?.longOpenInterest || 0) +
            Number(dailyInfoData?.[dailyInfoData.length - 2]?.shortOpenInterest || 0)),
      },
      users: {
        total: usersValue,
        delta: Number(dailyInfoData?.[dailyInfoData.length - 1]?.newCount || 0),
      },
      nlp: {
        total: Number(dailyInfoData?.[dailyInfoData.length - 1]?.nslpTvl || 0),
        delta:
          Number(dailyInfoData?.[dailyInfoData.length - 1]?.nslpTvl || 0) -
          Number(dailyInfoData?.[dailyInfoData.length - 2]?.nslpTvl || 0),
      },
      fees: {
        total: Number(dailyInfoData?.[dailyInfoData.length - 1]?.feesCumulative || 0),
        delta: Number(dailyInfoData?.[dailyInfoData.length - 1]?.totalFees || 0),
      },
      trades: {
        total: totalTradesValue,
        delta: Number(dailyInfoData?.[dailyInfoData.length - 1]?.actionMarginCount || 0),
      },
    };
    return result;
  } else {
    const result = {
      volume: {
        total: 0,
        delta: 0,
      },
      nlp: {
        total: 0,
        delta: 0,
      },
    };
    return result;
  }
};
export const parseNumberOrDefault = (value, defaultValue) => {
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? defaultValue : parsedValue;
};
export const get24hTradesDataV1 = (data) => {
  if (data.length <= 2) {
    return 0;
  }

  const lastElement = data[0];
  const secondLastElement = data[1];

  const lastSum =
    lastElement.uniqueMarginCountCumulative +
    lastElement.uniqueMintBurnCountCumulative +
    lastElement.uniqueSwapCountCumulative;
  const secondLastSum =
    secondLastElement.uniqueMarginCountCumulative +
    secondLastElement.uniqueMintBurnCountCumulative +
    secondLastElement.uniqueSwapCountCumulative;

  const difference = Math.abs(lastSum - secondLastSum);
  return difference;
};
export const mergeVolumeChartDataV1V2 = (chainName, isV1V2, graphDataV1, feesDatav1, dailyInfoV2) => {
  const v1Volume = getV1VolumeStats(graphDataV1, chainName);
  if (!isV1V2) {
    return v1Volume;
  } else {
    if (!graphDataV1 || !dailyInfoV2) {
      return null;
    }
    const chartData = mergeChartDataV1V2(chainName, graphDataV1, feesDatav1, dailyInfoV2, v1Volume);
    return chartData;
  }
};
function mergeChartDataV1V2(chainName, graphDataV1, feesDatav1, dailyInfoV2, v1Volume) {
  const nlpDataV1 = getAmunData(graphDataV1?.nlpStatsAllTime, chainName);
  const tradeDataV1 = getTradesData(graphDataV1);
  let v2Cumulative = 0;
  const uniqueVolumeNLP = findUniqueTimestamps(v1Volume, nlpDataV1).map((i) => ({ timestamp: i.timestamp }));
  const sortedDataStart = [...v1Volume, ...uniqueVolumeNLP];
  const uniqueVolumeNLPDailyInfo = findUniqueTimestamps(sortedDataStart, dailyInfoV2).map((i) => ({
    timestamp: i.timestamp,
  }));
  const sortedData = [...sortedDataStart, ...uniqueVolumeNLPDailyInfo].sort((a, b) => a.timestamp - b.timestamp);

  let storedCumulativeFeeV1 = 0;

  const chartData = sortedData?.map((item) => {
    const nlpData = nlpDataV1?.find((x) => x.timestamp === item.timestamp) || {};
    const longOpenInterestV1 = Number(
      tradeDataV1?.data?.find((x) => x.timestamp === item.timestamp)?.longOpenInterest || 0
    );
    const shortOpenInterestV1 = Number(
      tradeDataV1?.data?.find((x) => x.timestamp === item.timestamp)?.shortOpenInterest || 0
    );
    const longOpenInterestV2 = Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.longOpenInterest || 0);
    const shortOpenInterestV2 = Number(
      dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.shortOpenInterest || 0
    );

    v2Cumulative += Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.totalVolume || 0);

    const v1TradingVolume = item?.margin || 0;
    const v2TradingVolume = Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.marginTradingVolume || 0);

    const v1LiquidationVolume = item?.liquidation || 0;
    const v2LiquidationVolume = Number(
      dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.liquidationVolume || 0
    );

    const v1TradingFees = feesDatav1?.find((x) => x.timestamp === item.timestamp)?.margin || 0;
    const v2TradingFees = Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.marginTradingFees || 0);

    storedCumulativeFeeV1 = !!feesDatav1?.find((x) => x.timestamp === item.timestamp)
      ? feesDatav1?.find((x) => x.timestamp === item.timestamp)?.cumulative
      : storedCumulativeFeeV1;

    return {
      ...item,
      ...nlpData,
      totalCummulative: (item?.cumulative || 0) + v2Cumulative,
      all: (item?.all || 0) + Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.totalVolume || 0),
      tradingVolume: v1TradingVolume + v2TradingVolume,
      v1SwapVolume: item?.swap || 0,
      liquidationVolume: v1LiquidationVolume + v2LiquidationVolume,
      mintNLPVolume: item?.mint || 0,
      burnNLPVolume: item?.burn || 0,
      mintNSLPVolume: Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.mintVolume || 0),
      burnNSLPVolume: Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.burnVolume || 0),

      mintNLPFees: feesDatav1?.find((x) => x.timestamp === item.timestamp)?.mint || 0,
      burnNLPFees: feesDatav1?.find((x) => x.timestamp === item.timestamp)?.burn || 0,
      mintNSLPFees: Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.mintFees || 0),
      burnNSLPFees: Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.burnFees || 0),
      v1LiquidationFees: feesDatav1?.find((x) => x.timestamp === item.timestamp)?.liquidation || 0,
      tradingFees: v1TradingFees + v2TradingFees,
      v1SwapFees: feesDatav1?.find((x) => x.timestamp === item.timestamp)?.swap || 0,
      totalCumulativeFees:
        storedCumulativeFeeV1 + Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.feesCumulative || 0),
      allFees:
        (feesDatav1?.find((x) => x.timestamp === item.timestamp)?.all || 0) +
        Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.totalFees || 0),

      longOpenInterest: longOpenInterestV1 + longOpenInterestV2,
      shortOpenInterest: shortOpenInterestV2 + shortOpenInterestV1,
      openInterest: longOpenInterestV1 + longOpenInterestV2 + shortOpenInterestV1 + shortOpenInterestV2,
      nslpTvl: Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.nslpTvl || 0),
      nslpPrice: Number(dailyInfoV2?.find((x) => x.timestamp === item.timestamp)?.nslpPrice || 0),
    };
  });
  return chartData;
}
export const getMergedOnlyChartV1Data = (chainName, graphDataV1, feesDatav1) => {
  if (!graphDataV1 || !feesDatav1) {
    return null;
  }
  const v1Volume = getV1VolumeStats(graphDataV1, chainName);
  const chartData = mergeChartV1(chainName, graphDataV1, feesDatav1, v1Volume);
  return chartData;
};
function mergeChartV1(chainName, graphDataV1, feesDatav1, v1Volume) {
  const nlpDataV1 = getAmunData(graphDataV1?.nlpStatsAllTime, chainName);
  const tradeDataV1 = getTradesData(graphDataV1);
  const chartData = v1Volume?.map((item) => {
    const nlpData = nlpDataV1?.find((x) => x.timestamp === item.timestamp) || {};
    const longOpenInterestV1 = Number(
      tradeDataV1?.data?.find((x) => x.timestamp === item.timestamp)?.longOpenInterest || 0
    );
    const shortOpenInterestV1 = Number(
      tradeDataV1?.data?.find((x) => x.timestamp === item.timestamp)?.shortOpenInterest || 0
    );

    return {
      ...item,
      ...nlpData,
      totalCummulative: item?.cumulative || 0,
      all: item?.all,
      v1TradingVolume: item?.margin || 0,
      v2TradingVolume: 0,
      v1SwapVolume: item?.swap || 0,
      v1LiquidationVolume: item?.liquidation || 0,
      v2LiquidationVolume: 0,
      mintNLPVolume: item?.mint || 0,
      burnNLPVolume: item?.burn || 0,
      mintNSLPVolume: 0,
      burnNSLPVolume: 0,

      mintNLPFees: feesDatav1?.find((x) => x.timestamp === item.timestamp)?.mint || 0,
      burnNLPFees: feesDatav1?.find((x) => x.timestamp === item.timestamp)?.burn || 0,
      mintNSLPFees: 0,
      burnNSLPFees: 0,
      v1LiquidationFees: feesDatav1?.find((x) => x.timestamp === item.timestamp)?.liquidation || 0,
      v1TradingFees: feesDatav1?.find((x) => x.timestamp === item.timestamp)?.margin || 0,
      v2TradingFees: 0,
      v1SwapFees: feesDatav1?.find((x) => x.timestamp === item.timestamp)?.swap || 0,
      totalCumulativeFees: feesDatav1?.find((x) => x.timestamp === item.timestamp)?.cumulative || 0,
      allFees: feesDatav1?.find((x) => x.timestamp === item.timestamp)?.all || 0,

      longOpenInterest: longOpenInterestV1,
      shortOpenInterest: shortOpenInterestV1,
      openInterest: longOpenInterestV1 + shortOpenInterestV1,
      nslpTvl: 0,
      nslpPrice: 0,
    };
  });
  return chartData;
}
export const getListAssetsV2 = (data, chainId) => {
  let list = {};
  data = Object.values(data)?.filter((item) =>
    TOKEN_WHITE_LIST_ANALYTICS[chainId].includes(getTokenSymbolFromString(item?.symbol))
  );
  for (let i = 0; i < data.length; i++) {
    list[data[i].id] = {
      tokenSymbol: getTokenSymbolFromString(data[i]?.symbol),
      ...data[i],
      decimals: TOKEN_SHOWS_DECIMALS[data[i]?.symbol] || 2,
    };
  }
  return list;
};

export const mergeOpenInterestData = (dataV1, dataV2, listAssetsV2) => {
  let mergeListV2 = {};
  let listV1 = {};
  for (let [key, value] of Object.entries(dataV2)) {
    const symbol = listAssetsV2?.[key]?.tokenSymbol;
    if (symbol)
      mergeListV2[symbol] = value.map((i) => ({
        ...i,
        openInterest: Number(i.longOI) + Number(i.shortOI),
      }));
  }
  for (let [key, value] of Object.entries(dataV1)) {
    if (key !== "status") {
      listV1[key.replace("lz", "")] = value.map((i) => ({
        ...i,
        openInterest: Number(i.longOI) + Number(i.shortOI),
      }));
    }
  }
  let result = {};
  let allKeys = new Set([...Object.keys(listV1), ...Object.keys(mergeListV2)]);

  allKeys.forEach((key) => {
    result[key] = [];

    let combined = [];
    if (listV1[key]) combined = combined.concat(listV1[key]);
    if (mergeListV2[key]) combined = combined.concat(mergeListV2[key]);

    let grouped = {};

    combined.forEach((entry) => {
      if (!grouped[entry.timestamp]) {
        grouped[entry.timestamp] = { timestamp: entry.timestamp, volume: 0, longOI: 0, shortOI: 0, openInterest: 0 };
      }
      grouped[entry.timestamp].volume += Number(entry.volume);
      grouped[entry.timestamp].longOI += Number(entry.longOI);
      grouped[entry.timestamp].shortOI += Number(entry.shortOI);
      grouped[entry.timestamp].openInterest += Number(entry.openInterest);
    });

    result[key] = Object.values(grouped).sort((a, b) => a.timestamp - b.timestamp);
  });
  let finalResult = [];
  let allTimestamps = new Set();
  for (let key in result) {
    result[key].forEach((entry) => allTimestamps.add(entry.timestamp));
  }
  allTimestamps = Array.from(allTimestamps).sort((a, b) => a - b);
  allTimestamps.forEach((timestamp) => {
    let newObj = { timestamp: timestamp };

    for (let key in result) {
      let entry = result[key].find((item) => item.timestamp === timestamp);
      newObj[`openInterest_${key}`] = entry ? entry.openInterest : 0;
      newObj[`longOI_${key}`] = entry ? entry.longOI : 0;
      newObj[`shortOI_${key}`] = entry ? entry.shortOI : 0;
      newObj[`volume_${key}`] = entry ? entry.volume : 0;
    }

    finalResult.push(newObj);
  });

  const mergeFinal = finalResult.map((item) => {
    let total = 0;
    for (let key in result) {
      total += item[`openInterest_${key}`];
    }
    return { ...item, all: total };
  });

  const totalOpenInterest = finalResult.map((item) => {
    let totalLong = 0;
    let totalShort = 0;
    let total = 0;
    for (let key in result) {
      totalLong += item[`longOI_${key}`];
      totalShort += item[`shortOI_${key}`];
      total += item[`longOI_${key}`] + item[`shortOI_${key}`];
    }
    return { ...item, all: total, totalLong, totalShort };
  });
  const assetVolumes = {};
  for (let [key, value] of Object.entries(result)) {
    assetVolumes[key] = {
      assetSymbol: key + "/USD",
      type: findType(key),
      value: (value?.[value?.length - 1]?.longOI || 0) + (value?.[value?.length - 1]?.shortOI || 0),
    };
  }
  return {
    data: mergeFinal,
    keys: Array.from(allKeys)?.map((key) => ({ key: `openInterest_${key}`, name: key })) || [],
    dataOrigin: result,
    totalOpenInterest: totalOpenInterest,
    assetVolumes: assetVolumes,
  };
  // return { data: finalResult, keys: allKeys};
};
export const convertOpenInterestIntoPieData = (data) => {
  if (!data) return { data: {}, originData: {} };
  const results = {};
  const resultsOrigin = {};
  for (let [key, value] of Object.entries(data)) {
    let totalOI = value?.[value.length - 1]?.openInterest || 0;
    // for (let i = 0; i < value.length; i++) {
    //   totalOI += value[i].openInterest;
    // }
    let type = "other";
    if (CRYPTO_ANALYTICS.find((c) => c === key)) type = "crypto";
    if (FOREX.find((c) => c === key)) type = "forex";
    if (METALS.find((c) => c === key)) type = "metals";
    results[key] = {
      assetSymbol: key + "/USD",
      value: totalOI,
      type: type,
    };
    resultsOrigin[key + "/USD"] = totalOI;
  }
  return { data: results, originData: resultsOrigin };
};
export const getTotalNaviEsnaviStaked = (data) => {
  if (!data) return 0;
  const total = data?.[data.length - 1].naviStakedValue;
  return total;
};
export const getTotalNaviEsnaviStakedDelta = (data) => {
  if (!data) return 0;
  const delta = (data?.[data.length - 1]?.naviStakedValue || 0) - (data?.[data.length - 2]?.naviStakedValue || 0);
  return delta;
};

export const getNLPPoolData = (data, chainId) => {
  if (!data) return [];
  let results = [];
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    const index = results.findIndex((item) => item.timestamp === row.timestamp);
    const symbol =
      getTokenByAddress(chainId, row.token)?.symbol || getAdditionalTokenByAddress(chainId, row.token)?.symbol;
    if (index !== -1) {
      results[index] = {
        ...results[index],
        [`${symbol}`]: row.poolAmountUsd / 1e30,
        all: results[index].all + row.poolAmountUsd / 1e30,
      };
    } else {
      results.push({
        timestamp: row.timestamp,
        [`${symbol}`]: row.poolAmountUsd / 1e30,
        all: row.poolAmountUsd / 1e30,
      });
    }
  }
  return results;
};

export const mergeChartLockedData = (naviEsnavi, nlp, nslp) => {
  if (!naviEsnavi || !nlp || !nslp) return [];

  const uniqueTimestamps = new Set([...nslp.map((i) => i.timestamp), ...nlp.map((i) => i.timestamp)]);

  const result = [];

  for (const timestamp of uniqueTimestamps) {
    const nlpItem = nlp.find((i) => i.timestamp === timestamp);
    const nslpItem = nslp.find((i) => i.timestamp === timestamp);
    const naviEsnaviItem = naviEsnavi.find((i) => i.timestamp === timestamp);

    const item = {
      ...(nlpItem || {}),
      naviEsnavi: naviEsnaviItem?.naviStakedValue || 0,
      NSLP: nslpItem ? Number(nslpItem?.nslpTvl || 0) : 0,
      all: (nlpItem?.all || 0) + (naviEsnaviItem?.naviStakedValue || 0) + Number(nslpItem?.nslpTvl || 0),
      timestamp,
    };

    result.push(item);
  }

  return result;
};
export const getLockedChartKeys = (data) => {
  if (!data || data.length === 0) return [];

  const sample = merge(...data);
  let keys = [];
  for (let [key] of Object.entries(sample)) {
    if (key !== "timestamp" && key !== "all") {
      if (key !== "naviEsnavi") {
        keys.push({ key: key, name: key });
      }
    }
  }
  return keys;
};
export const getLastLockedSnapShot = (data) => {
  if (!data || data.length === 0) return [];
  const sample = data[data.length - 1];
  let keys = [];
  for (let [key, value] of Object.entries(sample)) {
    if (key !== "timestamp" && key !== "all") {
      keys.push({ key: key, name: key === "naviEsnavi" ? "NAVI & esNAVI " : key, value });
    }
  }
  return keys;
};
export function findUniqueTimestamps(arr1, arr2) {
  const timestampsInArr1 = new Set(arr1.map((item) => item.timestamp));
  const uniqueElements = arr2.filter((item) => !timestampsInArr1.has(item.timestamp));
  return uniqueElements;
}
const findType = (key) => {
  let type = "other";
  if (CRYPTO_ANALYTICS.find((c) => c === key)) type = "crypto";
  if (FOREX.find((c) => c === key)) type = "forex";
  if (METALS.find((c) => c === key)) type = "metals";
  return type;
};
