import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";

import {
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
} from "lib/legacy";

import Vault from "abis/Vault.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import NlpManager from "abis/NlpManager.json";
import useVaultNaviContract from "hooks/contracts/useVaultNaviContract";

import { useNaviPrice } from "domain/legacy";

import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";

const useProcessedData = (chainId) => {
  let { active, account } = useWeb3React();

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const naviAddress = getContract(chainId, "NAVI");
  const esNaviAddress = getContract(chainId, "ES_NAVI");
  const bnNaviAddress = getContract(chainId, "BN_NAVI");
  const nlpAddress = getContract(chainId, "NLP");
  const nslpAddress = getContract(chainId, "NSLP");
  const usdgAddress = getContract(chainId, "USDN");

  const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");
  const bonusNaviTrackerAddress = getContract(chainId, "BonusNaviTracker");
  const feeNaviTrackerAddress = getContract(chainId, "FeeNaviTracker");

  const stakedNlpTrackerAddress = getContract(chainId, "StakedNlpTracker");
  const feeNlpTrackerAddress = getContract(chainId, "FeeNlpTracker");

  const stakedNslpTrackerAddress = getContract(chainId, "StakedNslpTracker");
  const feeNslpTrackerAddress = getContract(chainId, "FeeNslpTracker");

  const nlpManagerAddress = getContract(chainId, "NlpManager");

  const naviVesterAddress = getContract(chainId, "NaviVester");
  const nlpVesterAddress = getContract(chainId, "NlpVester");
  const nslpVesterAddress = getContract(chainId, "NslpVester");

  const vesterAddresses = [naviVesterAddress, nlpVesterAddress, nslpVesterAddress];

  const walletTokens = [naviAddress, esNaviAddress, nlpAddress, stakedNaviTrackerAddress, nslpAddress];
  const depositTokens = [
    naviAddress,
    esNaviAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    bnNaviAddress,
    nlpAddress,
    nslpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedNaviTrackerAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNlpTrackerAddress,
    feeNslpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    stakedNlpTrackerAddress,
    feeNlpTrackerAddress,
    stakedNslpTrackerAddress,
    feeNslpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [`StakeV2:walletBalances:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(undefined, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: stakedNaviSupply } = useSWR(
    [`StakeV2:stakedNaviSupply:${active}`, chainId, naviAddress, "balanceOf", stakedNaviTrackerAddress],
    {
      fetcher: contractFetcher(undefined, Token),
      refreshInterval: 10000,
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, nlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(undefined, NlpManager),
    refreshInterval: 10000,
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );
  const tokensForSupplyQuery = [naviAddress, nlpAddress, usdgAddress, nslpAddress];

  const { data: totalSupplies } = useSWR(
    [`StakeV2:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );
  const { naviPrice } = useNaviPrice(chainId, {}, active);

  let naviSupply;
  if (totalSupplies && totalSupplies[1]) {
    naviSupply = totalSupplies[1];
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  const { nslpPrice } = useVaultNaviContract(chainId);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedNaviSupply,
    naviPrice,
    naviSupply,
    nslpPrice
  );

  return processedData;
};

export default useProcessedData;
