import cx from "classnames";
import { useAffiliateCodes, useUserReferralCodeV2 } from "domain/referrals";
import { toJpeg } from "html-to-image";
import icCheck from "img/icons/checkbox-ic.svg";
import icChecked from "img/icons/checkbox-ic1.svg";
import icCheckedLight from "img/icons/checkbox-ic1Light.svg";
import icCheckLight from "img/icons/checkbox-icLight.svg";
import naviLogo from "img/logo_NAVI.svg";
import { default as copyIc } from "img/icons/copy-ic.svg";
import downloadIc from "img/icons/download-ic.svg";
import shareBgImgV2 from "img/position-share-bg.png";
import tweetIc from "img/trade/tweet.svg";
import { useChainId } from "lib/chains";
import downloadImage from "lib/downloadImage";
import { helperToast } from "lib/helperToast";
import { getAppBaseUrl, getRootShareApiUrl, getTwitterIntentURL } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";
import useLoadImage from "lib/useLoadImage";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { useCopyToClipboard, useMedia } from "react-use";
import SpinningLoader from "../Common/SpinningLoader";

import { useThemeContext } from "contexts/ThemeProvider";
import { BigNumber } from "ethers";
import Modal from "../Modal/Modal";
import "./PositionShare.scss";
import "./PositionShareTheme.scss";
const ROOT_SHARE_URL = getRootShareApiUrl();
const UPLOAD_URL = ROOT_SHARE_URL + "/api/upload";
const UPLOAD_SHARE = ROOT_SHARE_URL + "/api/s";
const config = { quality: 1, canvasWidth: 375, canvasHeight: 375, type: "image/jpeg" };

function getShareURL(imageInfo, ref) {
  if (!imageInfo) return;
  let url = `${UPLOAD_SHARE}?id=${imageInfo.id}`;
  if (ref?.success && ref?.code) {
    url = url + `&ref=${ref.code}`;
  }
  return url;
}
export const getPositionNetValue = (position, markPrice) => {
  const { averagePrice, isLong, size } = position;

  const pnlWithoutFee = isLong
    ? markPrice.sub(parseValue(averagePrice, 30)).mul(parseValue(size, 30)).div(parseValue(averagePrice, 30))
    : parseValue(averagePrice, 30).sub(markPrice).mul(parseValue(size, 30)).div(parseValue(averagePrice, 30));

  const accruedFees =
    parseValue(position?.accruedPositionFee || 0, 30)
      .add(parseValue(position?.accruedFundingFee || 0, 30))
      .add(parseValue(position?.accruedBorrowFee || 0, 30)) || BigNumber.from(0);
  const accruedFeesWithoutPosition = parseValue(position?.accruedFundingFee || 0, 30).add(
    parseValue(position?.accruedBorrowFee || 0, 30)
  );
  const pnlWithFee = pnlWithoutFee.sub(accruedFees);
  // console.log("pnlWithoutFee", pnlWithFee);
  const percentPnl = pnlWithFee.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30));
  const percentPnlWithoutFee = pnlWithoutFee.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30));
  const pnlAfterPosition = pnlWithoutFee.sub(accruedFeesWithoutPosition);
  const pnl = pnlWithFee;
  return {
    percentPnl,
    pnl,
    pnlWithoutFee,
    pnlAfterPosition,
    percentPnlWithoutFee,
    netValue: pnlWithFee.add(parseValue(position.collateral, 30)),
    netValueWithoutFee: pnlWithoutFee.add(parseValue(position.collateral, 30)),
    prefix: pnl?.gt(0) ? "+" : pnl?.lt(0) ? "-" : "",
    prefixWithoutFee: pnlWithoutFee?.gt(0) ? "+" : pnlWithoutFee?.lt(0) ? "-" : "",
  };
};
function PositionShare({
  setIsPositionShareModalOpen,
  isPositionShareModalOpen,
  selectedPosition,
  account,
  chainId,
  marketTokensInfo,
  positions,
}) {
  const { isLightTheme } = useThemeContext();
  const userAffiliateCode = useAffiliateCodes(chainId, account);
  const { userReferralCode } = useUserReferralCodeV2(chainId, account);

  const [uploadedImageInfo, setUploadedImageInfo] = useState();
  const [uploadedImageError, setUploadedImageError] = useState();
  const [, copyToClipboard] = useCopyToClipboard();
  const sharePositionBgImg = useLoadImage(shareBgImgV2);
  const positionRef = useRef();
  const currentPos = positions.find((x) => x.posId === selectedPosition?.posId) || {};
  const currentMarket = marketTokensInfo?.find((x) => x.id === selectedPosition.tokenId);
  const markPrice = BigNumber.from(
    selectedPosition?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0
  );
  const positionNetValue = getPositionNetValue(currentPos, markPrice);
  const positionToShare = {
    ...selectedPosition,
    ...currentPos,
    markPrice: markPrice,
    positionNetValue,
  };
  const tweetLink = getTwitterIntentURL(
    `Latest $${positionToShare?.symbol} trade on @NaviExSonic`,
    getShareURL(uploadedImageInfo, userAffiliateCode)
  );
  const isMobile = useMedia("(max-width: 800px)");
  useEffect(() => {
    (async function () {
      await handleUploadFile();
    })();
  }, [sharePositionBgImg]);

  async function handleDownload() {
    // await handleUploadFile()
    const element = positionRef.current;
    if (!element) return;
    const imgBlob = await toJpeg(element, config)
      .then(() => toJpeg(element, config))
      .then(() => toJpeg(element, config));
    downloadImage(imgBlob, "share.jpeg");
  }

  async function handleCopy() {
    // await handleUploadFile();
    if (!uploadedImageInfo) return;
    const url = getShareURL(uploadedImageInfo, userAffiliateCode);
    copyToClipboard(url);
    helperToast.success(`Link copied to clipboard.`);
  }

  const LIST = [
    {
      name: "Leverage",
      checked: true,
      value: "leverage",
    },
    {
      name: "Pnl Amount",
      checked: true,
      value: "pnl",
    },
    {
      name: "Price",
      checked: true,
      value: "price",
    },
  ];
  const [checkList, setCheckList] = useState(LIST);

  const handleCheck = (item) => {
    const newList = [...checkList];
    newList.map((checkbox) => {
      if (checkbox.value === item.value) {
        checkbox.checked = !item.checked;
      }
      return item;
    });

    setCheckList(newList);
  };
  async function handleUploadFile() {
    const element = positionRef.current;

    // console.log("????", { element, userReferralCode, sharePositionBgImg, positionToShare });
    if (element && userAffiliateCode.success && sharePositionBgImg && positionToShare) {
      // We have to call the toJpeg function multiple times to make sure the canvas renders all the elements like background image
      // @refer https://github.com/tsayen/dom-to-image/issues/343#issuecomment-652831863
      const image = await toJpeg(element, config)
        .then(() => toJpeg(element, config))
        .then(() => toJpeg(element, config));
      try {
        const imageInfo = await fetch(UPLOAD_URL, { method: "POST", body: image }).then((res) => res.json());
        setUploadedImageInfo(imageInfo);
      } catch (err) {
        setUploadedImageInfo(null);
        setUploadedImageError(`Image generation error, please refresh and try again.`);
      }
    }
  }

  return (
    <Modal
      className={`position-share-modal`}
      isVisible={isPositionShareModalOpen}
      setIsVisible={setIsPositionShareModalOpen}
      label={`Share Position`}
    >
      <PositionShareCard
        userAffiliateCode={userAffiliateCode}
        positionRef={positionRef}
        position={positionToShare}
        chainId={chainId}
        account={account}
        uploadedImageInfo={uploadedImageInfo}
        uploadedImageError={uploadedImageError}
        sharePositionBgImg={sharePositionBgImg}
        checkList={checkList}
        currentMarket={currentMarket}
        userReferralCode={userReferralCode}
      />
      {uploadedImageError && <span className="error">{uploadedImageError}</span>}
      <div className="checkbox-list">
        {checkList.map((item) => {
          return (
            <div key={item.name} className="checkbox-item">
              {item.checked ? (
                <img
                  className="img-check"
                  alt="img"
                  src={isLightTheme ? icCheckedLight : icChecked}
                  onClick={() => handleCheck(item)}
                />
              ) : (
                <img
                  className="img-check"
                  src={isLightTheme ? icCheckLight : icCheck}
                  alt="img"
                  onClick={() => handleCheck(item)}
                />
              )}
              {item.name}
            </div>
          );
        })}
      </div>

      <div className="actions">
        <button className={cx("App-button-option  btn-share", { disabled: !uploadedImageInfo })}>
          <a
            target="_blank"
            className={cx("tweet-link", { disabled: !uploadedImageInfo })}
            rel="noreferrer"
            href={tweetLink}
          >
            {/* <img src={tweetIc} alt="img" className="icon" /> */}
            {/* <img src={tweetIcDisabled} alt="disabled" className="icon" /> */}
            <span>Share</span>
          </a>
        </button>
        <button disabled={!uploadedImageInfo} className="App-button-option" onClick={handleCopy}>
          <img src={copyIc} alt="img" className="icon" />
        </button>

        <button disabled={!uploadedImageInfo} className="App-button-option" onClick={handleDownload}>
          <img src={downloadIc} alt="img" className="icon" />
        </button>
      </div>
    </Modal>
  );
}

function PositionShareCard({
  positionRef,
  position,
  userAffiliateCode,
  uploadedImageInfo,
  uploadedImageError,
  sharePositionBgImg,
  checkList,
  currentMarket,
  userReferralCode,
}) {
  const { chainId } = useChainId();
  const isMobile = useMedia("(max-width: 400px)");
  const {
    deltaPercentageStr = "-",
    deltaStr,
    isLong,
    leverage,
    indexToken,
    averagePrice,
    markPrice,
    positionNetValue,
    symbol,
  } = position;
  const { code, success } = userAffiliateCode;
  const homeURL = getAppBaseUrl();
  return (
    <div className="relative">
      <div className="share-card-container">
        <div ref={positionRef} className="position-share" style={{ backgroundImage: `url(${sharePositionBgImg})` }}>
          <div className="info info-share-container">
            <div className="title">{symbol}/USD</div>
            <div className={`side ${isLong ? "side-long" : "side-short"}`}>{isLong ? "LONG" : "SHORT"}</div>
            {/* {checkList[0].checked && <li>{formatAmount(parseValue(leverage, 30), 30, 2, true)}X&nbsp;</li>} */}
          </div>
          {checkList[1].checked && (
            <h3 className={`pnl ${positionNetValue.prefix === "-" ? "negative" : "positive"}`}>
              {positionNetValue.prefix}
              {formatAmount(positionNetValue?.percentPnl, 30, 2, true).replace("-", "")}%
              {/* <div className="change-dollar">
              ({positionNetValue.prefix}$
              {formatAmount(positionNetValue?.pnl, 30, 2, true).replace("-", "")})
            </div> */}
            </h3>
          )}

          <div className="prices">
            {checkList[2].checked && (
              <div style={{ width: "fit-content" }}>
                <p className="muted" style={{ whiteSpace: "nowrap" }}>
                  Entry price:
                </p>
                <p className="price" style={{ whiteSpace: "nowrap" }}>
                  ${formatAmount(parseValue(averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
                </p>
              </div>
            )}
            {checkList[2].checked && (
              <div style={{ width: "fit-content" }}>
                <p className="muted" style={{ whiteSpace: "nowrap" }}>
                  Mark price:
                </p>
                <p className="price" style={{ whiteSpace: "nowrap" }}>
                  ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}
                </p>
              </div>
            )}
            {checkList[0].checked && (
              <div style={{ width: "fit-content" }}>
                <p className="muted" style={{ whiteSpace: "nowrap" }}>
                  Leverage:
                </p>
                <p className="price" style={{ whiteSpace: "nowrap" }}>
                  {formatAmount(parseValue(leverage, 30), 30, 2, true)}X
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="referral-code-container">
          <img className="logo" src={naviLogo} alt="Navi Logo" />
          <div className="referral-code">
            <div className="referral-code-info">
              {success && code ? (
                <>
                  <p className="label">Referral code</p>
                  <p className="code">{code}</p>
                </>
              ) : (
                <>
                  <p className="label">Trade now on</p>
                  <p className="code">navigator.exchange</p>
                </>
              )}
            </div>
            <div>
              <QRCodeSVG
                size={isMobile ? 36 : 56}
                value={userAffiliateCode?.code ? `${homeURL}/#/?ref=${userAffiliateCode.code}` : `${homeURL}`}
              />
            </div>
          </div>
        </div>
      </div>

      {!uploadedImageInfo && !uploadedImageError && (
        <div className="image-overlay-wrapper">
          <div className="image-overlay">
            <SpinningLoader />
            <p className="loading-text">
              <span>Generating shareable image...</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PositionShare;
