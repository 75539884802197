import { useMemo } from "react";
import Modal from "./Modal";
import styled from "styled-components";
import { formatAmount } from "lib/numbers";
import { BigNumber } from "ethers";
import moment from "moment";
const VestingDetailsModal = ({ isVisible, setIsVisible, lockData, pendingRewards }) => {
  const list = useMemo(() => {
    if (lockData?.userLocks && lockData?.userLocks?.length > 0) return [...lockData?.userLocks];
    return [];
  }, [lockData?.userLocks]);
  return (
    <ModalWrapper>
      <Modal label="Vesting Details" isVisible={isVisible} setIsVisible={setIsVisible} className={`wrong-modal`}>
        <div className="vesting-content">
          <div className="transaction-table vesting-table">
            <div className="transaction-line transaction-header">
              <div className="table-column">Vesting</div>
              <div className="table-column">Vested</div>
              <div className="table-column">Claimable</div>
              <div className="table-column">Ends In</div>
              <div className="table-column"></div>
            </div>
            <div className="transaction-content">
              {list.map((item, index) => {
                const pendingReward = pendingRewards?.[index]?.[0] || BigNumber.from(0);
                return (
                  <div className="transaction-line transaction-row" key={item?.startTime}>
                    <div className="table-column">{formatAmount(item?.amount || 0, 18, 2, true)} NAVI</div>
                    <div className="table-column">
                      {formatAmount(pendingReward?.add(item?.claimed), 18, 2, true)} NAVI
                    </div>
                    <div className="table-column">{formatAmount(pendingReward, 18, 2, true)} NAVI</div>
                    <div className={`table-column`}>
                      {moment(item?.endTime * 1000).format("DD MMM YYYY, HH:mm")}
                      {/* {new Date(item?.endTime * 1000).toLocaleString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })} */}
                    </div>
                    <div className="table-column"></div>
                  </div>
                );
              })}
              {list.length === 0 && <div className="no-data-record">No transactions data</div>}
            </div>
          </div>
          <div className="transaction-table-mobile vesting-table-mobile">
            {/* {!historyData || (historyData.length === 0 && <div className="no-data">No transactions data</div>)} */}
            {list.map((item, index) => {
              const pendingReward = pendingRewards?.[index]?.[0] || BigNumber.from(0);
              return (
                <div className="table-mobile-content" key={item?.startTime}>
                  <div className="content-line">
                    <div className="title">Vesting</div>
                    <div className="value">{formatAmount(item?.amount || 0, 18, 2, true)} NAVI</div>
                  </div>
                  <div className="content-line">
                    <div className="title">Vested</div>
                    <div className="value">{formatAmount(pendingReward?.add(item?.claimed), 18, 2, true)} NAVI</div>
                  </div>
                  <div className="content-line">
                    <div className="title">Claimable</div>
                    {formatAmount(pendingReward, 18, 2, true)} NAVI
                  </div>
                  <div className="content-line">
                    <div className="title">Ends In</div>
                    <div className={`value`}>
                      {new Date(item?.endTime * 1000).toLocaleString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </ModalWrapper>
  );
};

export default VestingDetailsModal;
const ModalWrapper = styled.div`
  font-size: 14px;
  .Modal-content {
    max-width: 660px !important;
    .table-column {
      font-weight: 500;
    }
  }
  .tokens-container {
    margin: 24px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: stretch;
    gap: 24px;

    .token {
      width: 60px;
      height: 60px;
    }
    .arrow {
      height: 24px;
      margin: auto 0;
    }
  }
  .input-container {
    width: 100%;
    padding: 16px;
    border-radius: 100px;
    background: var(--Nature-3, #11121b);
    input {
      padding: 0 !important;
      width: 100%;
      font-size: 14px !important;
    }
  }
  .BeginAccountTransfer-validations {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 0;
  }
`;
