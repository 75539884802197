import { ethers } from "ethers";
import { sample } from "lodash";
import { isDevelopment } from "lib/legacy";
import { NetworkMetadata } from "lib/wallets";
import { APP_RPC_INDEX, CUSTOM_RPC } from "./localStorage";

const { parseEther } = ethers.utils;

export const MAINNET = 56;
// export const ETH_MAINNET = 1;
export const AVALANCHE = 43114;
export const TESTNET = 97;
export const ARBITRUM_TESTNET = 421611;
export const ARBITRUM = 42161;
export const FANTOM = 250;
export const FANTOM_LEGACY = 2500;
export const FANTOM_TESTNET = 4002;
export const OP = 10;
export const BASE = 8453;
export const MUMBAI = 80001;
export const OP_TESTNET = 420;
export const SONIC_TESTNET = 146;
// TODO take it from web3
export const DEFAULT_CHAIN_ID = SONIC_TESTNET;
export const CHAIN_ID = DEFAULT_CHAIN_ID;

export const SUPPORTED_CHAIN_IDS = [SONIC_TESTNET];
export const SUPPORTED_CHAIN_ID_DEPOSIT = [SONIC_TESTNET, FANTOM_TESTNET];
export const SUPPORTED_V2_CHAINS = [SONIC_TESTNET, FANTOM_TESTNET];
export const SUPPORT_MERGED_ANALYTICS_CHAIN_IDS = [SONIC_TESTNET, FANTOM_TESTNET];
export const SUPPORTED_CHAIN_ID_VAULT = [ARBITRUM];

export const SUPPORTED_CHAIN_ID_NSLP = [SONIC_TESTNET, FANTOM_TESTNET];

export const MICROSWAP_SUPPORTED_CHAIN_IDS = [SONIC_TESTNET];

if (isDevelopment()) {
  // SUPPORTED_CHAIN_IDS.push(ARBITRUM_TESTNET);
}

export const IS_NETWORK_DISABLED = {
  [FANTOM]: false,
  [AVALANCHE]: false,
  [OP]: false,
  [MUMBAI]: false,
  [FANTOM_TESTNET]: false,
  [SONIC_TESTNET]: false,
};

export const CHAIN_NAMES_MAP = {
  [MAINNET]: "BSC",
  [TESTNET]: "BSC Testnet",
  [ARBITRUM_TESTNET]: "ArbRinkeby",
  [ARBITRUM]: "Arbitrum",
  [AVALANCHE]: "Avalanche",
  [OP]: "Optimism",
  [FANTOM]: "Fantom Opera",
  [BASE]: "Base",
  [FANTOM_TESTNET]: "Fantom Testnet",
  [SONIC_TESTNET]: "Sonic",
  // [OP]: "Optimistic",
};

export const CHAIN_NAMES_SIMPLE_MAP = {
  [OP]: "optimism",
  [FANTOM]: "fantom",
  [FANTOM_TESTNET]: "fantom-testnet",
  [SONIC_TESTNET]: "sonic",
  [BASE]: "base",
  [ARBITRUM]: "arbitrum",
};

export const GAS_PRICE_ADJUSTMENT_MAP = {
  [ARBITRUM]: "0",
  [AVALANCHE]: "3000000000", // 3 gwei
  [FANTOM]: "40000000000",
  [SONIC_TESTNET]: "40000000000",
  [FANTOM_TESTNET]: "40000000000",
  [OP]: "0",
  // [MUMBAI]: "40000000000",
};

export const MAX_GAS_PRICE_MAP = {
  [AVALANCHE]: "200000000000", // 200 gwei
  [FANTOM]: "50000000000",
};

export const HIGH_EXECUTION_FEES_MAP = {
  [ARBITRUM]: 3, // 3 USD
  [AVALANCHE]: 3, // 3 USD
  [FANTOM]: 3,
  [FANTOM_TESTNET]: 3,
  [SONIC_TESTNET]: 3,
  [OP]: 3,
  [BASE]: 3,
};

const constants = {
  [MAINNET]: {
    nativeTokenSymbol: "BNB",
    defaultCollateralSymbol: "BUSD",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 8,
    v2: false,
  },

  [TESTNET]: {
    nativeTokenSymbol: "BNB",
    defaultCollateralSymbol: "BUSD",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 8,
    v2: false,
  },

  [ARBITRUM_TESTNET]: {
    nativeTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDC.e",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [ARBITRUM]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC.e",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [AVALANCHE]: {
    nativeTokenSymbol: "AVAX",
    wrappedTokenSymbol: "WAVAX",
    defaultCollateralSymbol: "USDC.e",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0100001"),
  },
  [MUMBAI]: {
    nativeTokenSymbol: "MATIC",
    wrappedTokenSymbol: "WMATIC",
    defaultCollateralSymbol: "USDC.e",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.200001"),
  },
  [FANTOM]: {
    nativeTokenSymbol: "FTM",
    wrappedTokenSymbol: "WFTM",
    defaultCollateralSymbol: "USDC.e",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.200001"),
  },

  [SONIC_TESTNET]: {
    nativeTokenSymbol: "S",
    wrappedTokenSymbol: "WS",
    defaultCollateralSymbol: "USDC.e",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.200001"),
  },
  [OP]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC.e",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.00015"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0002"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00020001"),
  },
  [BASE]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC.e",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.00015"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0002"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00020001"),
  },
  [FANTOM_TESTNET]: {
    nativeTokenSymbol: "FTM",
    wrappedTokenSymbol: "WFTM",
    defaultCollateralSymbol: "USDC.e",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.2"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.200001"),
  },
};

const ALCHEMY_WHITELISTED_DOMAINS = ["navi.io", "app.navi.io"];

export const ARBITRUM_RPC_PROVIDERS = [getDefaultArbitrumRpcUrl(), "https://arbitrum-one.publicnode.com"];
export const AVALANCHE_RPC_PROVIDERS = ["https://api.avax.network/ext/bc/C/rpc"];
export const FANTOM_RPC_PROVIDERS = [
  "https://rpc.ftm.tools",
  "https://fantom.publicnode.com",
  "https://endpoints.omniatech.io/v1/fantom/mainnet/public",
  "https://1rpc.io/ftm",
  "https://fantom-mainnet.public.blastapi.io",
];
export const SONIC_TESTNET_RPC_PROVIDERS = [
  "https://rpc.soniclabs.com",
  "https://rpc.ankr.com/sonic",
  "https://rpc.ankr.com/sonic_mainnet",
  "https://sonic.drpc.org/",
];
export const MUMBAI_RPC_PROVIDERS = ["https://rpc-mumbai.maticvigil.com"];
export const OP_RPC_PROVIDERS = [
  "https://op-pokt.nodies.app",
  "https://optimism.publicnode.com",
  "https://optimism-mainnet.public.blastapi.io",
  "https://optimism.drpc.org",
  "https://optimism.meowrpc.com",
  "https://optimism.blockpi.network/v1/rpc/public",
];
export const BASE_RPC_PROVIDERS = ["https://base.publicnode.com", "https://1rpc.io/base", "https://base.meowrpc.com"];
export const FANTOM_TEST_RPC_PROVIDERS = [
  "https://fantom-testnet-rpc.publicnode.com",
  "https://rpc.testnet.fantom.network",
  "https://rpc.ankr.com/fantom_testnet",
];
export const RPC_NAMES = {
  [FANTOM]: ["FTM Tools", "FTM Public Node", "Omniatech", "1RPC", "FTM Blastapi"],
  [OP]: ["OP Pokt", "OP Public Node", "OP Blastapi", "OP Drpc", "OP Meowrpc", "OP Blockpi"],
  [ARBITRUM]: ["ARB1", "ARB Public Node"],
  [BASE]: ["BASE Public Node", "1RPC", "BASE Meowrpc"],
  [SONIC_TESTNET]: ["Sonic Labs", "Ankr", "Ankr Mainnet", "Sonic Drpc"],
};
// BSC TESTNET
// const RPC_PROVIDERS = [
//   "https://data-seed-prebsc-1-s1.binance.org:8545",
//   "https://data-seed-prebsc-2-s1.binance.org:8545",
//   "https://data-seed-prebsc-1-s2.binance.org:8545",
//   "https://data-seed-prebsc-2-s2.binance.org:8545",
//   "https://data-seed-prebsc-1-s3.binance.org:8545",
//   "https://data-seed-prebsc-2-s3.binance.org:8545"
// ]

// BSC MAINNET
export const BSC_RPC_PROVIDERS = [
  "https://bsc-dataseed.binance.org",
  "https://bsc-dataseed1.defibit.io",
  "https://bsc-dataseed1.ninicoin.io",
  "https://bsc-dataseed2.defibit.io",
  "https://bsc-dataseed3.defibit.io",
  "https://bsc-dataseed4.defibit.io",
  "https://bsc-dataseed2.ninicoin.io",
  "https://bsc-dataseed3.ninicoin.io",
  "https://bsc-dataseed4.ninicoin.io",
  "https://bsc-dataseed1.binance.org",
  "https://bsc-dataseed2.binance.org",
  "https://bsc-dataseed3.binance.org",
  "https://bsc-dataseed4.binance.org",
];

// export const ETH_MAINNET_PROVIDERS = ["https://rpc.ankr.com/eth"];

export const RPC_PROVIDERS = {
  // [ETH_MAINNET]: ETH_MAINNET_PROVIDERS,
  [MAINNET]: BSC_RPC_PROVIDERS,
  [ARBITRUM]: ARBITRUM_RPC_PROVIDERS,
  // [AVALANCHE]: AVALANCHE_RPC_PROVIDERS,
  [FANTOM]: FANTOM_RPC_PROVIDERS,
  [SONIC_TESTNET]: SONIC_TESTNET_RPC_PROVIDERS,
  [MUMBAI]: MUMBAI_RPC_PROVIDERS,
  [OP]: OP_RPC_PROVIDERS,
  [BASE]: BASE_RPC_PROVIDERS,
  [FANTOM_TESTNET]: FANTOM_TEST_RPC_PROVIDERS,
};

export const FALLBACK_PROVIDERS = {
  [MUMBAI]: MUMBAI_RPC_PROVIDERS,
  [FANTOM]: FANTOM_RPC_PROVIDERS,
  [FANTOM_TESTNET]: FANTOM_TEST_RPC_PROVIDERS,
  [SONIC_TESTNET]: SONIC_TESTNET_RPC_PROVIDERS,
  [ARBITRUM]: [getAlchemyHttpUrl()],
  [AVALANCHE]: ["https://avax-mainnet.gateway.pokt.network/v1/lb/626f37766c499d003aada23b"],
};
export const getLayerZeroExplorer = (chainId) => {
  if (chainId === FANTOM_TESTNET) return "https://testnet.layerzeroscan.com";
  return "https://layerzeroscan.com/";
};
export const NETWORK_METADATA: { [chainId: number]: NetworkMetadata } = {
  [MAINNET]: {
    chainId: "0x" + MAINNET.toString(16),
    chainName: "BSC",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: BSC_RPC_PROVIDERS,
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [OP]: {
    chainId: "0x" + OP.toString(16),
    chainName: "Optimism Ethereum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.optimism.io/"],
    blockExplorerUrls: ["https://optimistic.etherscan.io/"],
  },
  [BASE]: {
    chainId: "0x" + BASE.toString(16),
    chainName: "Base",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://base-mainnet.public.blastapi.io/"],
    blockExplorerUrls: ["https://basescan.org/"],
  },
  [TESTNET]: {
    chainId: "0x" + TESTNET.toString(16),
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
  [ARBITRUM_TESTNET]: {
    chainId: "0x" + ARBITRUM_TESTNET.toString(16),
    chainName: "Arbitrum Testnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://rinkeby.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://rinkeby-explorer.arbitrum.io/"],
  },
  [ARBITRUM]: {
    chainId: "0x" + ARBITRUM.toString(16),
    chainName: "Arbitrum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ARBITRUM_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(ARBITRUM)],
  },
  [AVALANCHE]: {
    chainId: "0x" + AVALANCHE.toString(16),
    chainName: "Avalanche",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: AVALANCHE_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(AVALANCHE)],
  },
  [FANTOM]: {
    chainId: "0x" + FANTOM.toString(16),
    chainName: "Fantom Opera",
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: FANTOM_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(FANTOM)],
  },
  [SONIC_TESTNET]: {
    chainId: "0x" + SONIC_TESTNET.toString(16),
    chainName: "Sonic",
    nativeCurrency: {
      name: "SONIC",
      symbol: "S",
      decimals: 18,
    },
    rpcUrls: SONIC_TESTNET_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(SONIC_TESTNET)],
  },
  [FANTOM_TESTNET]: {
    chainId: "0x" + FANTOM_TESTNET.toString(16),
    chainName: "Fantom Test",
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: FANTOM_TEST_RPC_PROVIDERS,
    blockExplorerUrls: [getExplorerUrl(FANTOM_TESTNET)],
  },
};

export const getConstant = (chainId: number, key: string) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }

  return constants[chainId][key];
};

export function getChainName(chainId: number) {
  return CHAIN_NAMES_MAP[chainId];
}

export function getSimpleChainName(chainId: number) {
  return CHAIN_NAMES_SIMPLE_MAP[chainId];
}

export function getDefaultArbitrumRpcUrl() {
  return "https://arb1.arbitrum.io/rpc";
}

export function getRpcUrl(chainId: number): string | undefined {
  // return sample(RPC_PROVIDERS[chainId]);
  const index = localStorage.getItem(`[${chainId},"${APP_RPC_INDEX}"]`);
  const customRPC = localStorage.getItem(`[${chainId},"${CUSTOM_RPC}"]`);

  if (customRPC && index === "-1") return customRPC.replaceAll('"', "");
  if (index && index !== "-1")
    // return sample(RPC_PROVIDERS[chainId]);
    return RPC_PROVIDERS[chainId][Number(index)];
  return RPC_PROVIDERS[chainId][0];
}

export function getAlchemyHttpUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "https://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "https://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getAlchemyWsUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "wss://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "wss://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}
export function getDocsClub(chainId) {
  if (chainId === FANTOM) {
    return "https://docs.navigator.exchange/navigator-club";
  } else if (chainId === OP) {
    return "https://docs.navigator.exchange/navigator-club-on-optimism";
  }
  return "https://docs.navigator.exchange/navigator-club";
}
export function getExplorerUrl(chainId) {
  if (chainId === 3) {
    return "https://ropsten.etherscan.io/";
  } else if (chainId === 42) {
    return "https://kovan.etherscan.io/";
  } else if (chainId === MAINNET) {
    return "https://bscscan.com/";
  } else if (chainId === TESTNET) {
    return "https://testnet.bscscan.com/";
  } else if (chainId === ARBITRUM_TESTNET) {
    return "https://testnet.arbiscan.io/";
  } else if (chainId === ARBITRUM) {
    return "https://arbiscan.io/";
  } else if (chainId === AVALANCHE) {
    return "https://snowtrace.io/";
  } else if (chainId === FANTOM) {
    return "https://ftmscan.com/";
  } else if (chainId === MUMBAI) {
    return "https://mumbai.polygonscan.com/";
  } else if (chainId === OP) {
    return "https://optimistic.etherscan.io/";
  } else if (chainId === BASE) {
    return "https://basescan.org/";
  } else if (chainId === FANTOM_TESTNET) {
    return "https://testnet.ftmscan.com/";
  } else if (chainId === SONIC_TESTNET) {
    return "https://sonicscan.org/";
  }

  return "https://etherscan.io/";
}

export const getLinkCDN = (chainId, isV2) => {
  let params = isV2 === true ? "-v2" : "";
  return chainId === FANTOM || chainId === MUMBAI
    ? `https://cdn.mummy.finance/nfts${params}/`
    : "https://cdn.mummy.finance/nfts-op/";
  // return chainId === FANTOM || chainId === MUMBAI
  //   ? `https://cdn.navigator.exchange/nfts${params}/`
  //   : "https://cdn.navigator.exchange/nfts-op/";
};
export const getApi = (chainId) => {
  return chainId === FANTOM || chainId === MUMBAI
    ? "https://api.navigator.exchange/fantom/api"
    : "https://api.navigator.exchange/optimism/api";
};
export function getHighExecutionFee(chainId) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3;
}

export function isSupportedChain(chainId) {
  return SUPPORTED_CHAIN_IDS.includes(chainId);
}

export const CHAIN_KEY_MAP = {
  [FANTOM_TESTNET]: "fantom-testnet",
  [FANTOM]: "fantom",
  [SONIC_TESTNET]: "sonic",
  [OP_TESTNET]: "op-testnet",
  [BASE]: "base",
};

export function getChainKey(chainId: number) {
  return CHAIN_KEY_MAP[chainId];
}
