import { SONIC_TESTNET, FANTOM, OP, FANTOM_TESTNET } from "config/chains";
import useNslpPrice from "./useNslpPrice";
import useInfoNslp from "./useInfoNslp";

function useNslpPriceMultiChain() {
  return {
    [SONIC_TESTNET]: useInfoNslp(SONIC_TESTNET).price,
    [FANTOM_TESTNET]: useInfoNslp(FANTOM_TESTNET)?.price,
    // [OP]: useInfoNslp(OP).price,
  };
}

export default useNslpPriceMultiChain;
