import { Link } from "react-router-dom";
import styled from "styled-components";
import Top2Icon from "img/analytics/top-2-icon.svg";
import Top3Icon from "img/analytics/top-3-icon.svg";

const WrapperItem = styled.div`
  position: relative;

  .copy-code {
    cursor: pointer;

    position: relative;
    z-index: 15;
    right: 30px;

    width: 100px;
    margin-left: 20px;

    font-weight: 500;
    color: #04d397;

    @media (max-width: 1024px) {
      width: unset !important;
      position: absolute;
      top: 21px;
      display: inline-flex !important;
      margin-right: 20px;

      align-self: flex-start;
      right: 50px;
    }
  }

  &.theme--light {
    .tradingVol,
    .winLoss {
      .label {
        color: #0d1a16b2;
      }
    }
  }
`;

const TraderItemWrapper = styled(Link)`
  position: relative;

  text-decoration: none;
  height: 64px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  gap: 20px;
  align-items: center;
  padding: 12px;
  background: #191b2e;
  border-radius: 16px;
  transition: 200ms;
  margin-top: 8px;
  overflow: hidden;

  &.trader-height {
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;

    .profile {
      column-gap: 48px;
      .rank {
        width: 32px;
      }
    }
  }

  .positive {
    color: #34f5ae;
  }

  .negative {
    color: rgb(246, 71, 93);
  }

  .zero {
    color: #fff;
  }

  .ribbon + .profile {
    @media (max-width: 1023px) {
      &::before {
        display: none;
      }

      .detail {
        margin-left: 50px;
      }
    }
  }

  .ribbon {
    position: fixed;
    background: #e8b80e;
    box-shadow: 0 0 0 999px #e8b80e;
    color: #0c0c0c;
    font-size: 10px;
    font-weight: bold;
    padding: 4px 0;
    clip-path: inset(0 -100%);
    inset: 0 auto auto 0;
    transform-origin: 100% 0;
    transform: translate(-29.3%) rotate(-45deg);
    position: absolute;
    z-index: 999;
  }

  .ribbon-alpha {
    background: #bbc1ff;
    box-shadow: #bbc1ff 0px 0px 0px 999px;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: flex-end;

    &.trader-height {
      height: unset;
    }

    > *:not(:first-child) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::before {
        content: attr(data-name);
        text-transform: capitalize;
        color: rgb(175, 175, 175);
      }
    }
  }

  .markPrice,
  .entryPrice {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  &:hover {
    scale: 1.02;
  }

  > * {
    flex: 1;
  }

  .wallet {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .avatar {
      @media (max-width: 1024px) {
        margin-left: auto;
      }
    }
  }

  .profile {
    display: flex;
    column-gap: 20px;
    align-items: center;

    @media (max-width: 1024px) {
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
      /* border-bottom: 1px solid rgb(54, 54, 54); */
      /* padding-bottom: 10px; */

      .rank {
        width: 32px !important;
      }
    }

    //first
    //second
    //third url("")

    .rank {
      width: 64px;
      height: 32px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      top: 0 !important;

      &.top {
        &::after {
          width: 100%;
          position: absolute;
          inset: 0;
          content: "";
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        &.top-1,
        &.top-2,
        &.top-3 {
          font-size: 24px;
        }
      }
      &.light {
        &.top-2 {
          &::after {
            background-image: url(${Top2Icon});
          }
        }

        &.top-3 {
          &::after {
            background-image: url(${Top3Icon});
          }
        }
      }
    }

    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      /* background-image: linear-gradient(225deg, rgb(137, 181, 133), rgb(242, 8, 199) 49%, rgb(120, 41, 35)); */
    }

    .detail {
      display: flex;
      align-items: center;
      gap: 8px;

      .Tooltip-handle {
        text-decoration: none;
      }
    }

    .token-info {
      display: flex;
      gap: 8px;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
      }
    }

    .token-detail {
      .name {
        color: #fff;

        span {
          font-weight: 600;
        }

        @media (max-width: 1024px) {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
      }

      .name span {
        font-size: 12px;
        color: #34f5ae;
      }

      .price {
        @media (max-width: 1024px) {
          display: none;
        }

        .title {
          font-size: 12px;
          color: #ffffff8a;
        }
        .value {
          font-size: 12px;
        }
      }
    }
  }

  .PnL {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fa3c58;

    @media (max-width: 1024px) {
      color: #34f5ae;
      img {
        display: none;
      }
    }
    &.positive {
      color: #34f5ae;
    }
  }

  .icon-view {
    position: absolute;
    /* top: 27px; */
    right: 20px;

    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

const StyledAnalyticsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1368px;
  padding-inline: 24px;
  margin: 0 auto 284px;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    padding-inline: 16px;
  }

  .filter {
    align-self: flex-end;
    display: flex;
    gap: 10px;
    font-weight: 600;

    > * {
      cursor: pointer;
      padding: 10px 15px;

      &:hover {
        color: #03f5ae;
      }
    }
  }

  .header-table {
    padding: 0 16px;
    width: 100%;
    height: 32px;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    font-weight: 500;
    margin-bottom: 5px;
    .group-arrow {
      margin-left: 5px;

      .down {
        margin-top: 2px;

        transform: rotate(180deg);
      }
    }

    span {
      display: flex;
      align-items: center;
      color: var(--Text-Text_Secondary, #828899);
      font-size: 12px;
      font-weight: 400;
      line-height: 140%;
    }

    div {
      span:first-child {
        justify-content: center;
      }
    }

    @media (max-width: 1024px) {
      display: none;
    }

    .filter {
      display: flex;
      align-items: center;

      span {
        padding: 0;
        display: flex;
        flex-direction: column;

        img {
          cursor: pointer;
          padding: 3px;
        }
      }

      img.down {
        transform: rotate(180deg);
      }
    }

    > * {
      flex: 1;
    }

    > div {
      display: flex;
      align-items: center;
      column-gap: 20px;

      span:first-child {
        width: 64px;
        text-align: center;
        .Tooltip {
          width: fit-content !important;
          margin-left: 4px;
        }
        .Tooltip-handle {
          width: fit-content !important;
        }
        .Tooltip-popup {
          width: fit-content !important;
        }
      }
    }
  }

  .orderedBy {
    align-self: flex-start;
  }

  .header-table {
    width: 100%;
    &.referrals {
      grid-template-columns: 2fr 1fr 1.3fr 1.3fr 1.7fr 1fr 1.3fr;
    }
  }

  .body-table {
    width: 100%;

    &.referrals {
      a {
        grid-template-columns: 2fr 1fr 1.3fr 1.3fr 1.7fr 1fr 1.3fr;
      }

      .Modal-title-bar {
        margin-left: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .Modal-content {
        .Modal-body {
          .header {
            display: flex;

            margin-bottom: 20px;

            > div:nth-child(1) {
              left: 16px;
              position: relative;
            }

            > div:nth-child(-n + 2) {
              flex: 1;
            }

            /* div {
              min-width: 110px;

              margin-right: 10px;

              &:last-child {
                margin-right: 0;
              }
            } */
          }
        }
      }
    }
  }
`;

const TabWrapper = styled.div`
  max-width: calc(1320px + 48px);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 286px;

  .top-filter {
    &:has(.label-last-update) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .label-last-update {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */

    &.onleft {
      display: block;
      text-align: right;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 1024px) {
    padding: 0 20px;
    max-width: unset;
  }

  .your-referrals {
    span {
      font-size: 16px;
    }

    .box-rewards {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 16px;
      margin-top: 20px;
      padding: 64px;

      border: 1px solid #ffffff1a;
    }
  }

  .time-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 26px;
    margin-left: auto;
    /* margin-bottom: 16px; */

    div {
      cursor: pointer;

      line-height: 24px;
      font-size: 14px;
      font-weight: 500;
      color: rgb(185, 185, 185);

      &.active {
        color: #34f5ae;
      }
    }

    @media (max-width: 767px) {
      justify-content: space-between;
    }
  }

  .round-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    margin: 20px 0;

    .label {
      font-size: 16px;

      color: #ffffff;
    }

    .select-round {
      cursor: pointer;

      display: flex;
      justify-content: space-between;

      width: 210px;
      padding: 10px 16px;

      border: 1px solid #ffffff1a;

      font-size: 16px;

      img {
        width: 16px;

        transform: rotate(180deg);
      }
    }
  }

  .filter-block {
    color: rgb(173, 171, 171);
    align-items: center;
  }

  @media (max-width: 767px) {
    &.account-postion-details {
      & > div:has(.back-url-styled) {
        gap: 12px !important;

        .info {
          gap: 8px !important;
        }
      }
    }
  }

  &.theme--light {
    .headingg {
      border-bottom-color: #0d1a16b2 !important;
    }

    .link-share {
      &:hover {
        path {
          fill: #02b27f;
        }
      }
    }
  }
`;

const StyledPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  margin-top: 20px;

  .rowsPerPage {
    select.decorated option:hover {
      box-shadow: 0 0 10px 100px #1882a8 inset;
      background: red;
    }
  }

  select {
    padding: 5px 10px;
    outline: none;
    background: transparent;
    border: 1px solid #ffffff1a;
    color: white;

    > * {
      color: black;
    }
  }

  option {
    background: #110e36;
    color: white;
  }

  .movePage {
    display: flex;
    align-items: center;
    span {
      margin: 0 8px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
`;

export { WrapperItem, TraderItemWrapper, StyledAnalyticsContent, StyledPagination, TabWrapper };
