import {
  IconCheck,
  IconCoin,
  IconFees,
  IconInterest,
  IconLocked,
  IconUsers,
  IconVolume,
  ImageBannerLanding,
  ImageChainLink,
  ImagePyth,
  ImageVerichains,
} from "assets";
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import Button from "components/Common/Button";
import { useMedia } from "react-use";
import { getAssetCoin } from "services";
import { MarketData } from "interfaces";
import { importImage, USD_DECIMALS } from "lib/legacy";
import { motion } from "framer-motion";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { StatsContext } from "contexts/StatsProvider";
import { bigNumberify, formatAmount } from "lib/numbers";
import CountUp from "react-countup";
import { NOT_WHITELISTED_TOKENS } from "config/tokens";

export const contentVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
      duration: 0.8,
    },
  },
};
export const descVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
      duration: 0.8,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.2, type: "spring", stiffness: 100, damping: 15 } },
};

const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
};

const BannerLanding = () => {
  const isMobile = useMedia("(max-width: 768px)");
  const [dataAssets, setDataAssets] = useState<MarketData[]>();
  const [isPlaying, setIsPlaying] = useState(true);
  const {
    v2,
    totalValueLocked,
    totalFees,
    totalUsers,
    totalTradingVolume,
    longOpenInterest,
    shortOpenInterest,
    totalAllVersion,
  } = useContext(StatsContext);

  const totalOpenInterest = useMemo(() => {
    let totalLongOpenInterest: any = bigNumberify(0);
    let totalShortOpenInterest = bigNumberify(0);
    if (longOpenInterest && totalAllVersion.longOpenInterest) {
      totalLongOpenInterest = totalAllVersion?.longOpenInterest;
    }
    if (shortOpenInterest && totalAllVersion.shortOpenInterest) {
      totalShortOpenInterest = totalAllVersion?.shortOpenInterest;
    }
    return totalLongOpenInterest.add(totalShortOpenInterest);
  }, [longOpenInterest, shortOpenInterest]);
  const listOverview = [
    {
      title: "Total Volume",
      value: (
        <CountUp
          end={parseFloat(formatAmount(totalAllVersion?.totalTradingVolume, USD_DECIMALS, 0))}
          separator=","
          prefix="$"
        />
      ),
      icon: <IconVolume size={isMobile ? "24" : "36"} />,
    },
    {
      title: "Total Value Locked",
      value: (
        <CountUp
          end={parseFloat(formatAmount(totalValueLocked?.["totalAll"], USD_DECIMALS, 0))}
          separator=","
          prefix="$"
        />
      ),
      icon: <IconLocked size={isMobile ? "24" : "36"} />,
    },
    {
      title: "Total Fees",
      value: (
        <CountUp end={parseFloat(formatAmount(totalAllVersion?.totalFees, USD_DECIMALS, 0))} separator="," prefix="$" />
      ),
      icon: <IconFees size={isMobile ? "24" : "36"} />,
    },
    {
      title: "Open Interest",
      value: <CountUp end={parseFloat(formatAmount(totalOpenInterest, USD_DECIMALS, 0))} separator="," prefix="$" />,
      icon: <IconInterest width={isMobile ? "24" : "36"} height={isMobile ? "24" : "36"} />,
    },
    {
      title: "Total Users",
      value: <CountUp end={parseFloat(formatAmount(totalAllVersion?.totalUsers, 0, 0))} />,
      icon: <IconUsers width={isMobile ? "24" : "36"} height={isMobile ? "24" : "36"} />,
    },
  ];

  const handleGetAssetsCoin = async () => {
    try {
      const res = await getAssetCoin();
      if (res?.data) {
        setDataAssets(res.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetAssetsCoin();
  }, []);

  return (
    <Wrapper>
      <div className="overlay-marquee" />
      <motion.div initial="hidden" whileInView="visible" className="banner-wrap ">
        <div className="bg-banner" />

        <div className="wrap-title ">
          <div className="title-banner container">
            <div className="wrap-border-left">
              <div className="border">
                <div className="border-child-top-left" />
                <div className="border-child-bottom-left" />
              </div>
            </div>
            <div className="wrap-border-right">
              <div className="border">
                <div className="border-child-top-right" />
                <div className="border-child-bottom-right" />
              </div>
            </div>
            <div className="box-title">
              <motion.div initial="hidden" whileInView="visible" variants={contentVariants} className="title">
                Decentralized Liquidity Marketplace
              </motion.div>
              <motion.div initial="hidden" whileInView="visible" variants={descVariant} className="desc">
                Effortless trading, continuous liquidity. Across Crypto, Forex, and beyond.
              </motion.div>
            </div>
            <motion.a
              href="https://app.navigator.exchange/#/trade"
              initial="hidden"
              whileInView="visible"
              variants={descVariant}
              className="default-btn"
            >
              Start Trading
            </motion.a>
          </div>
          <Marquee
            style={{
              background: "transparent",
            }}
            autoFill
            play={isPlaying}
          >
            {dataAssets &&
              dataAssets
                .filter((item) => !NOT_WHITELISTED_TOKENS.includes(item.symbol))
                .map((item, idx) => {
                  const url = `https://app.navigator.exchange/#/trade?symbol=${encodeURIComponent(item.symbol).replace(
                    /%2F/g,
                    "/"
                  )}`;
                  return (
                    <ExternalLink href={url} className="wrap-coin" key={idx}>
                      <div onMouseEnter={() => setIsPlaying(false)} onMouseLeave={() => setIsPlaying(true)}>
                        <img
                          className="coin-img"
                          src={importImage("ic_" + item.symbol.split("/")[0]?.toLowerCase() + "_40.svg")}
                          alt="symbol"
                        />
                      </div>
                    </ExternalLink>
                  );
                })}
          </Marquee>
        </div>
        <div className="audited container">
          <motion.div
            initial={{
              opacity: 0,
              x: -50,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: {
                duration: 0.4,
              },
            }}
            className="audi-item"
          >
            <div className="audi-text">
              <IconCheck />
              Audited by
            </div>
            <a href="https://github.com/verichains/public-audit-reports/blob/main/Verichains%20Public%20Audit%20Report%20-%20Mummy%20Finance%20-%20v1.0.pdf" target="_blank" rel="noreferrer">
              <img src={ImageVerichains} alt="" />
            </a>
          </motion.div>
          <div className="wall" />

          {/* <motion.div
            initial={{
              opacity: 0,
              y: 20,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.4,
              },
            }}
            className="audi-item"
          >
            <div className="audi-text">Powered by</div>
            <a href="https://chain.link/" target="_blank" rel="noreferrer">
              <img src={ImageChainLink} alt="" />
            </a>
          </motion.div> */}
          {/* <div className="wall" /> */}
          <motion.div
            initial={{
              opacity: 0,
              x: 50,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: {
                duration: 0.4,
              },
            }}
            className="audi-item"
          >
            <div className="audi-text">Powered by</div>
            <a href="https://pyth.network/" target="_blank" rel="noreferrer">
              <img src={ImagePyth} alt="" />
            </a>
          </motion.div>
        </div>
        <motion.div initial="hidden" whileInView="visible" variants={listVariants} className="wrap-overview container">
          {listOverview.map((item, idx) => (
            <motion.div variants={itemVariants} className="item-overview" key={idx}>
              <div className="overview-icon">{item.icon}</div>
              <div className="overview-info">
                <div className="overview-value">{item.value}</div>
                <div className="overview-title">{item.title}</div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </Wrapper>
  );
};

export default BannerLanding;

const Wrapper = styled.div`
  position: relative;

  .banner-wrap {
    padding-top: 214px;
    position: relative;
    @media screen and (max-width: 768px) {
      padding-top: 170px;
    }
  }
  .wrap-title {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title-banner {
    position: absolute;
    z-index: 90;
    display: flex;
    width: 871px;
    padding: 36px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: var(--ScrollBar-Background, rgba(255, 255, 255, 0.05));
    backdrop-filter: blur(24px);
    @media screen and (max-width: 768px) {
      width: inherit;
      padding: 24px 24px;
    }
  }
  .box-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    width: 100%;
  }
  .title {
    color: var(--Primary, #90fb75);
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }
  .desc {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .border {
    width: 16px;
    background-color: #91fb75;
    height: 100%;
    position: relative;
    @media screen and (max-width: 768px) {
      width: 12px;
    }
  }
  .wrap-border-left {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .border-child-top-left {
    width: 16px;
    height: 16px;
    background-color: #91fb75;
    position: absolute;
    top: -17px;
    right: -17px;
    @media screen and (max-width: 768px) {
      width: 12px;
      height: 12px;
      top: -13px;
      right: -13px;
    }
  }
  .border-child-bottom-left {
    width: 16px;
    height: 16px;
    background-color: #91fb75;
    position: absolute;
    bottom: -17px;
    right: -17px;
    @media screen and (max-width: 768px) {
      width: 12px;
      height: 12px;
      bottom: -13px;
      right: -13px;
    }
  }
  .wrap-border-right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .border-child-top-right {
    width: 16px;
    height: 16px;
    background-color: #91fb75;
    position: absolute;
    top: -17px;
    right: 17px;
    @media screen and (max-width: 768px) {
      width: 12px;
      height: 12px;
      top: -13px;
      right: 11px;
    }
  }
  .border-child-bottom-right {
    width: 16px;
    height: 16px;
    background-color: #91fb75;
    position: absolute;
    bottom: -17px;
    right: 17px;
    @media screen and (max-width: 768px) {
      width: 12px;
      height: 12px;
      bottom: -13px;
      right: 11px;
    }
  }
  .audited {
    padding-top: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    position: relative;
    z-index: 50;
    @media screen and (max-width: 768px) {
      padding-top: 120px;
      align-items: end;
      gap: 12px;
    }
  }
  .audi-item {
    display: flex;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  .audi-text {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .wall {
    width: 1px;
    align-self: stretch;
    background: rgba(255, 255, 255, 0.1);
  }
  .overlay-marquee {
    position: absolute;
    inset: 0;
    background: linear-gradient(90deg, transparent 0%, rgba(0, 0, 0, 0.8) 50%, transparent 100%);
    pointer-events: none;
    z-index: 10;
  }
  .wrap-coin {
    margin-left: 48px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      margin-left: 24px;
    }
  }
  .coin-mb {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  .coin-img {
    width: 80px;
    height: 80px;
    @media screen and (max-width: 768px) {
      width: 60px;
      height: 60px;
    }
  }
  .coin-pc {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .bg-banner {
    position: absolute;
    inset: 0;
    filter: brightness(200%);
  }
  .wrap-overview {
    border-radius: 24px;
    background: #e1ffd8;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 40;
    justify-content: center;
    width: fit-content;
    margin: 80px auto 0;
    width: 1320px;
    @media screen and (max-width: 1320px) {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 50px auto 0;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  .item-overview {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 768px) {
      padding: 20px;
      &:first-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        grid-column: span 2 / span 2;
      }
      &:nth-child(2) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
      &:nth-child(3) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
  .item-overview:last-child {
    border-right: none;
  }
  .overview-icon {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    border-radius: 16px;
    background: var(--Primary, #90fb75);
  }
  .overview-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  .overview-value {
    color: var(--Black, #000);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  .overview-title {
    color: var(--Black, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
`;
