import { IconDiscord, IconLogo, IconMedium, IconTelegram, IconX } from "assets";
import React from "react";
import styled from "styled-components";

const socialList = [
  {
    icon: <IconX />,
    link: "https://x.com/NaviExSonic",
  },
  {
    icon: <IconMedium />,
    link: "https://medium.com/@mummyftm",
  },
  {
    icon: <IconTelegram />,
    link: "https://t.me/navigator_exchange",
  },
  {
    icon: <IconDiscord />,
    link: "https://discord.com/invite/23gmsJHmYx",
  },
];

const subMenu = [
  // {
  //   title: "White Paper",
  //   link: "",
  // },
  // {
  //   title: "Audit Report",
  //   link: "",
  // },
  {
    title: "Docs",
    link: "https://docs.navigator.exchange/",
  },
  {
    title: "Contact Us",
    link: "https://discord.com/invite/23gmsJHmYx",
  },
];
const FooterLanding = () => {
  return (
    <Wrapper>
      <div className="container wrapper">
        <div className="info">
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <IconLogo />
          </div>

          <div className="copyright">Copyrights © {new Date().getFullYear()} Navigator. All rights reserved.</div>
        </div>
        <div className="right-foot">
          <div className="box-social">
            {socialList.map((item, idx) => (
              <a className="item-social" href={item.link} target="_blank" key={idx}>
                {item.icon}
              </a>
            ))}
          </div>
          <div className="box-menu">
            {subMenu.map((item, idx) => (
              <a className="item-menu" href={item.link} target="_blank" key={idx}>
                {item.title}
              </a>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default FooterLanding;

const Wrapper = styled.div`
  display: flex;
  padding: 120px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Base-Unit-XS-8, 8px);
  align-self: stretch;
  background: #0c0f0c;
  @media screen and (max-width: 768px) {
    padding: 60px 0px;
  }
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }
  }
  .info {
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .copyright {
    color: Text/Text_Primary;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    margin-top: 24px;
  }
  .box-social {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }
  .item-social {
    display: flex;
    padding: var(--Base-Unit-XS-8, 8px);
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    border-radius: 1000px;
    background: var(--Primary, #90fb75);
    &:hover {
      background: var(--Primary, #79ee5c);
    }
  }
  .right-foot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 24px;
    @media screen and (max-width: 768px) {
      align-items: center;
    }
  }
  .box-menu {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    justify-content: flex-end;
  }
  .item-menu {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
