function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16">
      <path
        fill="#90FB75"
        d="M8.658 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zm-.75 9l-2.75-2.75.5-.75 2.25 1.5 3.49-3 .76.75-4.25 4.25z"
      ></path>
    </svg>
  );
}

export default Icon;
