import React, { useEffect, useState } from "react";
import useSWR from "swr";
import cx from "classnames";
import { ethers } from "ethers";
import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import {
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DEPOSIT_FEE,
  DUST_BNB,
  getLiquidationPrice,
  MAX_ALLOWED_LEVERAGE,
  getLiqRisk,
} from "lib/legacy";
import { getContract } from "config/contracts";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";

import PositionRouter from "abis/PositionRouter.json";
import Token from "abis/Token.json";
import Tooltip from "../Tooltip/Tooltip";

import { getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { getTokenInfo } from "domain/tokens/utils";
import { approveTokens, shouldRaiseGasError } from "domain/tokens";
import { usePrevious } from "lib/usePrevious";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import ExternalLink from "components/ExternalLink/ExternalLink";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
import ExchangeInfoRow from "components/ExchangeV2/ExchangeInfoRow";
import { renderRisk, renderRiskV1 } from "lib/helper";
import PercentageButtons from "components/ExchangeV2/PercentageButtons";
import { TopPositionTab } from "pages/AnalyticsV2/components";
import TokenTopInfo from "./TokenTopInfo";

const DEPOSIT = "Deposit";
const WITHDRAW = "Withdraw";
const EDIT_OPTIONS = [DEPOSIT, WITHDRAW];
const { AddressZero } = ethers.constants;

export default function PositionEditor(props) {
  const {
    pendingPositions,
    setPendingPositions,
    positionsMap,
    positionKey,
    isVisible,
    setIsVisible,
    infoTokens,
    active,
    account,
    library,
    collateralTokenAddress,
    pendingTxns,
    setPendingTxns,
    getUsd,
    getLeverage,
    savedIsPnlInLeverage,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    chainId,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    isContractAccount,
    selectedPosition,
  } = props;
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined;
  const [option, setOption] = useState(DEPOSIT);
  const [fromValue, setFromValue] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isSwapping, setIsSwapping] = useState(false);
  const prevIsVisible = usePrevious(isVisible);
  const longOrShortText = position?.isLong ? `Long` : `Short`;

  const routerAddress = getContract(chainId, "Router");
  const positionRouterAddress = getContract(chainId, "PositionRouter");

  const { data: tokenAllowance } = useSWR(
    [active, chainId, collateralTokenAddress, "allowance", account, routerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  // const { data: minExecutionFee } = useSWR([active, chainId, positionRouterAddress, "minExecutionFee"], {
  //   fetcher: fetcher(library, PositionRouter),
  // });

  const isDeposit = option === DEPOSIT;
  const isWithdrawal = option === WITHDRAW;

  const needPositionRouterApproval = !positionRouterApproved;

  let collateralToken;
  let maxAmount;
  let maxAmountFormatted;
  let maxAmountFormattedFree;
  let fromAmount;
  let needApproval;

  let convertedAmount;
  let convertedAmountFormatted;

  let nextLeverage;
  let nextLeverageExcludingPnl;
  let liquidationPrice;
  let nextLiquidationPrice;
  let nextCollateral;

  let title;
  let collateralDelta;
  let newLiqRisk;
  let LiqRisk;
  if (position) {
    title = `Edit ${longOrShortText} ${position.indexToken.symbol}`;
    collateralToken = position.collateralToken;
    liquidationPrice = getLiquidationPrice(position);

    if (isDeposit) {
      fromAmount = parseValue(fromValue, collateralToken.decimals);
      maxAmount = collateralToken ? collateralToken.balance : bigNumberify(0);
      const tokenPrice = position?.collateralToken?.minPrice ? position.collateralToken.minPrice : bigNumberify(0);
      const minLeverage = parseValue(1.1, 30);
      const maxCollateral = position.size.mul(parseValue(1, USD_DECIMALS)).div(minLeverage).sub(position.collateral);

      const amountMaxCollateral = tokenPrice.gt(0)
        ? maxCollateral.mul(parseValue(1, collateralToken.decimals)).div(tokenPrice)
        : bigNumberify(0);
      maxAmount = amountMaxCollateral ? amountMaxCollateral : maxAmount;
      maxAmount = maxAmount.gt(collateralToken.balance) ? collateralToken.balance : maxAmount;
      maxAmountFormatted = formatAmount(maxAmount, collateralToken.decimals, 4, true);
      maxAmountFormattedFree = formatAmountFree(maxAmount, collateralToken.decimals, 8);
      if (fromAmount) {
        convertedAmount = getUsd(fromAmount, position.collateralToken.address, false, infoTokens);
        convertedAmountFormatted = formatAmount(convertedAmount, USD_DECIMALS, 2);
      }
    } else {
      fromAmount = parseValue(fromValue, USD_DECIMALS);
      maxAmount = position.collateral;
      const minCollateralRemain = parseValue(10, USD_DECIMALS);
      const maxLeverage = parseValue(MAX_ALLOWED_LEVERAGE(chainId) / BASIS_POINTS_DIVISOR, USD_DECIMALS);
      const minCollateral = position.size.mul(parseValue(1, USD_DECIMALS)).div(maxLeverage);

      const minCollateralEstimated = minCollateral?.lte(minCollateralRemain) ? minCollateralRemain : minCollateral;
      maxAmount = position.collateral.sub(minCollateralEstimated).lte(0)
        ? bigNumberify(0)
        : position.collateral.sub(minCollateralEstimated);

      maxAmountFormatted = formatAmount(maxAmount, USD_DECIMALS, 2, true);
      maxAmountFormattedFree = formatAmountFree(maxAmount, USD_DECIMALS, 2);
      if (fromAmount) {
        convertedAmount = fromAmount.mul(expandDecimals(1, collateralToken.decimals)).div(collateralToken.maxPrice);
        convertedAmountFormatted = formatAmount(convertedAmount, collateralToken.decimals, 4, true);
      }
    }
    needApproval = isDeposit && tokenAllowance && fromAmount && fromAmount.gt(tokenAllowance);

    if (fromAmount) {
      collateralDelta = isDeposit ? convertedAmount : fromAmount;
      if (position.isLong) {
        collateralDelta = collateralDelta.mul(BASIS_POINTS_DIVISOR - DEPOSIT_FEE).div(BASIS_POINTS_DIVISOR);
      }
      nextLeverage = getLeverage({
        size: position.size,
        collateral: position.collateral,
        collateralDelta,
        increaseCollateral: isDeposit,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: savedIsPnlInLeverage,
      });
      nextLeverageExcludingPnl = getLeverage({
        size: position.size,
        collateral: position.collateral,
        collateralDelta,
        increaseCollateral: isDeposit,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: false,
      });

      nextLiquidationPrice = getLiquidationPrice({
        isLong: position.isLong,
        size: position.size,
        collateral: position.collateral,
        averagePrice: position.averagePrice,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        collateralDelta,
        increaseCollateral: isDeposit,
      });

      nextCollateral = isDeposit ? position.collateral.add(collateralDelta) : position.collateral.sub(collateralDelta);
    }
    const isLong = position.isLong;
    const pnlWithoutFee_LiqPrice =
      position.markPrice && position.averagePrice
        ? isLong
          ? liquidationPrice.sub(position.averagePrice).mul(position.size).div(position.averagePrice)
          : position.averagePrice.sub(liquidationPrice).mul(position.size).div(position.averagePrice)
        : bigNumberify(0);

    const pnlWithoutFee_MarkPrice =
      position.markPrice && position.averagePrice
        ? isLong
          ? position.markPrice.sub(position.averagePrice).mul(position.size).div(position.averagePrice)
          : position.averagePrice.sub(position.markPrice).mul(position.size).div(position.averagePrice)
        : bigNumberify(0);

    LiqRisk =
      position.markPrice && position.averagePrice
        ? pnlWithoutFee_MarkPrice.mul(parseValue(100, USD_DECIMALS)).div(pnlWithoutFee_LiqPrice)
        : 0;
    const pnlWithoutFee_LiqPriceNew =
      position.markPrice && position.averagePrice && nextLiquidationPrice
        ? isLong
          ? nextLiquidationPrice.sub(position.averagePrice).mul(position.size).div(position.averagePrice)
          : position.averagePrice.sub(nextLiquidationPrice).mul(position.size).div(position.averagePrice)
        : bigNumberify(0);

    newLiqRisk =
      nextCollateral && nextCollateral.gt(0) && !pnlWithoutFee_LiqPriceNew.eq(0)
        ? pnlWithoutFee_MarkPrice.mul(parseValue(100, USD_DECIMALS)).div(pnlWithoutFee_LiqPriceNew)
        : null;
  }

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (isDeposit) return [`Deposit disabled, pending ${getChainName(chainId)} upgrade`];
      return [`Withdraw disabled, pending ${getChainName(chainId)} upgrade`];
    }
    if (!fromAmount) {
      return `Enter an amount`;
    }
    if (nextLeverage && nextLeverage.eq(0)) {
      return `Enter an amount`;
    }
    if (isDeposit && fromAmount) {
      if (fromAmount.gt(collateralToken.balance)) {
        return `Insufficient balance`;
      }
    }
    if (!isDeposit && fromAmount) {
      if (fromAmount.gt(position.collateral)) {
        return `Insufficient balance`;
      }
    }
    if (!isDeposit && fromAmount) {
      if (fromAmount.gte(position.collateral)) {
        return `Min order: 10 USD`;
      }
      if (position.collateral.sub(fromAmount).lt(expandDecimals(10, USD_DECIMALS))) {
        return `Min order: 10 USD`;
      }
    }

    if (!isDeposit && fromAmount && nextLiquidationPrice) {
      if (position.isLong && position.markPrice.lt(nextLiquidationPrice)) {
        return `Invalid liq. price`;
      }
      if (!position.isLong && position.markPrice.gt(nextLiquidationPrice)) {
        return `Invalid liq. price`;
      }
    }

    if (nextLeverageExcludingPnl && nextLeverageExcludingPnl.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return `Min leverage: 1.1x`;
    }

    if (nextLeverage && nextLeverage.gt(MAX_ALLOWED_LEVERAGE(chainId))) {
      return `Max leverage: ${(MAX_ALLOWED_LEVERAGE(chainId) / BASIS_POINTS_DIVISOR).toFixed(1)}x`;
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isSwapping) {
      return false;
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false;
    }
    if (isPositionRouterApproving) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isSwapping) {
      if (isDeposit) {
        return `Depositing...`;
      }
      return `Withdrawing...`;
    }

    if (isApproving) {
      return `Approving ${position.collateralToken.symbol}...`;
    }
    if (needApproval) {
      return `Approve ${position.collateralToken.symbol}`;
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return `Enabling Leverage`;
    }

    if (isPositionRouterApproving) {
      return `Enabling Leverage...`;
    }

    if (needPositionRouterApproval) {
      return `Enable Leverage`;
    }

    if (isDeposit) {
      return `Deposit`;
    }

    return `Withdraw`;
  };

  const resetForm = () => {
    setFromValue("");
  };

  useEffect(() => {
    if (prevIsVisible !== isVisible) {
      resetForm();
    }
  }, [prevIsVisible, isVisible]);

  const depositCollateral = async () => {
    setIsSwapping(true);
    const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;
    const path = [tokenAddress0];
    const indexTokenAddress =
      position.indexToken.address === AddressZero ? nativeTokenAddress : position.indexToken.address;

    const priceBasisPoints = position.isLong ? 11000 : 9000;
    const priceLimit = position.indexToken.maxPrice.mul(priceBasisPoints).div(10000);

    const referralCode = ethers.constants.HashZero;
    let params = [
      path, // _path
      indexTokenAddress, // _indexToken
      fromAmount, // _amountIn
      0, // _minOut
      0, // _sizeDelta
      position.isLong, // _isLong
      priceLimit, // _acceptablePrice
      minExecutionFee, // _executionFee
      referralCode, // _referralCode
      AddressZero, // _callbackTarget
    ];

    let method = "createIncreasePosition";
    let value = minExecutionFee;
    if (collateralTokenAddress === AddressZero) {
      method = "createIncreasePositionETH";
      value = fromAmount.add(minExecutionFee);
      params = [
        path, // _path
        indexTokenAddress, // _indexToken
        0, // _minOut
        0, // _sizeDelta
        position.isLong, // _isLong
        priceLimit, // _acceptablePrice
        minExecutionFee, // _executionFee
        referralCode, // _referralCode
        AddressZero, // _callbackTarget
      ];
    }

    if (shouldRaiseGasError(getTokenInfo(infoTokens, collateralTokenAddress), fromAmount)) {
      setIsSwapping(false);
      helperToast.error(`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ETH for gas`);
      return;
    }

    const contract = new ethers.Contract(positionRouterAddress, PositionRouter.abi, library.getSigner());
    callContract(chainId, contract, method, params, {
      value,
      sentMsg: `Deposit submitted.`,
      successMsg: `Requested deposit of ${formatAmount(fromAmount, position.collateralToken.decimals, 4)} ${
        position.collateralToken.symbol
      } into ${position.indexToken.symbol} ${longOrShortText}.`,
      failMsg: `Deposit failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setFromValue("");
        setIsVisible(false);

        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            collateralSnapshot: position.collateral,
            expectingCollateralChange: true,
          },
        };

        setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSwapping(false);
      });
  };

  const withdrawCollateral = async () => {
    setIsSwapping(true);
    const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;
    const indexTokenAddress =
      position.indexToken.address === AddressZero ? nativeTokenAddress : position.indexToken.address;
    const priceBasisPoints = position.isLong ? 9000 : 11000;
    const priceLimit = position.indexToken.maxPrice.mul(priceBasisPoints).div(10000);

    const withdrawETH =
      !isContractAccount && (collateralTokenAddress === AddressZero || collateralTokenAddress === nativeTokenAddress);
    const params = [
      [tokenAddress0], // _path
      indexTokenAddress, // _indexToken
      fromAmount, // _collateralDelta
      0, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit, // _acceptablePrice
      0, // _minOut
      minExecutionFee, // _executionFee
      withdrawETH, // _withdrawETH
      AddressZero, // _callbackTarget
    ];

    const method = "createDecreasePosition";

    const contract = new ethers.Contract(positionRouterAddress, PositionRouter.abi, library.getSigner());
    callContract(chainId, contract, method, params, {
      value: minExecutionFee,
      sentMsg: `Withdrawal submitted.`,
      successMsg: `Requested withdrawal of ${formatAmount(fromAmount, USD_DECIMALS, 2)} USD from ${
        position.indexToken.symbol
      } ${longOrShortText}.`,
      failMsg: `Withdrawal failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setFromValue("");
        setIsVisible(false);

        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            collateralSnapshot: position.collateral,
            expectingCollateralChange: true,
          },
        };
      })
      .finally(() => {
        setIsSwapping(false);
      });
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: collateralTokenAddress,
        spender: routerAddress,
        chainId: chainId,
        infoTokens,
        getTokenInfo,
        pendingTxns,
        setPendingTxns,
      });
      return;
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: isDeposit ? `Enable deposit sent.` : `Enable withdraw sent.`,
        failMsg: isDeposit ? `Enable deposit failed.` : `Enable withdraw failed.`,
      });
      return;
    }

    if (isDeposit) {
      depositCollateral();
      return;
    }

    withdrawCollateral();
  };
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const EDIT_OPTIONS_LABELS = {
    [DEPOSIT]: `Deposit`,
    [WITHDRAW]: `Withdraw`,
  };
  const onFromValueChange = (e) => {
    const value = e.target.value;
    const decimals = isDeposit ? position.collateralToken.decimals : 6;
    if (value && value.includes(".")) {
      const decimalsAfter = value.split(".")[1];
      if (decimalsAfter.length <= decimals) {
        setFromValue(value);
      }
    } else setFromValue(value);
  };
  const handleSelectPercentageCloseQuantity = (percentage) => {
    if (isDeposit) {
      const value = maxAmount
        .mul(parseValue(percentage, collateralToken.decimals))
        .div(parseValue(1, collateralToken.decimals));
      setFromValue(formatAmount(value, collateralToken.decimals, collateralToken.decimals, false));
    } else {
      const value = maxAmount.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
      setFromValue(formatAmount(value, 30, 6, false));
    }
  };
  return (
    <Wrapper className="PositionEditor">
      {position && (
        <Modal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label="Edit Collateral"
          className={`PositionSeller-modal confirmation-modal add-to-position-modal add-to-position-modal1`}
        >
          <div className="order-title-line order-title-line-details" style={{ paddingBottom: "0px" }}>
            <TokenTopInfo
              symbol={position?.indexToken?.symbol}
              isLong={position?.isLong}
              leverageOption={formatAmount(position?.leverage, 4, 2)}
              orderOption="Market"
              posId={selectedPosition?.posId}
            />
          </div>

          <div className="content-container">
            <Tab
              options={EDIT_OPTIONS}
              optionLabels={EDIT_OPTIONS_LABELS}
              option={option}
              setOption={setOption}
              onChange={resetForm}
            />

            {(isDeposit || isWithdrawal) && (
              <div>
                <div className="Exchange-swap-section">
                  <div className="Exchange-swap-section-top">
                    <Tooltip
                      position="left-top"
                      handle={
                        <div className="muted">
                          {convertedAmountFormatted && (
                            <div className="Exchange-swap-usd">
                              {isDeposit ? `Deposit` : `Withdraw`}: {convertedAmountFormatted}{" "}
                              {isDeposit ? "USD" : position.collateralToken.symbol}
                            </div>
                          )}
                          {!convertedAmountFormatted && `${isDeposit ? `Deposit` : `Withdraw`}`}
                        </div>
                      }
                      renderContent={() => {
                        return (
                          <>
                            {isDeposit
                              ? "Deposit an additional amount to ensure the leverage is not less than 1.1x."
                              : `Withdraw collateral amount to ensure the leverage is not greater than ${
                                  MAX_ALLOWED_LEVERAGE(chainId) / BASIS_POINTS_DIVISOR
                                }x.`}
                          </>
                        );
                      }}
                    />

                    {maxAmount && (
                      <div
                        className="muted align-right clickable"
                        onClick={() => {
                          handleSelectPercentageCloseQuantity(1);
                        }}
                      >
                        <span>Max: {maxAmountFormatted}</span>
                      </div>
                    )}
                  </div>
                  <div className="Exchange-swap-section-bottom">
                    <div className="Exchange-swap-input-container">
                      <input
                        type="number"
                        min="0"
                        placeholder="0.0"
                        className="Exchange-swap-input"
                        value={fromValue}
                        onChange={onFromValueChange}
                      />
                      {/* {fromValue !== maxAmountFormattedFree && (
                        <div
                          className="Exchange-swap-max"
                          onClick={() => {
                            setFromValue(maxAmountFormattedFree);
                          }}
                        >
                          <span>MAX</span>
                        </div>
                      )} */}
                    </div>
                    <div className="PositionEditor-token-symbol">
                      {isDeposit ? position.collateralToken.symbol : "USD"}
                    </div>
                  </div>
                </div>
                <PercentageButtons
                  onChangePercentage={handleSelectPercentageCloseQuantity}
                  balance={Number(
                    formatAmount(
                      maxAmount,
                      isDeposit ? collateralToken.decimals : 30,
                      isDeposit ? collateralToken.decimals : 30,
                      false
                    )
                  )}
                  value={fromValue}
                />
                <div className="PositionEditor-info-box">
                  {minExecutionFeeErrorMessage && (
                    <div className="Confirmation-box-warning">{minExecutionFeeErrorMessage}</div>
                  )}
                  <div style={{ height: "16px" }} />
                  <ExchangeInfoRow label={`Mark Price`}>
                    ${formatAmount(position.markPrice, USD_DECIMALS, position?.indexToken?.displayDecimals || 2, true)}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Size`}>
                    ${formatAmount(position.size, USD_DECIMALS, position?.indexToken?.displayDecimals || 2, true)}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Entry Price`}>
                    $
                    {formatAmount(
                      position.averagePrice,
                      USD_DECIMALS,
                      position?.indexToken?.displayDecimals || 2,
                      true
                    )}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Fees`}>
                    <Tooltip
                      handle={`$${formatAmount(minExecutionFeeUSD, USD_DECIMALS, 2)}`}
                      position="right-top"
                      renderContent={() => {
                        // const depositOrWithdrawalText = isDeposit ? `deposit` : `withdrawal`;
                        return (
                          <div style={{ paddingLeft: "10px" }}>
                            <StatsTooltipRow
                              label={` Network Fee`}
                              showDollar={false}
                              value={`${formatAmountFree(minExecutionFee, 18, 5)} ${nativeTokenSymbol} ($${formatAmount(
                                minExecutionFeeUSD,
                                USD_DECIMALS,
                                2
                              )})`}
                            />
                            <div style={{ marginTop: "8px" }}></div>
                            <div>
                              This is the network cost required to execute the deposit.
                              <div style={{ marginTop: "8px" }}>
                                {/* <ExternalLink href="https://docs.navigator.exchange/trading#execution-fee">
                                  More Info
                                </ExternalLink> */}
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    />
                  </ExchangeInfoRow>
                  <Divider />
                  <div className="position-change-line">
                    <div className="position-change-info">Leverage</div>
                    <div className="position-change-info change-value">
                      {nextLeverage ? (
                        <span>
                          <span style={{ textDecoration: "line-through", color: "#828899" }}>
                            {formatAmount(position.leverage, 4, 2, true)}x
                          </span>{" "}
                          <span style={{ position: "relative", top: "2px" }}>
                            {" "}
                            <GreenArraowIcon fill={"#828899"} />
                          </span>{" "}
                          {formatAmount(nextLeverage, 4, 2, true)}x
                          {/* <span
                            className={cx("Exchange-list-info-label", {
                              positive: nextLeverage.sub(position.leverage).gt(0),
                              negative: nextLeverage.sub(position.leverage).lt(0),
                            })}
                            style={{ marginLeft: "4px", fontSize: "14px" }}
                          >
                            ({nextLeverage.sub(position.leverage).gte(0) ? "+" : "-"}
                            {formatAmount(nextLeverage.sub(position.leverage), 4, 2, true).replace("-", "")}x )
                          </span> */}
                        </span>
                      ) : (
                        `${formatAmount(position.leverage, 4, 2, true)}x`
                      )}
                    </div>
                  </div>
                  <div className="position-change-line">
                    <div className="position-change-info">Collateral</div>
                    <div className="position-change-info change-value">
                      {nextCollateral ? (
                        <span>
                          <span style={{ textDecoration: "line-through", color: "#828899" }}>
                            ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                          </span>{" "}
                          <span style={{ position: "relative", top: "2px" }}>
                            {" "}
                            <GreenArraowIcon fill={"#828899"} />
                          </span>{" "}
                          ${formatAmount(nextCollateral, USD_DECIMALS, 2, true)}
                          {/* <span
                            className={cx("Exchange-list-info-label", {
                              positive: nextCollateral.sub(position.collateral).gt(0),
                              negative: nextCollateral.sub(position.collateral).lt(0),
                            })}
                            style={{ marginLeft: "4px", fontSize: "14px" }}
                          >
                            ({nextCollateral.sub(position.collateral).gte(0) ? "+" : "-"}$
                            {formatAmount(nextCollateral.sub(position.collateral), USD_DECIMALS, 2, true).replace(
                              "-",
                              ""
                            )}
                            )
                          </span> */}
                        </span>
                      ) : (
                        `$${formatAmount(position.leverage, USD_DECIMALS, 2, true)}`
                      )}
                    </div>
                  </div>
                  <div className="position-change-line">
                    <div className="position-change-info">Liq. Price</div>
                    <div className="position-change-info change-value">
                      {nextLiquidationPrice ? (
                        <span>
                          <span style={{ textDecoration: "line-through", color: "#828899" }}>
                            ${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}
                          </span>{" "}
                          <span style={{ position: "relative", top: "2px" }}>
                            {" "}
                            <GreenArraowIcon fill={"#828899"} />
                          </span>{" "}
                          ${formatAmount(nextLiquidationPrice, USD_DECIMALS, 2, true)}
                          {/* <span
                            className={cx("Exchange-list-info-label", {
                              positive: nextLiquidationPrice.sub(liquidationPrice).gt(0),
                              negative: nextLiquidationPrice.sub(liquidationPrice).lt(0),
                              muted: nextLiquidationPrice.sub(liquidationPrice).eq(0),
                            })}
                            style={{ marginLeft: "4px", fontSize: "14px" }}
                          >
                            ({nextLiquidationPrice.sub(liquidationPrice).gte(0) ? "+" : "-"}$
                            {formatAmount(nextLiquidationPrice.sub(liquidationPrice), USD_DECIMALS, 2, true).replace(
                              "-",
                              ""
                            )}
                            )
                          </span> */}
                        </span>
                      ) : (
                        `$${formatAmount(position.leverage, USD_DECIMALS, 2, true)}`
                      )}
                    </div>
                  </div>
                  <div className="position-change-line">
                    <div className="position-change-info">Liq. Risk</div>
                    <div className="position-change-info change-value">
                      {newLiqRisk ? (
                        <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                          <span style={{ textDecoration: "line-through", color: "#828899" }}>
                            {renderRiskV1(LiqRisk, false)}
                          </span>{" "}
                          <span style={{ position: "relative", top: "2px" }}>
                            {" "}
                            <GreenArraowIcon fill={"#828899"} />
                          </span>{" "}
                          <span>{renderRiskV1(newLiqRisk, false)}</span>
                          {/* <span
                            className={cx("Exchange-list-info-label", {
                              positive: liqRiskChanged.lt(0),
                              negative: liqRiskChanged.gt(0),
                              bold: true,
                            })}
                            style={{ marginLeft: "4px", fontSize: "14px" }}
                          >
                            ({liqRiskChanged.lte(0) ? "+" : "-"}
                            {formatAmount(liqRiskChanged, 30, 2, true).replace("-", "")}%)
                          </span> */}
                        </span>
                      ) : (
                        <span>{renderRiskV1(LiqRisk, false)}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      )}
    </Wrapper>
  );
}
const Divider = styled.div`
  background: var(--Border, rgba(55, 63, 92, 0.5));
  height: 1px;
  width: 100%;
  margin: 12px 0 12px 0;
`;

const Wrapper = styled.div`
  .Exchange-info-label {
    margin-bottom: 0 !important;
  }
`;
