import Tooltip from "components/Tooltip/Tooltip";
import pie_chart_ic from "img/earn/piechart.svg";

import useVaultNaviContract from "hooks/contracts/useVaultNaviContract";
import nlp_ic from "img/nlp.svg";
import nslp_ic from "img/nslp.svg";
import Countdown from "react-countdown";

import { useWeb3React } from "@web3-react/core";
import NlpManager from "abis/NlpManager.json";
import SettingsManager from "abis/SettingsManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import Vault from "abis/Vault.json";
import VaultNslp from "abis/VaultNslp.json";
import Footer from "components/Footer/Footer";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { FANTOM, getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { getTokens } from "config/tokens";
import { useNaviPrice } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { ethers } from "ethers";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import {
  NLP_COOLDOWN_DURATION,
  NLP_DECIMALS,
  NSLP_COOLDOWN_DURATION,
  PLACEHOLDER_ACCOUNT,
  USD_DECIMALS,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
} from "lib/legacy";
import { formatAddKeysAmount, formatKeyAmount, formatNumber } from "lib/numbers";
import { PoolCompositionNlpModal } from "pages/Earn/PoolCompositionNlpModal";
import { PoolCompositionNslpModal } from "pages/Earn/PoolCompositionNslpModal";
import StakeSection from "pages/Earn/StakeSection";
import VestSection from "pages/Earn/VestSection";
import { EarnFrame } from "pages/Earn/styles";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMedia } from "react-use";
import useSWR from "swr";
const { AddressZero } = ethers.constants;

const Earn = ({ setPendingTxns }) => {
  const [isModalCompositionNslpOpen, setIsModalCompositionNslpOpen] = useState(false);
  const [isModalCompositionNlpOpen, setIsModalCompositionNlpOpen] = useState(false);

  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const { naviPrice } = useNaviPrice(chainId, { arbitrum: chainId === FANTOM ? library : undefined }, active);

  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const naviAddress = getContract(chainId, "NAVI");
  const nslpAddress = getContract(chainId, "NSLP");
  const esNaviAddress = getContract(chainId, "ES_NAVI");
  const bnNaviAddress = getContract(chainId, "BN_NAVI");
  const nlpAddress = getContract(chainId, "NLP");
  const usdgAddress = getContract(chainId, "USDN");

  const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");
  const bonusNaviTrackerAddress = getContract(chainId, "BonusNaviTracker");
  const feeNaviTrackerAddress = getContract(chainId, "FeeNaviTracker");

  const stakedNlpTrackerAddress = getContract(chainId, "StakedNlpTracker");
  const feeNlpTrackerAddress = getContract(chainId, "FeeNlpTracker");

  const stakedNslpTrackerAddress = getContract(chainId, "StakedNslpTracker");
  const feeNslpTrackerAddress = getContract(chainId, "FeeNslpTracker");

  const nlpManagerAddress = getContract(chainId, "NlpManager");
  const nslpManagerAddress = getContract(chainId, "VaultNslp");
  const SettingsManagerAddress = getContract(chainId, "SettingsManager");

  const naviVesterAddress = getContract(chainId, "NaviVester");
  const nlpVesterAddress = getContract(chainId, "NlpVester");
  const nslpVesterAddress = getContract(chainId, "NslpVester");

  const vesterAddresses = [naviVesterAddress, nlpVesterAddress, nslpVesterAddress];

  const walletTokens = [naviAddress, esNaviAddress, nlpAddress, stakedNaviTrackerAddress, nslpAddress];
  const depositTokens = [
    naviAddress,
    esNaviAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    bnNaviAddress,
    nlpAddress,
    nslpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedNaviTrackerAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNlpTrackerAddress,
    feeNslpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    stakedNlpTrackerAddress,
    feeNlpTrackerAddress,
    stakedNslpTrackerAddress,
    feeNslpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );
  const { data: nlpCooldown } = useSWR([`StakeV2:nlpCooldown`, chainId, nlpManagerAddress, "cooldownDuration"], {
    fetcher: contractFetcher(library, NlpManager),
    refreshInterval: 10000,
  });
  const nlpCountHour = nlpCooldown % 3600 === 0 ? nlpCooldown / 3600 : parseInt(nlpCooldown / 3600) + 1;
  const { data: nslpCooldown } = useSWR([`StakeV2:nslpCooldown`, chainId, SettingsManagerAddress, "cooldownDuration"], {
    fetcher: contractFetcher(library, SettingsManager),
    refreshInterval: 10000,
  });
  const nslpCountHour = nslpCooldown % 3600 === 0 ? nslpCooldown / 3600 : parseInt(nslpCooldown / 3600) + 1;

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `StakeV2:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: stakedNaviSupply } = useSWR(
    [
      `StakeV2:stakedNaviSupply:${active}:${stakedNaviTrackerAddress}`,
      chainId,
      naviAddress,
      "balanceOf",
      stakedNaviTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, nlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, NlpManager),
    refreshInterval: 10000,
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `StakeV2:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );
  const tokensForSupplyQuery = [naviAddress, nlpAddress, usdgAddress, nslpAddress];

  const tokens = getTokens(chainId);
  const tokenAddresses = tokens.map((token) => token.address);

  const { data: totalSupplies } = useSWR(
    [
      `StakeV2:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );

  const { data: lastPurchaseTime } = useSWR(
    [`NlpSwap:lastPurchaseTime:${active}`, chainId, nlpManagerAddress, "lastAddedAt", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, NlpManager),
    }
  );

  const { data: lastPurchaseTimeNSLP } = useSWR(
    [
      `NlpSwap:lastPurchaseTimeNSLP:${active}`,
      chainId,
      nslpManagerAddress,
      "lastStakedAt",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, VaultNslp),
    }
  );

  const { data: tokenBalances } = useSWR(
    [`NlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 5000,
    }
  );

  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(NLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && parseInt(Date.now() / 1000) < redemptionTime;

  const redemptionTimeNSLP = lastPurchaseTimeNSLP ? lastPurchaseTimeNSLP.add(NSLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindowNSLP = redemptionTimeNSLP && parseInt(Date.now() / 1000) < redemptionTimeNSLP;

  let totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalSupply = totalSupplies[1];
  }

  let naviSupply = totalSupply;

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  const { nslpPrice } = useVaultNaviContract(chainId);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedNaviSupply,
    naviPrice,
    naviSupply,
    nslpPrice
  );

  let estNLPRewardsPerWeek = 0;
  if (processedData && processedData.nlpAprTotal && processedData.nlpBalanceUsd) {
    estNLPRewardsPerWeek = processedData.nlpAprTotal.mul(processedData.nlpBalanceUsd).div(365).mul(7).div(100);
  }
  let estNSLPRewardsPerWeek = 0;
  if (processedData && processedData.nslpAprTotal && processedData.nslpBalanceUsd) {
    estNSLPRewardsPerWeek = processedData.nslpAprTotal.mul(processedData.nslpBalanceUsd).div(365).mul(7).div(100);
  }

  let totalRewardTokens;
  if (processedData && processedData.bnNaviInFeeNavi && processedData.bonusNaviInFeeNavi) {
    totalRewardTokens = processedData.bnNaviInFeeNavi.add(processedData.bonusNaviInFeeNavi);
  }
  const countdownRenderer = (countdownProps) => {
    const {
      formatted: { hours, minutes, seconds },
    } = countdownProps;
    return <>{`${hours}:${minutes}:${seconds}`}</>;
  };
  const history = useHistory();

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const isMobile = useMedia("(max-width: 767px)");
  return (
    <EarnFrame>
      <StakeSection
        processedData={processedData}
        naviPrice={naviPrice}
        vestingData={vestingData}
        setPendingTxns={setPendingTxns}
        totalRewardTokens={totalRewardTokens}
      />

      <section className="Earn-Section">
        <h1 className="Section-Title">Pools</h1>
        <div className="Section-DoublePaper">
          <div className="Section-Paper">
            <div className="Section-PaperHead">
              <img src={nlp_ic} alt="nlp_ic" />
              <div className="Section-PaperHeadDetails">
                <h3>
                  Majors pool - NLP
                  <img src={pie_chart_ic} alt="pie_chart_ic" onClick={() => setIsModalCompositionNlpOpen(true)} />
                </h3>
                <p style={{ fontSize: "14px" }}>Index by crypto assets</p>
              </div>
              <Link className="Section-PaperHeadAction border-btn" to="/earn/nlp">
                Get NLP
              </Link>
            </div>
            <div className="divider" />
            <div className="Section-PaperBody">
              <div className="Section-PaperBodyTop">
                <div className="Section-PaperBodyTopItem">
                  APR
                  {processedData.nlpAprTotal ? (
                    <Tooltip
                      position={`${isMobile ? "right-bottom" : "left-bottom"}`}
                      className="tooltip-apr"
                      handle={
                        <h5 className="positive">
                          {formatNumber(formatKeyAmount(processedData, "nlpAprTotal", 2, 2), 2)}%
                        </h5>
                      }
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              label={`${nativeTokenSymbol} (${wrappedTokenSymbol}) APR`}
                              value={`${formatNumber(
                                formatKeyAmount(processedData, "nlpAprForNativeToken", 2, 2),
                                2
                              )}%`}
                              showDollar={false}
                            />
                            <StatsTooltipRow
                              label="Escrowed NAVI APR"
                              value={`${formatNumber(formatKeyAmount(processedData, "nlpAprForEsNavi", 2, 2), 2)}%`}
                              showDollar={false}
                            />
                            <div style={{ marginTop: "8px", fontWeight: 400 }}>
                              <span>APRs are updated weekly and will depend on the fees collected for the week.</span>
                            </div>
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                  )}
                </div>
                <div className="Section-PaperBodyTopItem">
                  Total Staked
                  <div>
                    {processedData.nlpSupply ? (
                      <>
                        <span>{formatKeyAmount(processedData, "nlpSupply", 18, 2, true)}</span> $
                        {formatNumber(formatKeyAmount(processedData, "nlpSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-PaperBodyTopItem">
                  Wallet Balance
                  <div>
                    {processedData.nlpBalance ? (
                      <>
                        <span>{formatKeyAmount(processedData, "nlpBalance", NLP_DECIMALS, 2, true)}</span>$
                        {formatNumber(formatKeyAmount(processedData, "nlpBalanceUsd", USD_DECIMALS, NLP_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    )}
                  </div>
                </div>
              </div>
              <div className="Section-PaperBodyBottom">
                <div className="Section-PaperBodyBottomItem">
                  <span>Staked</span>
                  {processedData.nlpBalance ? (
                    <>
                      <h5>{formatKeyAmount(processedData, "nlpBalance", NLP_DECIMALS, 2, true)} NLP</h5>
                      <span>${formatNumber(formatKeyAmount(processedData, "nlpBalanceUsd", USD_DECIMALS, 2))}</span>
                    </>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="Section-PaperBodyBottomItem">
                  <span>Rewards</span>
                  <h5>
                    $
                    {formatNumber(
                      formatAddKeysAmount(
                        processedData,
                        "feeNlpTrackerRewardsUsd",
                        "stakedNlpTrackerRewardsUsd",
                        USD_DECIMALS,
                        USD_DECIMALS
                      )
                    )}
                  </h5>
                  <span>
                    {processedData?.feeNlpTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "feeNlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} ${nativeTokenSymbol}, `}
                    {processedData.stakedNlpTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "stakedNlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} esNAVI`}
                  </span>
                </div>
              </div>
              <Link
                className={`border-btn full ${inCooldownWindow ? "disabled" : ""}`}
                to={inCooldownWindow ? "#" : "/earn/nlp"}
              >
                {inCooldownWindow ? "Cooldown to unstake" : "Unstake"}
              </Link>
              <div className="Section-PaperBodyBottomItemRows">
                <Tooltip
                  handle="Cooldown Period"
                  renderContent={() => {
                    return `There is a ${nlpCountHour || 0}-hour cooldown period after each stake.`;
                  }}
                />
                <h5>
                  <Countdown
                    key={redemptionTime}
                    date={new Date(Number(redemptionTime) * 1000)}
                    renderer={countdownRenderer}
                    daysInHours
                  />
                </h5>
              </div>
            </div>
          </div>
          <div className="Section-Paper">
            <div className="Section-PaperHead">
              <img src={nslp_ic} alt="nslp_ic" />
              <div className="Section-PaperHeadDetails">
                <h3>
                  Stable pool - NSLP
                  <img src={pie_chart_ic} alt="pie_chart_ic" onClick={() => setIsModalCompositionNslpOpen(true)} />
                </h3>
                <p style={{ fontSize: "14px" }}>Index by stable assets</p>
              </div>
              <Link className="Section-PaperHeadAction border-btn" to="/earn/nslp">
                Get NSLP
              </Link>
            </div>
            <div className="divider" />
            <div className="Section-PaperBody">
              <div className="Section-PaperBodyTop">
                <div className="Section-PaperBodyTopItem">
                  APR
                  {processedData.nlpAprTotal ? (
                    <Tooltip
                      position={`${isMobile ? "right-bottom" : "left-bottom"}`}
                      className="tooltip-apr"
                      handle={
                        <h5 className="positive">
                          {formatNumber(formatKeyAmount(processedData, "nslpAprTotal", 2, 2), 2)}%
                        </h5>
                      }
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              label={`${nativeTokenSymbol} (${wrappedTokenSymbol}) APR`}
                              value={`${formatNumber(
                                formatKeyAmount(processedData, "nslpAprForNativeToken", 2, 2),
                                2
                              )}%`}
                              showDollar={false}
                            />
                            <StatsTooltipRow
                              label="Escrowed NAVI APR"
                              value={`${formatNumber(formatKeyAmount(processedData, "nslpAprForEsNavi", 2, 2), 2)}%`}
                              showDollar={false}
                            />
                            <div style={{ marginTop: "8px", fontWeight: 400 }}>
                              <span>APRs are updated weekly and will depend on the fees collected for the week.</span>
                            </div>
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                  )}
                </div>
                <div className="Section-PaperBodyTopItem">
                  Total Staked
                  <div>
                    {processedData.nslpSupply ? (
                      <>
                        <span>{formatKeyAmount(processedData, "nslpSupply", 18, 2, true)}</span> $
                        {formatNumber(formatKeyAmount(processedData, "nslpSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-PaperBodyTopItem">
                  Wallet Balance
                  <div>
                    {processedData.nlpBalance ? (
                      <>
                        <span>{formatKeyAmount(processedData, "nslpBalance", NLP_DECIMALS, 2, true)}</span>$
                        {formatNumber(formatKeyAmount(processedData, "nslpBalanceUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    )}
                  </div>
                </div>
              </div>
              <div className="Section-PaperBodyBottom">
                <div className="Section-PaperBodyBottomItem">
                  <span>Staked</span>
                  {processedData.nlpBalance ? (
                    <>
                      <h5>{formatKeyAmount(processedData, "nslpBalance", NLP_DECIMALS, 2, true)} NSLP</h5>
                      <span>
                        ${formatNumber(formatKeyAmount(processedData, "nslpBalanceUsd", USD_DECIMALS, USD_DECIMALS), 2)}
                      </span>
                    </>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="Section-PaperBodyBottomItem">
                  <span>Rewards</span>
                  <h5>
                    $
                    {formatNumber(
                      formatAddKeysAmount(
                        processedData,
                        "feeNslpTrackerRewardsUsd",
                        "stakedNslpTrackerRewardsUsd",
                        USD_DECIMALS,
                        USD_DECIMALS
                      )
                    )}
                  </h5>
                  <span>
                    {processedData?.feeNslpTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "feeNslpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} ${nativeTokenSymbol}, `}
                    {processedData.stakedNslpTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "stakedNslpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} esNAVI`}
                  </span>
                </div>
              </div>

              <Link
                className={`border-btn full ${inCooldownWindowNSLP ? "disabled" : ""}`}
                to={inCooldownWindowNSLP ? "#" : "/earn/nslp"}
              >
                {inCooldownWindowNSLP ? "Cooldown to unstake" : "Unstake"}
              </Link>
              <div className="Section-PaperBodyBottomItemRows">
                <Tooltip
                  handle="Cooldown Period"
                  renderContent={() => {
                    return `There is a ${nslpCountHour || 0}-hour cooldown period after each stake.`;
                  }}
                />
                <h5>
                  <Countdown
                    key={redemptionTimeNSLP}
                    date={new Date(Number(redemptionTimeNSLP) * 1000)}
                    renderer={countdownRenderer}
                    daysInHours
                  />
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <VestSection
        vestingData={vestingData}
        totalRewardTokens={totalRewardTokens}
        processedData={processedData}
        setPendingTxns={setPendingTxns}
      />
      <PoolCompositionNlpModal
        isVisible={isModalCompositionNlpOpen}
        setIsVisible={setIsModalCompositionNlpOpen}
        infoTokens={infoTokens}
      />
      <PoolCompositionNslpModal
        isVisible={isModalCompositionNslpOpen}
        setIsVisible={setIsModalCompositionNslpOpen}
        infoTokens={infoTokens}
      />
      <Footer />
    </EarnFrame>
  );
};

export default Earn;
