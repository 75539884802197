import { useWeb3React } from "@web3-react/core";
import Token from "abis/Token.json";
import VaultNavi from "abis/VaultNavi.json";
import { getContract } from "config/contracts";
import { approveTokens, getTokenInfo, getUsd, useInfoTokens } from "domain/tokens";
import { ethers } from "ethers";
import React, { memo, useMemo } from "react";

import PriceManager from "abis/PriceManager.json";
import Reader from "abis/ReaderV2.json";
import RouterAggregatpr from "abis/RouterAggregatorMicroSwap.json";
import VaultV2 from "abis/VaultV2.json";
import TokenSelector from "components/Exchange/TokenSelector";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import { DEFAULT_FROM_V2_TOKEN, getToken, getTokens, getWhitelistedTokens } from "config/tokens";
import useTokenContract from "hooks/contracts/useTokenContract";
import useNSLPAggregator from "hooks/useNSLPAggregator";
import { callContract, contractFetcher } from "lib/contracts";
import {
  BASIS_POINTS_DIVISOR,
  DUST_BNB,
  USD_DECIMALS,
  getNextFromAmount,
  getNextToAmount,
  importImage,
} from "lib/legacy";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, formatNumber, parseValue } from "lib/numbers";
import { useCallback, useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import useSWR from "swr";
import Tooltip from "../Tooltip/Tooltip";
import "./index.scss";
import ExchangeArrowIc from "img/exchange-arrow-ic.svg";
import ExchangeArrowIcLight from "img/exchange-arrow-light-ic.svg";
import ArrowRoute from "img/arrow-route.svg";
import { useThemeContext } from "contexts/ThemeProvider.js";
import { getServerKyberUrl } from "config/backend";
import { useLocalStorageByChainId } from "lib/localStorage";
import icBlueInfo from "img/blue-info.svg";
import { MICROSWAP_SUPPORTED_CHAIN_IDS } from "config/chains";
const DepositModal = (props) => {
  const { isVisible, setIsVisible, chainId, library, setPendingTxns, savedSlippageAmount, savedSelectedDexes } = props;

  const { active, account } = useWeb3React();
  const { isLightTheme } = useThemeContext();

  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isDefaultTokenUSDC, setIsDefaultTokenUSDC] = useState(false);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  // const [toTokenAddress, setToTokenAddress] = useState("");
  const [anchorOnFromAmount, setAnchorOnFromAmount] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const tokens = getTokens(chainId);
  const destTokens = tokens.filter((x) => DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(x.address));
  const fromTokens = MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId) ? tokens : destTokens;
  const ROUTER_API = `https://api.microswap.org/aggregator/v2/encode`;
  const apiKeyFireBird = "navigatorfinance";
  const { AddressZero } = ethers.constants;
  const vaultNaviAddress = getContract(chainId, "VaultNslp");
  const vaultAddress = getContract(chainId, "Vault");
  const tokenAllowanceAddress = getContract(chainId, "USDC.e");
  const usdcAddress = getContract(chainId, "USDC.e");
  const readerNslpAddress = getContract(chainId, "ReaderNslp");
  const readerAddress = getContract(chainId, "Reader");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const priceManagerAddress = getContract(chainId, "PriceManager");
  const StakeHelperAddress = getContract(chainId, "StakeHelper");
  const usdgAddress = getContract(chainId, "USDN");
  // const [fromTokenAddress, setFromTokenAddress] = useState(DEFAULT_FROM_V2_TOKEN[chainId]?.[0] || AddressZero);
  const [fromTokenAddress, setFromTokenAddress] = useLocalStorageByChainId(
    chainId,
    `deposit-from-token-address`,
    DEFAULT_FROM_V2_TOKEN[chainId]?.[0] || usdcAddress
  );

  const [destAddress, setDestAddress] = useLocalStorageByChainId(
    chainId,
    `deposit-default-dest-token-address`,
    DEFAULT_FROM_V2_TOKEN[chainId]?.[0] || usdcAddress
  );
  const tokenAddresses = tokens.map((token) => token.address);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);
  //TOTO
  // const usdcToken = useTokenContract(chainId, active, account, usdcAddress, vaultNaviAddress);
  const usdcToken = useTokenContract(chainId, active, account, destAddress, vaultNaviAddress);

  const [aggregatorParams, setAggregatorParams] = useState({
    from: AddressZero,
    dest: destAddress,
    amount: 0,
    slippage: parseFloat(savedSlippageAmount / BASIS_POINTS_DIVISOR) || "0.005",
  });

  const { data: tokenBalances } = useSWR(active && [active, chainId, readerAddress, "getTokenBalances", account], {
    fetcher: contractFetcher(library, Reader, [tokenAddresses]),
  });

  const { data: fundingRateInfo } = useSWR([active, chainId, readerAddress, "getFundingRates"], {
    fetcher: contractFetcher(library, Reader, [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
  });

  const { data: usdgSupply } = useSWR([`Exchange:usdgSupply:${active}`, chainId, usdgAddress, "totalSupply"], {
    fetcher: contractFetcher(library, Token),
  });
  const { data: totalTokenWeights } = useSWR(
    [`Exchange:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, fundingRateInfo);

  const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
  const toTokenInfo = getTokenInfo(infoTokens, usdcToken.address);
  // console.log("?????", fromTokenAddress);
  const fromToken = getToken(chainId, fromTokenAddress);
  const toToken = getToken(chainId, usdcToken.address);

  const fromAmount = parseValue(fromValue, fromToken && fromToken.decimals);
  const toAmount = parseValue(toValue, toToken && toToken.decimals);

  const fromBalance = fromTokenInfo ? fromTokenInfo.balance : bigNumberify(0);
  const toBalance = toTokenInfo ? toTokenInfo.balance : bigNumberify(0);

  const fromUsdMin = getUsd(fromAmount, fromTokenAddress, false, infoTokens);

  const toUsdMax = getUsd(toAmount, usdcToken.address, true, infoTokens);

  const dataAggregator = useNSLPAggregator(chainId, aggregatorParams, savedSelectedDexes);
  // console.log("????", dataAggregator);
  useEffect(() => {
    if (!DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromToken?.address)) {
      setAggregatorParams({
        ...aggregatorParams,
        dest: destAddress,
        from: fromToken.address,
        amount: fromValue ? parseValue(fromValue, fromToken.decimals) : 0,
      });
    } else {
      if (aggregatorParams.amount !== 0) {
        setAggregatorParams({
          ...aggregatorParams,
          dest: destAddress,
          from: fromToken.address,
          amount: 0,
        });
      }
    }
  }, [fromValue, fromToken.address, destAddress]);

  useEffect(() => {
    if (fromToken) {
      if (DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromToken?.address)) {
        setIsDefaultTokenUSDC(true);
      } else {
        setIsDefaultTokenUSDC(false);
      }
    }
  }, [fromToken]);
  useEffect(() => {
    if (!isVisible) setFromValue("");
  }, [isVisible]);
  useEffect(() => {
    const updateDepositAmounts = () => {
      if (anchorOnFromAmount) {
        if (!fromValue) {
          setToValue("");
          return;
        }

        if (toToken) {
          const { amount: nextToAmount } = getNextToAmount(
            chainId,
            fromAmount,
            fromTokenAddress,
            usdcAddress,
            infoTokens,
            undefined,
            false,
            usdgSupply,
            totalTokenWeights,
            true
          );

          const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals);
          setToValue(nextToValue);

          // if (fromAmount && !fromAmount.eq(0) && usdcTokenToUsd) {
          //   const toValue =  fromAmount?.mul(usdcTokenToUsd).div(fromTokenInfo.maxPrice);

          //   setToValue(formatAmount(toValue, usdcToken.decimals, usdcToken.decimals));
          // }
        }
        return;
      }
      if (!toAmount) {
        setFromValue("");
        return;
      }

      if (fromValue) {
        const { amount: nextFromAmount } = getNextFromAmount(
          chainId,
          toAmount,
          fromTokenAddress,
          usdcAddress,
          infoTokens,
          undefined,
          false,
          usdgSupply,
          totalTokenWeights,
          true
        );
        const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals);
        setFromValue(nextFromValue);
      }
    };

    updateDepositAmounts();
  }, [
    anchorOnFromAmount,
    fromAmount,
    toAmount,
    fromToken,
    toToken,
    fromTokenAddress,
    usdcAddress,
    infoTokens,
    fromUsdMin,
    toUsdMax,
    chainId,
  ]);

  const toValueByFirebird = useMemo(() => {
    if (dataAggregator?.data?.quoteData) {
      const resultVal = formatAmount(dataAggregator?.data?.totalTo, 6, 6, false);
      return resultVal;
    } else return "0.0";
  }, [dataAggregator]);
  const { data: tokenAllowance } = useSWR(
    active
      ? [
          `Token:allowance:${active}`,
          chainId,
          fromToken.address,
          "allowance",
          account,
          DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromToken?.address) ? vaultNaviAddress : StakeHelperAddress,
        ]
      : null,
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const needApproval = fromValue && tokenAllowance && parseValue(fromValue, fromToken.decimals)?.gt(tokenAllowance);

  const approveFromToken = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: fromToken.address,
      spender: DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromToken?.address) ? vaultNaviAddress : StakeHelperAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
    });
  };

  async function sendTransaction(contract, method, params, value) {
    // debugger
    let customMethod = "swap";
    let funcData;
    try {
      funcData = await contract.interface.decodeFunctionData(customMethod, params.data);
    } catch (e) {
      // customMethod = "swapSimpleMode";
      // funcData = await contract.interface.decodeFunctionData(customMethod, params.data);
    }
    callContract(chainId, contract, "swapAndDeposit", funcData, {
      value,
      sentMsg: `Deposit submitted!`,
      failMsg: `Deposit failed.`,
      successMsg: `Deposited successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setFromValue("");
        setIsSubmitting(false);
      });
  }

  const onClickDepositPrimary = useCallback(async () => {
    if (needApproval) {
      approveFromToken();
      return;
    }
    setIsSubmitting(true);

    if (fromToken && DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromToken?.address)) {
      const contract = new ethers.Contract(vaultNaviAddress, VaultNavi.abi, library.getSigner());

      callContract(
        chainId,
        contract,
        "deposit",
        [account, fromToken.address, parseValue(fromValue, fromToken.decimals)],
        {
          sentMsg: `Deposit submitted!`,
          failMsg: `Deposit failed.`,
          successMsg: `Deposited successfully!`,
          setPendingTxns,
        }
      )
        .then(async (res) => {
          setIsVisible(false);
        })
        .finally(() => {
          setIsSubmitting(false);
          setFromValue("");
        });
    } else {
      if (dataAggregator) {
        const { encodedData, maxReturn } = await fetch(`${ROUTER_API}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": `${apiKeyFireBird}`,
            // "X-Client-Id": `${apiKeyFireBird}`,
          },
          body: JSON.stringify(dataAggregator?.data),
        }).then((r) => r.json());
        if (encodedData && maxReturn) {
          const amountIn = dataAggregator?.data.amount;
          const contract = new ethers.Contract(StakeHelperAddress, RouterAggregatpr.abi, library.getSigner());
          const tokenIn = dataAggregator?.data.from;
          const currencyAIsEth = tokenIn.toLowerCase() === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
          // routerContract = encodedData.router;
          const tx = {
            // caller: "0x24b5C2325c93eE0edf3a3E1a25632e4c0ED1d5fd",
            from: account,
            to: StakeHelperAddress,
            // maxFeePerGas: 1000000000000,
            // maxPriorityFeePerGas: 1000000000000  ,
            data: encodedData.data, // encoded contract data
            value: currencyAIsEth ? bigNumberify(amountIn.toString()) : undefined,
          };
          let value = bigNumberify(0);

          if (fromToken?.isNative) {
            value = bigNumberify(amountIn.toString());
          }
          // console.log("????", value);
          sendTransaction(contract, "swapAndDeposit", tx, value);
        } else {
          setIsSubmitting(true);
        }
      }
    }
  }, [
    vaultNaviAddress,
    library,
    chainId,
    account,
    usdcAddress,
    fromValue,
    usdcToken.decimals,
    dataAggregator,
    setPendingTxns,
  ]);

  const getError = () => {
    if (!fromAmount || fromAmount.eq(0)) {
      return `Enter an amount`;
    }
    if (fromBalance && fromAmount.gt(fromBalance)) {
      return `Amount exceeds balance`;
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (needApproval && isWaitingForApproval) {
      return `Waiting for Approval`;
    }
    if (isApproving) {
      return `Approving...`;
    }
    if (needApproval) {
      return `Approve ${fromToken.symbol}`;
    }
    if (isSubmitting) {
      return "Depositing...";
    }
    return `Deposit`;
  };

  function setFromValueToMaximumAvailable() {
    if (!fromToken || !fromBalance) {
      return;
    }

    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance;
    setFromValue(formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals));
    setAnchorOnFromAmount(true);
  }

  const onSelectFromToken = (token) => {
    setFromTokenAddress(token.address);
    setIsWaitingForApproval(false);
    setFromValue("");
  };
  const onSelectDestToken = (token) => {
    setDestAddress(token.address);
    // setIsWaitingForApproval(false);
  };

  const onFromValueChange = (e) => {
    setFromValue(e.target.value);
    setAnchorOnFromAmount(true);
    // const fromAmountVal = parseValue(fromValue, fromToken && fromToken.decimals);
    // if (fromAmountVal && fromAmountVal.gt(fromBalance)) {
    //   const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance;
    //   setFromValue(formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals));
    // } else {
    //   setFromValue(e.target.value);
    // }
  };

  function shouldShowMaxButton() {
    if (!fromToken || !fromBalance) {
      return false;
    }
    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance;
    return fromValue !== formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals);
  }

  return (
    <div className="modal-deposit-container">
      <ModalWithPortal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        label="Deposit Collateral"
        className={`hasDivider non-scrollable  Collateral-modal-deposit`}
      >
        <span className="Modal-description">
          Funds are deposited from your standard wallet into the exchange balance to serve as collateral for trading
          with the Stable pool.
        </span>

        {isDefaultTokenUSDC ? (
          <React.Fragment>
            <div className="Exchange-swap-section exchange-section-first">
              <div className="Exchange-swap-section-top">
                <div className="muted" style={{ opacity: "0" }}>
                  {fromUsdMin && (
                    <div className="Exchange-swap-usd">
                      <span>{formatAmount(fromUsdMin, USD_DECIMALS, 2, true)} USD</span>
                    </div>
                  )}
                  {!fromUsdMin && ``}
                </div>
                {fromToken && (
                  <div className="muted align-right clickable" onClick={setFromValueToMaximumAvailable}>
                    <span>Balance: {formatAmount(fromBalance, fromToken.decimals, 4, true)}</span>
                  </div>
                )}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue}
                    onChange={onFromValueChange}
                  />
                  {shouldShowMaxButton() && (
                    <div className="Exchange-swap-max" onClick={setFromValueToMaximumAvailable}>
                      <span>MAX</span>
                    </div>
                  )}
                </div>
                <div>
                  <TokenSelector
                    label={`From`}
                    chainId={chainId}
                    tokenAddress={fromTokenAddress}
                    onSelectToken={onSelectFromToken}
                    tokens={fromTokens}
                    infoTokens={infoTokens}
                    showMintingCap={false}
                    className="NlpSwap-from-token"
                    showSymbolImage={true}
                    showTokenImgInDropdown={true}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="Exchange-swap-section exchange-section-first">
              <div className="Exchange-swap-section-top">
                <div className="muted" style={{ opacity: "0" }}>
                  {fromUsdMin && (
                    <div className="Exchange-swap-usd">
                      <span>{formatAmount(fromUsdMin, USD_DECIMALS, 2, true)} USD</span>
                    </div>
                  )}
                  {!fromUsdMin && ``}
                </div>
                {fromBalance && (
                  <div className="muted align-right clickable" onClick={setFromValueToMaximumAvailable}>
                    <span>Balance: {formatAmount(fromBalance, fromToken.decimals, 4, true)}</span>
                  </div>
                )}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue}
                    onChange={onFromValueChange}
                  />
                  {shouldShowMaxButton() && (
                    <div className="Exchange-swap-max" onClick={setFromValueToMaximumAvailable}>
                      <span>MAX</span>
                    </div>
                  )}
                </div>
                <div>
                  <TokenSelector
                    label={`From`}
                    chainId={chainId}
                    tokenAddress={fromTokenAddress}
                    onSelectToken={onSelectFromToken}
                    tokens={fromTokens}
                    infoTokens={infoTokens}
                    showMintingCap={false}
                    className="NlpSwap-from-token"
                    showSymbolImage={true}
                    showTokenImgInDropdown={true}
                  />
                </div>
              </div>
            </div>

            <div className="Exchange-swap-ball-container">
              <div
                className={`Exchange-swap-ball`}
                // onClick={switchTokens}
              >
                <img src={isLightTheme ? ExchangeArrowIcLight : ExchangeArrowIc} className="Exchange-swap-ball-icon" />
                {/* <FaLongArrowAltRight className="Exchange-swap-ball-icon" /> */}
              </div>
            </div>
            {!isDefaultTokenUSDC && (
              <div className="set-colateral">
                <div className="title">Underlying token</div>
                <div className="value">
                  <TokenSelector
                    label={`To`}
                    chainId={chainId}
                    tokenAddress={destAddress}
                    onSelectToken={onSelectDestToken}
                    tokens={destTokens}
                    className="NlpSwap-from-token"
                    infoTokens={infoTokens}
                    showMintingCap={false}
                    showSymbolImage={true}
                    showTokenImgInDropdown={true}
                    showBalances={false}
                  />
                </div>
              </div>
            )}
            <div className="Exchange-swap-section">
              <div className="Exchange-swap-section-top">
                <div className="muted" style={{ display: "none", opacity: 0 }}>
                  {toUsdMax && (
                    <div className="Exchange-swap-usd">{formatAmount(toUsdMax, USD_DECIMALS, 2, true)} USD</div>
                  )}
                  {!toUsdMax && ""}
                </div>

                {/* {toBalance && (
                <div className="muted align-right">
                  <span>Balance</span>: {formatAmount(toBalance, toToken.decimals, 4, true)}
                </div>
              )} */}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div>
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={toValueByFirebird}
                    // onChange={onToValueChange}
                    disabled
                  />
                </div>
                <div className="TokenSelector">
                  <div className="TokenSelector-box">
                    {/* <img src={UsdcIcon} alt="icon" className="TokenSelector-box-symbol" /> */}
                    <span>USD</span>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {!isDefaultTokenUSDC && (
          <div className="route-underlying-info" style={{ marginBottom: "16px" }}>
            <img alt="info" src={icBlueInfo} />
            <div>
              Your {fromToken.symbol} will be swapped to {usdcToken.symbol} then deposited into the pool.
            </div>
          </div>
        )}
        {!isDefaultTokenUSDC && (
          <div className="Exchange-info-row">
            <div>
              <Tooltip
                handle={<div className="Exchange-info-label">Fees</div>}
                position="left-bottom"
                renderContent={() => {
                  return <>A swap fee of 0.05% (non-stable tokens) and 0.005% (stable tokens) charged by MicroSwap</>;
                }}
              ></Tooltip>
            </div>
            <div className={`align-right`}>
              {toAmount && usdcToken
                ? `$${formatAmount(toAmount.mul(fromToken.isStable ? 5 : 50).div(100000), usdcToken.decimals, 4)}`
                : "$--"}
            </div>
          </div>
        )}

        {/* <div className="Exchange-swap-button-container">
        </div> */}
        <button className="default-btn full" onClick={onClickDepositPrimary} disabled={!isPrimaryEnabled()}>
          {getPrimaryText()}
        </button>
      </ModalWithPortal>
    </div>
  );
};

export default memo(DepositModal);
