import { ethers } from "ethers";
import { getContract } from "./contracts";
import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  MAINNET,
  TESTNET,
  FANTOM,
  MUMBAI,
  OP,
  BASE,
  FANTOM_TESTNET,
  OP_TESTNET,
  SONIC_TESTNET,
} from "./chains";
import { Token } from "domain/tokens";
import ethIcon from "img/ic_eth_40.svg";
import ftmIcon from "img/ic_ftm_40.svg";
import beraIcon from "img/ic_bera_40.svg";
import btcIcon from "img/ic_btc_40.svg";
import linkIcon from "img/ic_link_40.svg";
import wethcon from "img/ic_weth_40.svg";
import { MANUAL_TOKENS } from "config/localStorage";

export const TOKENS: { [chainId: number]: Token[] } = {
  [MAINNET]: [
    {
      name: "Bitcoin (BTCB)",
      symbol: "BTC",
      decimals: 18,
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-bitcoin",
      imageUrl: "https://assets.coingecko.com/coins/images/14108/small/Binance-bitcoin.png",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png",
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-coin",
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
      isNative: true,
    },
    {
      name: "Wrapped Binance Coin",
      symbol: "WBNB",
      decimals: 18,
      address: getContract(MAINNET, "NATIVE_TOKEN"),
      isWrapped: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-coin",
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
      baseSymbol: "BNB",
    },
    {
      name: "USD Gambit",
      symbol: "USDN",
      decimals: 18,
      address: getContract(MAINNET, "USDN"),
      isUsdg: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-gambit",
      imageUrl: "https://assets.coingecko.com/coins/images/15886/small/usdg-02.png",
    },
    {
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-usd",
      imageUrl: "https://assets.coingecko.com/coins/images/9576/small/BUSD.png",
    },
    {
      name: "USD Coin",
      symbol: "USDC.e",
      decimals: 18,
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      imageUrl: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: "0x55d398326f99059fF775485246999027B3197955",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
  ],
  [TESTNET]: [
    {
      name: "Bitcoin (BTCB)",
      symbol: "BTC",
      decimals: 8,
      address: "0xb19C12715134bee7c4b1Ca593ee9E430dABe7b56",
      imageUrl: btcIcon,
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: "0x1958f7C067226c7C8Ac310Dc994D0cebAbfb2B02",
      imageUrl: ethIcon,
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
    },
    {
      name: "Wrapped Binance Coin",
      symbol: "WBNB",
      decimals: 18,
      address: "0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2",
      isWrapped: true,
      baseSymbol: "BNB",
      imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
    },
    {
      name: "USD Gambit",
      symbol: "USDN",
      decimals: 18,
      address: getContract(TESTNET, "USDN"),
      isUsdg: true,
      imageUrl: "https://assets.coingecko.com/coins/images/15886/small/usdg-02.png",
    },
    {
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: "0x3F223C4E5ac67099CB695834b20cCd5E5D5AA9Ef",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9576/small/BUSD.png",
    },
  ],
  [ARBITRUM_TESTNET]: [
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: "0x27960f9A322BE96A1535E6c19B3958e80E6a2670",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: ethIcon,
    },
    // https://github.com/OffchainLabs/arbitrum/blob/950c2f91b2e951cd3764394e0a73eac3797aecf3/packages/arb-ts/src/lib/networks.ts#L65
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: wethcon,
    },
    {
      name: "USD Coin",
      symbol: "USDC.e",
      decimals: 6,
      address: "0xf0DCd4737A20ED33481A49De94C599944a3Ca737",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0x818ED84bA1927945b631016e0d402Db50cE8865f",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
  ],
  [ARBITRUM]: [
    {
      name: "Wrapped SONIC",
      symbol: "WS",
      decimals: 18,
      displayDecimals: 4,
      address: "0x5695069e6ee67320EEA8eC3D0803884f45b98ED7",
      isWrapped: true,
      baseSymbol: "S",
      imageUrl: beraIcon,
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: ethIcon,
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: wethcon,
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      decimals: 8,
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      isShortable: true,
      imageUrl: btcIcon,
    },
    // {
    //   name: "Chainlink",
    //   symbol: "LINK",
    //   decimals: 18,
    //   address: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
    //   isStable: false,
    //   isShortable: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
    // },
    // {
    //   name: "Uniswap",
    //   symbol: "UNI",
    //   decimals: 18,
    //   address: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
    //   isStable: false,
    //   isShortable: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604",
    // },
    {
      name: "Radiant Capital",
      symbol: "RDNT",
      decimals: 18,
      address: "0x3082CC23568eA640225c2467653dB90e9250AaA0",
      displayDecimals: 4,
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26536/small/Radiant-Logo-200x200.png?1658715865",
    },
    {
      name: "Arbitrum",
      symbol: "ARB",
      decimals: 18,
      address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      displayDecimals: 4,
      isStable: false,
      isShortable: true,
      imageUrl:
        "https://raw.githubusercontent.com/firebird-finance/firebird-assets/master/blockchains/arbitrum/assets/0x912CE59144191C1204E64559FE8253a0e49E6548/logo.png",
    },
    {
      name: "NAVI",
      symbol: "NAVI",
      decimals: 18,
      address: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "USD Coin",
      symbol: "USDC.e",
      decimals: 6,
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996",
    },
  ],
  [AVALANCHE]: [
    {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      decimals: 18,
      address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      isWrapped: true,
      baseSymbol: "AVAX",
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Ethereum (WETH.e)",
      symbol: "ETH",
      address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      decimals: 18,
      isShortable: true,
      imageUrl: ethIcon,
    },
    {
      name: "Bitcoin (BTC.b)",
      symbol: "BTC",
      address: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
      decimals: 8,
      isShortable: true,
      imageUrl: btcIcon,
    },
    {
      name: "Bitcoin (WBTC.e)",
      symbol: "WBTC",
      address: "0x50b7545627a5162F82A992c33b87aDc75187B218",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "USD Coin",
      symbol: "USDC.e",
      address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USD Coin (USDC.e)",
      symbol: "USDC.e",
      address: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      address: "0x130966628846BFd36ff31a822705796e8cb8C18D",
      decimals: 18,
      isStable: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png",
    },
  ],
  [FANTOM]: [
    {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
      displayDecimals: 4,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: ftmIcon,
    },
    {
      name: "Wrapped FTM",
      symbol: "WFTM",
      decimals: 18,
      displayDecimals: 4,
      address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      isWrapped: true,
      baseSymbol: "FTM",
      imageUrl: ftmIcon,
    },
    // {
    //   name: "Ethereum",
    //   symbol: "ETH",
    //   address: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
    //   decimals: 18,
    //   isShortable: true,
    //   imageUrl: ethIcon,
    // },
    {
      name: "Axelar Wrapped ETH",
      symbol: "axlETH",
      address: "0xfe7eDa5F2c56160d406869A8aA4B2F365d544C7B",
      decimals: 18,
      displayDecimals: 2,
      isShortable: true,
      imageUrl: ethIcon,
    },
    {
      name: "Layer Zero Ethereum",
      symbol: "ETH",
      displayDecimals: 2,
      address: "0x695921034f0387eAc4e11620EE91b1b15A6A09fE",
      decimals: 18,
      isShortable: true,
      imageUrl: ethIcon,
    },
    {
      name: "Axelar Wrapped BTC",
      symbol: "axlBTC",
      address: "0x448d59B4302aB5d2dadf9611bED9457491926c8e",
      decimals: 8,
      displayDecimals: 2,
      isShortable: true,
      imageUrl: btcIcon,
    },
    {
      name: "Layer Zero Bitcoin",
      symbol: "BTC",
      address: "0xf1648C50d2863f780c57849D812b4B7686031A3D",
      decimals: 8,
      displayDecimals: 2,
      isShortable: true,
      imageUrl: btcIcon,
    },
    // {
    //   name: "Bridged USD Coin",
    //   symbol: "lzUSDC",
    //   address: "0x28a92dde19d9989f39a49905d7c9c2fac7799bdf",
    //   decimals: 6,
    //   displayDecimals: 2,
    //   isShortable: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    // },
    // {
    //   name: "BNB",
    //   symbol: "BNB",
    //   address: "0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454",
    //   decimals: 18,
    //   isShortable: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png",
    // },
    {
      name: "Bridged USDC",
      symbol: "USDC.e",
      address: "0x2F733095B80A04b38b0D10cC884524a3d09b836a",
      decimals: 6,
      displayDecimals: 2,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Axelar Wrapped USDC",
      symbol: "axlUSDC",
      address: "0x1B6382DBDEa11d97f24495C9A90b7c88469134a4",
      decimals: 6,
      displayDecimals: 2,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Layer Zero USD Coin",
      symbol: "USDC.e",
      address: "0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf",
      decimals: 6,
      displayDecimals: 2,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    // {
    //   name: "Tether",
    //   symbol: "USDT",
    //   address: "0x049d68029688eAbF473097a2fC38ef61633A3C7A",
    //   decimals: 6,
    //   isStable: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    // },
    {
      name: "Axelar Wrapped USDT",
      symbol: "axlUSDT",
      address: "0xd226392C23fb3476274ED6759D4a478db3197d82",
      decimals: 6,
      displayDecimals: 2,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
    {
      name: "Layer Zero Tether",
      symbol: "USDT",
      address: "0xcc1b99dDAc1a33c201a742A1851662E87BC7f22C",
      decimals: 6,
      displayDecimals: 2,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
    // {
    //   name: "Layer Zero DAI",
    //   symbol: "DAI",
    //   decimals: 18,
    //   address: "0x91a40C733c97a6e1BF876EaF9ed8c08102eB491f",
    //   isStable: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996",
    // },

    // {
    //   name: "LINK",
    //   symbol: "LINK",
    //   decimals: 18,
    //   displayDecimals: 3,
    //   address: "0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8",
    //   isShortable: true,
    //   imageUrl: "https://ftmscan.com/token/images/chainlink_32.png",
    // },
  ],
  [SONIC_TESTNET]: [
    {
      name: "SONIC",
      symbol: "S",
      decimals: 18,
      displayDecimals: 4,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: beraIcon,
    },
    {
      name: "Wrapped SONIC",
      symbol: "WS",
      decimals: 18,
      displayDecimals: 4,
      address: "0x039e2fB66102314Ce7b64Ce5Ce3E5183bc94aD38",
      isWrapped: true,
      baseSymbol: "S",
      imageUrl: beraIcon,
    },
    // {
    //   name: "Bitcoin (BTC)",
    //   symbol: "BTC",
    //   decimals: 8,
    //   address: "0xf5327D0cB1665892078B706FB62BAeB1908E2527",
    //   isShortable: true,
    //   imageUrl: btcIcon,
    // },
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x50c42dEAcD8Fc9773493ED674b675bE577f2634b",
      // address: "0x309c92261178fa0cf748a855e90ae73fdb79ebc7",
      decimals: 18,
      isShortable: true,
      imageUrl: ethIcon,
    },
    {
      name: "Bridged USDC",
      symbol: "USDC.e",
      decimals: 6,
      address: "0x29219dd400f2Bf60E5a23d13Be72B486D4038894",
      isStable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/usdc_32.png",
    },
    // {
    //   name: "Tether USD",
    //   symbol: "USDT",
    //   decimals: 6,
    //   address: "0x53680AEB6A50AE25dC9D429A9097054bc4823b47",
    //   isStable: true,
    //   imageUrl: "https://optimistic.etherscan.io/token/images/tether_32.png",
    // },
  ],
  [OP]: [
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      decimals: 8,
      address: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
      isShortable: true,
      imageUrl: btcIcon,
    },
    {
      name: "Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x4200000000000000000000000000000000000006",
      isWrapped: true,
      imageUrl: wethcon,
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isShortable: true,
      isNative: true,
      imageUrl: ethIcon,
    },
    {
      name: "USD Coin",
      symbol: "USDC.e",
      decimals: 6,
      address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
      isStable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/usdc_32.png",
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      decimals: 6,
      address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
      isStable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/tether_32.png",
    },
    {
      name: "Dai Stable Coin",
      symbol: "DAI",
      decimals: 18,
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996",
    },
    {
      name: "OP",
      symbol: "OP",
      decimals: 18,
      displayDecimals: 3,
      address: "0x4200000000000000000000000000000000000042",
      isShortable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/optimism_32.png",
    },
    {
      name: "LINK",
      symbol: "LINK",
      decimals: 18,
      displayDecimals: 3,
      address: "0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6",
      isShortable: true,
      imageUrl: linkIcon,
    },
    {
      name: "SNX",
      symbol: "SNX",
      decimals: 18,
      displayDecimals: 3,
      address: "0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4",
      isShortable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/SynthetixSNX_32.png",
    },
    // {
    //   name: "FXS",
    //   symbol: "FXS",
    //   decimals: 18,
    //   address: "0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be",
    //   isShortable: true,
    //   imageUrl: "https://optimistic.etherscan.io/token/images/fraxshareop_32.png",
    // },
  ],
  [BASE]: [
    // {
    //   name: "Bitcoin (tBTC)",
    //   symbol: "tBTC",
    //   decimals: 18,
    //   address: "0x236aa50979D5f3De3Bd1Eeb40E81137F22ab794b",
    //   isShortable: true,
    //   imageUrl: "https://basescan.org/token/images/tbtc_32.png",
    // },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x4200000000000000000000000000000000000006",
      isWrapped: true,
      imageUrl: wethcon,
    },
    // {
    //   name: "Coinbase Wrapped Staked",
    //   symbol: "cbETH",
    //   isShortable: true,
    //   decimals: 18,
    //   address: "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22",
    //   imageUrl: "https://basescan.org/token/images/coinbasecbeth_32.png",
    // },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isShortable: true,
      isNative: true,
      imageUrl: ethIcon,
    },
    {
      name: "USDC.e",
      symbol: "USDC.e",
      decimals: 6,
      address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
      isStable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/usdc_32.png",
    },
    {
      name: "USDbC",
      symbol: "USDbC",
      decimals: 6,
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      isStable: true,
      imageUrl: "https://basescan.org/token/images/usdbc_ofc_32.png",
    },
    {
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996",
    },
    // {
    //   name: "FXS",
    //   symbol: "FXS",
    //   decimals: 18,
    //   address: "0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be",
    //   isShortable: true,
    //   imageUrl: "https://optimistic.etherscan.io/token/images/fraxshareop_32.png",
    // },
  ],
  [MUMBAI]: [
    {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
      displayDecimals: 3,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: ftmIcon,
    },
    {
      name: "Wrapped FTM",
      symbol: "WFTM",
      decimals: 18,
      displayDecimals: 3,
      address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      isWrapped: true,
      baseSymbol: "FTM",
      imageUrl: ftmIcon,
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
      decimals: 18,
      isShortable: true,
      imageUrl: ethIcon,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      address: "0x321162Cd933E2Be498Cd2267a90534A804051b11",
      decimals: 8,
      isShortable: true,
      imageUrl: btcIcon,
    },
    {
      name: "USD Coin",
      symbol: "USDC.e",
      address: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      address: "0x049d68029688eAbF473097a2fC38ef61633A3C7A",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
    {
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      address: "0xc115F58075B8D73A848aadbbCD4EC2E27DE9FF7f",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996",
    },
  ],
  [FANTOM_TESTNET]: [
    {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
      displayDecimals: 4,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: ftmIcon,
    },
    {
      name: "Wrapped FTM",
      symbol: "WFTM",
      decimals: 18,
      displayDecimals: 4,
      address: "0xDba382b2e41C30C5939F898359C490bd94dD8e95",
      isWrapped: true,
      baseSymbol: "FTM",
      imageUrl: ftmIcon,
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      decimals: 8,
      address: "0xA0eFF81B641c823f398761F7cec195E91Aa2B691",
      isShortable: true,
      imageUrl: btcIcon,
    },
    {
      name: "Layer Zero USD Coin",
      symbol: "USDC.e",
      address: "0xBd78354966A0DDA78cEa0CDe635ADb1545C4d53d",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Layer Zero Tether",
      symbol: "USDT",
      address: "0x38f67FC4a1Faa675650214d5aD3603FF721e2Bd3",
      decimals: 6,
      displayDecimals: 2,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png",
    },
    {
      name: "Layer Zero Ethereum",
      symbol: "ETH",
      address: "0x529f36AB200eF0D2C2d7d248A930b5fb84C24a1D",
      decimals: 18,
      isShortable: true,
      imageUrl: ethIcon,
    },
  ],
};
export const OTHER_TOKENS_INFO: { [chainId: number]: Token[] } = {
  [MAINNET]: [],
  [TESTNET]: [],
  [ARBITRUM_TESTNET]: [],
  [ARBITRUM]: [],
  [AVALANCHE]: [],
  [FANTOM]: [
    {
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      address: "0x695921034f0387eAc4e11620EE91b1b15A6A09fE",
      displayDecimals: 2,
      isShortable: true,
      imageUrl: wethcon,
    },
    {
      name: "SpiritSwap",
      symbol: "SPIRIT",
      decimals: 18,
      address: "0x5Cc61A78F164885776AA610fb0FE1257df78E59B",
      displayDecimals: 2,
      isShortable: true,
      imageUrl: "https://ftmscan.com/token/images/spiritswap_32.png",
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      decimals: 18,
      address: "0x82f0B8B456c1A451378467398982d4834b6829c1",
      displayDecimals: 2,
      isShortable: true,
      imageUrl: "https://ftmscan.com/token/images/mimstablecoin_32.png",
    },
    {
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      address: "0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996",
    },
    {
      name: "Frax",
      symbol: "FRAX",
      decimals: 18,
      address: "0xaf319E5789945197e365E7f7fbFc56B130523B33",
      isShortable: true,
      imageUrl: "https://ftmscan.com/token/images/fraxfinance_32.png",
    },
    {
      name: "DEUS",
      symbol: "DEUS",
      decimals: 18,
      address: "0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44",
      isShortable: true,
      imageUrl: "https://ftmscan.com/token/images/deusfinance_new_32.png",
    },
    {
      name: "TOMB",
      symbol: "TOMB",
      decimals: 18,
      address: "0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7",
      isShortable: true,
      imageUrl: "https://ftmscan.com/token/images/tomb_32.png",
    },
    {
      name: "Equalizer",
      symbol: "EQUAL",
      decimals: 18,
      address: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6",
      imageUrl: "https://ftmscan.com/token/images/equalizer_32.png",
    },
    {
      name: "miMATIC",
      symbol: "miMATIC",
      decimals: 18,
      address: "0xfB98B335551a418cD0737375a2ea0ded62Ea213b",
      imageUrl: "https://ftmscan.com/token/images/mimatic_32.png",
    },
    {
      name: "Frapped USDT",
      symbol: "fUSDT",
      decimals: 6,
      address: "0x049d68029688eAbF473097a2fC38ef61633A3C7A",
      imageUrl: "https://ftmscan.com/token/images/frappedusdt_32.png",
    },
    {
      name: "Wrapped BTC",
      symbol: "WBTC",
      decimals: 8,
      address: "0xf1648C50d2863f780c57849D812b4B7686031A3D",
      imageUrl: btcIcon,
    },
    {
      name: "USD Coin",
      symbol: "USDC.e",
      address: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
  ],
  [OP]: [
    {
      name: "Wrapped liquid staked Ether",
      symbol: "wstETH",
      decimals: 18,
      address: "0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb",
      isWrapped: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/wsteth_32.png",
    },
    {
      name: "Velodrome",
      symbol: "VELO",
      decimals: 18,
      address: "0x3c8B650257cFb5f272f799F5e2b4e65093a11a05",
      isWrapped: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/velodrome_32.png",
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      decimals: 8,
      address: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
      isShortable: true,
      imageUrl: btcIcon,
    },
  ],
  [BASE]: [
    {
      name: "USD+",
      symbol: "USD+",
      decimals: 6,
      address: "0xb79dd08ea68a908a97220c76d19a6aa9cbde4376",
      isStable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/usdc_32.png",
    },
  ],
  [MUMBAI]: [],
  [FANTOM_TESTNET]: [],
};
export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {
  [ARBITRUM]: [
    {
      name: "NAVI",
      symbol: "NAVI",
      address: getContract(ARBITRUM, "NAVI"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed NAVI",
      symbol: "EsNAVI",
      address: getContract(ARBITRUM, "ES_NAVI"),
      decimals: 18,
    },
    {
      name: "NAVI LP",
      symbol: "NLP",
      address: getContract(ARBITRUM, "NLP"),
      decimals: 18,
      imageUrl: "https://github.com/navi-io/navi-assets/blob/main/NAVI-Assets/PNG/NLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [AVALANCHE]: [
    {
      name: "NAVI",
      symbol: "NAVI",
      address: getContract(AVALANCHE, "NAVI"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed NAVI",
      symbol: "EsNAVI",
      address: getContract(AVALANCHE, "ES_NAVI"),
      decimals: 18,
    },
    {
      name: "NAVI LP",
      symbol: "NLP",
      address: getContract(ARBITRUM, "NLP"),
      decimals: 18,
      imageUrl: "https://github.com/navi-io/navi-assets/blob/main/NAVI-Assets/PNG/NLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [OP]: [
    {
      name: "NAVI",
      symbol: "NAVI",
      address: getContract(OP, "NAVI"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed NAVI",
      symbol: "EsNAVI",
      address: getContract(OP, "ES_NAVI"),
      decimals: 18,
    },
    {
      name: "NAVI LP",
      symbol: "NLP",
      address: getContract(OP, "NLP"),
      decimals: 18,
      imageUrl: "https://github.com/navi-io/navi-assets/blob/main/NAVI-Assets/PNG/NLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [BASE]: [
    {
      name: "NAVI",
      symbol: "NAVI",
      address: getContract(BASE, "NAVI"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed NAVI",
      symbol: "EsNAVI",
      address: getContract(BASE, "ES_NAVI"),
      decimals: 18,
    },
    {
      name: "NAVI LP",
      symbol: "NLP",
      address: getContract(BASE, "NLP"),
      decimals: 18,
      imageUrl: "https://github.com/navi-io/navi-assets/blob/main/NAVI-Assets/PNG/NLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [FANTOM]: [
    {
      name: "NAVI",
      symbol: "NAVI",
      address: getContract(FANTOM, "NAVI"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed NAVI",
      symbol: "EsNAVI",
      address: getContract(FANTOM, "ES_NAVI"),
      decimals: 18,
    },
    {
      name: "NAVI LP",
      symbol: "NLP",
      address: getContract(FANTOM, "NLP"),
      decimals: 18,
      imageUrl: "https://github.com/navi-io/navi-assets/blob/main/NAVI-Assets/PNG/NLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [FANTOM_TESTNET]: [
    {
      name: "NAVI",
      symbol: "NAVI",
      address: getContract(FANTOM_TESTNET, "NAVI"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed NAVI",
      symbol: "EsNAVI",
      address: getContract(FANTOM_TESTNET, "ES_NAVI"),
      decimals: 18,
    },
    {
      name: "NAVI LP",
      symbol: "NLP",
      address: getContract(FANTOM_TESTNET, "NLP"),
      decimals: 18,
      imageUrl: "https://github.com/navi-io/navi-assets/blob/main/NAVI-Assets/PNG/NLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [SONIC_TESTNET]: [
    {
      name: "NAVI",
      symbol: "NAVI",
      address: getContract(SONIC_TESTNET, "NAVI"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed NAVI",
      symbol: "EsNAVI",
      address: getContract(SONIC_TESTNET, "ES_NAVI"),
      decimals: 18,
    },
    {
      name: "NAVI LP",
      symbol: "NLP",
      address: getContract(SONIC_TESTNET, "NLP"),
      decimals: 18,
      imageUrl: "https://github.com/navi-io/navi-assets/blob/main/NAVI-Assets/PNG/NLP_LOGO%20ONLY.png?raw=true",
    },
  ],
};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {
  [ARBITRUM]: {
    // arbitrum
    NAVI: {
      name: "NAVI",
      symbol: "NAVI",
      decimals: 18,
      address: getContract(ARBITRUM, "NAVI"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    NLP: {
      name: "NAVI LP",
      symbol: "NLP",
      decimals: 18,
      address: getContract(ARBITRUM, "StakedNlpTracker"), // address of fsNLP token because user only holds fsNLP
      imageUrl: "https://github.com/navi-io/navi-assets/blob/main/NAVI-Assets/PNG/NLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [AVALANCHE]: {
    // avalanche
    NAVI: {
      name: "NAVI",
      symbol: "NAVI",
      decimals: 18,
      address: getContract(AVALANCHE, "NAVI"),
    },
    NLP: {
      name: "NAVI LP",
      symbol: "NLP",
      decimals: 18,
      address: getContract(AVALANCHE, "StakedNlpTracker"), // address of fsNLP token because user only holds fsNLP
      // imageUrl: "https://github.com/navi-io/navi-assets/blob/main/NAVI-Assets/PNG/NLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [OP]: {
    NAVI: {
      name: "NAVI",
      symbol: "NAVI",
      decimals: 18,
      address: getContract(OP, "NAVI"),
    },
    NLP: {
      name: "NAVI LP",
      symbol: "NLP",
      decimals: 18,
      address: getContract(OP, "StakedNlpTracker"), // address of fsNLP token because user only holds fsNLP
      // imageUrl: "https://github.com/navi-io/navi-assets/blob/main/NAVI-Assets/PNG/NLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [BASE]: {
    NAVI: {
      name: "NAVI",
      symbol: "NAVI",
      decimals: 18,
      address: getContract(BASE, "NAVI"),
    },
    NLP: {
      name: "NAVI LP",
      symbol: "NLP",
      decimals: 18,
      address: getContract(BASE, "StakedNlpTracker"), // address of fsNLP token because user only holds fsNLP
      // imageUrl: "https://github.com/navi-io/navi-assets/blob/main/NAVI-Assets/PNG/NLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [FANTOM]: {
    NAVI: {
      name: "NAVI",
      symbol: "NAVI",
      decimals: 18,
      address: getContract(FANTOM, "NAVI"),
      imageUrl: "https://assets.coingecko.com/coins/images/28547/small/Logo_NAVI.png?1671599329",
    },
    NLP: {
      name: "NAVI LP",
      symbol: "NLP",
      decimals: 18,
      address: getContract(FANTOM, "StakedNlpTracker"), // address of fsNLP token because user only holds fsNLP
    },
  },
  [FANTOM_TESTNET]: {
    NAVI: {
      name: "NAVI",
      symbol: "NAVI",
      decimals: 18,
      address: getContract(FANTOM_TESTNET, "NAVI"),
      imageUrl: "https://assets.coingecko.com/coins/images/28547/small/Logo_NAVI.png?1671599329",
    },
    NLP: {
      name: "NAVI LP",
      symbol: "NLP",
      decimals: 18,
      address: getContract(FANTOM_TESTNET, "StakedNlpTracker"), // address of fsNLP token because user only holds fsNLP
    },
  },
  [SONIC_TESTNET]: {
    NAVI: {
      name: "NAVI",
      symbol: "NAVI",
      decimals: 18,
      address: getContract(SONIC_TESTNET, "NAVI"),
      imageUrl: "https://assets.coingecko.com/coins/images/28547/small/Logo_NAVI.png?1671599329",
    },
    NLP: {
      name: "NAVI LP",
      symbol: "NLP",
      decimals: 18,
      address: getContract(SONIC_TESTNET, "StakedNlpTracker"), // address of fsNLP token because user only holds fsNLP
    },
  },
};

export const ICONLINKS = {
  [ARBITRUM_TESTNET]: {
    NAVI: {
      coingecko: "https://www.coingecko.com/en/coins/navi",
      arbitrum: "https://arbiscan.io/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    NLP: {
      arbitrum: "https://testnet.arbiscan.io/token/0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    },
  },
  [SONIC_TESTNET]: {
    NAVI: {
      coingecko: "https://www.coingecko.com/en/coins/navigator-exchange",
      sonicchain_sonic: "https://sonicscan.org/address/0x6881B80ea7C858E4aEEf63893e18a8A36f3682f3",
    },
    NLP: {
      sonicchain_sonic: "https://sonicscan.org/token/0x489e0A78fCb4841729C00638eD9cE306973A88FE",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      sonicchain_sonic: "https://sonicscan.org/address/0x50c42dEAcD8Fc9773493ED674b675bE577f2634b",
    },
    // axlETH: {
    //   coingecko: "https://www.coingecko.com/en/coins/ethereum",
    //   sonicchain_sonic: "https://sonicscan.org/address/0xfe7eDa5F2c56160d406869A8aA4B2F365d544C7B",
    // },
    // ETH_L0: {
    //   coingecko: "https://www.coingecko.com/en/coins/ethereum",
    //   sonicchain_sonic: "https://sonicscan.org/address/0x695921034f0387eAc4e11620EE91b1b15A6A09fE",
    // },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      sonicchain_sonic: "https://sonicscan.org/address/0xf5327D0cB1665892078B706FB62BAeB1908E2527",
    },
    // axlBTC: {
    //   coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
    //   sonicchain_sonic: "https://sonicscan.org/address/0x448d59B4302aB5d2dadf9611bED9457491926c8e",
    // },
    // BTC_L0: {
    //   coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
    //   sonicchain_sonic: "https://sonicscan.org/address/0xf1648C50d2863f780c57849D812b4B7686031A3D",
    // },
    // LINK: {
    //   coingecko: "https://www.coingecko.com/en/coins/chainlink",
    //   sonicchain_sonic: "https://sonicscan.org/address/0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8",
    // },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      sonicchain_sonic: "https://sonicscan.org/address/0x29219dd400f2Bf60E5a23d13Be72B486D4038894",
    },
    // "USDC.e": {
    //   // coingecko: "https://www.coingecko.com/en/coins/usd-coin",
    //   sonicchain_sonic: "https://sonicscan.org/address/0x2f733095b80a04b38b0d10cc884524a3d09b836a",
    // },
    // axlUSDC: {
    //   coingecko: "https://www.coingecko.com/en/coins/usd-coin",
    //   sonicchain_sonic: "https://sonicscan.org/address/0x1B6382DBDEa11d97f24495C9A90b7c88469134a4",
    // },
    // USDC_L0: {
    //   coingecko: "https://www.coingecko.com/en/coins/usd-coin",
    //   sonicchain_sonic: "https://sonicscan.org/address/0x28a92dde19d9989f39a49905d7c9c2fac7799bdf",
    // },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      sonicchain_sonic: "https://sonicscan.org/address/0x53680AEB6A50AE25dC9D429A9097054bc4823b47",
    },
    // axlUSDT: {
    //   coingecko: "https://www.coingecko.com/en/coins/tether",
    //   sonicchain_sonic: "https://sonicscan.org/address/0xd226392C23fb3476274ED6759D4a478db3197d82",
    // },
    // USDT_L0: {
    //   coingecko: "https://www.coingecko.com/en/coins/tether",
    //   sonicchain_sonic: "https://sonicscan.org/address/0xcc1b99dDAc1a33c201a742A1851662E87BC7f22C",
    // },
    // DAI: {
    //   coingecko: "https://www.coingecko.com/en/coins/dai",
    //   sonicchain_sonic: "https://sonicscan.org/address/0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
    // },
    // DAI_L0: {
    //   coingecko: "https://www.coingecko.com/en/coins/dai",
    //   sonicchain_sonic: "https://sonicscan.org/address/0x91a40C733c97a6e1BF876EaF9ed8c08102eB491f",
    // },
    // FTM: {
    //   coingecko: "https://www.coingecko.com/en/coins/fantom",
    //   sonicchain_sonic: beraIcon,
    // },
    S: {
      // coingecko: "https://www.coingecko.com/en/coins/bera",
      sonicchain_sonic: "https://sonicscan.org/address/0x039e2fb66102314ce7b64ce5ce3e5183bc94ad38",
    },
    // BNB: {
    //   coingecko: "https://www.coingecko.com/en/coins/bnb",
    //   sonicchain_sonic: "https://sonicscan.org/address/0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454",
    // },
  },
  [FANTOM_TESTNET]: {
    NAVI: {
      coingecko: "https://www.coingecko.com/en/coins/navigator-exchange",
      fantom: "https://ftmscan.com/address/0x9CB7beAEcdE90a682BDb86AaA32EF032bD9e4079",
    },
    NLP: {
      fantom: "https://ftmscan.com/token/0xcf4D627f1bb9aB2deC8Ec4c928686b2b4165Ec73",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      fantom: "https://ftmscan.com/address/0x695921034f0387eac4e11620ee91b1b15a6a09fe",
    },
    axlETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      fantom: "https://ftmscan.com/address/0xfe7eDa5F2c56160d406869A8aA4B2F365d544C7B",
    },
    ETH_L0: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      fantom: "https://ftmscan.com/address/0x695921034f0387eAc4e11620EE91b1b15A6A09fE",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      fantom: "https://ftmscan.com/address/0xf1648c50d2863f780c57849d812b4b7686031a3d",
    },
    axlBTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      fantom: "https://ftmscan.com/address/0x448d59B4302aB5d2dadf9611bED9457491926c8e",
    },
    BTC_L0: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      fantom: "https://ftmscan.com/address/0xf1648C50d2863f780c57849D812b4B7686031A3D",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      fantom: "https://ftmscan.com/address/0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      fantom: "https://ftmscan.com/address/0x28a92dde19d9989f39a49905d7c9c2fac7799bdf",
    },
    "USDC.e": {
      // coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      fantom: "https://ftmscan.com/address/0x2f733095b80a04b38b0d10cc884524a3d09b836a",
    },
    axlUSDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      fantom: "https://ftmscan.com/address/0x1B6382DBDEa11d97f24495C9A90b7c88469134a4",
    },
    USDC_L0: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      fantom: "https://ftmscan.com/address/0x28a92dde19d9989f39a49905d7c9c2fac7799bdf",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      fantom: "https://ftmscan.com/address/0xcc1b99ddac1a33c201a742a1851662e87bc7f22c",
    },
    axlUSDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      fantom: "https://ftmscan.com/address/0xd226392C23fb3476274ED6759D4a478db3197d82",
    },
    USDT_L0: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      fantom: "https://ftmscan.com/address/0xcc1b99dDAc1a33c201a742A1851662E87BC7f22C",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      fantom: "https://ftmscan.com/address/0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
    },
    DAI_L0: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      fantom: "https://ftmscan.com/address/0x91a40C733c97a6e1BF876EaF9ed8c08102eB491f",
    },
    FTM: {
      coingecko: "https://www.coingecko.com/en/coins/fantom",
      fantom: ftmIcon,
    },
    BNB: {
      coingecko: "https://www.coingecko.com/en/coins/bnb",
      fantom: "https://ftmscan.com/address/0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454",
    },
  },
  [FANTOM]: {
    NAVI: {
      coingecko: "https://www.coingecko.com/en/coins/navigator-exchange",
      fantom: "https://ftmscan.com/address/0x9CB7beAEcdE90a682BDb86AaA32EF032bD9e4079",
    },
    NLP: {
      fantom: "https://ftmscan.com/token/0xcf4D627f1bb9aB2deC8Ec4c928686b2b4165Ec73",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      fantom: "https://ftmscan.com/address/0x695921034f0387eac4e11620ee91b1b15a6a09fe",
    },
    axlETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      fantom: "https://ftmscan.com/address/0xfe7eDa5F2c56160d406869A8aA4B2F365d544C7B",
    },
    ETH_L0: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      fantom: "https://ftmscan.com/address/0x695921034f0387eAc4e11620EE91b1b15A6A09fE",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      fantom: "https://ftmscan.com/address/0xf1648c50d2863f780c57849d812b4b7686031a3d",
    },
    axlBTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      fantom: "https://ftmscan.com/address/0x448d59B4302aB5d2dadf9611bED9457491926c8e",
    },
    BTC_L0: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      fantom: "https://ftmscan.com/address/0xf1648C50d2863f780c57849D812b4B7686031A3D",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      fantom: "https://ftmscan.com/address/0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      fantom: "https://ftmscan.com/address/0x28a92dde19d9989f39a49905d7c9c2fac7799bdf",
    },
    axlUSDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      fantom: "https://ftmscan.com/address/0x1B6382DBDEa11d97f24495C9A90b7c88469134a4",
    },
    USDC_L0: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      fantom: "https://ftmscan.com/address/0x28a92dde19d9989f39a49905d7c9c2fac7799bdf",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      fantom: "https://ftmscan.com/address/0xcc1b99ddac1a33c201a742a1851662e87bc7f22c",
    },
    axlUSDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      fantom: "https://ftmscan.com/address/0xd226392C23fb3476274ED6759D4a478db3197d82",
    },
    USDT_L0: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      fantom: "https://ftmscan.com/address/0xcc1b99dDAc1a33c201a742A1851662E87BC7f22C",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      fantom: "https://ftmscan.com/address/0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
    },
    DAI_L0: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      fantom: "https://ftmscan.com/address/0x91a40C733c97a6e1BF876EaF9ed8c08102eB491f",
    },
    FTM: {
      coingecko: "https://www.coingecko.com/en/coins/fantom",
      fantom: ftmIcon,
    },
    BNB: {
      coingecko: "https://www.coingecko.com/en/coins/bnb",
      fantom: "https://ftmscan.com/address/0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454",
    },
  },
  [OP]: {
    NAVI: {
      op: "https://optimistic.etherscan.io/address/0x385719545Ef34d457A88e723504544A53F0Ad9BC",
    },
    NLP: {
      op: "https://optimistic.etherscan.io/address/0xFfB69477FeE0DAEB64E7dE89B57846aFa990e99C",
    },
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      op: "https://optimistic.etherscan.io/address/0x4200000000000000000000000000000000000006",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      op: "https://optimistic.etherscan.io/address/0x68f180fcCe6836688e9084f035309E29Bf0A2095",
    },
    "BTC.b": {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      op: "https://optimistic.etherscan.io/address/0x152b9d0FdC40C096757F570A51E494bd4b943E50",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      op: "https://optimistic.etherscan.io/address/0x130966628846bfd36ff31a822705796e8cb8c18d",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin-avalanche-bridged-usdc-e",
      op: "https://optimistic.etherscan.io/address/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      op: "https://optimistic.etherscan.io/address/0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      op: "https://optimistic.etherscan.io/address/0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    },
    OP: {
      coingecko: "https://www.coingecko.com/en/coins/optimism",
      op: "https://optimistic.etherscan.io/address/0x4200000000000000000000000000000000000042",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      op: "https://optimistic.etherscan.io/address/0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6",
    },
    SNX: {
      coingecko: "https://www.coingecko.com/en/coins/synthetix-network-token",
      op: "https://optimistic.etherscan.io/address/0x8700daec35af8ff88c16bdf0418774cb3d7599b4",
    },
  },
  [BASE]: {
    NAVI: {
      base: "https://optimistic.etherscan.io/address/0x385719545Ef34d457A88e723504544A53F0Ad9BC",
    },
    NLP: {
      base: "https://optimistic.etherscan.io/address/0xFfB69477FeE0DAEB64E7dE89B57846aFa990e99C",
    },
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      base: "https://optimistic.etherscan.io/address/0x4200000000000000000000000000000000000006",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      base: "https://optimistic.etherscan.io/address/0x68f180fcCe6836688e9084f035309E29Bf0A2095",
    },
    tBTC: {
      coingecko: "https://www.coingecko.com/en/coins/tbtc",
    },
    cbETH: {
      coingecko: "https://www.coingecko.com/en/coins/coinbase-wrapped-staked-eth",
    },
    USDbC: {
      coingecko: "https://www.coingecko.com/en/coins/bridged-usd-coin-base",
    },

    "BTC.b": {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      base: "https://optimistic.etherscan.io/address/0x152b9d0FdC40C096757F570A51E494bd4b943E50",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      base: "https://optimistic.etherscan.io/address/0x130966628846bfd36ff31a822705796e8cb8c18d",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin-avalanche-bridged-usdc-e",
      base: "https://optimistic.etherscan.io/address/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      base: "https://optimistic.etherscan.io/address/0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      base: "https://optimistic.etherscan.io/address/0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    },
    OP: {
      coingecko: "https://www.coingecko.com/en/coins/optimism",
      base: "https://optimistic.etherscan.io/address/0x4200000000000000000000000000000000000042",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      base: "https://optimistic.etherscan.io/address/0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6",
    },
    SNX: {
      coingecko: "https://www.coingecko.com/en/coins/synthetix-network-token",
      base: "https://optimistic.etherscan.io/address/0x8700daec35af8ff88c16bdf0418774cb3d7599b4",
    },
  },
  [ARBITRUM]: {
    NAVI: {
      coingecko: "https://www.coingecko.com/en/coins/navi",
      arbitrum: "https://arbiscan.io/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    NLP: {
      arbitrum: "https://arbiscan.io/token/0x1aDDD80E6039594eE970E5872D247bf0414C8903",
      reserves: "https://arbiscan.io/address/0x489ee077994b6658eafa855c308275ead8097c4a",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      arbitrum: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      arbitrum: "https://arbiscan.io/address/0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
    },
    UNI: {
      coingecko: "https://www.coingecko.com/en/coins/uniswap",
      arbitrum: "https://arbiscan.io/address/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      arbitrum: "https://arbiscan.io/address/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      arbitrum: "https://arbiscan.io/address/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      arbitrum: "https://arbiscan.io/address/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      arbitrum: "https://arbiscan.io/address/0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
    },
    FRAX: {
      coingecko: "https://www.coingecko.com/en/coins/frax",
      arbitrum: "https://arbiscan.io/address/0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
    },
    ARB: {
      coingecko: "https://www.coingecko.com/en/coins/arbitrum",
      arbitrum: "https://arbiscan.io/address/0x912ce59144191c1204e64559fe8253a0e49e6548",
    },
    RDNT: {
      coingecko: "https://www.coingecko.com/en/coins/radiant-capital",
      arbitrum: "https://arbiscan.io/address/0x3082CC23568eA640225c2467653dB90e9250AaA0",
    },
  },
  [AVALANCHE]: {
    NAVI: {
      coingecko: "https://www.coingecko.com/en/coins/navi",
      avalanche: "https://snowtrace.io/address/0x385719545Ef34d457A88e723504544A53F0Ad9BC",
    },
    NLP: {
      avalanche: "https://snowtrace.io/address/0xC2C5968e16ec9fABc39e27f9AbFC07C8Cfba6F16",
      // reserves: "https://core.app/account/0x9ab2De34A33fB459b538c43f251eB825645e8595",
    },
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      avalanche: "https://snowtrace.io/address/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
    },
    WBTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      avalanche: "https://snowtrace.io/address/0x50b7545627a5162f82a992c33b87adc75187b218",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/bitcoin-avalanche-bridged-btc-b",
      avalanche: "https://snowtrace.io/address/0x152b9d0FdC40C096757F570A51E494bd4b943E50",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      avalanche: "https://snowtrace.io/address/0x130966628846bfd36ff31a822705796e8cb8c18d",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      avalanche: "https://snowtrace.io/address/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
    },
  },
};

export const NLP_POOL_COLORS = {
  ETH: "#6062a6",
  BTC: "#F7931A",
  tBTC: "#F7931A",
  WBTC: "#F7931A",
  USDC: "#2775CA",
  "USDC.e": "#2A5ADA",
  USDT: "#67B18A",
  MIM: "#9695F8",
  FRAX: "#000",
  DAI: "#FAC044",
  UNI: "#E9167C",
  AVAX: "#E84142",
  FTM: "#E84142",
  LINK: "#3256D6",
  OP: "#E84142",
  SNX: "#539165",
  BNB: "pink",
  ARB: "#539165",
  NAVI: "#9695F8",
  URDNT: "#E84142",
};

export const TOKEN_SHOWS_DECIMALS = {
  "BTC/USD": 2,
  "ETH/USD": 2,
  "EUR/USD": 4,
  "FTM/USD": 4,
  "BAL/USD": 4,
  "AVAX/USD": 4,
  "GBP/USD": 4,
  "CAD/USD": 4,
  "CNY/USD": 4,
  "JPY/USD": 6,
  "LINK/USD": 4,
  "XRP/USD": 4,
  "XAU/USD": 2,
  "ARB/USD": 4,
  "OP/USD": 4,
  "MATIC/USD": 4,
  "BNB/USD": 2,
  "USD/ZAR": 4,
  "USD/SEK": 4,
  "USD/NOK": 4,
  "USD/MXN": 4,
  "USD/HKD": 4,
  "USD/CHF": 4,
  "SOL/USD": 2,
  "ORDI/USD": 3,
  "FET/USD": 4,
  "SUI/USD": 4,
  "XAG/USD": 3,
  "AUD/USD": 4,
  "NZD/USD": 4,
  "PEPE/USD": 8,
};

export const CRYPTO = [
  "BTC",
  "ETH",
  "LINK",
  "FTM",
  "OP",
  "BNB",
  "XRP",
  "LINK",
  "AVAX",
  "BAL",
  "MATIC",
  "ARB",
  "axlBTC",
  "axlETH",
  "SNX",
  "RDNT",
  "NAVI",
  "SOL",
  "ORDI",
  // "FET",
  "SUI",
  "PEPE",
];
export const FOREX = ["AUD", "EUR", "GBP", "CAD", "CNY", "JPY", "ZAR", "SEK", "NOK", "MXN", "HKD", "CHF", "AUD", "NZD"];
export const METALS = ["XAG", "XAU"];

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

const CHAIN_IDS = [
  MAINNET,
  TESTNET,
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  FANTOM,
  SONIC_TESTNET,
  OP,
  BASE,
  FANTOM_TESTNET,
];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}
export const getTokenDisplayDecimals = (symbol) => {
  return TOKEN_SHOWS_DECIMALS[symbol?.includes("/USD") ? symbol : `${symbol}/USD`] || 2;
};

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}
export function getMicroSwapToken(microSwapTokens, address: string) {
  if (microSwapTokens.length > 0) {
    const manualTokensStorage = JSON.parse(localStorage.getItem(MANUAL_TOKENS) || "[]");

    const findToken = [...microSwapTokens, ...manualTokensStorage].find(
      (x) => x.address.toLowerCase() === address.toLowerCase()
    );
    if (!findToken) {
      return microSwapTokens.find((x) => x.address.toLowerCase() === "ether");
    }
    return findToken;
  }
}

export function getTokenLowerCase(chainId: number, address: string) {
  return address
    ? TOKENS[chainId].find((token) => token.address.toLocaleLowerCase() === address.toLocaleLowerCase())
    : null;
}

export function getTokenBySymbol(chainId: number = 250, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  if (chainId) {
    return TOKENS[chainId]?.filter((token) => token.symbol !== "USDN");
  }
  return [];
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId)?.filter((token) => !token.isWrapped && !token.isTempHidden);
}

export function getNormalizedTokenSymbol(tokenSymbol) {
  if (["WBTC", "WETH", "WAVAX", "WFTM"].includes(tokenSymbol)) {
    return tokenSymbol.substr(1);
  } else if (tokenSymbol === "BTC.b") {
    return "BTC";
  }
  return tokenSymbol;
}

const AVAILABLE_CHART_TOKENS = {
  [FANTOM]: ["ETH_L0", "BTC_L0", "ETH", "BTC", "FTM", "BNB", "LINK", "axlETH", "axlBTC"],
  [OP]: ["ETH", "BTC", "OP", "LINK", "SNX"],
  [AVALANCHE]: ["AVAX", "ETH", "BTC"],
  [BASE]: ["ETH", "tBTC", "cbETH", "BTC"],
  [ARBITRUM]: ["ETH", "BTC", "ARB", "NAVI", "RDNT"],
  [FANTOM_TESTNET]: ["ETH", "BTC"],
  [SONIC_TESTNET]: ["ETH", "BTC"],
};

export function isChartAvailabeForToken(chainId: number, tokenSymbol: string) {
  const token = getTokenBySymbol(chainId, tokenSymbol);
  if (!token) return false;
  return (token.isStable || AVAILABLE_CHART_TOKENS[chainId]?.includes(getNormalizedTokenSymbol(tokenSymbol))) ?? false;
}

export function getTokenSymbol(chainId: number, tokenAddress: string) {
  return getNormalizedTokenSymbol(getTokenLowerCase(chainId, tokenAddress)?.symbol);
}
export function getTokenV1Adress(chainId: number, tokenAddress: string) {
  return getNormalizedTokenSymbol(getTokenLowerCase(chainId, tokenAddress)?.address);
}
export function getTokenDecimal(chainId: number, tokenAddress: string) {
  return getTokenLowerCase(chainId, tokenAddress)?.decimals || 18;
}
export function getTokenDisplayDecimal(chainId: number, tokenAddress: string) {
  return getTokenLowerCase(chainId, tokenAddress)?.displayDecimals || 2;
}
export function getNormalizedAxlTokenSymbol(tokenSymbol: string) {
  if (tokenSymbol.includes("axl")) return tokenSymbol.slice(tokenSymbol.indexOf("axl") + 3, tokenSymbol.length + 1);
  return tokenSymbol;
}
export const TOKEN_WHITE_LIST = {
  [OP_TESTNET]: ["BTC", "ETH", "EUR", "FTM", "LINK", "XRP", "OP", "BNB", "JPY", "XAU", "GBP", "CAD", "CNY"],
  [FANTOM]: [
    "BTC",
    "ETH",
    "LINK",
    "FTM",
    "XRP",
    "EUR",
    "XAU",
    "GBP",
    "BNB",
    "OP",
    "SOL",
    "ARB",
    "ORDI",
    // "FET",
    "SUI",
    "XAG",
    "AUD",
    "NZD",
    "PEPE",
  ],
  [ARBITRUM]: ["BTC", "ETH", "LINK", "FTM", "XRP", "EUR", "XAU", "GBP", "BNB", "OP", "SOL", "ARB", "ORDI"],
  [OP]: ["BTC", "ETH", "LINK", "FTM", "XRP", "EUR", "XAU", "GBP", "BNB", "OP", "SOL", "ARB", "ORDI"],
  [SONIC_TESTNET]: [
    "BTC",
    "ETH",
    "LINK",
    "XRP",
    "BNB",
    "OP",
    "SOL",
    "ARB",
    "ORDI",
    "SUI",
    "PEPE",
    "EUR",
    "XAU",
    "GBP",
    "XAG",
    "AUD",
    "NZD",
  ],
};
export const DEFAULT_FROM_V2_TOKEN = {
  [FANTOM]: [
    "0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf",
    "0x2F733095B80A04b38b0D10cC884524a3d09b836a",
    "0x1B6382DBDEa11d97f24495C9A90b7c88469134a4",
  ],
  [OP]: [
    "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
    "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
  ],
  [SONIC_TESTNET]: ["0x29219dd400f2Bf60E5a23d13Be72B486D4038894"],
};
export const DEFAULT_FROM_V2_TOKEN_SYMBOL = {
  [FANTOM]: ["USDC.e", "USDC.e", "axlUSDC"],
  [OP]: ["USDC.e", "DAI", "USDT"],
  [SONIC_TESTNET]: ["USDC.e"],
};
export const WHITELISTED_MICROSWAP_TOKENS = {
  [SONIC_TESTNET]: require("./registry.sonic.json"),
};
export const PINNED_MICROSWAP_TOKENS = {
  [FANTOM]: [],
  [SONIC_TESTNET]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      name: "Sonic",
      symbol: "S",
      decimals: 18,
      precision: 4,
      isNative: true,
    },
    {
      address: "0x039e2fb66102314ce7b64ce5ce3e5183bc94ad38",
      name: "Wrapped Sonic",
      symbol: "wS",
      decimals: 18,
      precision: 4,
      isWrapped: true,
    },

    {
      address: "0x29219dd400f2Bf60E5a23d13Be72B486D4038894",
      name: "USD Coin ",
      symbol: "USDC.e",
      decimals: 6,
      precision: 4,
    },
  ],
};

export const ADDITIONAL_MERGED_TOKENS = {
  [FANTOM]: [
    {
      name: "Layer Zero DAI",
      symbol: "DAI",
      decimals: 18,
      address: "0x91a40C733c97a6e1BF876EaF9ed8c08102eB491f",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996",
    },
    {
      name: "LINK",
      symbol: "LINK",
      decimals: 18,
      displayDecimals: 3,
      address: "0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8",
      isShortable: true,
      imageUrl: "https://ftmscan.com/token/images/chainlink_32.png",
    },
  ],
  [OP]: [
    {
      name: "FXS",
      symbol: "FXS",
      decimals: 18,
      address: "0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be",
      isShortable: true,
      imageUrl: "https://optimistic.etherscan.io/token/images/fraxshareop_32.png",
    },
  ],
};
export const TRADE_V1_TOKENS = {
  [SONIC_TESTNET]: ["ETH/USD"],
};
export const TRADE_ONLY_V1_TOKENS = {
  [SONIC_TESTNET]: [],
  [FANTOM_TESTNET]: [],
};

export const TOKEN_WHITE_LIST_ANALYTICS = {
  [OP_TESTNET]: ["BTC", "ETH", "EUR", "FTM", "LINK", "XRP", "OP", "BNB", "JPY", "XAU", "GBP", "CAD", "CNY"],
  [FANTOM]: [
    "BTC",
    "ETH",
    "LINK",
    "FTM",
    "XRP",
    "EUR",
    "XAU",
    "GBP",
    "BNB",
    "OP",
    "SOL",
    "ARB",
    "ORDI",
    "FET",
    "SUI",
    "XAG",
    "AUD",
    "NZD",
    "PEPE",
    "TON",
    "NOT",
  ],
  [SONIC_TESTNET]: [
    "BTC",
    "ETH",
    "LINK",
    "XRP",
    "BNB",
    "OP",
    "SOL",
    "ARB",
    "ORDI",
    "SUI",
    "PEPE",
    "EUR",
    "XAU",
    "GBP",
    "XAG",
    "AUD",
    "NZD",
  ],
  [FANTOM_TESTNET]: [
    "BTC",
    "ETH",
    "LINK",
    "FTM",
    "XRP",
    "EUR",
    "XAU",
    "GBP",
    "BNB",
    "OP",
    "SOL",
    "ARB",
    "ORDI",
    "FET",
    "SUI",
    "XAG",
    "AUD",
    "NZD",
    "PEPE",
    "TON",
    "NOT",
  ],
  [ARBITRUM]: ["BTC", "ETH", "LINK", "FTM", "XRP", "EUR", "XAU", "GBP", "BNB", "OP", "SOL", "ARB", "ORDI"],
  [OP]: [
    "BTC",
    "ETH",
    "LINK",
    "FTM",
    "XRP",
    "EUR",
    "XAU",
    "GBP",
    "BNB",
    "OP",
    "SOL",
    "ARB",
    "ORDI",
    "TON",
    "NOT",
    "PEPE",
    "SUI",
    "FXS",
  ],
  [BASE]: [
    ...CRYPTO,
    ...FOREX,
    ...METALS,
    "PYTH",
    // "BTC", "ETH", "LINK", "FTM", "OP", "BNB", "XRP", "LINK", "AVAX", "BAL", "MATIC",
    // "AUD", "EUR", "GBP", "CAD", "CNY", "JPY", "ZAR", "SEK", "NOK", "MXN", "HKD", "CHF",
    // "XAG", "XAU"
  ],
};
export const CRYPTO_ANALYTICS = [
  "BTC",
  "ETH",
  "LINK",
  "FTM",
  "OP",
  "BNB",
  "XRP",
  "LINK",
  "AVAX",
  "BAL",
  "MATIC",
  "ARB",
  "axlBTC",
  "axlETH",
  "SNX",
  "RDNT",
  "NAVI",
  "SOL",
  "ORDI",
  "FET",
  "PEPE",
  "SUI",
  "TON",
  "NOT",
];
export const ASSET_LIST_V1_DEFAULT = {
  [SONIC_TESTNET]: {
    // "0xf5327d0cb1665892078b706fb62baeb1908e2527": {
    //   volume24H: "0",
    //   openInterest: "0",
    //   longOpenInterest: "0",
    //   shortOpenInterest: "0",
    //   price: "0",
    //   last24HPrice: "0",
    // },
    "0x50c42dEAcD8Fc9773493ED674b675bE577f2634b": {
      volume24H: "0",
      openInterest: "0",
      longOpenInterest: "0",
      shortOpenInterest: "0",
      price: "0",
      last24HPrice: "0",
    },
  },
  [FANTOM_TESTNET]: {
    "0xa0eff81b641c823f398761f7cec195e91aa2b691": {
      volume24H: "0",
      openInterest: "0",
      longOpenInterest: "0",
      shortOpenInterest: "0",
      price: "0",
      last24HPrice: "0",
    },
    "0x529f36ab200ef0d2c2d7d248a930b5fb84c24a1d": {
      volume24H: "0",
      openInterest: "0",
      longOpenInterest: "0",
      shortOpenInterest: "0",
      price: "0",
      last24HPrice: "0",
    },
  },
};
export const ASSET_LIST_V2_DEFAULT = {
  [FANTOM_TESTNET]: [],
  [SONIC_TESTNET]: [
    {
      id: "11",
      pythId: "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "BTC/USD",
      tokenName: "BTC",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "19",
      pythId: "0x193c739db502aadcef37c2589738b1e37bdb257d58cf1ab3c7ebc8e6df4e3ec0",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "ORDI/USD",
      tokenName: "ORDI",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "20",
      pythId: "0x23d7315113f5b1d3ba7a83604c44b94d79f4fd69af77f804fc7f920a6dc65744",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "SUI/USD",
      tokenName: "SUI",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "21",
      pythId: "0xd69731a2e74ac1ce884fc3890f7ee324b6deb66147055249568869ed700882e4",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "PEPE/USD",
      tokenName: "PEPE",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "12",
      pythId: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "ETH/USD",
      tokenName: "ETH",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "13",
      pythId: "0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "LINK/USD",
      tokenName: "LINK",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "14",
      pythId: "0xec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "XRP/USD",
      tokenName: "XRP",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "500",
      pythId: "0xa995d00bb36a63cef7fd2c287dc105fc8f3d93779f062f09551b0af3e81ec30b",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "EUR/USD",
      tokenName: "EUR",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "501",
      pythId: "0x765d2ba906dbc32ca17cc11f5310a89e9ee1f6420508c63861f2f8ba4ee34bb2",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "XAU/USD",
      tokenName: "XAU",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "502",
      pythId: "0x84c2dde9633d93d1bcad84e7dc41c9d56578b7ec52fabedc1f335d673df0a7c1",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "GBP/USD",
      tokenName: "GBP",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "503",
      pythId: "0xf2fb02c32b055c805e7238d628e5e9dadef274376114eb1f012337cabe93871e",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "XAG/USD",
      tokenName: "XAG",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "504",
      pythId: "0x67a6f93030420c1c9e3fe37c1ab6b77966af82f995944a9fefce357a22854a80",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "AUD/USD",
      tokenName: "AUD",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "505",
      pythId: "0x92eea8ba1b00078cdc2ef6f64f091f262e8c7d0576ee4677572f314ebfafa4c7",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "NZD/USD",
      tokenName: "NZD",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "15",
      pythId: "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "BNB/USD",
      tokenName: "BNB",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "16",
      pythId: "0x385f64d993f7b77d8182ed5003d97c60aa3361f3cecfe711544d2d59165e9bdf",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "OP/USD",
      tokenName: "OP",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "17",
      pythId: "0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "SOL/USD",
      tokenName: "SOL",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
    {
      id: "18",
      pythId: "0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5",
      allowedStaleness: "60",
      allowedDeviation: "1000",
      maxLeverage: "1000000",
      price: "0",
      symbol: "ARB/USD",
      tokenName: "ARB",
      timestamp: "1698128890",
      tokenDecimals: 8,
      latestPrice: "0",
      fundingIndex: "0",
      fundingRate: "0",
      borrowRates: [],
      maxOpenInterestPerAssetPerSides: [],
      price24HChanged: 0,
      openInterest: {},
      volume: "0",
      volume24h: "0",
    },
  ],
};

export const NOT_WHITELISTED_TOKENS = [""];
