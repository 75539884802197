import { FiX } from "react-icons/fi";
import logoImg from "img/logo_NAVI_v2.svg";
import { t } from "@lingui/macro";

import "./Header.scss";
import { Link } from "react-router-dom";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ImageLogo } from "assets";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
};

const HOME_MENUS = [
  {
    label: `App`,
    link: "https://app.navigator.exchange/#/trade",
    isTargetSelf: true,
  },
  // {
  //   label: `Protocol`,
  //   link: "https://github.com/navigator-exchange",
  // },
  // {
  //   label: `Governance`,
  //   link: "https://gov.navigator.exchange",
  // },
  // {
  //   label: `Voting`,
  //   link: "https://snapshot.org/#/navi.eth",
  // },
  {
    label: `Docs`,
    link: "https://docs.navigator.exchange/",
  },
];

export function HomeHeaderLinks({ small, clickCloseIcon }: Props) {
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <Link className="App-header-link-main" to="/">
            <img src={ImageLogo} alt="Navi Logo" />
            {/* <img src={logoImg} alt="NAVI Logo" /> */}
          </Link>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}
      {HOME_MENUS.map(({ link, label, isTargetSelf }) => (
        <div key={label} className="App-header-link-container head-landing">
          <ExternalLink target={isTargetSelf ? "_self" : "_blank"} href={link}>
            {label}
          </ExternalLink>
        </div>
      ))}
    </div>
  );
}
