import { useWeb3React } from "@web3-react/core";
import bg from "img/home/bg_nft.png";
import bgLarge from "img/home/bg_nft_large.png";
import mum1 from "img/home/mum11.png";
import icCheckbox from "img/icons/Checkbox--inactive.svg";
import icCheckboxLight from "img/icons/Checkbox--inactiveLight.svg";
import icCheckboxActive from "img/icons/Checkbox.svg";
import icCheckboxActiveLight from "img/icons/CheckboxLight.svg";
import icFlash from "img/icons/flash.svg";
import icLink from "img/icons/link-green.svg";
import icLinkLight from "img/icons/link-greenLight.svg";
import Countdown from "react-countdown";
import NavigatorClubStaking from "abis/NavigatorClubStaking.json";

import { getTokenBySymbol } from "config/tokens";
import { useInfoTokens } from "domain/tokens";
import { ethers } from "ethers";
import { bigNumberify, expandDecimals, formatAmount, formatNumber } from "lib/numbers";
import styled from "styled-components";

import { getContract } from "config/contracts";
import useGetListStakedNFT from "hooks/useGetListStakedNFT";
import useGetListStakedNFTv2 from "hooks/useGetListStakedNFTv2";
import { useChainId } from "lib/chains";
import { Link } from "react-router-dom";
import useSWR from "swr";

// import NavigatorClubStaking from "abis/NavigatorClubStaking.json";
import NFTClub from "abis/NFTClub.json";

import { callContract, contractFetcher } from "lib/contracts";

import { commify } from "@ethersproject/units";
import { OP, FANTOM, getLinkCDN, MUMBAI } from "config/chains";
import iconLink from "img/icons/link-green.svg";
import iconLinkLight from "img/icons/link-greenLight.svg";
import { isEmpty } from "lodash";
import { useState } from "react";
import { TXT } from "../multichainTxt";
import { useThemeContext } from "contexts/ThemeProvider";

function StakeNWFTMain({ isVisible, setIsVisible, connectWallet, setPendingTxns, pendingTxns }) {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { active, activate, account, library } = useWeb3React();
  const { chainId } = useChainId();

  const [choosenNFT, setChoosenNFT] = useState([]);

  const handleChange = (clickItem) => {
    const lists = [...choosenNFT];

    if (lists.includes(clickItem.toString())) {
      const filterList = lists.filter((item) => {
        return item !== clickItem;
      });

      setChoosenNFT(filterList);
      return;
    } else {
      lists.push(clickItem.toString());
      setChoosenNFT(lists);
    }
  };

  const handleSelect = (type) => {
    if (type === "remove") {
      setChoosenNFT([]);
      return;
    }
    let array = [];
    listMyNFT.map((item) => {
      array.push(item.toString());
      return item;
    });

    setChoosenNFT(array);
  };
  // const nftClubAddress = getContract(chainId, chainId === FANTOM ? "NFTClubV2" : "NFTClub");
  const nftClubAddress = getContract(chainId, "NFTClub");
  const navigatorClubStakingAddress = getContract(chainId, "NavigatorClubStaking");

  const { listMyNFT: listMyNFTv1 } = useGetListStakedNFT();
  // const { listMyNFT: listMyNFTv2 } = useGetListStakedNFTv2();

  const listMyNFT = listMyNFTv1;
  // const listMyNFT = chainId === FANTOM || chainId === MUMBAI ? listMyNFTv2 : listMyNFTv1;

  // const listMyNFT = [
  //   [1, 100, true],
  //   [21, 100, true],
  //   [31, 100, true],
  //   [41, 100, false],
  //   [541, 100, false],
  //   [641, 100, false],
  // ];

  const yourStakedNFT = listMyNFT?.filter((item) => item.includes(true))?.length;
  const countdownRenderer = (countdownProps) => {
    const { days, hours, minutes, seconds } = countdownProps;
    const d = String(days);
    const h = String(hours);
    const m = String(minutes);
    const s = String(seconds);
    return (
      <div className="time-countdown">
        <div className="time-left">
          {d.padStart(2, "0")}
          <span>d </span>
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {h.padStart(2, "0")}
          <span>h </span>
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {m.padStart(2, "0")}
          <span>m</span>{" "}
        </div>{" "}
        <div className="space">: </div>
        <div className="time-left">
          {s.padStart(2, "0")}
          <span>s</span>
        </div>
      </div>
    );
  };
  const { data: isApprovedNFT } = useSWR(
    account && [`StakeNFT:isApprovedForAll:${active && account}`, chainId, nftClubAddress, "isApprovedForAll"],
    {
      fetcher: contractFetcher(library, NFTClub, [account, navigatorClubStakingAddress]),
    }
  );
  const { data: epoch } = useSWR([`StakeNFT:epoch`, chainId, navigatorClubStakingAddress, "epoch"], {
    fetcher: contractFetcher(library, NavigatorClubStaking),
  });
  const { data: nextEpoch } = useSWR(
    [`StakeNFT:nextEpochPoint:${active}`, chainId, navigatorClubStakingAddress, "nextEpochPoint"],
    {
      fetcher: contractFetcher(library, NavigatorClubStaking),
    }
  );
  const { data: epochReward } = useSWR(
    [`StakeNFT:epochReward:${active}`, chainId, navigatorClubStakingAddress, "epochReward"],
    {
      fetcher: contractFetcher(library, NavigatorClubStaking),
    }
  );
  const { data: totalRewardDistributed } = useSWR(
    [`StakeNFT:totalRewardDistributed:${active}`, chainId, navigatorClubStakingAddress, "totalRewardDistributed"],
    {
      fetcher: contractFetcher(library, NavigatorClubStaking, []),
    }
  );
  const { data: stakedPower } = useSWR(
    [`StakeNFT:stakedPower:${active && account}`, chainId, navigatorClubStakingAddress, "balances"],
    {
      fetcher: contractFetcher(library, NavigatorClubStaking, [account]),
    }
  );
  const { data: pendingReward } = useSWR(
    [`StakeNFT:pendingReward:${active}`, chainId, navigatorClubStakingAddress, "earned"],
    {
      fetcher: contractFetcher(library, NavigatorClubStaking, [account]),
    }
  );
  const { data: totalPower } = useSWR(
    [`StakeNFT:totalPower:${active}`, chainId, navigatorClubStakingAddress, "totalPower"],
    {
      fetcher: contractFetcher(library, NavigatorClubStaking),
    }
  );
  const { data: totalSupply } = useSWR(
    [`StakeNFT:totalSupply:${active}`, chainId, navigatorClubStakingAddress, "totalSupply"],
    {
      fetcher: contractFetcher(library, NavigatorClubStaking),
    }
  );
  const { data: totalNFTMint } = useSWR([`NFT:totalNFTMint:${active}`, chainId, nftClubAddress, "totalSupply"], {
    fetcher: contractFetcher(library, NFTClub),
  });
  let listIDStaked = [];

  let listIDNoStake = [];
  choosenNFT.forEach((element) => {
    if (element.includes("true")) {
      listIDStaked.push(element.split(",")[0]);
    } else {
      listIDNoStake.push(element.split(",")[0]);
    }
  });
  let nextYourReward;

  if (totalPower && totalPower.gt(0) && stakedPower && epochReward) {
    nextYourReward = epochReward.mul(stakedPower).div(totalPower);
  }
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  const token =
    chainId === FANTOM
      ? infoTokens[getTokenBySymbol(250, "FTM").address]
      : infoTokens[getTokenBySymbol(146, "S").address];

  let estAPR, estYourAPR = bigNumberify(0);
  // let priceFTM = bigNumberify("203784000000000000")
  let priceFTM = token?.minPrice || bigNumberify(0);
  let pricePowder = chainId === FANTOM ? priceFTM.div(bigNumberify(22)) : priceFTM.div(bigNumberify(22));
  if (epochReward && totalPower && totalPower.gt(0) && priceFTM && priceFTM.gt(0) && pricePowder && pricePowder.gt(0)) {
    estAPR = epochReward
      .mul(bigNumberify(52))
      .mul(priceFTM)
      .div(expandDecimals(1, 30))
      .div(totalPower.mul(pricePowder).div(expandDecimals(1, 30)))
      .mul(bigNumberify(100));

    if (chainId === OP && totalSupply) {
      estAPR = epochReward
        .mul(bigNumberify(52))
        .div(totalSupply.mul(bigNumberify("130000000000000000")).div(expandDecimals(1, 18)))
        .mul(bigNumberify(100));
    }
  }
  
  if (nextYourReward && stakedPower && stakedPower.gt(0) && pricePowder && priceFTM.gt(0) && pricePowder && pricePowder.gt(0)) {
    estYourAPR = nextYourReward
      .mul(bigNumberify(52))
      .mul(priceFTM)
      .div(expandDecimals(1, 30))
      .div(stakedPower.mul(pricePowder).div(expandDecimals(1, 30)))
      .mul(bigNumberify(100));
    if (estYourAPR.gt(estAPR)) {
      estYourAPR = estAPR;
    }
    if (chainId === OP && totalSupply) {
      estYourAPR = nextYourReward
        .mul(bigNumberify(52))
        .div(totalSupply.mul(bigNumberify("130000000000000000")).div(expandDecimals(1, 18)))
        .mul(bigNumberify(100));
    }
  }
  
  const onApprove = () => {
    const contract = new ethers.Contract(nftClubAddress, NFTClub.abi, library.getSigner());
    callContract(chainId, contract, "setApprovalForAll", [navigatorClubStakingAddress, true], {
      sentMsg: `Approve NFT submitted!`,
      failMsg: `Approve NFT failed.`,
      successMsg: `Approve NFT completed!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {});
  };
  const onClaim = () => {
    const contract = new ethers.Contract(navigatorClubStakingAddress, NavigatorClubStaking.abi, library.getSigner());
    callContract(chainId, contract, "claimReward", [], {
      sentMsg: `Claim submitted!`,
      failMsg: `Claim failed.`,
      successMsg: `Claim completed!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {});
  };
  async function stakeNFT(listNftID) {
    if (listNftID) {
      const opts = {
        // gasLimit: bigNumberify(7258590),
        successMsg: `Stake completed`,
        failMsg: `Stake failed`,
        sentMsg: `Stake submitted`,
        setPendingTxns,
      };
      const params = [listNftID];
      const method = "stake";
      const contract = new ethers.Contract(navigatorClubStakingAddress, NavigatorClubStaking.abi, library.getSigner());
      const res = await callContract(chainId, contract, method, params, opts);
      if (res) await res.wait();
      // let newList = choosenNFT.filter((item) => item.includes("true"));
      setChoosenNFT([]);
      // .then(async (tx) => {})
      // .finally(() => {
      //   // let newList = choosenNFT.filter((item) => item.includes("true"));
      //   // setChoosenNFT(newList);
      // });
    }
  }
  async function unStakeNFT(listNftID) {
    if (listNftID) {
      const opts = {
        // gasLimit: bigNumberify(7258590),
        successMsg: `Unstake completed`,
        failMsg: `Unstake failed`,
        sentMsg: `Unstake submitted`,
        setPendingTxns,
      };
      const params = [listNftID];
      const method = "withdraw";
      const contract = new ethers.Contract(navigatorClubStakingAddress, NavigatorClubStaking.abi, library.getSigner());
      const res = await callContract(chainId, contract, method, params, opts);
      // .then(async (tx) => {})
      // .finally(() => {
      //   // let newList = choosenNFT.filter((item) => item.includes("false"));
      //   // setChoosenNFT(newList);
      // });
      if (res) await res.wait();
      // let newList = choosenNFT.filter((item) => item.includes("false"));
      setChoosenNFT([]);
    }
  }

  const renderButton = (item, isStaked) => {
    // if (chainId === FANTOM || chainId === MUMBAI) {
    //   return (
    //     <button className="btn-outline" disabled>
    //       Approve
    //     </button>
    //   );
    // }

    if (!isApprovedNFT)
      return (
        <button className="border-btn" onClick={onApprove}>
          Approve
        </button>
      );
    //điều kiện là chưa stake
    if (!isStaked) {
      return (
        <button className="btn-primary" onClick={() => stakeNFT([item])}>
          Stake
        </button>
      );
    } else
      return (
        <button className="border-btn" onClick={() => unStakeNFT([item])}>
          Unstake
        </button>
      );
  };

  const chosenStakeCountArr = choosenNFT.filter((item) => {
    return item.includes("false");
  });

  const chosenUnStakeCount = choosenNFT.filter((item) => {
    return item.includes("true");
  });

  const renderStats = () => {
    return (
      <>
        <EpochStats className={`epoch-stats ${lightThemeClassName}`}>
          <div className="current-epoch">
            <div className="label">Current epoch</div>
            <div className="amount">
              {epoch ? (
                "#" + commify(epoch)
              ) : (
                <span className="skeleton-box" style={{ width: "60px", height: "25px" }} />
              )}
            </div>
          </div>
          <div className="stake-nft">
            <div className="label">Staked NFT</div>
            {totalSupply ? (
              <div className="amount">
                {" "}
                {totalSupply ? commify(totalSupply) : "--"}/{totalNFTMint ? commify(totalNFTMint) : "--"}
              </div>
            ) : (
              <div className="amount">
                --
                {/* <div className="skeleton-box" style={{ width: "60px", height: "25px" }} /> */}
              </div>
            )}
          </div>
          <div className="total-reward">
            <div className="label">Total distributed</div>
            {totalRewardDistributed ? (
              <div className="amount">
                {" "}
                <img alt="img" src={TXT[chainId]?.nativeIcon} />{" "}
                {formatAmount(totalRewardDistributed, 18, 2, true) + ` ${TXT[chainId]?.wrapToken}`}
              </div>
            ) : (
              <div className="amount">
                --
                {/* <div className="skeleton-box" style={{ width: "60px", height: "25px" }} /> */}
              </div>
            )}
          </div>
          <div className="next-epoch">
            <div className="label">Next epoch</div>
            {nextEpoch ? (
              <div className="amount">
                {nextEpoch * 1000 > Date.now() ? (
                  <Countdown date={new Date(nextEpoch * 1000)} renderer={countdownRenderer} />
                ) : (
                  "-- : -- : --"
                )}
              </div>
            ) : (
              <div className="amount">
                --
                {/* <div className="skeleton-box" style={{ width: "60px", height: "25px" }} /> */}
              </div>
            )}
          </div>{" "}
          <div className="apr">
            <div className="label">Est. APR</div>
            {estAPR ? (
              <div className="amount amount-highlight"> ~{estAPR ? formatAmount(estAPR, 18, 2, true) : "--"}%</div>
            ) : (
              <div className="amount amount-highlight">
                {" "}
                --
                {/* <div className="skeleton-box" style={{ width: "60px", height: "25px" }} /> */}
              </div>
            )}
          </div>
          <div className="next-epoch-reward">
            <div className="label">Next epoch reward</div>
            {epochReward ? (
              <div className="amount">
                {" "}
                <img alt="img" src={TXT[chainId]?.nativeIcon} />
                {formatAmount(epochReward, 18, 2, true) + ` ${TXT[chainId]?.wrapToken}`}
              </div>
            ) : (
              <div className="amount">
                --
                {/* <div className="skeleton-box" style={{ width: "60px", height: "25px" }} /> */}
              </div>
            )}
          </div>
        </EpochStats>
        <StakePower className="stats-box">
          <div className="label">Your staked power</div>
          <div className="amount amount--large">
            {stakedPower ? formatNumber(stakedPower.toString(), 0) : "--"}
            {/* {formatAmount(stakedPower, 0, 0, true)} */}
          </div>
          <div className="est-apr">
            Est. your APR: &nbsp;
            {estYourAPR && estYourAPR.gte(0) ? (
              <span className="amount-highlight">~{estYourAPR ? formatAmount(estYourAPR, 18, 2, true) : "--"}%</span>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "25px" }} />
            )}
            {/* <span className="amount-highlight">--</span> */}
          </div>
        </StakePower>
        <Reward className="stats-box">
          <div>
            <div className="label">Next reward</div>
            {nextYourReward ? (
              <div className="amount">
                <span className="amount-highlight">
                  {" "}
                  {nextYourReward
                    ? formatNumber(formatAmount(nextYourReward, 18, 18, false), 4)
                    : "--"}
                </span>
                {TXT[chainId]?.wrapToken}
              </div>
            ) : (
              <div className="amount amount-highlight">
                {" "}
                --
                {/* <span className="skeleton-box" style={{ width: "60px", height: "25px" }} /> */}
              </div>
            )}
          </div>
          <div>
            <div className="label">Pending reward</div>
            {pendingReward ? (
              <div className="amount ">
                <span className="amount-highlight">
                  {/* {formatAmount(pendingReward, 18, 2, true)} */}
                  {pendingReward
                    ? formatNumber(formatAmount(pendingReward, 18, 18, false), 4)
                    : "--"}
                </span>
                {TXT[chainId]?.wrapToken}
              </div>
            ) : (
              <div className="amount amount-highlight">
                {" "}
                --
                {/* <span className="skeleton-box" style={{ width: "60px", height: "25px" }} /> */}
              </div>
            )}
          </div>
          {!active ? (
            <button className="default-btn btn-claim" onClick={() => connectWallet()}>
              Connect Wallet
            </button>
          ) : (
            <button
              className="default-btn btn-claim"
              onClick={onClaim}
              disabled={!(pendingReward && pendingReward.gt(0))}
            >
              Claim
            </button>
          )}
        </Reward>
      </>
    );
  };
  return (
    <StyledHowTo className={lightThemeClassName}>
      <div className="container">
        <SectionTitle>Earn real profit with Navigator Club</SectionTitle>
        <Desc>Earn $S from platform's collected fees with your NFT</Desc>
        {/* {chainId !== FANTOM && chainId !== MUMBAI && (
          <Steps>
            <div className="referral-step">
              <div className="referral-step-line">
                <div className="line"></div>
                <div className="step-box">
                  <div className="step-number">1</div>
                  <div className="step-item">Get NFT</div>
                </div>
                <div className="step-box">
                  <div className="step-number">2</div>
                  <div className="step-item">Stake NFT</div>
                </div>
                <div className="step-box">
                  <div className="step-number">3</div>
                  <div className="step-item">Earn reward</div>
                </div>
              </div>
            </div>
          </Steps>
        )} */}

        <NFTWrap>{renderStats()}</NFTWrap>
        <ListNFTWrap>
          <ListNFTHead>
            <div className="amount-nft">
              <div className="amount-box">
                <div>
                  Your NFT: <span>{listMyNFT?.length || "--"}</span>
                </div>
                <div>
                  Your staked NFT: <span>{yourStakedNFT || "--"}</span>
                </div>
              </div>
              {/* {listMyNFT?.length > 0 && (
                <Link className="link-mint-more" to="/nft?min=true">
                  Mint more NFT <img src={isLightTheme ? iconLinkLight : iconLink} alt="img" />{" "}
                </Link>
              )} */}
            </div>
            <div className="head-right">
              {listMyNFT.length > 0 && (
                <div className="txt-select">
                  <div>
                    Selected: <span className="txt-bold">{choosenNFT?.length}</span>{" "}
                  </div>
                  <div style={{ display: "flex", gap: "12px" }}>
                    <div className="txt-green" onClick={handleSelect}>
                      Select All
                    </div>
                    <div className="txt-deselect" onClick={() => handleSelect("remove")}>
                      Deselect All
                    </div>
                  </div>
                </div>
              )}
              {/* {!isApprovedNFT || isEmpty(listMyNFT) ? null : ( */}
              <div className="action-nft">
                {
                  <button
                    className="default-btn"
                    onClick={() => stakeNFT(listIDNoStake)}
                    disabled={chosenStakeCountArr?.length < 1}
                  >
                    Stake ({chosenStakeCountArr?.length})
                  </button>
                }
                <button
                  className="border-btn "
                  onClick={() => unStakeNFT(listIDStaked)}
                  disabled={chosenUnStakeCount?.length < 1}
                >
                  Unstake ({chosenUnStakeCount?.length})
                </button>
              </div>
              {/* )} */}
            </div>
            <div className="action-nft-mobile">
              {
                <button
                  className="default-btn"
                  onClick={() => stakeNFT(listIDNoStake)}
                  disabled={chosenStakeCountArr?.length < 1}
                >
                  Stake ({chosenStakeCountArr?.length})
                </button>
              }
              <button
                className="border-btn "
                onClick={() => unStakeNFT(listIDStaked)}
                disabled={chosenUnStakeCount?.length < 1}
              >
                Unstake ({chosenUnStakeCount?.length})
              </button>
            </div>
          </ListNFTHead>
          {isEmpty(listMyNFT) ? (
            <EmptyContent>
              <img src={TXT[chainId]?.noNFTImg} className="img-avt" alt="img" />
              <div className="content">
                <div className="txt-asset">You don't have any NFT</div>

                {/* <Link
                  onClick={() => {
                    setIsVisible(true);
                  }}
                  to={`/nft`}
                  className="txt-link"
                >
                  Get Your NFT <img src={icLink} alt="" />
                </Link> */}
              </div>
            </EmptyContent>
          ) : (
            <ListNFT>
              {listMyNFT.map((item) => {
                return (
                  <Item className={`${choosenNFT.includes(item.toString()) ? "item--active" : ""}`}>
                    <div
                      className={`${
                        choosenNFT.includes(item.toString()) ? "checkbox-wrap checkbox-wrap--active" : "checkbox-wrap"
                      }`}
                    >
                      {choosenNFT.includes(item.toString()) ? (
                        <img
                          onClick={() => handleChange(item.toString())}
                          src={isLightTheme ? icCheckboxActiveLight : icCheckboxActive}
                          alt="img"
                        />
                      ) : (
                        <img
                          src={isLightTheme ? icCheckboxLight : icCheckbox}
                          alt="img"
                          onClick={() => handleChange(item.toString())}
                        />
                      )}
                    </div>{" "}
                    <div className="wrap-item">
                      <div className="nft-profile">
                        <div className="nft-name">Navigator #{item[0]}</div>
                        <div className="nft-power">
                          <img alt="img" src={icFlash} />
                          <span>{item[1] ? formatNumber(item[1]) : "--"}</span>{" "}
                        </div>
                      </div>
                      <img className="nft-avt" alt="mint" src={`${getLinkCDN(chainId, true)}${item[0]}.png`} />
                      {/* <img className="nft-avt" alt="mint" src={mum1} /> */}
                      {renderButton(item[0], item[2])}
                    </div>
                  </Item>
                );
              })}
            </ListNFT>
          )}
        </ListNFTWrap>
      </div>
    </StyledHowTo>
  );
}
const ListNFTWrap = styled.div`
  border: 2px solid rgba(55, 63, 92, 0.5);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 24px 40px;
  margin-bottom: 120px;

  @media screen and (max-width: 991px) {
    padding: 24px;
    margin-bottom: 48px;
  }
  @media screen and (max-width: 700px) {
    margin-bottom: 48px;
    padding: 16px;
    border: none;
    padding: 0;
    background: none;
    border: none;
  }
`;
const StakePower = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .est-apr {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }
  .label {
    margin-bottom: 8px;
  }
  @media screen and (max-width: 700px) {
    padding: 24px !important;
  }
`;
const Reward = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    &:first-child {
      margin-bottom: 16px;
      @media screen and (max-width: 700px) {
        margin-bottom: 12px;
      }
    }
  }
  .amount {
    font-size: 16px;
    span {
      margin-right: 4px;
    }
  }
  .btn-claim {
    height: 40px;
    width: 100%;
    margin-top: 24px;
    @media screen and (max-width: 700px) {
      margin-top: 16px;
    }
  }
  @media screen and (max-width: 700px) {
    .label {
      color: #828899;
    }
  }
`;

const EmptyContent = styled.div`
  .txt-link {
    justify-content: center;
  }
  .img-avt {
    filter: grayscale(1);
    margin-bottom: 16px;
    @media screen and (max-width: 700px) {
      width: 200px;
    }
  }

  a {
    display: flex;
    align-items: center;
    color: #90fb75;
    margin-top: 8px;
    img {
      margin-left: 4px;
    }
  }
  text-align: center;
  border-radius: 24px;

  width: 100%;
  padding: 24px;
  height: 472px;
  margin-bottom: 82px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 700px) {
    height: fit-content;
    padding: 16px;
  }
`;
export default StakeNWFTMain;

const ListNFTHead = styled.div`
  .txt-deselect {
    color: #f6475d;
  }
  .action-nft {
    display: flex;
    gap: 12px;
    .btn-outline {
      margin-left: 16px;
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  .amount-nft {
    .amount-box {
      display: flex;
      > div {
        &:first-child {
          margin-right: 24px;
        }
      }
    }
    font-weight: 400;
    font-size: 14px;

    a {
      font-weight: 500;
      font-size: 14px;
      color: #90fb75;
      display: flex;
      align-items: center;
      margin-top: 8px;
      img {
        margin-left: 4px;
      }
    }
    span {
      margin-left: 4px;
      font-weight: 700;
      font-size: 16px;
    }
  }

  button {
    padding: 8px 16px;
    height: 40px;
  }
  .head-right {
    font-weight: 500;
  }
  .action-nft-mobile {
    display: none;
  }
  @media screen and (max-width: 991px) {
    .txt-select {
      > div:first-child {
        margin-left: 0;
      }
    }
    .amount-box,
    .head-right {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .head-right {
      align-items: flex-end !important;
      .txt-select {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 700px) {
    margin-bottom: 24px;
    padding: 16px;
    flex-wrap: wrap;
    /* display: grid;
    grid-template-columns: 1fr 1fr; */

    border-radius: 16px;
    border: 2px solid var(--Border, rgba(55, 63, 92, 0.5));
    .head-right {
      flex-direction: column;
      width: 50%;
      white-space: nowrap;

      align-items: flex-start;
      .txt-select {
        margin-top: 0px;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
      }
    }
    .amount-box {
      gap: 8px;
      > div {
        display: flex;
        white-space: nowrap;
        align-items: center;
        &:first-child {
          margin-right: 0 !important;
        }
      }
    }
    .action-nft-mobile {
      display: flex;
      width: 100%;
      margin-top: 16px;

      > button {
        width: calc(50% - 8px);

        &:first-child {
          margin-right: 16px;
        }
      }
    }

    .action-nft {
      display: none;
    }
    .action-stake-only {
      button {
        width: 100%;
      }
    }
  }
`;

const ListNFT = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 200px);

  grid-gap: 40px 60px;
  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 24px;
  }

  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 16px;
  }
`;
const NFTWrap = styled.div`
  .label {
    color: #fff;
  }
  > div {
    max-height: 171px;
  }
  .amount {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    &-highlight {
      color: #90fb75;
      margin-right: 4px;
    }

    &--large {
      font-weight: 700;
      font-size: 48px;
      line-height: 140%;
      justify-content: center;
      /* @media screen and (max-width: 700px) {
        font-size: 36px;
      } */
    }
    img {
      width: 20px;
      margin-right: 4px;
      @media screen and (max-width: 700px) {
        width: 16px;
      }
    }
  }

  margin-bottom: 40px;
  .amount-highlight {
    color: #90fb75;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-right: 0;
  }
  display: grid;
  grid-template-columns: auto 312px 312px;
  gap: 24px;
  > div {
    border-radius: 16px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
    padding: 24px;
  }

  @media screen and (max-width: 1099px) {
    display: flex;

    flex-wrap: wrap;
    .epoch-stats {
      width: 100%;
    }
    .stats-box {
      width: calc(50% - 12px);
    }
  }
  /* @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  } */
  @media screen and (max-width: 991px) {
    margin-bottom: 24px;
  }
  @media screen and (max-width: 700px) {
    gap: 16px;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    .epoch-stats {
      padding: 16px;
      gap: 12px;
      .label {
        color: #828899;
      }
    }
    .stats-box {
      width: 100%;
      padding: 16px !important;
    }
  }
  .actions {
    display: flex;
    a {
      padding: 8px 16px;
      height: 40px;
      text-decoration: none;
      img {
        margin-right: 4px;
      }
    }
    a:first-child {
      margin-right: 12px;
    }
    @media screen and (max-width: 991px) {
      margin-top: 16px;
    }
    @media screen and (max-width: 700px) {
      flex-direction: column;
      width: 100%;
      a {
        width: 100%;
      }
      a:first-child {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
  .nft-avt-box {
    display: flex;
    align-items: center;
    img {
      width: 120px;
      height: 120px;
      margin-right: 24px;
    }
    .total-nft {
      margin-right: 32px;
    }
    .label {
      font-weight: 400;
      font-size: 14px;

      margin-bottom: 4px;
    }
    .amount {
      font-weight: 700;
      font-size: 36px;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      .amount {
        font-size: 24px;
      }
      .total-nft {
        margin-right: 4px;
        flex: 1;
      }
      .avt-wrapper img {
        width: 80px;
        height: 80px;
        margin-right: 16px;
      }
      /* flex-wrap: wrap;
      .avt-wrapper {
        width: 100%;
        margin-bottom: 8px;
        display: flex;
      }
      .total-nft {
        width: calc(50% - 16px);
      } */
    }
  }
`;
const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  text-align: center;

  @media screen and (max-width: 991px) {
    font-size: 24px;
    padding: 0;
    margin-top: 24px;
  }
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 12px 0 24px 0;
  color: #828899;
  text-align: center;
  @media screen and (max-width: 700px) {
    margin: 4px 0 24px 0;
  }
  @media screen and (max-width: 700px) {
    margin: 8px 0 16px 0;
  }
`;
const Item = styled.div`
  position: relative;

  &.item--active {
    border: 1px solid #90fb75;
    background: #000;
  }
  .checkbox-wrap {
    width: calc(100%);
    display: flex;
    justify-content: center;
    position: absolute;
    img {
      pointer-events: initial;
      width: 24px;
      cursor: pointer;
      position: relative;
      top: -30px;
    }
  }

  .nft-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
  }

  .border-btn,
  .btn-primary {
    width: calc(100% - 32px) !important;
    margin-left: 16px;
    height: 40px;
  }
  border-radius: 16px;
  border: 2px solid var(--Border, rgba(55, 63, 92, 0.5));
  background: var(--Nature-3, #262933);
  padding: 16px 0;
  img {
    width: 100%;
    max-height: 200px;
    margin-bottom: 12px;
    @media screen and (max-width: 700px) {
      height: auto;
    }
  }
  .nft-name {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
  .nft-power {
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    align-items: center;
    opacity: 0.6;
    img {
      width: 16px;
      margin-bottom: 0;
    }

    span {
      color: #ffffff;
    }
  }
`;

const EpochStats = styled.div`
  gap: 24px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  div.amount {
    font-size: 14px;
  }
  @media screen and (max-width: 991px) {
    gap: 12px 0;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    max-height: unset !important;
    .current-epoch {
      order: 1;
    }
    .next-epoch {
      order: 2;
    }
    .stake-nft {
      order: 3;
    }
    .total-reward {
      order: 5;
    }
    .apr {
      order: 4;
    }
    .next-epoch-reward {
      order: 6;
    }

    > div {
      display: flex;
      justify-content: space-between;
      .label {
        margin-bottom: 0;
        font-size: 12px;
      }
      .amount {
        font-size: 14px;
      }
    }
  }
  .label {
    color: #fff;
    margin-bottom: 8px;
  }

  &.theme--light {
    .label {
      color: var(--Text-Text_Primary, #0d1a16);
    }
  }
`;

const StyledHowTo = styled.section`
  .txt-green {
    color: #90fb75;
    cursor: pointer;
  }
  .head-right {
    display: flex;
    align-items: center;
  }
  .txt-select {
    margin-right: 24px;
    display: flex;
    > div {
      margin-left: 12px;
    }
  }
  .time-countdown {
    display: flex;
    .space {
      margin: 0 4px;
    }
  }
  .nft-avt {
  }
  color: #ffffff;
  a {
    text-decoration: none;
  }

  .stats-box {
    padding: 24px;

    @media screen and (max-width: 900px) {
      box-shadow: none;
    }
  }
`;
