import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { ethers } from "ethers";
import { getContract } from "config/contracts";
import SettingsManager from "abis/SettingsManager.json";
import {
  adjustForDecimals,
  NLP_DECIMALS,
  USD_DECIMALS,
  NLP_COOLDOWN_DURATION,
  USDN_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  NSLP_COOLDOWN_DURATION,
} from "lib/legacy";
import styled from "styled-components";
import ReaderV2 from "abis/ReaderV2.json";
import VaultNslp from "abis/VaultNslp.json";
import RewardTracker from "abis/RewardTracker.json";
import Vester from "abis/Vester.json";
import RewardRouter from "abis/RewardRouter.json";
import Token from "abis/Token.json";
import infoIcon from "img/icons/info.svg";
import nlp24Icon from "img/nslp.svg";
import usdcIcon from "img/ic_usdc_24.svg";
import arrowIcon from "img/ic_arrow.svg";

import { getChainName, IS_NETWORK_DISABLED, BASE } from "config/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { DEFAULT_FROM_V2_TOKEN, getToken, getTokens, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import Tooltip from "components/Tooltip/Tooltip";
import Tab from "components/Tab/Tab";
import BuyInputSection from "components/BuyInputSection/BuyInputSection";
import useSettingsManagerContract from "hooks/contracts/useSettingsManagerContract";
import useInfoNslp from "hooks/useInfoNslp";
import useMintNslp from "hooks/useMintNslp";
import useVaultNaviContract from "hooks/contracts/useVaultNaviContract";
import useRedeemNslp from "hooks/useRedeemNslp";
import { helperToast } from "lib/helperToast";
import Countdown from "react-countdown";

const { AddressZero } = ethers.constants;

export default function BuySellNslp({
  isBuying,
  setPendingTxns,
  connectWallet,
  setIsBuying,
  savedShouldDisableValidationForTesting,
}) {
  const history = useHistory();
  const swapLabel = isBuying ? "Buy NSLP" : "Sell NSLP";
  const tabLabel = isBuying ? `Buy NSLP` : `Sell NSLP`;
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  const tokens = getTokens(chainId);
  const usdcAddress = getContract(chainId, "USDC.e");

  const { stakingFee, unstakingFee, maxTotalNslp } = useSettingsManagerContract(chainId, active, account);
  const { price: nslpPrice, totalSupply: totalSupplyNslp } = useInfoNslp(chainId);

  const nslpAvailableToMint = useMemo(() => {
    if (maxTotalNslp && totalSupplyNslp) {
      return maxTotalNslp.sub(totalSupplyNslp);
    }
  }, [maxTotalNslp, totalSupplyNslp]);
  const whitelistedTokens = getWhitelistedTokens(chainId).filter((t) =>
    DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(t.address)
  );
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const [swapValue, setSwapValue] = useState("");
  const [nslpValue, setNslpValue] = useState("");
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${swapLabel}-swap-token-address2`,
    usdcAddress
  );
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const readerAddress = getContract(chainId, "Reader");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const feeNslpTrackerAddress = getContract(chainId, "FeeNslpTracker");
  const nslpManagerAddress = getContract(chainId, "VaultNslp");
  const nslpAddress = getContract(chainId, "NSLP");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(
    [
      `BuySellNslp:getTokenBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 30000,
    }
  );

  const tokenAllowanceAddress = swapTokenAddress === AddressZero ? nativeTokenAddress : swapTokenAddress;
  const { data: tokenAllowance } = useSWR(
    [active, chainId, tokenAllowanceAddress, "allowance", account || PLACEHOLDER_ACCOUNT, rewardRouterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 30000,
    }
  );

  const { data: lastPurchaseTime } = useSWR(
    [
      `BuySellNslp:lastPurchaseTime:${active}`,
      chainId,
      nslpManagerAddress,
      "lastStakedAt",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, VaultNslp),
      refreshInterval: 30000,
    }
  );

  const { data: nslpBalance } = useSWR(
    [
      `BuySellNslp:nslpBalance:${active}`,
      chainId,
      feeNslpTrackerAddress,
      "stakedAmounts",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 30000,
    }
  );

  const nslpVesterAddress = getContract(chainId, "NslpVester");
  const { data: reservedAmount } = useSWR(
    [`BuySellNslp:reservedAmount:${active}`, chainId, nslpVesterAddress, "pairAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 30000,
    }
  );

  // const nlpVesterV1Address = getContract(chainId, "NlpVesterV1");
  // const { data: reservedAmountV1 } = useSWR(
  //   chainId !== BASE && [
  //     `BuySellNslp:reservedAmountV1:${active}`,
  //     chainId,
  //     nlpVesterV1Address,
  //     "pairAmounts",
  //     account || PLACEHOLDER_ACCOUNT,
  //   ],
  //   {
  //     fetcher: contractFetcher(library, Vester),
  //     refreshInterval: 30000,
  //   }
  // );

  const SettingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: nslpCooldown } = useSWR([`StakeV2:nslpCooldown`, chainId, SettingsManagerAddress, "cooldownDuration"], {
    fetcher: contractFetcher(library, SettingsManager),
    refreshInterval: 10000,
  });
  const coundowHour = nslpCooldown % 3600 === 0 ? nslpCooldown / 3600 : parseInt(nslpCooldown / 3600) + 1;
  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(NSLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && parseInt(Date.now() / 1000) < redemptionTime;
  // const inCooldownWindow = false;
  // const nslpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);
  // const usdgSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0);
  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = isBuying ? aums[0] : aums[1];
  // }
  // const nslpPrice =
  //   aum && aum.gt(0) && nslpSupply.gt(0)
  //     ? aum.mul(expandDecimals(1, NLP_DECIMALS)).div(nslpSupply)
  //     : expandDecimals(1, USD_DECIMALS);

  var maxSellAmount = nslpBalance;
  if (nslpBalance && reservedAmount) {
    maxSellAmount = maxSellAmount.sub(reservedAmount);
  }
  // if (nslpBalance && reservedAmountV1) {
  //   maxSellAmount = maxSellAmount.sub(reservedAmountV1);
  // }
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const swapToken = getToken(chainId, swapTokenAddress);
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);

  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0);

  const swapAmount = parseValue(swapValue, swapToken && swapToken.decimals);
  const nslpAmount = parseValue(nslpValue, 18);

  const needApproval =
    isBuying && swapTokenAddress !== AddressZero && tokenAllowance && swapAmount && swapAmount.gt(tokenAllowance);

  const swapUsdMin = getUsd(swapAmount, swapTokenAddress, false, infoTokens);
  const nslpUsdMax =
    nslpAmount && nslpPrice ? nslpAmount.mul(nslpPrice).div(expandDecimals(1, NLP_DECIMALS)) : undefined;

  let isSwapTokenCapReached;
  if (swapTokenInfo.managedUsd && swapTokenInfo.maxUsdgAmount) {
    isSwapTokenCapReached = swapTokenInfo.managedUsd.gt(
      adjustForDecimals(swapTokenInfo.maxUsdgAmount, USDN_DECIMALS, USD_DECIMALS)
    );
  }
  const { data: totalSupplyBlp } = useSWR([`Blp:getTotalSupply`, chainId, nslpAddress, "totalSupply"], {
    fetcher: contractFetcher(undefined, Token),
    refreshInterval: 10000,
  });
  const { totalUSD } = useVaultNaviContract(chainId);

  const { outputMint } = useMintNslp({
    chainId,
    fromValue: swapAmount,
    nslpPrice: nslpPrice,
    stakingFee,
    isMint: isBuying,
    totalUSD,
    totalSupplyBlp,
    fromAddress: swapToken?.address,
  });

  const { outputRedeem } = useRedeemNslp({
    chainId,
    fromValue: nslpAmount,
    nslpPrice: nslpPrice,
    unstakingFee,
    isRedeem: !isBuying,
    totalUSD,
    totalSupplyBlp,
    destToken: swapToken?.symbol,
    destAddress: swapToken.address,
    decimals: swapToken.decimals,
  });

  const onSwapValueChange = (e) => {
    // setAnchorOnSwapAmount(true);
    setSwapValue(e.target.value);
  };

  const onNslpValueChange = (e) => {
    // setAnchorOnSwapAmount(false);
    setNslpValue(e.target.value);
  };

  // const onSelectSwapToken = (token) => {
  //   setSwapTokenAddress(token.address);
  //   setIsWaitingForApproval(false);
  // };

  useEffect(() => {
    //THIS CALCULATE OUTPUT AMOUNT
    if (isBuying && outputMint !== null) {
      setNslpValue(formatAmount(outputMint, 30, 18));
    }
  }, [outputMint, isBuying]);

  useEffect(() => {
    if (!isBuying && outputRedeem !== null) {
      setSwapValue(formatAmount(outputRedeem, 6, swapToken.decimals || 6));
    }
  }, [isBuying, outputRedeem, swapAmount, swapToken]);
  const switchSwapOption = (hash = "") => {
    history.push(`${history.location.pathname}#${hash}`);
    setIsBuying(hash === "redeem" ? false : true);
  };

  const fillMaxAmount = () => {
    if (isBuying) {
      // setAnchorOnSwapAmount(true);
      setSwapValue(formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals));
      return;
    }

    // setAnchorOnSwapAmount(false);
    setNslpValue(formatAmountFree(maxSellAmount, NLP_DECIMALS, NLP_DECIMALS));
  };

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (isBuying) return [`NSLP buy disabled, pending ${getChainName(chainId)} upgrade`];
      return [`NSLP sell disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (!isBuying && inCooldownWindow) {
      return [`Redemption time not yet reached`];
    }

    if ((!nslpAmount || nslpAmount.eq(0)) && (!swapAmount || swapAmount.eq(0))) {
      return [`Enter an amount`];
    }

    if (isBuying) {
      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        !savedShouldDisableValidationForTesting &&
        swapTokenInfo &&
        swapTokenInfo.balance &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.balance)
      ) {
        return [`Insufficient ${swapTokenInfo.symbol} balance`];
      }

      if (swapTokenInfo.maxUsdgAmount && swapTokenInfo.usdgAmount && swapUsdMin) {
        const usdgFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDN_DECIMALS);
        const nextUsdgAmount = swapTokenInfo.usdgAmount.add(usdgFromAmount);
        if (swapTokenInfo.maxUsdgAmount.gt(0) && nextUsdgAmount.gt(swapTokenInfo.maxUsdgAmount)) {
          return [`${swapTokenInfo.symbol} pool exceeded, try different token`, true];
        }
      }
    }

    if (!isBuying) {
      if (maxSellAmount && nslpAmount && nslpAmount.gt(maxSellAmount)) {
        return [`Insufficient NSLP balance`];
      }

      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        swapTokenInfo &&
        swapTokenInfo.availableAmount &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.availableAmount)
      ) {
        return [`Insufficient liquidity`];
      }
    }

    return [false];
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (isBuying && isSwapTokenCapReached) {
      return false;
    }

    if (!isBuying && inCooldownWindow) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return `Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    if (isBuying && isSwapTokenCapReached) {
      return `Max Capacity for ${swapToken.symbol} Reached`;
    }

    if (needApproval && isWaitingForApproval) {
      return `Waiting for Approval`;
    }
    if (isApproving) {
      return `Approving ${swapToken.symbol}...`;
    }
    if (needApproval) {
      return `Approve ${swapToken.symbol}`;
    }

    if (isSubmitting) {
      return isBuying ? `Buying...` : `Selling...`;
    }

    if (!isBuying && inCooldownWindow) {
      return "Cooldown to sell NSLP";
    }

    return isBuying ? `Buy NSLP` : `Sell NSLP`;
  };

  const approveFromToken = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: swapToken.address,
      spender: rewardRouterAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
    });
  };

  const buyNslp = () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    const method = "mintAndStakeNslp";
    const params = [swapTokenAddress, swapAmount];
    const value = 0;

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: `Buy submitted.`,
      failMsg: `Buy failed.`,
      successMsg: `${formatAmount(nslpAmount, 18, 4, true)} NSLP bought with ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setSwapValue("");
        setIsSubmitting(false);
      });
  };

  const sellNslp = () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    const method = "unstakeAndRedeemNslp";
    const params = [swapTokenAddress, nslpAmount, outputRedeem];

    callContract(chainId, contract, method, params, {
      sentMsg: `Sell submitted!`,
      failMsg: `Sell failed.`,
      successMsg: `${formatAmount(nslpAmount, 18, 4, true)} NSLP sold for ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setSwapValue("");
        setIsSubmitting(false);
      });
  };

  const onClickPrimary = () => {
    if (!active) {
      connectWallet();
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    const [err, modal] = getError();

    if (modal) {
      helperToast.error(err);
      return;
    }

    if (isBuying) {
      buyNslp();
    } else {
      sellNslp();
    }
  };

  let payLabel = `Pay`;
  let receiveLabel = `Receive`;
  let payBalance = "$0.00";
  let receiveBalance = "$0.00";
  if (isBuying) {
    if (swapUsdMin) {
      payBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
    if (nslpUsdMax) {
      receiveBalance = `$${formatAmount(nslpUsdMax, USD_DECIMALS, 2, true)}`;
    }
  } else {
    if (nslpUsdMax) {
      payBalance = `$${formatAmount(nslpUsdMax, USD_DECIMALS, 2, true)}`;
    }
    if (swapUsdMin) {
      receiveBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
  }

  // let feePercentageText = formatAmount(isBuying ? stakingFee : unstakingFee, 2, 2, true, "-");

  const feePercentageText = useMemo(() => {
    if (!swapValue || Number(swapValue) <= 0) return "--";
    let fee;

    if (isBuying) {
      if (stakingFee && swapUsdMin) {
        fee = Number(formatAmount(swapUsdMin, USD_DECIMALS)) * (stakingFee.toNumber() / 1000);
        return (fee / 100).toFixed(2);
      }
    } else {
      if (unstakingFee && nslpUsdMax) {
        fee = Number(formatAmount(nslpUsdMax, USD_DECIMALS)) * (unstakingFee.toNumber() / 1000);
        return (fee / 100).toFixed(2);
      }
    }

    return "--";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapValue, nslpValue]);

  const onSwapOptionChange = (opt) => {
    if (opt === `Sell NSLP`) {
      switchSwapOption("redeem");
    } else {
      switchSwapOption();
    }
  };

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  return (
    <Wrapper>
      <Tab options={[`Buy NSLP`, `Sell NSLP`]} option={tabLabel} onChange={onSwapOptionChange} />
      {isBuying && (
        <BuyInputSection
          topLeftLabel={payLabel}
          topRightLabel={`Balance:`}
          tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
          inputValue={swapValue}
          onInputValueChange={onSwapValueChange}
          showMaxButton={swapValue !== formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals)}
          onClickTopRightLabel={fillMaxAmount}
          onClickMax={fillMaxAmount}
          selectedToken={swapToken}
          balance={payBalance}
          onlyNumber
        >
          <div className="selected-token">
            <img src={usdcIcon} alt="usdcIcon" />
            USDC.e
          </div>
        </BuyInputSection>
      )}

      {!isBuying && (
        <BuyInputSection
          topLeftLabel={payLabel}
          topRightLabel={`Available:`}
          tokenBalance={`${formatAmount(maxSellAmount, NLP_DECIMALS, 4, true)}`}
          inputValue={nslpValue}
          onInputValueChange={onNslpValueChange}
          showMaxButton={nslpValue !== formatAmountFree(maxSellAmount, NLP_DECIMALS, NLP_DECIMALS)}
          onClickTopRightLabel={fillMaxAmount}
          onClickMax={fillMaxAmount}
          balance={payBalance}
          defaultTokenName={"NSLP"}
          onlyNumber
        >
          <div className="selected-token">
            <img src={nlp24Icon} alt="nlp24Icon" />
            NSLP
          </div>
        </BuyInputSection>
      )}

      <div className="AppOrder-ball-container">
        <div className="AppOrder-ball">
          <img
            src={arrowIcon}
            alt="arrowIcon"
            onClick={() => {
              setIsBuying(!isBuying);
              switchSwapOption(isBuying ? "redeem" : "");
            }}
          />
        </div>
      </div>
      <Wrap>
        {isBuying && (
          <BuyInputSection
            topLeftLabel={receiveLabel}
            topRightLabel={`Balance:`}
            tokenBalance={`${formatAmount(nslpBalance, NLP_DECIMALS, 4, true)}`}
            inputValue={nslpValue}
            onInputValueChange={onNslpValueChange}
            balance={receiveBalance}
            defaultTokenName={"NSLP"}
            onlyNumber
          >
            <div className="selected-token">
              <img src={nlp24Icon} alt="nlp24Icon" />
              NSLP
            </div>
          </BuyInputSection>
        )}

        {!isBuying && (
          <BuyInputSection
            topLeftLabel={receiveLabel}
            topRightLabel={`Balance:`}
            tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
            inputValue={swapValue}
            onInputValueChange={onSwapValueChange}
            balance={receiveBalance}
            selectedToken={swapToken}
            onlyNumber
          >
            {/* <TokenSelector
            label={`Receive`}
            chainId={chainId}
            tokenAddress={swapTokenAddress}
            onSelectToken={onSelectSwapToken}
            tokens={whitelistedTokens}
            infoTokens={infoTokens}
            className="BuySellNslp-from-token"
            showSymbolImage={true}
            showTokenImgInDropdown={true}
          /> */}
            <div className="selected-token">
              <img src={usdcIcon} alt="usdcIcon" />
              USDC.e
            </div>
          </BuyInputSection>
        )}
      </Wrap>
      <div>
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">NSLP Price</div>
          <div className="align-right fee-block">
            {nslpPrice > 0 && <div className="text-white">{formatAmount(nslpPrice, 30, 4, true)} USD</div>}
          </div>
        </div>
        <div className="Exchange-info-row">
          {/* <div className="Exchange-info-label">{feeBasisPoints > 50 ? `WARNING: High Fees` : `Fees`}</div> */}
          <div className="Exchange-info-label">Fees</div>
          <div className="align-right fee-block">
            {isBuying && (
              <Tooltip
                handle={isBuying && isSwapTokenCapReached ? "NA" : feePercentageText + " USD"}
                position="right-bottom"
                className="fit-content"
                renderContent={() => {
                  // if (!feeBasisPoints) {
                  //   return (
                  //     <div className="text-white">
                  //       <span>Fees will be shown once you have entered an amount in the order form.</span>
                  //     </div>
                  //   );
                  // }
                  return (
                    <div className="text-white">
                      {/* {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to pay with.</span>} */}
                      <span>0.05% mint amount.</span>
                    </div>
                  );
                }}
              />
            )}
            {!isBuying && (
              <Tooltip
                handle={feePercentageText + " USD"}
                position="right-bottom"
                className="fit-content"
                renderContent={() => {
                  // if (!feeBasisPoints) {
                  //   return (
                  //     <div className="text-white">
                  //       <span>Fees will be shown once you have entered an amount in the order form.</span>
                  //     </div>
                  //   );
                  // }
                  return (
                    <div className="text-white">
                      {/* {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to receive.</span>} */}
                      <span>0.15% redeem amount.</span>
                    </div>
                  );
                }}
              />
            )}
          </div>
        </div>
        {isBuying && (
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">Remaining Mintable Amount</div>
            <div className="align-right fee-block">
              {nslpAvailableToMint > 0 && (
                <div className="text-white">{formatAmount(nslpAvailableToMint, 18, 0, true)} USD</div>
              )}
            </div>
          </div>
        )}
        {!isBuying && (
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">
              <Tooltip
                handle="Cooldown Period"
                renderContent={() => {
                  return `There is a ${coundowHour}-hour cooldown period after each stake.`;
                }}
              />
            </div>

            <div className="align-right fee-block">
              <Countdown
                key={redemptionTime}
                date={new Date(Number(redemptionTime) * 1000)}
                renderer={countdownRenderer}
                daysInHours
              />
            </div>
          </div>
        )}
        {isBuying && (
          <Stylednoti>
            <div>
              <img src={infoIcon} alt="info" />
            </div>
            <div>NSLP will automatically be staked after purchase.</div>
          </Stylednoti>
        )}
      </div>
      <div className="BuySellNslp-cta Exchange-swap-button-container">
        <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
          {getPrimaryText()}
        </button>
      </div>
    </Wrapper>
  );
}

const countdownRenderer = (countdownProps) => {
  const {
    formatted: { hours, minutes, seconds },
  } = countdownProps;
  return <>{`${hours}:${minutes}:${seconds}`}</>;
};

const Wrap = styled.div`
  margin-top: -34px;
`;
const Wrapper = styled.div`
  @media screen and (max-width: 700px) {
    .AppOrder-ball {
      img {
        width: 24px !important;
        height: 24px !important;
        position: relative;
        top: 5px;
      }
    }

    .body .body-row {
      border-radius: 16px;
    }
  }
`;
const Stylednoti = styled.div`
  border-radius: 100px;
  background: var(--Nature-2, #1a1d26);
  display: flex;
  padding: var(--Base-Unit-XS-8, 8px);
  align-items: center;
  gap: var(--Base-Unit-XS-8, 8px);
  margin-top: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  @media screen and (max-width: 700px) {
    img {
      width: 16px;
      height: 16px;
    }
  }
`;
