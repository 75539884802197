import { Menu } from "@headlessui/react";
import { useWeb3React } from "@web3-react/core";
import { LANGUAGE_LOCALSTORAGE_KEY } from "config/localStorage";
import accountOverviewIcon from "img/account_overview.svg";
import accountOverviewIconLight from "img/account_overview_light.svg";
import DefaultAvatar from "img/default_avatar.svg";
import DefaultAvatarLight from "img/default_avatar_light.svg";
import dark_mode from "img/dark_mode.svg";
import light_mode from "img/light_mode.svg";
import dark_modeLight from "img/dark_modeLight.svg";
import light_modeLight from "img/light_modeLight.svg";
import settingsIcon from "img/ic_settings_16.svg";
import settingsIconLight from "img/ic_settings_16_light.svg";
import switchIcon from "img/icons/ic-switch.svg";
// import accountReferralIcon from "img/ic_account_referral.svg";
import { defaultLocale } from "lib/i18n";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./NetworkDropdown.css";
import cx from "classnames";
import { useThemeContext } from "contexts/ThemeProvider";

export default function UserDropdown(props) {
  const currentLanguage = useRef(localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale);
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const { account } = useWeb3React();

  return (
    <>
      <DesktopDropdown
        currentLanguage={currentLanguage}
        activeModal={activeModal}
        setActiveModal={setActiveModal}
        {...props}
      />
    </>
  );
}

function DesktopDropdown({ openSettings, isLightMode }) {
  const { toggleTheme } = useThemeContext() as any;
  return (
    <div
      className="App-header-user-address1"
      style={{
        margin: "0 8px",
        display: "flex",
        alignItems: "center",
        height: "40px",
      }}
    >
      <Menu>
        <Menu.Button as="div" className="network-dropdown data-[open]">
          <img src={isLightMode ? DefaultAvatarLight : DefaultAvatar} alt="Avatar" style={{ width: "24px" }} />
        </Menu.Button>

        <Menu.Items
          as="div"
          className="menu-items network-dropdown-items"
          style={{
            padding: 0,
          }}
        >
          <div className="menu-items-wrap">
            <div className="label">Account</div>
            {/* <Menu.Item>
              <Link to="/portfolio" className="network-dropdown-menu-item menu-item">
                <div className="menu-item-group">
                  <div className="menu-item-icon">
                    <img
                      className="network-dropdown-icon"
                      src={isLightMode ? accountOverviewIconLight : accountOverviewIcon}
                      alt="Portfolio"
                    />
                  </div>
                  <span className="network-dropdown-item-label">
                    <span>Portfolio</span>
                  </span>
                </div>
              </Link>
            </Menu.Item> */}
            <Menu.Item>
              <div className="network-dropdown-menu-item menu-item" onClick={openSettings}>
                <div className="menu-item-group">
                  <div className="menu-item-icon">
                    <img
                      className="network-dropdown-icon"
                      src={isLightMode ? settingsIconLight : settingsIcon}
                      alt=""
                    />
                  </div>
                  <span className="network-dropdown-item-label">
                    <span>Settings</span>
                  </span>
                </div>
              </div>
            </Menu.Item>
            <Menu.Item>
              <Link to="/transfer-account" className="network-dropdown-menu-item menu-item">
                <div className="menu-item-group">
                  <div className="menu-item-icon">
                    <img
                      className="network-dropdown-icon"
                      src={isLightMode ? switchIcon : switchIcon}
                      alt="Transfer account"
                    />
                  </div>
                  <span className="network-dropdown-item-label">
                    <span>Transfer Account</span>
                  </span>
                </div>
              </Link>
            </Menu.Item>
          </div>
          {/* <div className="divider" /> */}
          <div className="menu-items-wrap">
            {/* <div className="label">Theme</div>
            <div
              className={cx("network-dropdown-menu-item menu-item", {
                "network-dropdown-menu-item--active": !isLightMode,
              })}
              onClick={() => toggleTheme("dark")}
            >
              <div className="menu-item-group">
                <div className="menu-item-icon">
                  <img className="network-dropdown-icon" src={isLightMode ? dark_modeLight : dark_mode} alt="" />
                </div>
                <span className="network-dropdown-item-label">
                  <span>Dark mode</span>
                  <div
                    className={cx("radio-input", {
                      active: !isLightMode,
                    })}
                  ></div>
                </span>
              </div>
            </div> */}
            {/* <div
              className={cx("network-dropdown-menu-item menu-item", {
                "network-dropdown-menu-item--active": isLightMode,
              })}
              onClick={() => toggleTheme("light")}
            >
              <div className="menu-item-group">
                <div className="menu-item-icon">
                  <img className="network-dropdown-icon" src={isLightMode ? light_modeLight : light_mode} alt="" />
                </div>
                <span className="network-dropdown-item-label">
                  <span>Light mode</span>
                  <div
                    className={cx("radio-input radio-input-inactive", {
                      active: isLightMode,
                    })}
                  ></div>
                </span>
              </div>
            </div> */}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
}
