import { useWeb3React } from "@web3-react/core";
import { MICROSWAP_SUPPORTED_CHAIN_IDS, SONIC_TESTNET, getConstant } from "config/chains";
import { ethers } from "ethers";
import ChartPad from "img/icons/chart-pad.svg";
import ArrowUp from "img/icons/icon-up.svg";
import { BASIS_POINTS_DIVISOR, LONG, SHORT, SWAP, useAccountOrders } from "lib/legacy";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";

import { getContract } from "config/contracts";

import ExchangeTVChartPadSwapAggregator from "components/Exchange/ExchangeTVChartPadSwapAggregator";
import ExchangeTVChartSwapAggregator from "components/Exchange/ExchangeTVChartSwapAggregator";
import SpotArregatorBoxSwap from "components/Exchange/SpotArregatorBoxSwap";
import ExternalLink from "components/ExternalLink/ExternalLink";
import ArrowRightIcon from "components/Icon/TradeIcon/ArrowRight";
import { ARBITRUM, BASE, FANTOM, OP } from "config/chains";
import { DEX_IMG, TOKEN_IMG } from "config/dex";
import {
  OTHER_TOKENS_INFO,
  TOKENS,
  getMicroSwapToken,
  getToken,
  getTokenBySymbol,
  getWrappedToken,
} from "config/tokens";
import useAggregator from "hooks/useAggregator";
import OtherTokenIc from "img/trade/other-token-icon.svg";
import OtherTokenLightIc from "img/trade/other-token-icon-light.svg";
import { useChainId } from "lib/chains";
import { useLocalStorageByChainId } from "lib/localStorage";
import { formatNumber, parseValue } from "lib/numbers";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import TradingView from "../../components/Exchange/TradingView";
import { InitQueriesParam } from "./config";
import "styles/SpotArregator.scss";
import "styles/Spot.scss";
import { mergePaths } from "domain/tradingview/utils";
import { useThemeContext } from "contexts/ThemeProvider.js";
import _kyberTokens from "config/kyberTokens";
import SmallTextNumber from "components/Tooltip/SmallTextNumber";
import useTokenBalances from "hooks/aggregator/useTokenBalances";
import { getLocalTokenIcon, getLogoUrlByChainId } from "config/aggregators/tokens";
import { onLoadFailed } from "domain/tokens";

const { AddressZero } = ethers.constants;

export const SpotThroughArregator = forwardRef((props, ref) => {
  const {
    savedIsPnlInLeverage,
    setSavedIsPnlInLeverage,
    savedSlippageAmount,
    pendingTxns,
    setPendingTxns,
    savedShouldShowPositionLines,
    connectWallet,
    setIsSettingsSlippageVisible,
    savedSelectedDexes,
  } = props;

  const { isLightTheme } = useThemeContext();
  const [showChart, setShowChart] = useState(false);

  const [fromTokenValue, setFromTokenValue] = useState("0");
  const [toTokenValue, setToTokenValue] = useState("0");
  const [paramsAggregator, setParamsAggregator] = useState(InitQueriesParam);
  const [onRefresh, setOnRefresh] = useState();

  const isPadScreen = useMediaQuery({ query: "(max-width: 1365px)" });
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });

  const { active, account, library } = useWeb3React();
  const { chainId } = useChainId();

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const microSwapTokens = useTokenBalances(chainId);

  const { data: dataAggregator, isLoading: isLoadingPathSwaps } = useAggregator(
    chainId,
    paramsAggregator,
    onRefresh,
    savedSelectedDexes
  );

  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");

  const defaultTokenSelection = useMemo(
    () => ({
      [SWAP]: {
        from: MICROSWAP_SUPPORTED_CHAIN_IDS?.includes(chainId) ? microSwapTokens?.[0]?.address : AddressZero,
        to: MICROSWAP_SUPPORTED_CHAIN_IDS?.includes(chainId)
          ? getContract(chainId || FANTOM, "USDC.e")
          : getContract(FANTOM, "USDC.e") || AddressZero,
      },
    }),
    [chainId, microSwapTokens]
  );
  const mergePathsData = useMemo(() => {
    if (dataAggregator) {
      const resultData = mergePaths(dataAggregator);
      return resultData;
    }
    return [];
  }, [dataAggregator]);

  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    "swap-aggregator-token",
    defaultTokenSelection
  );
  const [swapOption, setSwapOption] = useLocalStorageByChainId(chainId, "swap-aggregator-Swap-option", SWAP);

  const fromTokenAddress = tokenSelection[swapOption].from;
  const toTokenAddress = tokenSelection[swapOption].to;
  const setFromTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].from = address;
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );

  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].to = address;
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        newTokenSelection[LONG].to = address;
        newTokenSelection[SHORT].to = address;
      }
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );

  const [isConfirming, setIsConfirming] = useState(false);
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);

  const fromToken = getMicroSwapToken(microSwapTokens, fromTokenAddress) || {};
  const toToken = getMicroSwapToken(microSwapTokens, toTokenAddress) || {};
  // useEffect(() => {
  //   if (fromTokenAddress || toTokenAddress) {
  //     const fromToken = getMicroSwapToken(microSwapTokens, fromTokenAddress);
  //     const toToken = getMicroSwapToken(microSwapTokens, toTokenAddress);

  //     let selectedToken = getChartToken(swapOption, fromToken, toToken, chainId);
  //     let currentTokenPriceStr = formatAmount(selectedToken.maxPrice, USD_DECIMALS, 2, true);
  //     let title = getPageTitle(
  //       currentTokenPriceStr + ` | ${selectedToken.symbol}${selectedToken.isStable ? "" : "USD"}`
  //     );
  //     document.title = title;
  //   }
  // }, [tokenSelection, chainId, fromTokenAddress, toTokenAddress]);
  useEffect(() => {
    if (fromToken && toToken) {
      if (
        !(fromToken.isNative && toToken.isWrapped) &&
        !(toToken.isNative && fromToken.isWrapped) &&
        fromToken.symbol !== toToken.symbol
      ) {
        if (fromTokenValue) {
          setParamsAggregator({
            ...paramsAggregator,
            amount: parseValue(fromTokenValue, fromToken.decimals),
            from: fromTokenAddress,
            to: toTokenAddress,
            receiver: account || AddressZero,
          });
        }
      }
    }
  }, [fromTokenAddress, toTokenAddress, fromTokenValue]);
  const onChangeFromTokenValue = (val) => {
    const fromToken = getMicroSwapToken(chainId, fromTokenAddress);
    const toToken = getMicroSwapToken(chainId, toTokenAddress);

    if (
      fromToken &&
      toToken &&
      !(fromToken.isNative && toToken.isWrapped) &&
      !(toToken.isNative && fromToken.isWrapped) &&
      fromToken.symbol !== toToken.symbol
    ) {
      setFromTokenValue(val);
      setParamsAggregator({
        ...paramsAggregator,
        amount: parseValue(val, fromToken.decimals),
        from: fromTokenAddress,
        to: toTokenAddress,
        receiver: account,
      });
    } else {
      setFromTokenValue(val);
    }
  };

  useEffect(() => {
    if (savedSlippageAmount) {
      setParamsAggregator({
        ...paramsAggregator,
        slippage: parseFloat(savedSlippageAmount / BASIS_POINTS_DIVISOR),
        receiver: account || AddressZero,
      });
    }
  }, [savedSlippageAmount]);

  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false);
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false);
  const [isPluginApproving, setIsPluginApproving] = useState(false);
  const [isPositionRouterApproving, setIsPositionRouterApproving] = useState(false);
  const [chartToken, setChartToken] = useState({
    maxPrice: null,
    minPrice: null,
  });
  const [isProChart, setIsProChart] = useState(false);

  const getListSection = () => {
    const listExchangeitems = [
      {
        rate: "31.24%",
        tokenValidAddress: "",
        tokenName: "BTC",
      },
      {
        rate: "14.24%",
        tokenValidAddress: "",
        tokenName: "ETH",
      },
    ];

    const ScanLinkByChain = (addressToken) => {
      switch (chainId) {
        case SONIC_TESTNET:
          return `https://sonicscan.org/address/${addressToken}`;
        case FANTOM:
          return `https://ftmscan.com/address/${addressToken}`;
        case ARBITRUM:
          return `https://arbiscan.io/token/${addressToken}`;
        case BASE:
          return `https://basescan.org/token/${addressToken}`;
        case OP:
          return `https://optimistic.etherscan.io/token/${addressToken}`;
        default:
          return `https://ftmscan.com/address/${addressToken}`;
      }
    };
    // return null;
    if (fromToken && toToken) {
      const fromTokenImg = getLocalTokenIcon(fromToken.symbol) || getLogoUrlByChainId(chainId, fromToken.address, true);
      const toTokenImg = getLocalTokenIcon(toToken.symbol) || getLogoUrlByChainId(chainId, toToken.address, true);
      return (
        <div className={`list-section-wrapper ${mergePathsData.length === 0 ? "list-section-wrapper-none" : ""}`}>
          <div className="content-section-wrapper">
            <div
              className="item-section-container"
              style={{
                border: Number(fromTokenValue) && Number(toTokenValue) ? "1px dashed #828899" : "none",
              }}
            >
              {!isLoadingPathSwaps ? (
                <div className="from-token-head-group">
                  <div>
                    <img src={fromTokenImg} onError={onLoadFailed} />
                  </div>
                  {fromToken.isNative ? (
                    <div className="value-token">
                      {fromTokenValue && Number(fromTokenValue) ? (
                        Number(fromTokenValue) < 1 ? (
                          <SmallTextNumber decimal={4} val={fromTokenValue} />
                        ) : (
                          <span>{formatNumber(fromTokenValue, 4)}</span>
                        )
                      ) : (
                        "0.0000"
                      )}
                      <span style={{ marginLeft: "4px" }}>{fromToken?.symbol}</span>
                    </div>
                  ) : (
                    <ExternalLink href={ScanLinkByChain(fromToken.address)} className="value-token">
                      {fromTokenValue && Number(fromTokenValue) ? (
                        Number(fromTokenValue) < 1 ? (
                          <SmallTextNumber decimal={4} val={fromTokenValue} />
                        ) : (
                          <span>{formatNumber(fromTokenValue, 4)}</span>
                        )
                      ) : (
                        "0.0000"
                      )}
                      <span style={{ marginLeft: "4px" }}>{fromToken?.symbol}</span>
                    </ExternalLink>
                  )}
                </div>
              ) : (
                <div className="skeleton-box" style={{ height: "20px", width: "40px" }} />
              )}
              {!isLoadingPathSwaps ? (
                <div className="to-token-head-group">
                  {toToken?.isNative ? (
                    <div className="value-token">
                      {toTokenValue && Number(toTokenValue) ? (
                        Number(toTokenValue) < 1 ? (
                          <SmallTextNumber decimal={4} val={toTokenValue} />
                        ) : (
                          <span>{formatNumber(toTokenValue, 4)}</span>
                        )
                      ) : (
                        "0.0000"
                      )}
                      <span style={{ marginLeft: "4px" }}>{toToken?.symbol}</span>
                    </div>
                  ) : (
                    <ExternalLink href={ScanLinkByChain(toToken.address)} className="value-token">
                      {toTokenValue && Number(toTokenValue) ? (
                        Number(toTokenValue) < 1 ? (
                          <SmallTextNumber decimal={4} val={toTokenValue} />
                        ) : (
                          <span>{formatNumber(toTokenValue, 4)}</span>
                        )
                      ) : (
                        "0.0000"
                      )}
                      <span style={{ marginLeft: "4px" }}>{toToken.symbol}</span>
                    </ExternalLink>
                  )}

                  <div>
                    <img src={toTokenImg} onError={onLoadFailed} style={{ borderRadius: 100 }} />
                  </div>
                </div>
              ) : (
                <div className="skeleton-box" style={{ height: "20px", width: "40px" }} />
              )}
            </div>

            <div className="path-swaps-wrapper">
              {!(fromToken.isNative && toToken.isWrapped) &&
              !(toToken.isNative && fromToken.isWrapped) &&
              fromToken.symbol !== toToken.symbol &&
              mergePathsData &&
              Number(fromTokenValue) &&
              // Number(toTokenValue) &&
              !isLoadingPathSwaps ? (
                mergePathsData.map((item, index) => {
                  return (
                    <div className="item-section-wrapper" key={index}>
                      <div
                        className={`road-exchange-group ${index == mergePathsData.length - 1 ? "last-item" : ""}`}
                        style={{ border: index == mergePathsData.length - 1 && "none" }}
                      >
                        <div className="rate-exchange-token">
                          <div className="rate-value">{item.totalPercent}%</div>
                        </div>
                        {/* <div className="exchange-icon">
                           <ArrowRightIcon />
                         </div> */}
                        <div className="token-swap-path-wrapper">
                          {item?.paths.map((path, index) => {
                            // const tokenInfoValid = dataAggregator?.tokens[swap.to];
                            const swapItem = path[0];

                            const name = swapItem.symbol?.replace("AXL", "axl");
                            const tokenImg =
                              getLocalTokenIcon(swapItem.symbol) ||
                              getLogoUrlByChainId(chainId, swapItem.address, true);
                            return (
                              <>
                                <div className="to-token-group-container">
                                  <div className="to-token-group">
                                    <img src={tokenImg} onError={onLoadFailed} alt="token" />
                                    {swapItem.address ===
                                    ("0x0000000000000000000000000000000000000000" ||
                                      "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") ? (
                                      <div className="value-token-child">{name}</div>
                                    ) : (
                                      <ExternalLink
                                        href={ScanLinkByChain(swapItem.address)}
                                        className="value-token-child"
                                      >
                                        {name}
                                      </ExternalLink>
                                    )}
                                  </div>
                                  <div className="dexs-wrapper">
                                    {path.map((e) => {
                                      // const foundDexs = dexs.find((dex) => dex.dexId === e?.dex);

                                      return (
                                        <div className="dex-container">
                                          <img alt="" src={DEX_IMG[e?.dex] || DEX_IMG["default"]} /> {e?.dex}{" "}
                                          {e?.percent}%
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>

                                {index == item?.paths?.length - 1 ? (
                                  <></>
                                ) : (
                                  <div className="exchange-icon">
                                    <ArrowRightIcon />
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        <div className="exchange-icon" style={{ margin: "20px 15px 0px" }}>
                          <ArrowRightIcon />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderChart = () => {
    return (
      <ExchangeTVChartSwapAggregator
        isProChart={isProChart}
        chartToken={chartToken}
        setChartToken={setChartToken}
        fromTokenAddress={fromTokenAddress}
        toTokenAddress={toTokenAddress}
        swapOption={swapOption}
        chainId={chainId}
        savedShouldShowPositionLines={savedShouldShowPositionLines}
        setToTokenAddress={setToTokenAddress}
        isSwap={true}
        fromToken={fromToken}
        toToken={toToken}
        dataAggregator={dataAggregator}
      />
    );
  };
  const onHandleReloadData = () => {
    setOnRefresh(!onRefresh);
  };

  return (
    <WrapperContainer className="spot-aggregator-wrapper">
      <div className="Exchange page-layout Exchange-updated-ui exchange-v1 swap-v1-container spot-arregator-container">
        <HeaderTitle>NAVI-X: Your Gateway to the Best Swaps</HeaderTitle>
        <div className={`Exchange-content ${showChart ? "chart-pad-show-content" : ""}`}>
          <div className="Exchange-left">
            {renderChart()}
            {/* {isProChart ? <TradingView symbol={chartToken?.symbol} /> : null} */}
            <div className="Exchange-lists large">{getListSection()}</div>
          </div>
          <div className="Exchange-right swap-box-swap-container">
            <SpotArregatorBoxSwap
              setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
              setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
              isPluginApproving={isPluginApproving}
              isPositionRouterApproving={isPositionRouterApproving}
              isWaitingForPluginApproval={isWaitingForPluginApproval}
              isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
              chainId={chainId}
              active={active}
              connectWallet={connectWallet}
              library={library}
              account={account}
              fromTokenAddress={fromTokenAddress}
              setFromTokenAddress={setFromTokenAddress}
              toTokenAddress={toTokenAddress}
              setToTokenAddress={setToTokenAddress}
              swapOption={swapOption}
              setSwapOption={setSwapOption}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              tokenSelection={tokenSelection}
              setTokenSelection={setTokenSelection}
              isConfirming={isConfirming}
              setIsConfirming={setIsConfirming}
              isPendingConfirmation={isPendingConfirmation}
              setIsPendingConfirmation={setIsPendingConfirmation}
              savedIsPnlInLeverage={savedIsPnlInLeverage}
              setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
              nativeTokenAddress={nativeTokenAddress}
              savedSlippageAmount={savedSlippageAmount}
              setChartToken={setChartToken}
              setIsSettingsSlippageVisible={setIsSettingsSlippageVisible}
              chartToken={chartToken}
              onSelectFromTokenChanged={(value) => onChangeFromTokenValue(value)}
              onSelectToTokenChanged={(value) => setToTokenValue(value)}
              onHandleReloadState={onHandleReloadData}
              dataAggregator={dataAggregator}
              microSwapTokens={microSwapTokens}
            />
          </div>
          <div className="Exchange-lists small">{getListSection()}</div>
        </div>
        {isPadScreen && (
          <div className="pad-chart-container">
            <div className="show-ui-content">
              <div className="index-chart">
                <img src={ChartPad} alt="" /> {isMobileScreen ? "Chart" : "Index chart"}
              </div>
              <div className="chart-tick-container">
                {/* {!isProChart && showChart ? (
                  <Checkbox
                    isChecked={savedShouldShowPositionLines}
                    setIsChecked={setSavedShouldShowPositionLines}
                    className={cx("muted chart-positions", { active: savedShouldShowPositionLines })}
                  >
                    <div className="group-actions">
                      <span>Chart positions</span>
                    </div>
                  </Checkbox>
                ) : null} */}
                <div className="view-chart" onClick={() => setShowChart(!showChart)}>
                  {showChart ? "Hide" : "Show"} chart
                  <img style={showChart ? { transform: "rotate(180deg)" } : {}} src={ArrowUp} alt="" />
                </div>
              </div>
            </div>
            <div style={!showChart ? { display: "none" } : {}}>
              <ExchangeTVChartPadSwapAggregator
                isProChart={isProChart}
                chartToken={chartToken}
                setChartToken={setChartToken}
                fromTokenAddress={fromTokenAddress}
                toTokenAddress={toTokenAddress}
                swapOption={swapOption}
                chainId={chainId}
                setToTokenAddress={setToTokenAddress}
                isSwap={true}
                fromToken={fromToken}
                toToken={toToken}
              />
            </div>
          </div>
        )}

        {/* <Footer /> */}
      </div>
    </WrapperContainer>
  );
});

const WrapperContainer = styled.div`
  width: 100%;
  .Exchange {
    display: flex;
    flex-direction: column;
    max-width: 1320px;
    margin: auto;
    width: 100%;
    margin-top: 24px;
    .Exchange-right {
      margin-left: 0;
    }
  }
  .Exchange-content {
    display: grid;
    grid-template-columns: 1fr 424px;
    width: 100%;
    gap: 24px;
    margin-top: 16px !important;
  }
  .Exchange-left {
    width: 100%;
    margin-bottom: 40px;
  }
  .Exchange-right {
    border-left: none !important;
  }
  .Exchange-swap-box {
    flex: none !important;
  }
  .ExchangeChart {
    background-color: #12141a;
    border-radius: 16px;

    @media screen and (max-width: 767px) {
      padding-top: 12px;
    }
  }
  .Exchange-updated-ui .Exchange-content .ExchangeChart-bottom {
    top: 95px !important;
    left: 24px !important;
    right: 24px !important;
    bottom: 24px !important;
  }

  .ExchangeChart.tv .ExchangeChart-top {
    padding: 16px 24px !important;
    height: auto !important;
    align-items: flex-end;

    .Tab-option {
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      padding: 0 6px;
      width: unset;
      color: #828899;

      &.active {
        color: #90fb75;
      }
    }

    @media screen and (max-width: 767px) {
      margin: 0 16px;

      align-items: flex-start;
    }
  }
  .exchange-section-first {
    margin-bottom: 16px !important;
  }
  .Exchange-swap-ball {
    @media screen and (min-width: 701px) {
      top: -26px !important;
    }
    /* @media screen and (max-width: 700px) {
      top: -20px !important;
    } */
  }
  .Tab-option {
    font-size: 14px !important;
    &:hover {
      background: hsla(0, 0%, 100%, 0.2);
    }
  }
  .exchange-v1 .Exchange-list-tabs .Tab-option {
    font-size: 14px !important;
  }
  .swap-box-v2 {
    height: auto !important;
    padding: 24px;
    border-bottom: none !important;
    padding-bottom: 0px;
  }
  .Exchange-swap-box-inner {
    padding: 24px;
    padding-top: 16px;
  }
  .Exchange-swap-box-info {
    margin-bottom: 8px;
  }
  .Exchange-swap-box {
    max-width: 424px;
    width: 100%;
  }
  .Exchange-swap-section {
    margin-bottom: 16px !important;
  }
  .Exchange-lists.large {
    padding: 0;
    margin-top: 24px;
  }
  .box-trade-swap {
    border-radius: 16px !important;
  }
  .exchange-v1 {
    height: auto !important;
  }
  .Exchange-should-show-position-lines {
    display: none;
  }
  .no-trade-history {
    margin-top: 10px !important;
  }
  .Exchange-swap-max {
    outline: none !important;
  }
  .PositionEditor-token-symbol {
    font-size: 16px !important;
  }

  @media screen and (max-width: 1110px) {
    .Exchange {
      max-width: 534px;
      justify-content: normal;
    }
    .Exchange-content {
      display: flex;
      flex-direction: column;
      justify-content: normal;
      margin-top: 0 !important;
      gap: 16px;
      height: auto !important;
    }
    .Exchange-left {
      display: none;
    }
    .Exchange-right {
      flex-direction: column;
      width: 100%;
    }
    .Exchange-lists.small {
      display: flex;
      flex-direction: column;
      margin-bottom: 80px !important;
    }
    .swap-box-v2 {
      padding: 16px;
    }
    .Exchange-swap-box-inner {
      padding: 16px;
    }

    .Exchange-lists {
      padding: 0 !important;
    }
    .Exchange-swap-box {
      max-width: none;
    }
    .Exchange-list-tab-container {
      margin-bottom: 16px !important;
    }
  }
  @media screen and (max-width: 991px) {
    .Exchange {
      padding-top: 16px !important;
    }
    .Exchange-content {
      padding: 16px 0 0 0 !important;
    }
  }
  @media screen and (max-width: 767px) {
    .Exchange-list-tab-container {
      margin-top: 0 !important;
    }

    .Exchange-swap-box-info {
      margin-bottom: 16px;
    }
  }
`;
const HeaderTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  @media screen and (max-width: 767px) {
    padding: 0px 16px;
  }
`;
