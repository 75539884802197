import { Select } from "antd";
import Tooltip from "components/Tooltip/Tooltip";
import useLeaderboard, { DEFAULT_SORT, configContest } from "hooks/useLeaderboard";
import downIc from "img/downTriangleIc.svg";
import Pagination from "pages/Analytics/components/Pagination";
import styled from "styled-components";
import "../styles/AnalyticsV2.styled";

import Avatar from "components/Avatar";
import { useThemeContext } from "contexts/ThemeProvider";
import { BigNumber } from "ethers";
import { SECONDS_PER_DAY, shortenAddress } from "lib/legacy";
import { useMediaQuery } from "react-responsive";

import icView from "img/ic-view.svg";
import top1 from "img/top1.svg";
import top2 from "img/top2.svg";
import top2Light from "img/top2Light.svg";
import top3 from "img/top3.svg";
import top3Light from "img/top3Light.svg";

import { formatAmount } from "lib/numbers";
import moment from "moment";
import NoItemFound from "pages/Analytics/components/NoItemFound";
import { useMemo, useState } from "react";
import { DataTable, SkeletonLoadingTable, TableRow, renderArrows } from "../helper/table";
import useTopTrader from "hooks/useTopTrader";
import { commify } from "@ethersproject/units";
import PaginationV2 from "../../AnalyticsV3/components/PaginationV2";

const topsImg = ["🥇", "🥈", "🥉"];
const topsImgLight = ["🥇", "🥈", "🥉"];

const PNL_DESC = "pnl DESC";
const PNL_ASC = "pnl ASC";
const VOLUME_DESC = "tradingVolume DESC";
const VOLUME_ASC = "tradingVolume ASC";

const ORDER_BY_OPTIONS = [
  {
    value: PNL_DESC,
    label: "Highest to lowest in PnL",
  },
  {
    value: PNL_ASC,
    label: "Lowest to highest in PnL",
  },
  {
    value: VOLUME_DESC,
    label: "Highest to lowest in Trading Volume",
  },
  {
    value: VOLUME_ASC,
    label: "Lowest to highest in Trading Volume",
  },
];

const MAPPED_BY_KEY = {
  tradingVolume: "Trading Volume",
  pnl: "PnL",
};

const TopTraders = ({ chainId, isValidChain }) => {
  const isSmallDevice = useMediaQuery({ query: "(max-width: 1023px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const [from, setFrom] = useState();
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { sort, handleSort, isLoading, data, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage } = useTopTrader(
    chainId,
    {
      toNow: true,
      from: from || 1698278400,
    }
  );
  // const { sort, handleSort, isLoading, data, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage } =
  //   useLeaderboard(chainId, {
  //     toNow: true,
  //     from: from || 1698278400,
  //   });
  const FILTER_BY_OPTIONS = useMemo(() => {
    return [
      {
        label: "All time",
        value: configContest?.[chainId]?.START_TIMESTAMP,
      },
      {
        label: "24 Hours",
        value: moment().unix() - SECONDS_PER_DAY,
      },
      {
        label: "7 Days",
        value: moment().unix() - SECONDS_PER_DAY * 7,
      },
      {
        label: "1 Month",
        value: moment().unix() - SECONDS_PER_DAY * 30,
      },
    ];
  }, []);

  function getRank(index) {
    if (sort.type === "ASC") {
      const total = data.count;
      return total - index - (currentPage - 1) * rowsPerPage;
    }
    return currentPage * rowsPerPage - rowsPerPage + index + 1;
  }
  const isEmpty = !isLoading && data?.rows?.length === 0;

  return (
    <Wrapper className={lightThemeClassName}>
      {isValidChain && (
        <>
          {!isSmallDevice && (
            <div className="top-filter">
              <StyledSelect1
                showArrow
                suffixIcon={<img className="downIc" src={downIc} alt="" />}
                onChange={(value) => {
                  setFrom(value);
                }}
                defaultValue={FILTER_BY_OPTIONS[0]}
                bordered={false}
                options={FILTER_BY_OPTIONS}
                popupClassName={lightThemeClassName}
                className={lightThemeClassName}
              />
              <div className="last-update">
                Last update: {moment(data.lastUpdateTime ? data.lastUpdateTime * 1000 : Date.now()).fromNow()}
              </div>
            </div>
          )}
          {isSmallDevice && (
            <Wrapfilter>
              <div className="filter-block">
                Ordered by:{" "}
                <StyledSelect
                  showArrow
                  suffixIcon={<img className="downIc" src={downIc} alt="" />}
                  onChange={(value) => {
                    const [field, type] = value.split(" ");
                    handleSort(field, type);
                  }}
                  defaultValue={ORDER_BY_OPTIONS.find((opt) => opt.value === DEFAULT_SORT)}
                  bordered={false}
                  options={ORDER_BY_OPTIONS}
                  popupClassName={lightThemeClassName}
                  className={lightThemeClassName}
                />
              </div>
              <div className="filter-block">
                <div className="filter-child">
                  Filtered by:{" "}
                  <StyledSelect1
                    showArrow
                    suffixIcon={<img className="downIc" src={downIc} alt="" />}
                    onChange={(value) => {
                      setFrom(value);
                    }}
                    defaultValue={FILTER_BY_OPTIONS[0]}
                    bordered={false}
                    options={FILTER_BY_OPTIONS}
                    popupClassName={lightThemeClassName}
                    className={lightThemeClassName}
                  />
                </div>
                <div className="last-update">
                  Last update: {moment(data.lastUpdateTime ? data.lastUpdateTime * 1000 : Date.now()).fromNow()}
                </div>
              </div>
              <div className="last-update-mobile">
                Last update: {moment(data.lastUpdateTime ? data.lastUpdateTime * 1000 : Date.now()).fromNow()}
              </div>
            </Wrapfilter>
          )}
          {isEmpty ? (
            <NoItemFound />
          ) : (
            <DataTable $rowHeightSkeletonMobile="146.38px" className={`${lightThemeClassName} table-wrapper`}>
              <div className="head">
                <div className="col-name">
                  <Tooltip
                    handle={`Top`}
                    position="center-top"
                    className="fit-content"
                    renderContent={() => {
                      return <div>Sort by {MAPPED_BY_KEY[sort.field]}</div>;
                    }}
                  />
                  {/* Top */}
                </div>
                <div
                  className="col-name"
                  style={{
                    minWidth: 540,
                  }}
                >
                  Wallet
                </div>
                <div className="col-name">Win/Loss</div>
                <div className="col-name" onClick={() => handleSort("tradingVolume")}>
                  Trading Volume
                  {renderArrows("tradingVolume", sort, isLightTheme)}
                </div>
                <div className="col-name" onClick={() => handleSort("pnl")}>
                  PnL
                  {renderArrows("pnl", sort, isLightTheme)}
                </div>
              </div>
              <div className={`body ${data?.rows?.length === 1 ? "body-single-row" : ""}`}>
                {!isLoading &&
                  data?.rows &&
                  data?.rows?.map((row, i) => {
                    const rank = getRank(i);

                    const isPnlPositive = row.pnl > 0;
                    return (
                      <TableRow
                        key={i}
                        // noScaleOnHover
                        to={`/analytics/trader/${row.account}`}
                      >
                        {isMobile ? (
                          <StyledHead>
                            <div className="col-top user">{shortenAddress(row.account, 13)}</div>

                            <div className={`col-top top-${rank}`}>
                              <span className="rank-content">
                                {!isLightTheme && <>{topsImgLight[rank - 1] ? topsImgLight[rank - 1] : rank}</>}
                                {isLightTheme && <>{topsImgLight[rank - 1] ? topsImgLight[rank - 1] : rank}</>}
                              </span>
                            </div>
                          </StyledHead>
                        ) : (
                          <div d-label="Top" className={`col-body col-top top-${rank}`}>
                            <span className="rank-content">
                              {!isLightTheme && <>{topsImgLight[rank - 1] ? topsImgLight[rank - 1] : rank}</>}
                              {isLightTheme && <>{topsImgLight[rank - 1] ? topsImgLight[rank - 1] : rank}</>}
                            </span>
                          </div>
                        )}

                        {isMobile ? <div className="row-line"></div> : ""}
                        <div
                          d-label="Wallet"
                          className="col-body user col-min-width col-wallet"
                          style={{
                            minWidth: 540,
                          }}
                        >
                          {/* <Avatar address={row.account} size="24px" chainId={chainId} NFTid={undefined} /> */}
                          {isMobile ? shortenAddress(row.account, 13) : row.account}
                        </div>
                        <div d-label="Win/Loss" className="col-body">
                          {row.win}/{row.loss}
                        </div>
                        <div d-label="Trading Volume" className="col-body">
                          ${commify(row.tradingVolume?.toFixed(2))}
                        </div>
                        <div d-label="PnL" className={`col-body ${isPnlPositive ? "positive" : "negative"}`}>
                          {isPnlPositive ? "+" : "-"}${commify(row.pnl?.toFixed(2).replace("-", ""))}
                        </div>
                        <img className="icon-view" src={icView} alt="view_ic" />
                      </TableRow>
                    );
                  })}
              </div>
              {isLoading && <SkeletonLoadingTable noHeading />}{" "}
              {!isEmpty && (
                <PaginationV2
                  currentPage={currentPage}
                  rowsPerPage={rowsPerPage}
                  // setRowsPerPage={setRowsPerPage}
                  setCurrentPage={setCurrentPage}
                  rows={data?.count}
                />
              )}
            </DataTable>
          )}
        </>
      )}
      {!isValidChain && <NoItemFound />}
    </Wrapper>
  );
};
const StyledHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledSelect1 = styled(Select)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    width: fit-content;
    display: flex;
    height: 44px !important;
    padding: 0px var(--Base-Unit-S-12, 12px) !important;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    border-radius: 100px;
    border: 1px solid rgba(55, 63, 92, 0.5) !important;
    background-color: #12141a !important;
  }

  .ant-select-arrow {
    rotate: 180deg;
  }
`;

const StyledSelect = styled(StyledSelect1)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    height: 44px !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  .top-1,
  .top-2,
  .top-3 {
    .rank-content {
      font-size: 24px !important;
    }
  }
  .row-line {
    display: none;
  }
  .body {
    .body-row {
      background: #1a1d26;
    }
  }
  @media screen and (max-width: 1023px) {
    .row-line {
      display: flex;
      width: calc(100% + 48px);
      height: 1px;
      background: rgba(55, 63, 92, 0.5);
      margin-left: -24px;
    }
    .col-wallet {
      display: none !important;
    }
    .body {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      gap: 24px;
      /* gap: 16px; */
      margin-top: 48px;
      &.body-single-row {
        grid-template-columns: 1fr;
      }
      .body-row {
        padding: 0 24px 16px 24px;
        border-radius: 16px !important;
        background: #12141a;
      }
      .user {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
      .col-top {
        padding-top: 12px !important;
        .rank-content {
          height: auto;
        }
      }
    }
    .rank-content {
      justify-content: flex-end;
    }
  }
  @media screen and (max-width: 700px) {
    .body {
      grid-template-columns: minmax(0, 1fr);
      gap: 16px;
      margin-top: 32px;
      .body-row {
        padding: 0 16px 16px 16px;
      }
    }
    .row-line {
      width: calc(100% + 32px);

      margin-left: -16px;
    }
  }

  .table-wrapper {
    border-radius: 16px;
    background: var(--Nature-1, #12141a);
    padding: 24px;

    @media screen and (max-width: 900px) {
      background: transparent;
      padding: 0;
    }
  }
  .top-filter {
    position: absolute;
    top: -118px;
    right: 0;
    z-index: 20;
    margin: 0 auto 48px;
    gap: 24px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    transform: translateY(50%);
    @media screen and (max-width: 1023px) {
      /* position: static; */
      margin-top: 0;
    }
    @media screen and (max-width: 700px) {
      margin-top: -0px;
      margin-bottom: 24px;
    }
    .ant-select {
      border-radius: 8px;
    }
  }
  .last-update,
  .last-update-mobile {
    color: #828899;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
  .pagination-analytics {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .ant-select {
      & > .ant-select-selector {
        border-radius: 4px;
      }
    }

    .btn.btn-primary {
      border-radius: var(--Border_radius, 4px);
      border: 1px solid var(--Border, rgba(55, 63, 92, 0.5));
      width: 24px;
      height: 24px;
    }

    .btn-label {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1023px) {
    .filter-block {
      color: #828899;
      margin-bottom: 0px;

      &:has(.filter-child) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 12px;

    .top-filter {
      flex-direction: column;
      align-items: flex-start;
    }

    .filter-block {
      display: flex;
      /* flex-direction: column; */
      gap: 8px;
      align-items: center;
      margin-bottom: 0px;

      .ant-select-selector {
        padding-left: 8px;
      }
    }
  }

  /* &.theme--light {
    .ant-select {
    }
    .top-filter {
      .ant-select {
        background: #fff;
      }

      .last-update {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }
    }
  } */
`;
const Wrapfilter = styled.div`
  .last-update-mobile {
    display: none;
  }
  @media screen and (max-width: 1023px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    .ant-select {
      display: flex;
      flex: 1;
    }
    .filter-block,
    .filter-child {
      display: flex;
      align-items: center;

      gap: 12px;
      .ant-select-selector {
        width: 100% !important;
      }
    }
    .filter-child {
      width: 100%;
    }
    .last-update {
      display: none;
    }
    .last-update-mobile {
      display: block;
    }
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;
export default TopTraders;
