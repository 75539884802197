import React, { memo } from "react";

const IconScan = ({ noOpacity = false, ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7752 1.66675C12.3371 1.66675 11.9815 2.0223 11.9815 2.4604C11.9815 2.89849 12.3371 3.25405 12.7752 3.25405H15.6212L9.03944 9.83577C8.72951 10.1457 8.72951 10.6481 9.03944 10.958C9.19459 11.1132 9.3974 11.1906 9.60057 11.1906C9.80375 11.1906 10.0065 11.1132 10.1617 10.958L16.7434 4.37632V7.2223C16.7434 7.6604 17.099 8.01595 17.5371 8.01595C17.9752 8.01595 18.3307 7.6604 18.3307 7.2223V2.4604C18.3307 2.0223 17.9752 1.66675 17.5371 1.66675H12.7752ZM4.04502 2.4604C2.73192 2.4604 1.66406 3.52826 1.66406 4.84135V15.9525C1.66406 17.2656 2.73192 18.3334 4.04502 18.3334H15.1561C16.4692 18.3334 17.5371 17.2656 17.5371 15.9525V11.1906C17.5371 10.7525 17.1815 10.3969 16.7434 10.3969C16.3053 10.3969 15.9498 10.7525 15.9498 11.1906V15.9525C15.9498 16.3902 15.5938 16.7461 15.1561 16.7461H4.04502C3.60732 16.7461 3.25136 16.3902 3.25136 15.9525V4.84135C3.25136 4.40365 3.60732 4.0477 4.04502 4.0477H8.80692C9.24502 4.0477 9.60057 3.69215 9.60057 3.25405C9.60057 2.81595 9.24502 2.4604 8.80692 2.4604H4.04502Z"
      fill="#FFF"
    />
  </svg>
);
export default memo(IconScan);
