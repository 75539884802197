import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { Trans, t } from "@lingui/macro";
import { ethers } from "ethers";
import { BsArrowRight } from "react-icons/bs";

import {
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DEPOSIT_FEE,
  DUST_BNB,
  getLiquidationPrice,
  MAX_ALLOWED_LEVERAGE,
} from "lib/legacy";
import { getContract } from "config/contracts";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";

import PositionRouter from "abis/PositionRouter.json";
import Token from "abis/Token.json";
import Tooltip from "../Tooltip/Tooltip";

import { getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { getTokenInfo } from "domain/tokens/utils";
import { approveTokens, shouldRaiseGasError } from "domain/tokens";
import { usePrevious } from "lib/usePrevious";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, limitDecimals, parseValue } from "lib/numbers";
import ExternalLink from "components/ExternalLink/ExternalLink";
import useMarketAssets from "hooks/useMarketAssets";

const DEPOSIT = "Deposit";
const WITHDRAW = "Withdraw";
const EDIT_OPTIONS = [DEPOSIT, WITHDRAW];
const { AddressZero } = ethers.constants;

export default function PositionEditor(props) {
  const { isVisible, setIsVisible, active, account, library, savedIsPnlInLeverage, chainId, position } = props;
  const [option, setOption] = useState(DEPOSIT);
  const [fromValue, setFromValue] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isSwapping, setIsSwapping] = useState(false);
  const prevIsVisible = usePrevious(isVisible);
  const longOrShortText = position?.isLong ? `Long` : `Short`;

  // const { data: minExecutionFee } = useSWR([active, chainId, positionRouterAddress, "minExecutionFee"], {
  //   fetcher: fetcher(library, PositionRouter),
  // });

  const isDeposit = option === DEPOSIT;
  const isWithdrawal = option === WITHDRAW;

  let collateralToken;
  let maxAmount;
  let maxAmountFormatted;
  let maxAmountFormattedFree;
  let fromAmount;
  let needApproval;

  let convertedAmount;
  let convertedAmountFormatted;

  let nextLeverage;
  let nextLeverageExcludingPnl;
  let liquidationPrice;
  let nextLiquidationPrice;
  let nextCollateral;

  let title;
  let collateralDelta;
  const marketAsssets = useMarketAssets();
  if (position) {
    title = `Edit ${longOrShortText} ${marketAsssets?.[position.tokenId]?.symbol}`;
    if (isDeposit) {
      fromAmount = parseValue(fromValue, 6);
      if (fromAmount) {
      }
    } else {
      fromAmount = parseValue(fromValue, USD_DECIMALS);

      if (fromAmount) {
      }
    }

    if (fromAmount) {
      if (position.isLong) {
        // collateralDelta = collateralDelta.mul(BASIS_POINTS_DIVISOR - DEPOSIT_FEE).div(BASIS_POINTS_DIVISOR);
      }
    }
  }

  const getError = () => {};

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isSwapping) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isSwapping) {
      if (isDeposit) {
        return `Depositing...`;
      }
      return `Withdrawing...`;
    }

    if (isApproving) {
      return `Approving vUSD...`;
    }
    if (needApproval) {
      return `Approve vUSD`;
    }

    if (isDeposit) {
      return `Deposit`;
    }

    return `Withdraw`;
  };

  const resetForm = () => {
    setFromValue("");
  };

  useEffect(() => {
    if (prevIsVisible !== isVisible) {
      resetForm();
    }
  }, [prevIsVisible, isVisible]);

  const depositCollateral = async () => {};

  const withdrawCollateral = async () => {};

  const onClickPrimary = () => {};
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const EDIT_OPTIONS_LABELS = {
    [DEPOSIT]: `Deposit`,
    [WITHDRAW]: `Withdraw`,
  };

  return (
    <div className="PositionEditor">
      {position && (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
          <div>
            <Tab
              options={EDIT_OPTIONS}
              optionLabels={EDIT_OPTIONS_LABELS}
              option={option}
              setOption={setOption}
              onChange={resetForm}
            />
            {(isDeposit || isWithdrawal) && (
              <div>
                <div className="Exchange-swap-section">
                  <div className="Exchange-swap-section-top">
                    <div className="muted">
                      {convertedAmountFormatted && (
                        <div className="Exchange-swap-usd">
                          {isDeposit ? `Deposit` : `Withdraw`}:{isDeposit ? "USD" : "USD"}
                        </div>
                      )}
                      {`${isDeposit ? `Deposit` : `Withdraw`}`}
                    </div>
                    <div className="muted align-right clickable">
                      <span>Max:</span>
                    </div>
                  </div>
                  <div className="Exchange-swap-section-bottom">
                    <div className="Exchange-swap-input-container">
                      <input
                        type="number"
                        min="0"
                        placeholder="0.0"
                        className="Exchange-swap-input"
                        value={fromValue}
                        onChange={(e) => setFromValue(e.target.value)}
                      />
                      {fromValue !== maxAmountFormattedFree && (
                        <div
                          className="Exchange-swap-max"
                          // onClick={() => {
                          //   setFromValue(maxAmountFormattedFree);
                          // }}
                        >
                          <span>MAX</span>
                        </div>
                      )}
                    </div>
                    <div className="PositionEditor-token-symbol">vUSD</div>
                  </div>
                </div>
                <div className="PositionEditor-info-box">
                  {/* {minExecutionFeeErrorMessage && (
                    <div className="Confirmation-box-warning">{minExecutionFeeErrorMessage}</div>
                  )} */}
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <span>Size</span>
                    </div>
                    <div className="align-right">{position.size} USD</div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <span>Collateral</span>
                    </div>
                    <div className="align-right">
                      <div>${position.collateral}</div>
                      {/* {!nextCollateral && <div>${formatAmount(position.collateral, USD_DECIMALS, 2, true)}</div>}
                      {nextCollateral && (
                        <div>
                          <div className="inline-block muted">
                            ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                            <BsArrowRight className="transition-arrow" />
                          </div>
                          ${formatAmount(nextCollateral, USD_DECIMALS, 2, true)}
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <span>Leverage</span>
                    </div>
                    <div className="align-right">
                      <div>{position.leverage}x</div>
                      {/* {!nextLeverage && <div>{formatAmount(position.leverage, 4, 2, true)}x</div>}
                      {nextLeverage && (
                        <div>
                          <div className="inline-block muted">
                            {formatAmount(position.leverage, 4, 2, true)}x
                            <BsArrowRight className="transition-arrow" />
                          </div>
                          {formatAmount(nextLeverage, 4, 2, true)}x
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <span>Mark Price</span>
                    </div>
                    <div className="align-right">
                      ${limitDecimals(position.markPrice, 4)}
                      {/* {formatAmount(position.markPrice, USD_DECIMALS, position?.indexToken?.displayDecimals || 2, true)} */}
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <span>Liq. Price</span>
                    </div>
                    <div className="align-right">
                      {limitDecimals(position.liquidationPrice, 2)}
                      {/* {!nextLiquidationPrice && (
                        <div>
                          {!fromAmount &&
                            `$${formatAmount(
                              liquidationPrice,
                              USD_DECIMALS,
                              position?.indexToken?.displayDecimals || 2,
                              true
                            )}`}
                          {fromAmount && "-"}
                        </div>
                      )}
                      {nextLiquidationPrice && (
                        <div>
                          <div className="inline-block muted">
                            $
                            {formatAmount(
                              liquidationPrice,
                              USD_DECIMALS,
                              position?.indexToken?.displayDecimals || 2,
                              true
                            )}
                            <BsArrowRight className="transition-arrow" />
                          </div>
                          $
                          {formatAmount(
                            nextLiquidationPrice,
                            USD_DECIMALS,
                            position?.indexToken?.displayDecimals || 2,
                            true
                          )}
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <span>Execution Fee</span>
                    </div>
                    <div className="align-right">
                      --
                      {/* <Tooltip
                        handle={`${formatAmountFree(minExecutionFee, 18, 5)} ${nativeTokenSymbol}`}
                        position="right-top"
                        renderContent={() => {
                          const depositOrWithdrawalText = isDeposit ? `deposit` : `withdrawal`;
                          return (
                            <div style={{ paddingLeft: "10px" }}>
                              <StatsTooltipRow
                                label={` Network fee`}
                                showDollar={false}
                                value={`${formatAmountFree(
                                  minExecutionFee,
                                  18,
                                  5
                                )} ${nativeTokenSymbol} ($${formatAmount(minExecutionFeeUSD, USD_DECIMALS, 2)})`}
                              />
                              <div style={{ marginTop: "8px" }}></div>
                              <div>
                                This is the network cost required to execute the {depositOrWithdrawalText}.
                                <div style={{ marginTop: "8px" }}>
                                  <ExternalLink href="https://docs.navigator.exchange/trading#execution-fee">
                                    More Info
                                  </ExternalLink>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      /> */}
                    </div>
                  </div>
                </div>

                <div className="Exchange-swap-button-container">
                  <button
                    className="App-cta Exchange-swap-button"
                    // onClick={onClickPrimary}
                    // disabled={!isPrimaryEnabled()}
                  >
                    {/* {getPrimaryText()} */}
                    Confirm
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}
