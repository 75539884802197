import { ARBITRUM, AVALANCHE, BASE, SONIC_TESTNET, FANTOM, OP, FANTOM_TESTNET } from "./chains";

export const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    stats: "https://api.studio.thegraph.com/query/74668/navigator-arb-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/navigator-arb-referrals/version/latest",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/navi-vault",
  },
  [AVALANCHE]: {
    stats: "https://api.thegraph.com/subgraphs/name/navi-io/navi-avalanche-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/navi-io/navi-avalanche-referrals",
  },
  [OP]: {
    stats: "https://api.studio.thegraph.com/query/74668/navigator-op-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/navigator-op-referrals/version/latest",
  },
  [BASE]: {
    stats: "https://api.studio.thegraph.com/query/74668/navigator-base-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/navigator-base-referrals/version/latest",
  },
  [FANTOM]: {
    stats: "https://api.studio.thegraph.com/query/74668/navigator-fantom-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/navigator-fantom-referrals/version/latest",
  },
  [FANTOM_TESTNET]: {
    stats: "https://api.studio.thegraph.com/query/74668/navigator-fantom-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/navigator-fantom-referrals/version/latest",
  },
  [SONIC_TESTNET]: {
    stats: "https://api.navigator.exchange/subgraph/sonic/query/stats",
    referrals: "https://api.navigator.exchange/subgraph/sonic/query/referrals",
    positions: "https://api.studio.thegraph.com/query/98833/sonic-middleware/version/latest",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
