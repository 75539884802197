import { PoolFrame } from "pages/Earn/styles";

import nslp_ic from "img/nslp.svg";

import { useWeb3React } from "@web3-react/core";
import Footer from "components/Footer/Footer";
import { SUPPORTED_CHAIN_ID_NSLP } from "config/chains";
import { getContract } from "config/contracts";
import { getNativeToken } from "config/tokens";
import { useNaviPrice } from "domain/legacy";
import useVaultNaviContract from "hooks/contracts/useVaultNaviContract";
import { useChainIdWithDefault } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import {
  NLP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  USD_DECIMALS,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
} from "lib/legacy";
import { bigNumberify, formatAddKeysAmount, formatAmount, formatKeyAmount, formatNumber } from "lib/numbers";
import BuySellNslp from "pages/Earn/BuySellNslp";
import PoolCompositionNslp from "pages/Earn/PoolCompositionNslp";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as RechartToolTip,
} from "recharts";
import styled from "styled-components";
import useSWR from "swr";

import NlpManager from "abis/NlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import RewardTracker from "abis/RewardTracker.json";
import Token from "abis/Token.json";
import Vault from "abis/Vault.json";

import Tooltip from "components/Tooltip/Tooltip";
import { ethers } from "ethers";
import useNslpTvl from "hooks/useNslpTvl";
import { getTime } from "./Nlp";
import { tooltipFormatter, tooltipLabelFormatter, yaxisFormatter, yaxisFormatterNumber1 } from "lib/helper";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";

export const MINT_NSLP = "Mint NSLP";
export const REDEEM_NSLP = "Redeem NSLP";

const { AddressZero } = ethers.constants;

const Nslp = ({
  setPendingTxns,
  connectWallet,
  savedSelectedDexes,
  savedSlippageAmount,
  savedShouldDisableValidationForTesting,
}) => {
  const [isBuying, setIsBuying] = useState(true);
  const history = useHistory();

  const { account, active, library } = useWeb3React();
  const chainId = useChainIdWithDefault({
    chains: SUPPORTED_CHAIN_ID_NSLP,
    isUseDefaultChain: false,
  });

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash !== "redeem";
    setIsBuying(buying);
  }, [history.location.hash]);

  const { naviPrice } = useNaviPrice(chainId, {}, active);

  const nativeTokenSymbol = getNativeToken(chainId).symbol;
  // const tokens = getTokens(chainId);
  const readerAddress = getContract(chainId, "Reader");
  const stakedNslpTrackerAddress = getContract(chainId, "StakedNslpTracker");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const usdgAddress = getContract(chainId, "USDN");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const vaultAddress = getContract(chainId, "Vault");
  const nlpManagerAddress = getContract(chainId, "NlpManager");

  const naviAddress = getContract(chainId, "NAVI");
  const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");
  const nslpAddress = getContract(chainId, "NSLP");

  const esNaviAddress = getContract(chainId, "ES_NAVI");
  const bnNaviAddress = getContract(chainId, "BN_NAVI");
  const bonusNaviTrackerAddress = getContract(chainId, "BonusNaviTracker");
  const feeNaviTrackerAddress = getContract(chainId, "FeeNaviTracker");
  const feeNslpTrackerAddress = getContract(chainId, "FeeNslpTracker");

  const naviVesterAddress = getContract(chainId, "NaviVester");
  const nslpVesterAddress = getContract(chainId, "NslpVester");

  const tokensForBalanceAndSupplyQuery = [stakedNslpTrackerAddress, usdgAddress];
  const rewardTrackersForStakingInfo = [
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    stakedNslpTrackerAddress,
    feeNslpTrackerAddress,
    stakedNslpTrackerAddress,
    feeNslpTrackerAddress,
  ];

  const vesterAddresses = [naviVesterAddress, nslpVesterAddress, nslpVesterAddress];
  const walletTokens = [naviAddress, esNaviAddress, nslpAddress, stakedNaviTrackerAddress, nslpAddress];
  const depositTokens = [
    naviAddress,
    esNaviAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    bnNaviAddress,
    nslpAddress,
    nslpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedNaviTrackerAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNslpTrackerAddress,
    feeNslpTrackerAddress,
  ];

  // const tokenAddresses = tokens.map((token) => token.address);

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash !== "redeem";
    setIsBuying(buying);
  }, [history.location.hash]);

  // const { data: tokenBalances } = useSWR(
  //   [`Nslp:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
  //   }
  // );

  const { data: balancesAndSupplies } = useSWR(
    [
      `Nslp:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForBalanceAndSupplyQuery]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `Nslp:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: aums } = useSWR([`Nslp:getAums:${active}`, chainId, nlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, NlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`Nslp:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: walletBalances } = useSWR(
    [
      `Nslp:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `Nslp:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `Nslp:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );

  const { data: nslpBalance } = useSWR(
    [`Nslp:nslpBalance:${active}`, chainId, feeNslpTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const { data: stakedNaviSupply } = useSWR(
    [
      `Nslp:stakedNaviSupply:${active}:${stakedNaviTrackerAddress}`,
      chainId,
      naviAddress,
      "balanceOf",
      stakedNaviTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const tokensForSupplyQuery = [naviAddress, nslpAddress, usdgAddress, nslpAddress];
  const { data: totalSupplies } = useSWR(
    [
      `Nslp:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );

  let totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalSupply = totalSupplies[1];
  }

  let naviSupply = totalSupply;

  let aum;
  if (aums && aums.length > 0) {
    aum = isBuying ? aums[0] : aums[1];
  }

  // const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const nslpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);
  const { nslpPrice } = useVaultNaviContract(chainId);

  // const nslpPrice =
  //   aum && aum.gt(0) && nslpSupply.gt(0)
  //     ? aum.mul(expandDecimals(1, NSLP_DECIMALS)).div(nslpSupply)
  //     : expandDecimals(1, USD_DECIMALS);

  // const nslpSupplyUsd = nslpSupply.mul(nslpPrice).div(expandDecimals(1, NSLP_DECIMALS));
  const stakingData = getStakingData(stakingInfo);
  // const nativeToken = getTokenInfo(infoTokens, AddressZero);

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedNaviSupply,
    naviPrice,
    naviSupply,
    nslpPrice
  );
  const CustomTooltip = (props) => {
    const { active, payload, label } = props;
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          <div className="label-total">{tooltipLabelFormatter(label, payload)}</div>
          {payload.map((item, index) => {
            return (
              <div className="item-group" key={index}>
                <div style={{ color: item.color || item.stroke || item.fill || "white" }}>{item.name}: </div>
                <div style={{ color: item.color || item.stroke || item.fill || "white" }}>
                  {tooltipFormatter(item.value, item.name, item)} {item.unit || ""}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };
  const nslp = useNslpTvl({ from: getTime() });
  // console.log("????", nslp);

  return (
    <PoolFrame>
      <div className="Pool-Head">
        <div className="Pool-HeadDetails">
          <h1>
            <img src={nslp_ic} alt="nslp_ic" />
            <div>
              Stable pool - NSLP
              <p>Purchase NSLP tokens to earn SONIC fees from swaps and leveraged trading.</p>
            </div>
          </h1>
        </div>
        <Claimbox>
          <div className="content">
            <span className="muted">Rewards:</span>
            <span>
              <Tooltip
                handle={`$${formatNumber(
                  formatAddKeysAmount(
                    processedData,
                    "feeNslpTrackerRewardsUsd",
                    "stakedNslpTrackerRewardsUsd",
                    USD_DECIMALS,
                    USD_DECIMALS
                  )
                )}`}
                position="right-bottom"
                tooltipIconPosition="right"
                renderContent={() => (
                  <>
                    <StatsTooltipRow
                      label={`esNAVI`}
                      showDollar={false}
                      value={`${formatKeyAmount(
                        processedData,
                        "stakedNslpTrackerRewards",
                        18,
                        4,
                        true
                      )} ($${formatNumber(
                        formatKeyAmount(processedData, "stakedNslpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )})`}
                    />
                    <StatsTooltipRow
                      label={`S (WS)`}
                      showDollar={false}
                      value={`${formatKeyAmount(processedData, "feeNslpTrackerRewards", 18, 4, true)} ($${formatNumber(
                        formatKeyAmount(processedData, "feeNslpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )})`}
                    />
                  </>
                )}
              />{" "}
            </span>
          </div>
          <Link to="/earn">
            <button className="default-btn">Claim</button>
          </Link>
        </Claimbox>
        {/* <div className="Pool-Links">
          <Link to="/earn" className="Pool-Link">
            Staking page
          </Link>
          <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
            <circle cx="2" cy="2" r="2" fill="#807E7C" />
          </svg>
          <button className="Pool-Link disabled">
            Docs <img src={green_arrow_ic} alt="green_arrow_ic" />
          </button>
        </div> */}
      </div>
      <div className="Pool-Papers">
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            APR
            {processedData.nslpAprTotal ? (
              <>
                <h5 className="positive">{formatKeyAmount(processedData, "nslpAprTotal", 2, 2, true)}%</h5>
                {nativeTokenSymbol} {formatKeyAmount(processedData, "nslpAprForNativeToken", 2, 2, true)}%{" "}
                <br className="br-mobile" />+ esNAVI {formatKeyAmount(processedData, "nslpAprForEsNavi", 2, 2, true)}%
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Staked
            {processedData.nslpSupply ? (
              <>
                <h5>{formatKeyAmount(processedData, "nslpSupply", 18, 2, true)} NSLP</h5> $
                {formatNumber(formatKeyAmount(processedData, "nslpSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Supply
            {formatAmount(nslpSupply, 18, 4, true) !== "0.0000" ? (
              <>
                <h5>{formatKeyAmount(processedData, "nslpSupply", 18, 2, true)} NSLP</h5>$
                {formatNumber(formatKeyAmount(processedData, "nslpSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
        </div>
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            Your Staked
            {nslpBalance ? (
              <>
                <h5>{formatAmount(nslpBalance, NLP_DECIMALS, 2, true)} NSLP</h5>
              </>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
            {processedData?.nslpBalanceUsd ? (
              <>${formatNumber(formatKeyAmount(processedData, "nslpBalanceUsd", USD_DECIMALS, USD_DECIMALS))}</>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
          </div>
          <div className="Pool-PaperStat">
            Your Wallet
            {nslpBalance ? (
              <>
                <h5>{formatAmount(nslpBalance, NLP_DECIMALS, 2, true)} NSLP</h5>$
                {formatNumber(formatKeyAmount(processedData, "nslpBalanceUsd", USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
        </div>
        <div className="Pool-Aggregate">
          <div className="Pool-Paper Pool-Chart">
            <h3>Token Distribution</h3>
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={400}
                data={nslp ? nslp.map((i) => ({ ...i, all: null })) : []}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <RechartToolTip content={<CustomTooltip />} />
                <CartesianGrid opacity={0.1} />
                <XAxis dataKey="timestamp" color="red" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                <YAxis yAxisId="left" tickFormatter={yaxisFormatter} dataKey="nslpTvl" />
                <Area yAxisId="left" type="monotone" dataKey="nslpTvl" stroke={0} fill="#ffc658" name="USDC.e" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <PoolCompositionNslp />
        </div>
        <div className="Pool-Paper Pool-Form">
          <BuySellNslp
            isBuying={isBuying}
            setPendingTxns={setPendingTxns}
            connectWallet={connectWallet}
            savedSelectedDexes={savedSelectedDexes}
            savedSlippageAmount={savedSlippageAmount}
            savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
          />
        </div>
      </div>
      <Footer />
    </PoolFrame>
  );
};

export default Nslp;

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const Claimbox = styled.div`
  border-radius: 16px;
  border: 1px solid var(--Primary, #90fb75);
  background: var(--Nature-1, #12141a);
  width: 424px;
  padding: var(--Base-Unit-XS-8, 8px) var(--Base-Unit-XS-8, 8px) var(--Base-Unit-XS-8, 8px) 16px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .content {
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    gap: 8px;
  }
`;
