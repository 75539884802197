import { useState, useEffect, useCallback } from "react";
import useSWR from "swr";
import { ethers } from "ethers";
import styled from "styled-components";
import { callContract } from "lib/contracts";
import VaultStrategyNLP from "abis/VaultStrategyNLP.json";
import NlpManager from "abis/NlpManager.json";

import ExternalLink from "components/ExternalLink/ExternalLink";
import Tab from "components/Tab/Tab";
import { approveTokens } from "domain/tokens";
import { formatNumber, parseValue } from "lib/numbers";
import { FANTOM, getExplorerUrl } from "config/chains";
import Countdown from "react-countdown";
import TooltipComponent from "components/Tooltip/Tooltip";

import { StyledVaultAction } from "./index.style";

import { NAVI_VAULT, NLP_VAULT, NAVI_WFTM_OLD_VAULT, NAVI_WFTM_VAULT, NAVI_WETH_VAULT } from "./index";

import naviIcon from "img/ic_navi_custom.svg";
import nlpIcon from "img/ic_nlp_40.svg";
import NAVI_FTM from "img/NAVI-FTM.svg";
import NAVI_ETH from "img/NAVI-ETH.svg";
import icLink from "img/icons/link-green.svg";
import icLinkLight from "img/icons/redirectLight.svg";

import Modal from "components/Modal/Modal";
import getImageChainMini from "./getImageChainMini";
import { NLP_COOLDOWN_DURATION, PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { getContract } from "config/contracts";
import { useThemeContext } from "contexts/ThemeProvider";
import Tooltip from "components/Tooltip/Tooltip";
import RedirectIcon from "components/Icon/RedirectIcon";

export const DEPOSIT = "Deposit";
export const WITHDRAW = "Withdraw";

const INVALID_LIST_INPUT = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  ".",
  "Backspace",
  "Delete",
  "ArrowLeft",
  "ArrowRight",
];

const TAB_OPTIONS = [DEPOSIT, WITHDRAW];

const StyledTab = styled(Tab)`
  display: flex;
  height: 45px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;

  > div {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    flex: 1;
  }
`;

const VaultAction = ({
  active,
  library,
  balance,
  value,
  setValue,
  account,
  connectWallet,
  currentActiveTab,
  chainId,
  Token,
  currentVaultAddress,
  NewVaultJson,
  contractFetcher,
  amountTokenDeposit,
  rateWithdraw,
  setPendingTxns,
}) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const [isVisible, setIsVisible] = useState(false);
  const [flagValue, setFlagValue] = useState(balance);
  const TAB_OPTION_LABELS = { [DEPOSIT]: `Deposit`, [WITHDRAW]: `Withdraw` };
  const [activeTab, setActiveTab] = useState(DEPOSIT);
  const [isApproving, setIsApproving] = useState(false);

  let currentTokenName = "";
  let imgToken = <img src={currentActiveTab === NAVI_VAULT ? naviIcon : nlpIcon} alt="navigator" />;

  switch (currentActiveTab) {
    case NAVI_VAULT:
      currentTokenName = "NAVI";
      break;
    case NLP_VAULT:
      currentTokenName = "NLP";
      break;

    case NAVI_WFTM_OLD_VAULT:
      currentTokenName = "NAVI-WFTM";
      imgToken = <img src={NAVI_FTM} alt="navigator" />;
      break;

    case NAVI_WFTM_VAULT:
      currentTokenName = "NAVI-WFTM";
      imgToken = <img src={NAVI_FTM} alt="navigator" />;
      break;

    case NAVI_WETH_VAULT:
      currentTokenName = "NAVI-WETH";
      imgToken = <img src={NAVI_ETH} alt="navigator" />;
      break;

    default:
      currentTokenName = "NAVI";
      imgToken = <img src={currentActiveTab === NAVI_VAULT ? naviIcon : nlpIcon} alt="navigator" />;
      break;
  }

  const { data: addressTokenStake } = useSWR(
    [`NewVault:addressTokenStake:${currentTokenName}`, chainId, currentVaultAddress, "want"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const stakingTokenAddress = addressTokenStake || "0x104d0312f95a1b3056435d19668f2272ab1e7df2"; // FANTOM || OP

  const { data: tokenAllowance, mutate: updateTokenAllowance } = useSWR(
    [active, chainId, stakingTokenAddress, "allowance", account, currentVaultAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );
  const { data: withdrawOpen } = useSWR(
    [`Vault:withdrawOpen:${active}`, chainId, "0x12E2611b357EE749D3C202dF1342108598365497", "withdrawOpen"],
    {
      fetcher: contractFetcher(library, VaultStrategyNLP),
    }
  );
  const nlpManagerAddress = getContract(chainId, "NlpManager");

  const { data: lastPurchaseTime } = useSWR(
    [`Vault:lastPurchaseTime:${active}`, chainId, nlpManagerAddress, "lastAddedAt", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, NlpManager),
    }
  );
  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(NLP_COOLDOWN_DURATION) : undefined;

  useEffect(() => {
    setFlagValue(activeTab === DEPOSIT ? balance : amountTokenDeposit);
  }, [balance, amountTokenDeposit, activeTab]);

  useEffect(() => {
    if (active) {
      library.on("block", () => {
        updateTokenAllowance(undefined, true);
      });
      return () => {
        library.removeAllListeners("block");
      };
    }
  }, [active, library, updateTokenAllowance]);

  const needApproval = (tokenAllowance || 0).toString() === "0";

  // console.log("test", tokenAllowance, addressTokenStake);

  const getPrimaryText = useCallback(() => {
    if (flagValue === "...") {
      return `Loading ...`;
    }

    if (flagValue === "0") {
      if (activeTab === DEPOSIT) {
        return `Deposit`;
      }

      return `Withdraw`;
    }

    if (isApproving && activeTab === DEPOSIT) {
      return `Approving ${currentTokenName}...`;
    }

    if (needApproval && activeTab === DEPOSIT) {
      return `Approve ${currentTokenName}`;
    }

    if (activeTab === DEPOSIT) {
      return `Deposit`;
    }

    return `Withdraw`;
  }, [flagValue, isApproving, needApproval, activeTab, currentTokenName]);

  const handleChangeValue = (event) => {
    const eventValue = event.target.value;
    if (Number(eventValue) < 0) return;

    if (eventValue.includes(".") && eventValue.split(".")[1].length > 18) {
      return;
    }

    setValue(eventValue);
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: currentVaultAddress,
        chainId,
      });
      return;
    }

    if (activeTab === DEPOSIT) {
      if (currentActiveTab === NAVI_VAULT || currentActiveTab === NLP_VAULT) {
        setIsVisible(true);
      } else {
        handleDeposit();
      }
    } else {
      let amount = parseValue(value, 18);

      const contract = new ethers.Contract(currentVaultAddress, NewVaultJson.abi, library.getSigner());

      callContract(chainId, contract, "withdraw", [amount], {
        sentMsg: `Withdraw submitted!`,
        failMsg: `Withdraw failed.`,
        setPendingTxns,
      }).then(async (res) => {
        setValue("");
      });
    }
  };

  function renderCurrentTab() {
    return (
      <>
        <div className="your-balance">
          <div className="left">Avail: {formatNumber(flagValue, 4)}</div>
        </div>
      </>
    );
  }

  useEffect(() => {
    setValue("");
  }, [currentActiveTab, account, setValue]);

  const handleChangeTab = (currentActiveTab) => {
    setValue("");
    setFlagValue(currentActiveTab === DEPOSIT ? balance : amountTokenDeposit);
  };

  const handleDeposit = () => {
    let amount = parseValue(value, 18);
    const contract = new ethers.Contract(currentVaultAddress, NewVaultJson.abi, library.getSigner());

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: `Deposit submitted!`,
      failMsg: `Deposit failed.`,
      setPendingTxns,
    }).then(async (res) => {
      setValue("");
      setIsVisible(false);
    });
  };
  const countdownRenderer = (countdownProps) => {
    const { days, hours, minutes, seconds } = countdownProps;
    const d = String(days);
    const h = String(hours);
    const m = String(minutes);
    const s = String(seconds);
    return (
      <span>
        {h.padStart(2, "0")}
        {":"}
        {m.padStart(2, "0")}
        {":"}
        {s.padStart(2, "0")}
      </span>
    );
  };
  return (
    <StyledVaultAction className={lightThemeClassName}>
      <ConfirmModal isVisible={isVisible} setIsVisible={setIsVisible} label={"Confirm deposit"} allowContentTouchMove>
        <div className="centent">
          Disclaimer: The EsNAVI APY reflects the rewards used to compound for more FTM, EsNAVI, and MP and will not be
          directly calculated when withdrawing tokens.
        </div>
        <div className="footer">
          <button className="border-btn" onClick={() => setIsVisible(false)}>
            Cancel
          </button>
          <button className="default-btn" onClick={handleDeposit}>
            Deposit
          </button>
        </div>
      </ConfirmModal>
      <div className="Exchange-swap-box-inner App-box-highlight vault-v2">
        <StyledTab
          options={TAB_OPTIONS}
          optionLabels={TAB_OPTION_LABELS}
          option={activeTab}
          setOption={setActiveTab}
          onChange={handleChangeTab}
          isBoldFontWeight={true}
        />

        <div className="total-wrapper-action">
          <div className="Available">
            <span
              style={{
                fontWeight: 400,
                fontSize: 14,
                color: "#828899",
              }}
            >
              Amount
            </span>
            {renderCurrentTab()}
          </div>

          <div className="wrapper-input">
            <div className="input-balance">
              <input
                onKeyDown={(e) => {
                  !INVALID_LIST_INPUT.includes(e.key) && e.preventDefault();

                  (String(value) || "").includes(".") && e.key === "." && e.preventDefault();
                }}
                placeholder="0.00"
                value={value}
                onChange={handleChangeValue}
              />
            </div>

            <div
              style={{
                display: "flex",
                gap: 4,
                alignItems: "center",
              }}
            >
              <div className="max-btn" onClick={() => setValue(activeTab === DEPOSIT ? balance : amountTokenDeposit)}>
                MAX
              </div>
              <div
                style={{
                  marginRight: 0,
                }}
                className="App-card-title-mark-icon"
              >
                {imgToken}
                {[NAVI_VAULT, NLP_VAULT].includes(currentActiveTab) && getImageChainMini(chainId)}
              </div>
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: "140%",
                  color: "#FFFFFF",
                }}
              >
                {currentTokenName} {activeTab === WITHDRAW && "Vault"}
              </span>
            </div>
          </div>
        </div>

        {currentActiveTab === NAVI_VAULT ? (
          <StyledExternalLink href="https://app.navigator.exchange/#/buy_navi">
            Buy NAVI <RedirectIcon color={isLightTheme ? "#02b27f" : "rgb(3, 245, 174)"} className="redirect-icon" />
          </StyledExternalLink>
        ) : currentActiveTab === NLP_VAULT ? (
          <StyledExternalLink href="https://app.navigator.exchange/#/buy_nlp">
            Buy NLP <RedirectIcon color={isLightTheme ? "#02b27f" : "rgb(3, 245, 174)"} className="redirect-icon" />
          </StyledExternalLink>
        ) : [NAVI_WFTM_OLD_VAULT, NAVI_WFTM_VAULT].includes(currentActiveTab) ? (
          <StyledExternalLink href="https://equalizer.exchange/liquidity/0xdc935ffe9f9c972b7b304e0b7e61eb774037e394">
            Add liquidity{" "}
            <RedirectIcon color={isLightTheme ? "#02b27f" : "rgb(3, 245, 174)"} className="redirect-icon" />
          </StyledExternalLink>
        ) : (
          <StyledExternalLink href="https://app.velodrome.finance/liquidity/manage?address=0x104d0312f95a1b3056435d19668f2272ab1e7df2">
            Add liquidity{" "}
            <RedirectIcon color={isLightTheme ? "#02b27f" : "rgb(3, 245, 174)"} className="redirect-icon" />
          </StyledExternalLink>
        )}

        {chainId === FANTOM &&
        activeTab === DEPOSIT &&
        redemptionTime &&
        currentActiveTab === NLP_VAULT &&
        Date.now() < redemptionTime * 1000 ? (
          <div className="muted">
            <Tooltip
              handle={
                <span
                  style={{
                    paddingTop: 10,
                    display: "block",
                  }}
                >
                  Cooldown:
                </span>
              }
              renderContent={() => {
                return <>Due to your last NLP mint.</>;
              }}
            />{" "}
            <span
              style={{
                color: "#ffffff",
                fontWeight: "700",
              }}
            >
              <Countdown date={new Date(redemptionTime * 1000)} renderer={countdownRenderer} />
            </span>
          </div>
        ) : null}
        {activeTab === WITHDRAW ? (
          <div
            class="est muted"
            style={{
              marginTop: 10,
              marginBottom: 10,
              fontWeight: 400,
            }}
          >
            Est. receive:{" "}
            <span
              style={{
                color: "#ffffff",
                fontWeight: 700,
              }}
            >
              {formatNumber((Number(value) - Number(value) * (0.1 / 100)) * rateWithdraw, 4)} {currentTokenName}
            </span>
          </div>
        ) : null}
        {chainId === FANTOM &&
        activeTab === WITHDRAW &&
        withdrawOpen &&
        currentActiveTab === NLP_VAULT &&
        Date.now() < withdrawOpen * 1000 ? (
          <div className="muted">
            <Tooltip
              handle={
                <span
                  style={{
                    display: "block",
                  }}
                >
                  Cooldown:
                </span>
              }
              renderContent={() => {
                return <>Due to the last auto compound of the vault.</>;
              }}
            />{" "}
            <span
              style={{
                color: "#ffffff",
                fontWeight: "700",
              }}
            >
              <Countdown date={new Date(withdrawOpen * 1000)} renderer={countdownRenderer} />
            </span>
          </div>
        ) : null}

        {account ? (
          <button
            // disabled={["", "0", "0.0", "0.00"].includes(value) && !needApproval}
            disabled={
              flagValue === "0" ||
              flagValue === "..." ||
              (!needApproval && !value) ||
              (!needApproval && Number(value) > Number(flagValue)) ||
              (!needApproval && ["", "0", "0.0", "0.00"].includes(value)) ||
              (currentActiveTab === NAVI_WFTM_OLD_VAULT && activeTab === DEPOSIT) ||
              (chainId === FANTOM &&
                activeTab === WITHDRAW &&
                withdrawOpen &&
                currentActiveTab === NLP_VAULT &&
                Date.now() < withdrawOpen * 1000) ||
              (chainId === FANTOM &&
                activeTab === DEPOSIT &&
                redemptionTime &&
                currentActiveTab === NLP_VAULT &&
                Date.now() < redemptionTime * 1000)
            }
            className="btn-deposit default-btn"
            onClick={onClickPrimary}
          >
            {getPrimaryText()}
          </button>
        ) : (
          <button className="btn-deposit default-btn" onClick={() => connectWallet()}>
            Connect Wallet
          </button>
        )}
      </div>

      <div className="perfomance-fee">
        <div className="deposit-withdraw-fee">
          <div>
            Deposit fee
            <span>0%</span>
          </div>

          <div>
            Withdraw fee
            <span>0.1%</span>
          </div>
        </div>
        <div className="label">Performance fee</div>

        <div className="percent">4%</div>

        <div className="description">Performance fee is already subtracted from the displayed APY.</div>
      </div>
    </StyledVaultAction>
  );
};

const StyledTooltipComponent = styled(TooltipComponent)`
  .Tooltip-row-value {
    margin-top: 0 !important;
  }
  .Tooltip-row > span {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .Tooltip {
    margin-top: 0 !important;
    margin-bottom: 18px;
  }
  .active {
    color: #828899 !important;
  }
  display: inline-block !important;

  .Tooltip-handle {
    display: inline-block;
    margin-top: 0;
  }

  .Tooltip-popup {
    /* top: 150%;
    left: 100%;
    transform: translateX(-50%); */
  }
`;

const ConfirmModal = styled(Modal)`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  @media (max-width: 600px) {
    width: calc(100% - 32px);
  }

  .footer {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      border: none;

      padding: 8px 16px;
      gap: 4px;

      width: 376px;
      height: 40px;

      background: #90fb75;
      border-radius: 4px;

      font-weight: 500;
      font-size: 14px;
      line-height: 140%;

      color: #080715;

      &.cancel {
        background-color: transparent;
        border: 1px solid #90fb75;
        color: #90fb75;
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #90fb75;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
`;

export default VaultAction;
