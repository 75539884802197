import { createClient } from "./utils";
import { SUBGRAPH_URLS } from "config/subgraph";
import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, BASE, SONIC_TESTNET, FANTOM, OP, FANTOM_TESTNET } from "config/chains";

export const chainlinkClient = createClient(SUBGRAPH_URLS.common.chainLink);

export const arbitrumGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].stats);
export const arbitrumReferralsGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].referrals);
export const nissohGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].nissohVault);

export const avalancheGraphClient = createClient(SUBGRAPH_URLS[AVALANCHE].stats);
export const avalancheReferralsGraphClient = createClient(SUBGRAPH_URLS[AVALANCHE].referrals);

export const fantomGraphClient = createClient(SUBGRAPH_URLS[FANTOM].stats);
export const fantomReferralsGraphClient = createClient(SUBGRAPH_URLS[FANTOM].referrals);



export const opGraphClient = createClient(SUBGRAPH_URLS[OP].stats);
export const opReferralsGraphClient = createClient(SUBGRAPH_URLS[OP].referrals);

export const baseGraphClient = createClient(SUBGRAPH_URLS[BASE].stats);
export const baseReferralsGraphClient = createClient(SUBGRAPH_URLS[BASE].referrals);

export const sonicGraphClient = createClient(SUBGRAPH_URLS[SONIC_TESTNET].stats);
export const sonicReferralsGraphClient = createClient(SUBGRAPH_URLS[SONIC_TESTNET].referrals);

export function getNaviGraphClient(chainId: number) {
  if (chainId === ARBITRUM) {
    return arbitrumGraphClient;
  } else if (chainId === AVALANCHE) {
    return avalancheGraphClient;
  } else if (chainId === FANTOM) {
    return fantomGraphClient;
  } else if (chainId === OP) {
    return opGraphClient;
  } else if (chainId === BASE) {
    return baseGraphClient;
  } else if (chainId === SONIC_TESTNET) {
    return sonicGraphClient;
  } else if (chainId === ARBITRUM_TESTNET) {
    return null;
  }
  else if (chainId === FANTOM_TESTNET) {
    return sonicGraphClient;
  }

  throw new Error(`Unsupported chain ${chainId}`);
}
