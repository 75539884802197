import Tooltip from "components/Tooltip/Tooltip";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  formatKeyAmountFixed,
  formatNumber,
  parseValue,
} from "lib/numbers";

import { useWeb3React } from "@web3-react/core";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ARBITRUM, BASE, getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { useTotalEsNaviStaked, useTotalNaviStaked } from "domain/legacy";
import esgum_ic from "img/esgum.svg";
import gum_ic from "img/gum.svg";
import bera_ic from "img/ic_bera_24.svg";
import multipoint_ic from "img/multipoint.svg";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { BASIS_POINTS_DIVISOR, USD_DECIMALS } from "lib/legacy";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";

import RewardRouter from "abis/RewardRouter.json";
import Token from "abis/Token.json";

import Checkbox from "components/Checkbox/Checkbox";
import Modal from "components/Modal/Modal";
import NAVIAprTooltip from "components/Stake/NAVIAprTooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { approveTokens } from "domain/tokens";
import { ethers } from "ethers";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { useMedia } from "react-use";

const { AddressZero } = ethers.constants;

const StakeSection = ({ processedData, naviPrice, vestingData, setPendingTxns, totalRewardTokens }) => {
  const isMobile = useMedia("(max-width: 767px)");

  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);
  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);

  let { total: totalNaviStaked } = useTotalNaviStaked();
  let { total: totalEsNaviStaked } = useTotalEsNaviStaked();
  const { chainId } = useChainId();

  let stakedNaviSupplyUsd = 0;
  if (!totalNaviStaked.isZero() && naviPrice) {
    stakedNaviSupplyUsd = totalNaviStaked.mul(naviPrice).div(expandDecimals(1, 18));
  }

  let stakedEsNaviSupplyUsd = 0;
  if (!totalEsNaviStaked.isZero() && naviPrice) {
    stakedEsNaviSupplyUsd = totalEsNaviStaked.mul(naviPrice).div(expandDecimals(1, 18));
  }

  let estGMYRewardsPerWeek = 0;
  if (processedData && processedData.naviAprTotal && processedData.naviBalanceUsd) {
    estGMYRewardsPerWeek = processedData.naviAprTotal.mul(processedData.naviBalanceUsd).div(365).mul(7).div(100);
  }

  let estEsGMYRewardsPerWeek = 0;
  if (processedData && processedData.naviAprTotal && processedData.esNaviBalanceUsd) {
    estEsGMYRewardsPerWeek = processedData.naviAprTotal.mul(processedData.esNaviBalanceUsd).div(365).mul(7).div(100);
  }

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const { account, active, library } = useWeb3React();
  const isNaviTransferEnabled = true;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");
  const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");

  const esNaviAddress = getContract(chainId, "ES_NAVI");
  const naviAddress = getContract(chainId, "NAVI");

  const showStakeNaviModal = () => {
    if (!isNaviTransferEnabled) {
      helperToast.error(`NAVI transfers not yet enabled`);
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake NAVI`);
    setStakeModalMaxAmount(processedData.naviBalance);
    setStakeValue("");
    setStakingTokenSymbol("NAVI");
    setStakingTokenAddress(naviAddress);
    setStakingFarmAddress(stakedNaviTrackerAddress);
    setStakeMethodName("stakeNavi");
  };

  const showStakeEsNaviModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake esNAVI`);
    setStakeModalMaxAmount(processedData.esNaviBalance);
    setStakeValue("");
    setStakingTokenSymbol("esNAVI");
    setStakingTokenAddress(esNaviAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsNavi");
  };
  const showUnstakeEsNaviModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake esNAVI`);
    let maxAmount = processedData.esNaviInStakedNavi;
    if (
      processedData.esNaviInStakedNavi &&
      vestingData &&
      vestingData.naviVesterPairAmount.gt(0) &&
      maxUnstakeableNavi &&
      maxUnstakeableNavi.lt(processedData.esNaviInStakedNavi)
    ) {
      maxAmount = maxUnstakeableNavi;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.naviVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esNAVI");
    setUnstakeMethodName("unstakeEsNavi");
  };

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  let stakedNaviMultiplierPoints = bigNumberify(0);
  if (
    processedData &&
    processedData.naviInStakedNavi &&
    processedData.bnNaviInFeeNavi &&
    processedData.esNaviInStakedNavi &&
    (processedData.naviInStakedNavi.gt(0) || processedData.esNaviInStakedNavi.gt(0))
  ) {
    stakedNaviMultiplierPoints = processedData.naviInStakedNavi
      .mul(expandDecimals(1, 18))
      .div(processedData.naviInStakedNavi.add(processedData.esNaviInStakedNavi))
      .mul(processedData.bnNaviInFeeNavi)
      .div(expandDecimals(1, 18));
  }

  let stakedEsNaviMultiplierPoints = bigNumberify(0);
  if (
    processedData &&
    processedData.esNaviInStakedNavi &&
    processedData.bnNaviInFeeNavi &&
    processedData.naviInStakedNavi &&
    (processedData.naviInStakedNavi.gt(0) || processedData.esNaviInStakedNavi.gt(0))
  ) {
    stakedEsNaviMultiplierPoints = processedData.esNaviInStakedNavi
      .mul(expandDecimals(1, 18))
      .div(processedData.naviInStakedNavi.add(processedData.esNaviInStakedNavi))
      .mul(processedData.bnNaviInFeeNavi)
      .div(expandDecimals(1, 18));
  }

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusNaviTrackerRewards && processedData.bnNaviInFeeNavi) {
    multiplierPointsAmount = processedData.bonusNaviTrackerRewards.add(processedData.bnNaviInFeeNavi);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }

  let maxUnstakeableNavi = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.naviVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusNaviInFeeNavi
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.naviVesterPairAmount);
    const stakedTokens = processedData.bonusNaviInFeeNavi;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableNavi = availableTokens.mul(stakedTokens).div(divisor);
    }
  }

  const bonusNaviInFeeNavi = processedData ? processedData.bonusNaviInFeeNavi : undefined;

  const showUnstakeNaviModal = () => {
    if (!isNaviTransferEnabled) {
      helperToast.error(`NAVI transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake NAVI`);
    let maxAmount = processedData.naviInStakedNavi;
    if (
      processedData.naviInStakedNavi &&
      vestingData &&
      vestingData.naviVesterPairAmount.gt(0) &&
      maxUnstakeableNavi &&
      maxUnstakeableNavi.lt(processedData.naviInStakedNavi)
    ) {
      maxAmount = maxUnstakeableNavi;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.naviVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("NAVI");
    setUnstakeMethodName("unstakeNavi");
  };
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <Wrap className="Earn-Section">
      <h1 className="Section-Title" id="stake">
        Stake
      </h1>

      <div className="Section-StakePapers">
        <div className="Section-StakePaper">
          <div className="Section-StakePaperHead">
            <div className="Section-StakePaperHeadDetails">
              <img src={gum_ic} alt="gum_ic" />
              <div>
                <h3>NAVI & esNAVI</h3>
                <p>Stake Navigator governance token</p>
              </div>
            </div>
            <div className="Section-StakePaperHeadItems">
              <div className="Section-StakePaperHeadItem">
                APR
                {processedData.naviAprTotalWithBoost ? (
                  <Tooltip
                    position={`${isMobile ? "right-bottom" : "left-bottom"}`}
                    className="tooltip-apr"
                    handle={
                      <div className="positive">
                        {formatKeyAmount(processedData, "naviAprTotalWithBoost", 2, 2, true)}%
                      </div>
                    }
                    renderContent={() => (
                      <NAVIAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "60px", height: "19.6px" }} />
                )}
              </div>{" "}
              <div className="Section-StakePaperHeadItem">
                Boost Percentage
                {processedData.boostBasisPoints ? (
                  <Tooltip
                    popupStyled={{ width: "250px" }}
                    position={"right-bottom"}
                    handle={`${formatAmount(processedData.boostBasisPoints, 2, 2, false)}%`}
                    renderContent={() => {
                      return (
                        <div>
                          You are earning{" "}
                          <span style={{ fontWeight: 700, color: "white" }}>
                            {formatAmount(processedData.boostBasisPoints, 2, 2, false)}%
                          </span>{" "}
                          more {nativeTokenSymbol} rewards using{" "}
                          <span style={{ fontWeight: 700, color: "white" }}>
                            {formatAmount(processedData.bnNaviInFeeNavi, 18, 4, 2, true)}
                          </span>{" "}
                          Staked Multiplier Points.
                          <br />
                          <div style={{ marginTop: "8px" }}>
                            <span>Use the "Compound" button to stake your Multiplier Points.</span>
                          </div>
                        </div>
                      );
                    }}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                )}
              </div>
              <div className="Section-StakePaperHeadItem">
                MPs APR
                <Tooltip
                  className="excep"
                  handle={`100.00%`}
                  position={`${isMobile ? "right-bottom" : "left-bottom"}`}
                  renderContent={() => {
                    return (
                      <span>
                        Boost your rewards with Multiplier Points.&nbsp;
                        <ExternalLink href="https://docs.navigator.exchange/rewards#multiplier-points">
                          More info
                        </ExternalLink>
                        .
                      </span>
                    );
                  }}
                />
              </div>
            </div>
            {/* <Link className="Section-StakePaperHeadAction border-btn" to="/buy-gum">
              Buy NAVI
            </Link> */}
            {/* <button className="Section-StakePaperHeadAction border-btn" onClick={() => history.push("/buy-gum")}>
              {" "}
              Buy NAVI
            </button> */}
            <ExternalLink
              href=" https://equalizer.exchange/swap?fromToken=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&toToken=0x6881B80ea7C858E4aEEf63893e18a8A36f3682f3"
              target={"_blank"}
              className="Section-StakePaperHeadAction border-btn"
            >
              Buy NAVI
            </ExternalLink>
          </div>
          <div className="divider" style={{ margin: 0 }} />

          <div className="Section-StakePaperBody">
            <div className="Section-StakePaperBodyItem">
              <div className="Section-StakePaperBodyItemStats">
                <div className="Section-StakePaperBodyItemStatItem">
                  Total NAVI staked
                  <div>
                    {totalNaviStaked ? (
                      <>
                        <h5>{formatAmount(totalNaviStaked, 18, 2, true)}</h5> $
                        {formatNumber(formatAmount(stakedNaviSupplyUsd, USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="divider" />
                <div className="Section-StakePaperBodyItemStatItem">
                  MPs Staked
                  <div>
                    <h5>{formatAmount(stakedNaviMultiplierPoints, 18, 2, true)}</h5>
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  NAVI In Wallet
                  <div>
                    {processedData?.naviBalance ? (
                      <>
                        <h5>{formatKeyAmountFixed(processedData, "naviBalance", 18, 2, true)}</h5> $
                        {formatNumber(
                          formatKeyAmountFixed(processedData, "naviBalanceUsd", USD_DECIMALS, USD_DECIMALS)
                        )}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  NAVI Staked
                  <div>
                    {processedData?.naviInStakedNavi ? (
                      <>
                        <h5>{formatKeyAmount(processedData, "naviInStakedNavi", 18, 2, true)}</h5>$
                        {formatNumber(
                          formatKeyAmount(processedData, "naviInStakedNaviUsd", USD_DECIMALS, USD_DECIMALS)
                        )}
                      </>
                    ) : (
                      <>
                        <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                        <span className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                      </>
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemActions">
                  <button className="default-btn" onClick={showStakeNaviModal}>
                    Stake
                  </button>
                  <button className="border-btn" onClick={showUnstakeNaviModal}>
                    Unstake
                  </button>
                </div>
              </div>
            </div>
            <div className="Section-StakePaperBodyItem">
              <div className="Section-StakePaperBodyItemStats">
                <div className="Section-StakePaperBodyItemStatItem">
                  Total esNAVI staked
                  <div>
                    {totalEsNaviStaked ? (
                      <>
                        <h5> {formatAmount(totalEsNaviStaked, 18, 2, true)}</h5> $
                        {formatNumber(formatAmount(stakedEsNaviSupplyUsd, USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="divider" />
                <div className="Section-StakePaperBodyItemStatItem">
                  MPs Staked
                  <div>
                    <h5>{formatAmount(stakedEsNaviMultiplierPoints, 18, 2, true)}</h5>
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  esNAVI In Wallet
                  <div>
                    {processedData.esNaviBalance ? (
                      <>
                        <h5>{formatKeyAmountFixed(processedData, "esNaviBalance", 18, 2, true)}</h5> $
                        {formatNumber(
                          formatKeyAmountFixed(processedData, "esNaviBalanceUsd", USD_DECIMALS, USD_DECIMALS)
                        )}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  esNAVI Staked
                  <div>
                    {processedData?.esNaviInStakedNavi ? (
                      <>
                        <h5>{formatKeyAmount(processedData, "esNaviInStakedNavi", 18, 2, true)}</h5>$
                        {formatNumber(
                          formatKeyAmount(processedData, "esNaviInStakedNaviUsd", USD_DECIMALS, USD_DECIMALS)
                        )}
                      </>
                    ) : (
                      <>
                        <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                        <span className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                      </>
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemActions">
                  <button className="default-btn" onClick={showStakeEsNaviModal}>
                    Stake
                  </button>
                  <button className="border-btn" onClick={showUnstakeEsNaviModal}>
                    Unstake
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Section-StakePaper">
          <div className="Section-StakePaperHead right">
            <div className="Section-StakePaperHeadDetails">
              <div>
                <h3>Total Rewards</h3>
                <p>Rewards from staking NAVI, esNAVI, NLP and NSLP</p>
              </div>
              <div className="Section-StakePaperHeadDetailTotal">
                <h3>Total</h3>
                <p>
                  {processedData.totalRewardsUsd ? (
                    `$${formatNumber(formatKeyAmount(processedData, "totalRewardsUsd", USD_DECIMALS, USD_DECIMALS))}`
                  ) : (
                    <div
                      className="skeleton-box"
                      style={{
                        height: 20,
                      }}
                    />
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="divider" style={{ margin: 0 }} />

          <div className="wrapper-boxs">
            <div className="right-box-rows">
              <div>
                <div className="right-box-row">
                  <div className="right-box-row-title">
                    <img src={bera_ic} alt="bera_ic" />
                    {nativeTokenSymbol} ({wrappedTokenSymbol})
                  </div>
                  <div className="right-box-row-value">
                    {processedData.totalNativeTokenRewards ? (
                      <div className="value native-token-reward">
                        <Tooltip
                          position={`${isMobile ? "right-bottom" : "left-bottom"}`}
                          className="nowrap"
                          handle={
                            <>
                              {formatKeyAmount(processedData, "totalNativeTokenRewards", 18, 4, true)}{" "}
                              <span class="reward-dollar">
                                $
                                {formatNumber(
                                  formatKeyAmount(
                                    processedData,
                                    "totalNativeTokenRewardsUsd",
                                    USD_DECIMALS,
                                    USD_DECIMALS
                                  )
                                )}
                              </span>
                            </>
                          }
                          renderContent={() => (
                            <>
                              <StatsTooltipRow
                                label={`NAVI & esNAVI`}
                                showDollar={false}
                                value={`${formatKeyAmount(
                                  processedData,
                                  "feeNaviTrackerRewards",
                                  18,
                                  4,
                                  true
                                )} ($${formatNumber(
                                  formatKeyAmount(processedData, "feeNaviTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                                )})`}
                              />
                              <StatsTooltipRow
                                label={`NLP`}
                                showDollar={false}
                                value={`${formatKeyAmount(
                                  processedData,
                                  "feeNlpTrackerRewards",
                                  18,
                                  4,
                                  true
                                )} ($${formatNumber(
                                  formatKeyAmount(processedData, "feeNlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                                )})`}
                              />
                              <StatsTooltipRow
                                label="NSLP"
                                showDollar={false}
                                value={`${formatKeyAmount(
                                  processedData,
                                  "feeNslpTrackerRewards",
                                  18,
                                  4,
                                  true
                                )} ($${formatNumber(
                                  formatKeyAmount(processedData, "feeNslpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                                )})`}
                              />
                            </>
                          )}
                        />
                      </div>
                    ) : (
                      <div className="value">
                        <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="right-box-row">
                  <div className="right-box-row-title">
                    <img src={gum_ic} alt="gum_ic" />
                    NAVI
                  </div>
                  <div className="right-box-row-value">
                    {processedData.totalVesterRewards ? (
                      <div className="value native-token-reward">
                        <Tooltip
                          position={`${isMobile ? "right-bottom" : "left-bottom"}`}
                          className="nowrap"
                          handle={
                            <>
                              {formatKeyAmount(processedData, "totalVesterRewards", 18, 4, true)}{" "}
                              <span class="reward-dollar">
                                $
                                {formatNumber(
                                  formatKeyAmount(processedData, "totalVesterRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                                )}
                              </span>
                            </>
                          }
                          renderContent={() => (
                            <>
                              <StatsTooltipRow
                                label={`NAVI Vault`}
                                showDollar={false}
                                value={`${formatKeyAmount(
                                  processedData,
                                  "naviVesterRewards",
                                  18,
                                  4,
                                  true
                                )} ($${formatNumber(
                                  formatKeyAmount(processedData, "naviVesterRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                                )})`}
                              />
                              <StatsTooltipRow
                                label={`NLP Vault`}
                                showDollar={false}
                                value={`${formatKeyAmount(
                                  processedData,
                                  "nlpVesterRewards",
                                  18,
                                  4,
                                  true
                                )} ($${formatKeyAmount(processedData, "nlpVesterRewardsUsd", USD_DECIMALS, 2, true)})`}
                              />
                              <StatsTooltipRow
                                label="NSLP Vault"
                                showDollar={false}
                                value={`${formatKeyAmount(
                                  processedData,
                                  "nslpVesterRewards",
                                  18,
                                  4,
                                  true
                                )} ($${formatNumber(
                                  formatKeyAmount(processedData, "nslpVesterRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                                )})`}
                              />
                            </>
                          )}
                        />
                      </div>
                    ) : (
                      <div className="value">
                        <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="right-box-row">
                  <div className="right-box-row-title">
                    <img src={esgum_ic} alt="esgum_ic" />
                    esNAVI
                  </div>
                  <div className="right-box-row-value">
                    {processedData.totalEsNaviRewards ? (
                      <div className="value native-token-reward">
                        <Tooltip
                          position="right-bottom"
                          className="nowrap"
                          handle={
                            <>
                              {formatKeyAmount(processedData, "totalEsNaviRewards", 18, 4, true)}{" "}
                              <span class="reward-dollar">
                                $
                                {formatNumber(
                                  formatKeyAmount(processedData, "totalEsNaviRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                                )}
                              </span>
                            </>
                          }
                          renderContent={() => (
                            <>
                              <StatsTooltipRow
                                label={`NAVI & esNAVI`}
                                showDollar={false}
                                value={`${formatKeyAmount(
                                  processedData,
                                  "stakedNaviTrackerRewards",
                                  18,
                                  4,
                                  true
                                )} ($${formatNumber(
                                  formatKeyAmount(
                                    processedData,
                                    "stakedNaviTrackerRewardsUsd",
                                    USD_DECIMALS,
                                    USD_DECIMALS
                                  )
                                )})`}
                              />
                              <StatsTooltipRow
                                label={`NLP`}
                                showDollar={false}
                                value={`${formatKeyAmount(
                                  processedData,
                                  "stakedNlpTrackerRewards",
                                  18,
                                  4
                                )} ($${formatNumber(
                                  formatKeyAmount(
                                    processedData,
                                    "stakedNlpTrackerRewardsUsd",
                                    USD_DECIMALS,
                                    USD_DECIMALS
                                  )
                                )})`}
                              />
                              <StatsTooltipRow
                                label="NSLP"
                                showDollar={false}
                                value={`${formatKeyAmount(
                                  processedData,
                                  "stakedNslpTrackerRewards",
                                  18,
                                  4,
                                  true
                                )} ($${formatNumber(
                                  formatKeyAmount(
                                    processedData,
                                    "stakedNslpTrackerRewardsUsd",
                                    USD_DECIMALS,
                                    USD_DECIMALS
                                  )
                                )})`}
                              />
                            </>
                          )}
                        />
                      </div>
                    ) : (
                      <div className="value">
                        <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="right-box-row">
                  <div className="right-box-row-title">
                    <img src={multipoint_ic} alt="multipoint_ic" />
                    Multiplier Points
                  </div>
                  <div className="right-box-row-value">
                    {processedData?.bonusNaviTrackerRewards ? (
                      `${formatKeyAmount(processedData, "bonusNaviTrackerRewards", 18, 4, true)}`
                    ) : (
                      <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                {/* <div className="right-box-row">
              <div className="right-box-row-title">Staked Multiplier Points</div>
              <div className="right-box-row-value">
                {processedData?.bnNaviInFeeNavi ? (
                  `${formatKeyAmount(processedData, "bnNaviInFeeNavi", 18, 4, true)}`
                ) : (
                  <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                )}
              </div>
            </div> */}
                {/* <div className="right-box-row">
              <div className="right-box-row-title">Total</div>
              <div className="right-box-row-value">

              </div>
            </div> */}
              </div>
              <div className="actions">
                <button
                  className="default-btn"
                  disabled={!processedData?.totalRewardsUsd || processedData.totalRewardsUsd.eq(0)}
                  onClick={() => setIsCompoundModalVisible(true)}
                >
                  Compound
                </button>
                <button
                  className="view-btn border-btn"
                  onClick={() => setIsClaimModalVisible(true)}
                  disabled={!processedData?.totalRewardsUsd || processedData.totalRewardsUsd.eq(0)}
                >
                  Claim
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusNaviInFeeNavi={bonusNaviInFeeNavi}
      />
      <ClaimModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData?.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <CompoundModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
    </Wrap>
  );
};

const Wrap = styled.section`
  .Section-StakePaperHead {
    @media screen and (max-width: 700px) {
      padding: 0;
    }
  }
  .Section-StakePaperBody {
    @media screen and (max-width: 700px) {
      padding: 0;
    }
  }
  .wrapper-boxs {
    @media screen and (max-width: 700px) {
      padding: 0;
    }
  }
`;

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active &&
      stakingTokenAddress && [
        `allowance:${active}:${account}:${farmAddress}`,
        chainId,
        stakingTokenAddress,
        "allowance",
        account,
        farmAddress,
      ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );
  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: `Stake submitted!`,
      failMsg: `Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return `Staking...`;
    }
    return `Stake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Stake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{stakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {stakingTokenSymbol === "esGMY" && chainId !== ARBITRUM && chainId !== BASE ? (
          <Warning>
            Please note that your staked esNAVI amount includes the esNAVI bonus from minting NFTs. Keep in mind that if
            you decide to unstake and vest esNAVI to NAVI later, you will be required to deposit a reserve amount of
            NAVI that corresponds to the rewards generated.
          </Warning>
        ) : null}

        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}
function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusNaviInFeeNavi,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusNaviInFeeNavi &&
    bonusNaviInFeeNavi.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusNaviInFeeNavi);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusNaviInFeeNavi) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusNaviInFeeNavi);
  }

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: `Unstake submitted!`,
      failMsg: `Unstake failed.`,
      successMsg: `Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }

    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return `Unstaking...`;
    }
    return `Unstake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Unstake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{unstakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            Unstaking will burn&nbsp;
            <ExternalLink href="https://docs.navigator.exchange/rewards">
              {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
            </ExternalLink>
            .&nbsp;
            {shouldShowReductionAmount && (
              <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            )}
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}

const Warning = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: #828899;
  line-height: 19.6px;
`;

const StyledTokenSymbol = styled.div`
  font-size: 16px !important;
`;

const StyledInput = styled.input`
  font-weight: 700 !important;
`;
const StyledSectionTop = styled.div`
  > div {
    font-weight: 400 !important;
  }
`;
const ModalWrapper = styled.div`
  .Modal-body {
    padding-left: 24px !important;
    padding-right: 24px !important;

    @media (max-width: 767px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 16px !important;
    }
  }
`;

function ClaimModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    library,
    chainId,
    setPendingTxns,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isClaiming, setIsClaiming] = useState(false);
  const [shouldClaimNavi, setShouldClaimNavi] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-navi"],
    true
  );
  const [shouldClaimEsNavi, setShouldClaimEsNavi] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-es-navi"],
    true
  );
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-convert-weth"],
    true
  );

  const isPrimaryEnabled = () => {
    return !isClaiming;
  };

  const getPrimaryText = () => {
    if (isClaiming) {
      return `Claiming...`;
    }
    return `Claim`;
  };

  const onClickPrimary = () => {
    setIsClaiming(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimNavi,
        false, // shouldStakeNavi
        shouldClaimEsNavi,
        false, // shouldStakeEsNavi
        false, // shouldStakeMultiplierPoints
        shouldClaimWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Claim submitted.`,
        failMsg: `Claim failed.`,
        successMsg: `Claim completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsClaiming(false);
      });
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={`Claim General Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox isChecked={shouldClaimNavi} setIsChecked={setShouldClaimNavi}>
              <span>Claim NAVI Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsNavi} setIsChecked={setShouldClaimEsNavi}>
              <span>Claim esNAVI Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <span>Claim {wrappedTokenSymbol} Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <span>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
function CompoundModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    active,
    account,
    library,
    chainId,
    setPendingTxns,
    totalVesterRewards,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isCompounding, setIsCompounding] = useState(false);
  // const shouldClaimNavi = false; // delete here
  // const shouldStakeNavi = false; // delete here

  // const [shouldClaimNavitest,setShouldClaimNavi] = useLocalStorageSerializeKey([chainId, "StakeV2-compound-should-claim-navi"], true); // delete here
  // const [shouldStakeNavitest,setShouldStakeNavi] = useLocalStorageSerializeKey([chainId, "StakeV2-compound-should-stake-navi"], true); // delete here

  // open here //

  const [shouldClaimNavi, setShouldClaimNavi] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-navi"],
    true
  );
  const [shouldStakeNavi, setShouldStakeNavi] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-navi"],
    true
  );

  // open here //

  const [shouldClaimEsNavi, setShouldClaimEsNavi] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-es-navi"],
    true
  );
  const [shouldStakeEsNavi, setShouldStakeEsNavi] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-es-navi"],
    true
  );
  const [shouldStakeMultiplierPoints, setShouldStakeMultiplierPoints] = useState(true);
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-convert-weth"],
    true
  );

  const naviAddress = getContract(chainId, "NAVI");
  const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");

  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, naviAddress, "allowance", account, stakedNaviTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const needApproval = shouldStakeNavi && tokenAllowance && totalVesterRewards && totalVesterRewards.gt(tokenAllowance);

  const isPrimaryEnabled = () => {
    return !isCompounding && !isApproving && !isCompounding;
  };

  const getPrimaryText = () => {
    if (isApproving) {
      return `Approving NAVI...`;
    }
    if (needApproval) {
      return `Approve NAVI`;
    }
    if (isCompounding) {
      return `Compounding...`;
    }
    return `Compound`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: naviAddress,
        spender: stakedNaviTrackerAddress,
        chainId,
      });
      return;
    }

    setIsCompounding(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimNavi || shouldStakeNavi,
        shouldStakeNavi,
        shouldClaimEsNavi || shouldStakeEsNavi,
        shouldStakeEsNavi,
        shouldStakeMultiplierPoints,
        shouldClaimWeth || shouldConvertWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Compound submitted!`,
        failMsg: `Compound failed.`,
        successMsg: `Compound completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsCompounding(false);
      });
  };

  const toggleShouldStakeNavi = (value) => {
    if (value) {
      setShouldClaimNavi(true);
    }
    setShouldStakeNavi(value);
  };

  const toggleShouldStakeEsNavi = (value) => {
    if (value) {
      setShouldClaimEsNavi(true);
    }
    setShouldStakeEsNavi(value);
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };
  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={`Compound General Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox
              isChecked={shouldStakeMultiplierPoints}
              setIsChecked={setShouldStakeMultiplierPoints}
              disabled={true}
            >
              <span>Stake Multiplier Points</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimNavi} setIsChecked={setShouldClaimNavi} disabled={shouldStakeNavi}>
              <span>Claim NAVI Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeNavi} setIsChecked={toggleShouldStakeNavi}>
              <span>Stake NAVI Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsNavi} setIsChecked={setShouldClaimEsNavi} disabled={shouldStakeEsNavi}>
              <span>Claim esNAVI Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeEsNavi} setIsChecked={toggleShouldStakeEsNavi}>
              <span>Stake esNAVI Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <span>Claim {wrappedTokenSymbol} Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <span>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default StakeSection;
