import { useWeb3React } from "@web3-react/core";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Tooltip from "components/Tooltip/Tooltip";
import { getContract } from "config/contracts";
import gum_ic from "img/gum.svg";
import nlp_ic from "img/nlp.svg";
import nslp_ic from "img/nslp.svg";
import { useChainId } from "lib/chains";
import { callContract } from "lib/contracts";
import { bigNumberify, formatAmount, formatAmountFree, formatKeyAmount, parseValue } from "lib/numbers";
import { useEffect, useState } from "react";

import Vester from "abis/Vester.json";
import SEO from "components/Common/SEO";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Modal from "components/Modal/Modal";
import { ethers } from "ethers";
import { helperToast } from "lib/helperToast";
import { getPageTitle } from "lib/legacy";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
const VestSection = ({ vestingData, totalRewardTokens, processedData, setPendingTxns }) => {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  const [isVesterDepositModalVisible, setIsVesterDepositModalVisible] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [vesterDepositTitle, setVesterDepositTitle] = useState("");
  const [vesterDepositStakeTokenLabel, setVesterDepositStakeTokenLabel] = useState("");
  const [vesterDepositMaxAmount, setVesterDepositMaxAmount] = useState("");

  const [vesterDepositBalance, setVesterDepositBalance] = useState("");
  const [vesterDepositEscrowedBalance, setVesterDepositEscrowedBalance] = useState("");
  const [vesterDepositVestedAmount, setVesterDepositVestedAmount] = useState("");
  const [vesterDepositAverageStakedAmount, setVesterDepositAverageStakedAmount] = useState("");
  const [vesterDepositMaxVestableAmount, setVesterDepositMaxVestableAmount] = useState("");
  const [vesterDepositValue, setVesterDepositValue] = useState("");
  const [vesterDepositReserveAmount, setVesterDepositReserveAmount] = useState("");
  const [vesterDepositMaxReserveAmount, setVesterDepositMaxReserveAmount] = useState("");
  const [vesterDepositAddress, setVesterDepositAddress] = useState("");

  const [isVesterWithdrawModalVisible, setIsVesterWithdrawModalVisible] = useState(false);
  const [vesterWithdrawTitle, setVesterWithdrawTitle] = useState(false);
  const [vesterWithdrawAddress, setVesterWithdrawAddress] = useState("");
  const [fromVesterAddresssNLP, setFromVesterAddresssNLP] = useState("");
  const [fromVesterAddresssNAVI, setFromVesterAddresssNAVI] = useState("");
  const [isMigrateNAVIEnabled, setIsMigrateNAVIEnabled] = useState(false);
  const [isMigrateNLPEnabled, setIsMigrateNLPEnabled] = useState(false);

  const naviVesterAddress = getContract(chainId, "NaviVester");
  const nlpVesterAddress = getContract(chainId, "NlpVester");
  const nslpVesterAddress = getContract(chainId, "NslpVester");

  // const naviVesterAddressV1 = getContract(chainId, "NaviVesterV1");
  // const nlpVesterAddressV1 = getContract(chainId, "NlpVesterV1");

  // const migrateVesterAddress = getContract(chainId, "MigrateVester");

  useEffect(() => {
    switch (naviVesterAddress) {
      case "0x8eF5634F5C6Ef625302Ab6e4C424Dbe6B59496C0":
        setFromVesterAddresssNAVI("0xa1a65D3639A1EFbFB18C82003330a4b1FB620C5a");
        break;
      case "0x4F697DeDF2b3862CA43493c33589F42CFEF66026":
        setFromVesterAddresssNAVI("0x2A3E489F713ab6F652aF930555b5bb3422711ac1");
        break;
      default:
        break;
    }
    switch (nlpVesterAddress) {
      case "0xDdeaC5Dc3A5352cCa9dD13C3067Cbbdbe2A43700":
        setFromVesterAddresssNLP("0x2A3E489F713ab6F652aF930555b5bb3422711ac1");
        break;
      case "0xA25d432ae720e57e85778905935A9A0B9284114A":
        setFromVesterAddresssNLP("0xb54AbE3FEC8e1aE64620185A1E111Aa0c3a81542");
        break;
      default:
        break;
    }
  }, [nlpVesterAddress, naviVesterAddress, setFromVesterAddresssNLP, setFromVesterAddresssNAVI]);

  // const { data: hasCalledMigrateNLP } = useSWR(
  //   [
  //     `StakeV2:hasCalledMigrateNLP:${[active, fromVesterAddresssNLP, account]}`,
  //     chainId,
  //     migrateVesterAddress,
  //     "hasCalledMigrate",
  //     account,
  //     fromVesterAddresssNLP,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, MigrateVester),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: hasCalledMigrateNAVI } = useSWR(
  //   [
  //     `StakeV2:hasCalledMigrateNAVI:${[active, fromVesterAddresssNAVI, account]}`,
  //     chainId,
  //     migrateVesterAddress,
  //     "hasCalledMigrate",
  //     account,
  //     fromVesterAddresssNAVI,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, MigrateVester),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: vestedNAVIAmountV1 } = useSWR(
  //   [`StakeV2:vestedNAVIAmountV1:${[active, account]}`, chainId, naviVesterAddressV1, "getVestedAmount", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: vestedNLPAmountV1 } = useSWR(
  //   [`StakeV2:vestedNLPAmountV1:${[active, account]}`, chainId, nlpVesterAddressV1, "getVestedAmount", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: vestedNAVIAmountV1Fantom } = useSWR(
  //   [
  //     `StakeV2:vestedNAVIAmountV1Fantom:${[active, account]}`,
  //     chainId,
  //     naviVesterAddressV1,
  //     "getMaxVestableAmount",
  //     account,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: vestedNLPAmountV1Fantom } = useSWR(
  //   [
  //     `StakeV2:vestedNLPAmountV1Fantom:${[active, account]}`,
  //     chainId,
  //     nlpVesterAddressV1,
  //     "getMaxVestableAmount",
  //     account,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: vestedNAVIAmountV1OP } = useSWR(
  //   [`StakeV2:vestedNAVIAmountV1OP:${[active, account]}`, chainId, naviVesterAddressV1, "bonusRewards", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: vestedNLPAmountV1OP } = useSWR(
  //   [`StakeV2:vestedNLPAmountV1OP:${[active, account]}`, chainId, nlpVesterAddressV1, "bonusRewards", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );

  // useEffect(() => {
  //   if (chainId === FANTOM) {
  //     if (hasCalledMigrateNLP === false && vestedNLPAmountV1Fantom && vestedNLPAmountV1Fantom.gt(0))
  //       setIsMigrateNLPEnabled(true);
  //   }
  //   if (chainId === OP) {
  //     if (hasCalledMigrateNLP === false && vestedNLPAmountV1OP && vestedNLPAmountV1OP.gt(0))
  //       setIsMigrateNLPEnabled(true);
  //   }
  //   if (hasCalledMigrateNLP && hasCalledMigrateNLP === true) {
  //     setIsMigrateNLPEnabled(false);
  //   }
  // }, [
  //   chainId,
  //   hasCalledMigrateNLP,
  //   vestedNLPAmountV1,
  //   vestedNLPAmountV1Fantom,
  //   vestedNLPAmountV1OP,
  //   vestingData?.nlpVester?.vestedAmount,
  // ]);

  // useEffect(() => {
  //   if (chainId === FANTOM) {
  //     if (hasCalledMigrateNAVI === false && vestedNAVIAmountV1Fantom && vestedNAVIAmountV1Fantom.gt(0))
  //       setIsMigrateNAVIEnabled(true);
  //   }
  //   if (chainId === OP) {
  //     if (hasCalledMigrateNAVI === false && vestedNAVIAmountV1OP && vestedNAVIAmountV1OP.gt(0))
  //       setIsMigrateNAVIEnabled(true);
  //   }
  //   if (hasCalledMigrateNAVI === true) {
  //     setIsMigrateNAVIEnabled(false);
  //   }
  // }, [
  //   chainId,
  //   hasCalledMigrateNAVI,
  //   vestedNAVIAmountV1,
  //   vestedNAVIAmountV1Fantom,
  //   vestedNAVIAmountV1OP,
  //   vestingData?.naviVester?.vestedAmount,
  // ]);

  const showNaviVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.naviVester.maxVestableAmount.sub(vestingData.naviVester.vestedAmount);
    if (processedData.esNaviBalance && processedData.esNaviBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esNaviBalance;
    }
    setVesterDepositAddress(naviVesterAddress);
    setVesterDepositTitle(`NAVI Vault`);
    //TODO off setIsMigrateNLPEnabled
    if (Date.now() < 1692180000000 && isMigrateNAVIEnabled) {
      setVesterDepositTitle(`Migrate NAVI Vault`);
    } else {
      setIsVesterDepositModalVisible(true);
    }

    setVesterDepositStakeTokenLabel("staked NAVI + esNAVI + Multiplier Points");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esNaviBalance);
    setVesterDepositEscrowedBalance(vestingData.naviVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.naviVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.naviVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.naviVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.naviVester.pairAmount);
    setVesterDepositMaxReserveAmount(totalRewardTokens);
    setVesterDepositValue("");
  };
  const showNlpVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.nlpVester.maxVestableAmount.sub(vestingData.nlpVester.vestedAmount);
    if (processedData.esNaviBalance && processedData.esNaviBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esNaviBalance;
    }
    setVesterDepositTitle(`NLP Vault`);
    setVesterDepositAddress(nlpVesterAddress);
    if (Date.now() < 1692180000000 && isMigrateNLPEnabled) {
      setVesterDepositTitle(`Migrate NLP Vault`);
    } else {
      setIsVesterDepositModalVisible(true);
    }
    setVesterDepositStakeTokenLabel("staked NLP");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esNaviBalance);
    setVesterDepositEscrowedBalance(vestingData.nlpVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.nlpVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.nlpVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.nlpVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.nlpVester.pairAmount);
    setVesterDepositMaxReserveAmount(processedData.nlpBalance);
    setVesterDepositValue("");
  };

  const showNslpVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.nslpVester.maxVestableAmount.sub(vestingData.nslpVester.vestedAmount);
    if (processedData.esNaviBalance && processedData.esNaviBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esNaviBalance;
    }
    setVesterDepositTitle(`NSLP Vault`);
    setVesterDepositAddress(nslpVesterAddress);
    if (Date.now() < 1692180000000 && isMigrateNLPEnabled) {
      setVesterDepositTitle(`Migrate NSLP Vault`);
    } else {
      setIsVesterDepositModalVisible(true);
    }
    setVesterDepositStakeTokenLabel("staked NSLP");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esNaviBalance);
    setVesterDepositEscrowedBalance(vestingData.nslpVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.nslpVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.nslpVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.nslpVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.nslpVester.pairAmount);
    setVesterDepositMaxReserveAmount(processedData.nslpBalance);
    setVesterDepositValue("");
  };
  const showNaviVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.naviVesterVestedAmount || vestingData.naviVesterVestedAmount.eq(0)) {
      helperToast.error(`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(`Withdraw from NAVI Vault`);
    setVesterWithdrawAddress(naviVesterAddress);
  };
  const showNlpVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.nlpVesterVestedAmount || vestingData.nlpVesterVestedAmount.eq(0)) {
      helperToast.error(`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(`Withdraw from NLP Vault`);
    setVesterWithdrawAddress(nlpVesterAddress);
  };

  const showNslpVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.nslpVesterVestedAmount || vestingData.nslpVesterVestedAmount.eq(0)) {
      helperToast.error(`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(`Withdraw from NAVI Vault`);
    setVesterWithdrawAddress(nslpVesterAddress);
  };
  const history = useHistory();

  // useEffect(() => {
  //   if (history.location.hash.includes("#vest")) {
  //     const element = document.getElementById("vest");
  //     if (!element) return;
  //     window.scroll({
  //       top: element.offsetTop - 20,
  //       behavior: "smooth",
  //     });
  //   }
  // }, history.location.hash);

  return (
    <Wrapper className="Earn-Section">
      <h1 className="Section-Title" id="vest">
        Vest
      </h1>
      <p className="Section-Desc">
        Convert esNAVI tokens to NAVI tokens. Please read the{" "}
        <ExternalLink href="https://docs.navigator.exchange/rewards#vesting">vesting details</ExternalLink> before using the
        vaults.
      </p>

      <div className="Section-TriplePaper">
        <div className="Section-SmallPaper">
          <div className="Section-SmallPaperHead">
            <img src={gum_ic} alt="gum_ic" />
            NAVI Vault
          </div>
          <div className="Section-SmallPaperBody">
            <div className="Section-SmallPaperBodyBottom">
              <div className="Section-SmallPaperBodyBottomItemRows">
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Staked Tokens
                  {totalRewardTokens ? (
                    <Tooltip
                      handle={<h5>{formatAmount(totalRewardTokens, 18, 2, true)}</h5>}
                      position={`${isMobile ? "right-bottom" : "left-bottom"}`}
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              showDollar={false}
                              label="NAVI"
                              value={formatAmount(processedData.naviInStakedNavi, 18, 2, true)}
                            />
                            <StatsTooltipRow
                              showDollar={false}
                              label="esNAVI"
                              value={formatAmount(processedData.esNaviInStakedNavi, 18, 2, true)}
                            />
                            <StatsTooltipRow
                              showDollar={false}
                              label="Multiplier Points"
                              value={formatAmount(processedData.bnNaviInFeeNavi, 18, 2, true)}
                            />
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Reserved For Vesting
                  {vestingData?.naviVesterPairAmount ? (
                    <h5>
                      {formatKeyAmount(vestingData, "naviVesterPairAmount", 18, 2, true)} /{" "}
                      {formatAmount(totalRewardTokens, 18, 2, true)}
                    </h5>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Vesting Status
                  {vestingData?.naviVesterClaimSum ? (
                    <Tooltip
                      handle={
                        <h5>
                          {formatKeyAmount(vestingData, "naviVesterClaimSum", 18, 4, true)} /{" "}
                          {formatKeyAmount(vestingData, "naviVesterVestedAmount", 18, 4, true)}
                        </h5>
                      }
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: "140%",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "naviVesterClaimSum", 18, 4, true)}
                              </span>{" "}
                              tokens have been converted to NAVI from the{" "}
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "naviVesterVestedAmount", 18, 4, true)} esNAVI
                              </span>{" "}
                              deposited for vesting.
                            </span>
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Claimable
                  {vestingData?.naviVesterClaimable ? (
                    <Tooltip
                      position="right-bottom"
                      handle={<h5>{formatKeyAmount(vestingData, "naviVesterClaimable", 18, 4, true)} NAVI</h5>}
                      renderContent={() => (
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: "140%",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: 14,
                              lineHeight: "140%",
                            }}
                          >
                            {formatKeyAmount(vestingData, "naviVesterClaimable", 18, 4, true)} NAVI
                          </span>{" "}
                          tokens can be claimed, use the options under the Total Rewards section to claim them.
                        </span>
                      )}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="Section-SmallPaperBodyBottomActions">
                <button className="default-btn" onClick={showNaviVesterDepositModal}>
                  Deposit
                </button>
                <button className="border-btn" onClick={showNaviVesterWithdrawModal}>
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="Section-SmallPaper">
          <div className="Section-SmallPaperHead">
            <img src={nlp_ic} alt="nlp_ic" />
            NLP Vault
          </div>
          <div className="Section-SmallPaperBody">
            <div className="Section-SmallPaperBodyBottom">
              <div className="Section-SmallPaperBodyBottomItemRows">
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Staked Tokens
                  {processedData.nlpBalance ? (
                    <h5>{formatAmount(processedData.nlpBalance, 18, 2, true)} NLP</h5>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Reserved For Vesting
                  {vestingData?.nlpVesterPairAmount ? (
                    <h5>
                      {formatKeyAmount(vestingData, "nlpVesterPairAmount", 18, 2, true)} /{" "}
                      {formatAmount(processedData.nlpBalance, 18, 2, true)}
                    </h5>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Vesting Status
                  {vestingData?.nlpVesterClaimSum ? (
                    <Tooltip
                      handle={
                        <h5>
                          {formatKeyAmount(vestingData, "nlpVesterClaimSum", 18, 4, true)} /{" "}
                          {formatKeyAmount(vestingData, "nlpVesterVestedAmount", 18, 4, true)}
                        </h5>
                      }
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: "140%",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "nlpVesterClaimSum", 18, 4, true)}
                              </span>{" "}
                              tokens have been converted to NAVI from the{" "}
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "nlpVesterVestedAmount", 18, 4, true)} esNAVI
                              </span>{" "}
                              deposited for vesting.
                            </span>
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Claimable
                  {vestingData?.nlpVesterClaimable ? (
                    <Tooltip
                      position="right-bottom"
                      handle={<h5>{formatKeyAmount(vestingData, "nlpVesterClaimable", 18, 4, true)} NAVI</h5>}
                      renderContent={() => (
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: "140%",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: 14,
                              lineHeight: "140%",
                            }}
                          >
                            {formatKeyAmount(vestingData, "nlpVesterClaimable", 18, 4, true)} NAVI
                          </span>{" "}
                          tokens can be claimed, use the options under the Total Rewards section to claim them.
                        </span>
                      )}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="Section-SmallPaperBodyBottomActions">
                <button className="default-btn" onClick={showNlpVesterDepositModal}>
                  Deposit
                </button>
                <button className="border-btn" onClick={showNlpVesterWithdrawModal}>
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="Section-SmallPaper">
          <div className="Section-SmallPaperHead">
            <img src={nslp_ic} alt="nslp_ic" />
            NSLP Vault
          </div>
          <div className="Section-SmallPaperBody">
            <div className="Section-SmallPaperBodyBottom">
              <div className="Section-SmallPaperBodyBottomItemRows">
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Staked Tokens
                  <h5>
                    {processedData.nslpBalance ? (
                      <h5>{formatAmount(processedData.nslpBalance, 18, 2, true)} NSLP</h5>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    )}
                  </h5>
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Reserved For Vesting
                  <h5>
                    {vestingData?.nslpVesterPairAmount ? (
                      <h5>
                        {formatKeyAmount(vestingData, "nslpVesterPairAmount", 18, 2, true)} /{" "}
                        {formatAmount(processedData.nslpBalance, 18, 2, true)}
                      </h5>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    )}
                  </h5>
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Vesting Status
                  {vestingData?.nslpVesterClaimSum ? (
                    <Tooltip
                      handle={
                        <h5>
                          {formatKeyAmount(vestingData, "nslpVesterClaimSum", 18, 4, true)} /{" "}
                          {formatKeyAmount(vestingData, "nslpVesterVestedAmount", 18, 4, true)}
                        </h5>
                      }
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: "140%",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "nslpVesterClaimSum", 18, 4, true)}
                              </span>{" "}
                              tokens have been converted to NAVI from the{" "}
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "nslpVesterVestedAmount", 18, 4, true)} esNAVI
                              </span>{" "}
                              deposited for vesting.
                            </span>
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
                <div className="Section-SmallPaperBodyBottomItemRow">
                  Claimable
                  {vestingData?.nlpVesterClaimable ? (
                    <Tooltip
                      position="right-bottom"
                      handle={<h5>0.0000 NAVI</h5>}
                      renderContent={() => (
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: "140%",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: 14,
                              lineHeight: "140%",
                            }}
                          >
                            0.0000 NAVI
                          </span>{" "}
                          tokens can be claimed, use the options under the Total Rewards section to claim them.
                        </span>
                      )}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="Section-SmallPaperBodyBottomActions">
                <button className="default-btn" onClick={showNslpVesterDepositModal}>
                  Deposit
                </button>
                <button className="border-btn" onClick={showNslpVesterWithdrawModal}>
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VesterDepositModal
        isVisible={isVesterDepositModalVisible}
        setIsVisible={setIsVesterDepositModalVisible}
        chainId={chainId}
        title={vesterDepositTitle}
        stakeTokenLabel={vesterDepositStakeTokenLabel}
        maxAmount={vesterDepositMaxAmount}
        balance={vesterDepositBalance}
        escrowedBalance={vesterDepositEscrowedBalance}
        vestedAmount={vesterDepositVestedAmount}
        averageStakedAmount={vesterDepositAverageStakedAmount}
        maxVestableAmount={vesterDepositMaxVestableAmount}
        reserveAmount={vesterDepositReserveAmount}
        maxReserveAmount={vesterDepositMaxReserveAmount}
        value={vesterDepositValue}
        setValue={setVesterDepositValue}
        library={library}
        vesterAddress={vesterDepositAddress}
        setPendingTxns={setPendingTxns}
      />
      <VesterWithdrawModal
        isVisible={isVesterWithdrawModalVisible}
        setIsVisible={setIsVesterWithdrawModalVisible}
        vesterAddress={vesterWithdrawAddress}
        chainId={chainId}
        title={vesterWithdrawTitle}
        library={library}
        setPendingTxns={setPendingTxns}
      />
    </Wrapper>
  );
};

function VesterWithdrawModal(props) {
  const { isVisible, setIsVisible, chainId, title, library, vesterAddress, setPendingTxns } = props;
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const onClickPrimary = () => {
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: `Withdraw submitted.`,
      failMsg: `Withdraw failed.`,
      successMsg: `Withdrawn!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <span>
          <div>
            This will withdraw and unreserve all tokens as well as pause vesting.
            <br />
            <br />
            esNAVI tokens that have been converted to NAVI will remain as NAVI tokens.
            <br />
            <br />
            To claim NAVI tokens without withdrawing, use the "Claim" button under the General Rewards section.
            <br />
            <br />
          </div>
        </span>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={isWithdrawing}>
            {!isWithdrawing && "Confirm Withdraw"}
            {isWithdrawing && "Confirming..."}
          </button>
        </div>
      </Modal>
    </div>
  );
}
function VesterDepositModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    balance,
    vestedAmount,
    averageStakedAmount,
    maxVestableAmount,
    library,
    stakeTokenLabel,
    reserveAmount,
    maxReserveAmount,
    vesterAddress,
    setPendingTxns,
  } = props;
  const [isDepositing, setIsDepositing] = useState(false);

  let amount = parseValue(value, 18);

  let nextReserveAmount = reserveAmount;

  let nextDepositAmount = vestedAmount;
  if (amount) {
    nextDepositAmount = vestedAmount.add(amount);
  }

  let additionalReserveAmount = bigNumberify(0);
  if (amount && averageStakedAmount && maxVestableAmount && maxVestableAmount.gt(0)) {
    nextReserveAmount = nextDepositAmount.mul(averageStakedAmount).div(maxVestableAmount);
    if (nextReserveAmount.gt(reserveAmount)) {
      additionalReserveAmount = nextReserveAmount.sub(reserveAmount);
    }
  }

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
    if (nextReserveAmount.gt(maxReserveAmount)) {
      return `Insufficient staked tokens`;
    }
  };

  const onClickPrimary = () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: `Deposit submitted!`,
      failMsg: `Deposit failed!`,
      successMsg: `Deposited!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isDepositing) {
      return `Depositing...`;
    }
    return `Deposit`;
  };

  return (
    <SEO title={getPageTitle("Earn")}>
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title} className="non-scrollable">
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">
                  <span>Deposit</span>
                </div>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}
              >
                <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">esNAVI</div>
            </div>
          </div>
          <div className="VesterDepositModal-info-rows">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Wallet</span>
              </div>
              <div className="align-right">{formatAmount(balance, 18, 2, true)} esNAVI</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Vault Capacity</span>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(nextDepositAmount, 18, 2, true)} / ${formatAmount(
                    maxVestableAmount,
                    18,
                    2,
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div>
                        <p className="text-white" style={{ marginBottom: "8px" }}>
                          <span>Vault Capacity for your Account:</span>
                        </p>
                        <StatsTooltipRow
                          showDollar={false}
                          label={`Deposited`}
                          value={`${formatAmount(vestedAmount, 18, 2, true)} esNAVI`}
                        />
                        <StatsTooltipRow
                          showDollar={false}
                          label={`Max Capacity`}
                          value={`${formatAmount(maxVestableAmount, 18, 2, true)} esNAVI`}
                        />
                      </div>
                    );
                  }}
                  className="excep"
                />
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Reserve Amount</span>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(
                    reserveAmount && reserveAmount.gte(additionalReserveAmount)
                      ? reserveAmount
                      : additionalReserveAmount,
                    18,
                    2,
                    true
                  )} / ${formatAmount(maxReserveAmount, 18, 2, true)}`}
                  position={amount && nextReserveAmount.gt(maxReserveAmount) ? "right-top" : "right-bottom"}
                  renderContent={() => {
                    return (
                      <>
                        <StatsTooltipRow
                          label={`Current Reserved`}
                          value={formatAmount(reserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={`Additional Reserve Required`}
                          value={formatAmount(additionalReserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        {amount && nextReserveAmount.gt(maxReserveAmount) && (
                          <>
                            <br />
                            <span>
                              You need a total of at least {formatAmount(nextReserveAmount, 18, 2, true)}{" "}
                              {stakeTokenLabel} to vest {formatAmount(amount, 18, 2, true)} esNAVI.
                            </span>
                          </>
                        )}
                      </>
                    );
                  }}
                  className="excep"
                />
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      </div>
    </SEO>
  );
}

const Wrapper = styled.div`
  .Section-SmallPaperBodyBottom {
    @media screen and (max-width: 1024px) and (min-width: 700px) {
      .Section-SmallPaperBodyBottomItemRows {
        flex-direction: row;

        gap: 12px;
        > div {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: flex-start;
        }
      }
    }
  }
`;
export default VestSection;
