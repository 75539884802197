import { PoolFrame } from "pages/Earn/styles";

import { useWeb3React } from "@web3-react/core";
import Footer from "components/Footer/Footer";
import { getContract } from "config/contracts";
import { getNativeToken, getTokens } from "config/tokens";
import { getTokenInfo, useInfoTokens } from "domain/tokens";
import { ethers } from "ethers";
import nlp_ic from "img/nlp.svg";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import {
  BASIS_POINTS_DIVISOR,
  NLP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  SECONDS_PER_YEAR,
  USD_DECIMALS,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
} from "lib/legacy";
import {
  bigNumberify,
  expandDecimals,
  formatAddKeysAmount,
  formatAmount,
  formatKeyAmount,
  formatNumber,
  parseValue,
} from "lib/numbers";
import BuySellNlp from "pages/Earn/BuySellNlp";
import PoolCompositionNlp from "pages/Earn/PoolCompositionNlp";
import { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";

import NlpManager from "abis/NlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import RewardTracker from "abis/RewardTracker.json";
import Token from "abis/Token.json";
import Vault from "abis/Vault.json";
import VaultV2 from "abis/VaultV2.json";

import Tooltip from "components/Tooltip/Tooltip";
import { FANTOM } from "config/chains";
import { useTokenStats } from "dataProvider";
import { useNaviPrice } from "domain/legacy";
import { COINCOLORS, convertToPercents } from "lib/helper";
import GenericChart from "pages/Analytics/components/GenericChart";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";

const { AddressZero } = ethers.constants;

const Nlp = ({
  setPendingTxns,
  connectWallet,
  savedSelectedDexes,
  savedSlippageAmount,
  savedShouldDisableValidationForTesting,
}) => {
  const { active, library, account } = useWeb3React();
  const [nlpValue, setNlpValue] = useState("");
  const { chainId } = useChainId();
  const history = useHistory();
  const [isBuying, setIsBuying] = useState(true);

  const { naviPrice } = useNaviPrice(chainId, { arbitrum: chainId === FANTOM ? library : undefined }, active);

  const nativeTokenSymbol = getNativeToken(chainId).symbol;
  const tokens = getTokens(chainId);
  const readerAddress = getContract(chainId, "Reader");
  const stakedNlpTrackerAddress = getContract(chainId, "StakedNlpTracker");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const usdgAddress = getContract(chainId, "USDN");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const feeNlpTrackerAddress = getContract(chainId, "FeeNlpTracker");
  const vaultAddress = getContract(chainId, "Vault");
  const nlpManagerAddress = getContract(chainId, "NlpManager");

  const naviAddress = getContract(chainId, "NAVI");
  const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");
  const nslpAddress = getContract(chainId, "NSLP");
  const nlpAddress = getContract(chainId, "NLP");

  const esNaviAddress = getContract(chainId, "ES_NAVI");
  const bnNaviAddress = getContract(chainId, "BN_NAVI");
  const bonusNaviTrackerAddress = getContract(chainId, "BonusNaviTracker");
  const feeNaviTrackerAddress = getContract(chainId, "FeeNaviTracker");
  const feeNslpTrackerAddress = getContract(chainId, "FeeNslpTracker");

  const naviVesterAddress = getContract(chainId, "NaviVester");
  const nlpVesterAddress = getContract(chainId, "NlpVester");
  const nslpVesterAddress = getContract(chainId, "NslpVester");
  const stakedNslpTrackerAddress = getContract(chainId, "StakedNslpTracker");

  const tokensForBalanceAndSupplyQuery = [stakedNlpTrackerAddress, usdgAddress];
  const rewardTrackersForStakingInfo = [
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    stakedNlpTrackerAddress,
    feeNlpTrackerAddress,
    stakedNslpTrackerAddress,
    feeNslpTrackerAddress,
  ];

  const vesterAddresses = [naviVesterAddress, nlpVesterAddress, nslpVesterAddress];
  const walletTokens = [naviAddress, esNaviAddress, nlpAddress, stakedNaviTrackerAddress, nslpAddress];
  const depositTokens = [
    naviAddress,
    esNaviAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    bnNaviAddress,
    nlpAddress,
    nslpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedNaviTrackerAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNlpTrackerAddress,
    feeNslpTrackerAddress,
  ];

  const tokenAddresses = tokens.map((token) => token.address);

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash !== "redeem";
    setIsBuying(buying);
  }, [history.location.hash]);

  const { data: tokenBalances } = useSWR(
    [`Nlp:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
    }
  );

  const { data: balancesAndSupplies } = useSWR(
    [
      `Nlp:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForBalanceAndSupplyQuery]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `Nlp:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: nlpBalance } = useSWR(
    [`Nlp:nlpBalance:${active}`, chainId, feeNlpTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const { data: aums } = useSWR([`Nlp:getAums:${active}`, chainId, nlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, NlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`Nlp:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: walletBalances } = useSWR(
    [
      `Nlp:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `Nlp:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `Nlp:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );

  const { data: stakedNaviSupply } = useSWR(
    [
      `Nlp:stakedNaviSupply:${active}:${stakedNaviTrackerAddress}`,
      chainId,
      naviAddress,
      "balanceOf",
      stakedNaviTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const { data: totalTokenWeights } = useSWR(
    [`BuySellNlp:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  const tokensForSupplyQuery = [naviAddress, nlpAddress, usdgAddress, nslpAddress];
  const { data: totalSupplies } = useSWR(
    [
      `Nlp:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );

  let totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalSupply = totalSupplies[1];
  }

  let naviSupply = totalSupply;

  const usdgSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0);
  const nlpAmount = parseValue(nlpValue, NLP_DECIMALS);

  let aum;
  if (aums && aums.length > 0) {
    aum = isBuying ? aums[0] : aums[1];
  }

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const nlpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);

  const nlpPrice =
    aum && aum.gt(0) && nlpSupply.gt(0)
      ? aum.mul(expandDecimals(1, NLP_DECIMALS)).div(nlpSupply)
      : expandDecimals(1, USD_DECIMALS);

  const nlpSupplyUsd = nlpSupply.mul(nlpPrice).div(expandDecimals(1, NLP_DECIMALS));
  const stakingData = getStakingData(stakingInfo);
  const nativeToken = getTokenInfo(infoTokens, AddressZero);

  let nlpBalanceUsd;
  if (nlpBalance) {
    nlpBalanceUsd = nlpBalance.mul(nlpPrice).div(expandDecimals(1, NLP_DECIMALS));
  }

  let totalApr = bigNumberify(0);

  let feeNlpTrackerAnnualRewardsUsd;
  let feeNlpTrackerApr;
  if (
    stakingData &&
    stakingData.feeNlpTracker &&
    stakingData.feeNlpTracker.tokensPerInterval &&
    nativeToken &&
    nativeToken.minPrice &&
    nlpSupplyUsd &&
    nlpSupplyUsd.gt(0)
  ) {
    feeNlpTrackerAnnualRewardsUsd = stakingData.feeNlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(nativeToken.minPrice)
      .div(expandDecimals(1, 18));
    feeNlpTrackerApr = feeNlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(nlpSupplyUsd);
    totalApr = totalApr.add(feeNlpTrackerApr);
  }

  let stakedNlpTrackerAnnualRewardsUsd;
  let stakedNlpTrackerApr;

  if (
    naviPrice &&
    stakingData &&
    stakingData.stakedNlpTracker &&
    stakingData.stakedNlpTracker.tokensPerInterval &&
    nlpSupplyUsd &&
    nlpSupplyUsd.gt(0)
  ) {
    stakedNlpTrackerAnnualRewardsUsd = stakingData.stakedNlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(naviPrice)
      .div(expandDecimals(1, 18));
    stakedNlpTrackerApr = stakedNlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(nlpSupplyUsd);
    totalApr = totalApr.add(stakedNlpTrackerApr);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedNaviSupply,
    naviPrice,
    naviSupply
  );

  const [tokenStatsData, tokenStatsLoading] = useTokenStats({ from: getTime() });
  const poolChart = useMemo(() => {
    if (tokenStatsData) {
      const { poolAmountUsd, tokenSymbols } = tokenStatsData;
      if (poolAmountUsd && poolAmountUsd.length > 0) {
        let data = [];
        for (let i = 0; i < poolAmountUsd.length; i++) {
          const pool = poolAmountUsd[i];
          let info = { ...pool };
          for (let j = 0; j < tokenSymbols.length; j++) {
            const symbol = tokenSymbols[j];
            if (!info[symbol]) {
              if (data.length > 0) {
                const value = data[data.length - 1][symbol];
                info[symbol] = value;
                info["all"] = info["all"] + value;
              } else info[symbol] = 0;
            }
          }
          data.push(info);
        }
        const returnData = data.map(item => {
          delete item["0x309c92261178fa0cf748a855e90ae73fdb79ebc7"];
          return item;
        })

        return returnData;
      } else return [];
    } else return [];
  }, [tokenStatsData]);
  // console.log("?????", poolChart);

  const chartLegendItems =
    tokenStatsData && tokenStatsData.tokenSymbols
      ? tokenStatsData.tokenSymbols.map((token, i) => ({
        key: token,
        color: getTokenColor(i),
        fillOpacity: 0.5,
      }))
      : [];

  return (
    <PoolFrame>
      <div className="Pool-Head">
        <div className="Pool-HeadDetails">
          <h1>
            <img src={nlp_ic} alt="nlp_ic" />{" "}
            <div>
              Majors pool - NLP
              <p>Purchase NLP tokens to earn SONIC, esNAVI fees from swaps and leveraged trading.</p>
            </div>
          </h1>
        </div>
        <Claimbox>
          <div className="content">
            <span className="muted">Rewards:</span>
            <span>
              {" "}
              <Tooltip
                handle={`$${formatNumber(
                  formatAddKeysAmount(
                    processedData,
                    "feeNlpTrackerRewardsUsd",
                    "stakedNlpTrackerRewardsUsd",
                    USD_DECIMALS,
                    USD_DECIMALS
                  )
                )}`}
                position="right-bottom"
                tooltipIconPosition="right"
                renderContent={() => (
                  <>
                    <StatsTooltipRow
                      label={`esNAVI`}
                      showDollar={false}
                      value={`${formatKeyAmount(
                        processedData,
                        "stakedNlpTrackerRewards",
                        18,
                        4,
                        true
                      )} ($${formatNumber(
                        formatKeyAmount(processedData, "stakedNlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )})`}
                    />
                    <StatsTooltipRow
                      label={`S (WS)`}
                      showDollar={false}
                      value={`${formatKeyAmount(processedData, "feeNlpTrackerRewards", 18, 4, true)} ($${formatNumber(
                        formatKeyAmount(processedData, "feeNlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )})`}
                    />
                  </>
                )}
              />{" "}
            </span>
          </div>
          <Link to="/earn">
            <button className="default-btn">Claim</button>
          </Link>
        </Claimbox>
        {/* <div className="Pool-Links">
          <Link to="/earn" className="Pool-Link">
            Staking page
          </Link>
          <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
            <circle cx="2" cy="2" r="2" fill="#807E7C" />
          </svg>
          <button className="Pool-Link disabled">
            Docs <img src={green_arrow_ic} alt="green_arrow_ic" />
          </button>
        </div> */}
      </div>
      <div className="Pool-Papers">
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            APR
            {totalApr ? (
              <>
                <h5 className="positive">{formatAmount(totalApr || 0, 2, 2, true)}%</h5>
                {nativeTokenSymbol} {formatAmount(feeNlpTrackerApr || 0, 2, 2, false)}% <br className="br-mobile" />+ esNAVI{" "}
                {formatAmount(stakedNlpTrackerApr || 0, 2, 2, false)}%
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Supply
            {formatAmount(nlpSupply, NLP_DECIMALS, 4, true) !== "0.0000" ? (
              <>
                <h5>{formatAmount(nlpSupply, NLP_DECIMALS, 2, true)} NLP</h5>$
                {formatNumber(formatAmount(nlpSupplyUsd, USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Staked
            {processedData.nlpSupply ? (
              <>
                <h5>{formatKeyAmount(processedData, "nlpSupply", 18, 2, true)} NLP</h5> $
                {formatNumber(formatKeyAmount(processedData, "nlpSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
            )}
          </div>
        </div>
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            Your Staked
            {nlpBalance ? (
              <>
                <h5>{formatAmount(nlpBalance, NLP_DECIMALS, 2, true)} NLP</h5>
              </>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
            {nlpBalanceUsd ? (
              <>${formatNumber(formatAmount(nlpBalanceUsd, USD_DECIMALS, USD_DECIMALS))}</>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
          </div>
          <div className="Pool-PaperStat">
            Your Wallet
            {nlpBalance ? (
              <>
                <h5>{formatAmount(nlpBalance, NLP_DECIMALS, 2, true)} NLP</h5>$
                {formatNumber(formatAmount(nlpBalanceUsd, USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
        </div>
        <div className="Pool-Aggregate">
          <div className="Pool-Paper Pool-Chart pool-chart-nlp">
            <h3>Token Distribution</h3>
            {/* <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid opacity={0.1} />
                <XAxis dataKey="name" color="red" />
                <YAxis />
                <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
              </AreaChart>
            </ResponsiveContainer> */}
            <GenericChart
              syncId={"syncNlp"}
              loading={tokenStatsLoading}
              data={tokenStatsData ? poolChart : null}
              controls={{
                convertToPercents: convertToPercentsHandler,
              }}
              yaxisDataKey="all"
              items={chartLegendItems}
              type="Area"
              height={270}
              hideHeader
              tooltipWidth={150}
            />
          </div>
          <PoolCompositionNlp
            isBuying={isBuying}
            nlpAmount={nlpAmount}
            nlpPrice={nlpPrice}
            usdgSupply={usdgSupply}
            totalTokenWeights={totalTokenWeights}
          />
        </div>
        <div className="Pool-Paper Pool-Form">
          <BuySellNlp
            isBuying={isBuying}
            setPendingTxns={setPendingTxns}
            connectWallet={connectWallet}
            savedSelectedDexes={savedSelectedDexes}
            savedSlippageAmount={savedSlippageAmount}
            savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
            nlpValue={nlpValue}
            setNlpValue={setNlpValue}
            nlpAmount={nlpAmount}
            nlpPrice={nlpPrice}
            usdgSupply={usdgSupply}
            totalTokenWeights={totalTokenWeights}
          />
        </div>
      </div>
      <Footer />
    </PoolFrame>
  );
};

export default Nlp;

const convertToPercentsHandler = (data) => convertToPercents(data, { totalKey: "all", ignoreKeys: [] });

function getTokenColor(index) {
  return COINCOLORS[index % COINCOLORS.length];
}
const Claimbox = styled.div`
  border-radius: 16px;
  border: 1px solid var(--Primary, #90fb75);
  background: var(--Nature-1, #12141a);
  width: 424px;
  padding: var(--Base-Unit-XS-8, 8px) var(--Base-Unit-XS-8, 8px) var(--Base-Unit-XS-8, 8px) 16px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .content {
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    gap: 8px;
  }
`;
export const getTime = () => {
  const now = new Date();

  const twoWeeksAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14);

  twoWeeksAgo.setHours(0, 0, 0, 0);

  const timestamp = Math.floor(twoWeeksAgo.getTime() / 1000);
  return timestamp;
};
