import { useThemeContext } from "contexts/ThemeProvider";
import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  amount: string;
  dollar: string;
  symbol?: string;
}

const AmountAndDollar: FC<Props> = ({ amount, dollar, symbol }) => {
  const { isLightTheme } = useThemeContext();

  return (
    <Wrapper>
      {amount}
      {symbol && ` ${symbol}`}
      <span style={{ color: isLightTheme ? "#0D1A16B2" : "" }}>{dollar}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;

  span {
    font-weight: 400;
    color: #828899;
    font-size: 14px;
    margin-left: 4px;
  }
`;

export default AmountAndDollar;
