import { t } from "@lingui/macro";
import { getServerUrl } from "config/backend";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";

import { ARBITRUM, BASE, DEFAULT_CHAIN_ID, FANTOM, OP, SONIC_TESTNET } from "config/chains";
import { getWhitelistedTokens } from "config/tokens";
import { useNaviPrice } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { PLACEHOLDER_ACCOUNT, USD_DECIMALS } from "lib/legacy";
import { bigNumberify, formatAmount, formatKeyAmount, formatNumber } from "lib/numbers";

import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import TooltipComponent from "components/Tooltip/Tooltip";

import ExternalLink from "components/ExternalLink/ExternalLink";
import { useAprContext } from "contexts/AprProvider";
import { useThemeContext } from "contexts/ThemeProvider";
import useNAVIFarmContractMulticall from "hooks/contracts/useNAVIFarmContractMulticall";
import useNslpApr from "hooks/useNslpApr";
import useNslpAprOP from "hooks/useNslpAprOP";
import useNslpPriceMultiChain from "hooks/useNslpPriceMultiChain";
import useTokensPrice from "hooks/useTokensPrice";
import useTokensPriceOP from "hooks/useTokensPriceOP";
import arb24Icon from "img/ic_arbitrum_24.svg";
import base24Icon from "img/ic_base_24.svg";
import ftm24Icon from "img/ic_ftm_24.svg";
import nlp40Icon from "img/dashboard/img1.svg";
import navi40Icon from "img/dashboard/img3.svg";
import nslp40Icon from "img/dashboard/img2.svg";
import icToken from "img/ic_s_24.svg";
import op24Icon from "img/ic_op_24.svg";
import { caculateAPYHourly2 } from "lib/helper";
import useProcessedData from "hooks/useProcessedData";

const TokenCards = ({ chainId, active, library }) => {
  const { lightThemeClassName } = useThemeContext();

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens?.filter((t) => !t.isWrapped);

  const { data: nlpInfo } = useSWR(getServerUrl(chainId, "/nlp-info"), {
    refreshInterval: 30000,
  });

  // const { data: nlpInfoFantom } = useSWR(getServerUrl(SONIC_TESTNET, "/nlp-info"), {
  //   refreshInterval: 30000,
  // });

  // const { data: nlpInfoOP } = useSWR(getServerUrl(OP, "/nlp-info"), {
  //   refreshInterval: 30000,
  // });

  // const { data: nlpInfoArbitrum } = useSWR(getServerUrl(ARBITRUM, "/nlp-info"), {
  //   refreshInterval: 30000,
  // });
  // const { data: nlpInfoBase } = useSWR(getServerUrl(BASE, "/nlp-info"), {
  //   refreshInterval: 30000,
  // });

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  const { naviPrice, naviPriceFromFantom, naviPriceFromOP, naviPriceFromArbitrum, naviPriceFromBase } = useNaviPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
    active
  );

  let nlpPrice;
  let nlpPriceFantom;
  let nlpPriceOp;
  let nlpPriceArb;
  let nlpPriceBase;
  if (nlpInfo) {
    nlpPrice = bigNumberify(nlpInfo?.price || "0");
  }
  // if (nlpInfoFantom) {
  //   nlpPriceFantom = bigNumberify(nlpInfoFantom?.price || "0");
  // }

  // if (nlpInfoOP) {
  //   nlpPriceOp = bigNumberify(nlpInfoOP?.price || 0);
  // }

  // if (nlpInfoArbitrum) {
  //   nlpPriceArb = bigNumberify(nlpInfoArbitrum?.price || "0");
  // }

  // if (nlpInfoBase) {
  //   nlpPriceBase = bigNumberify(nlpInfoBase?.price || "0");
  // }

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  // const nslpPrice = useNslpPrice(FANTOM);
  const nslpPrices = useNslpPriceMultiChain();
  const nslpPrice = nslpPrices[chainId] || nslpPrices[DEFAULT_CHAIN_ID];

  // const {
  //   nlpPoolInfo: [totalStakedFantom],
  //   poolRewardsPerSec: poolRewardsPerSecFantom,
  // } = useNAVIFarmContractMulticall(FANTOM, active, PLACEHOLDER_ACCOUNT, library);

  // const {
  //   nlpPoolInfo: [totalStakedOp],
  //   poolRewardsPerSec: poolRewardsPerSecOp,
  // } = useNAVIFarmContractMulticall(OP, active, PLACEHOLDER_ACCOUNT, library);
  // const rewardsPriceFantom = useTokensPrice({
  //   naviPrice: naviPriceFromFantom,
  //   FANTOM,
  // });
  const rewardsPriceOp = useTokensPriceOP({
    naviPrice: naviPriceFromOP,
    OP,
  });

  // const aprFantom = useNslpApr({
  //   nslpPrice: nslpPrices[SONIC_TESTNET],
  //   rewardsPrice: rewardsPriceFantom,
  //   totalStaked: totalStakedFantom,
  //   poolRewardsPerSec: poolRewardsPerSecFantom,
  // });
  // const aprOp = useNslpAprOP({
  //   nslpPrice: nslpPrices[OP],
  //   rewardsPrice: rewardsPriceOp,
  //   totalStaked: totalStakedOp,
  //   poolRewardsPerSec: poolRewardsPerSecOp,
  // });

  const processedData = useProcessedData(SONIC_TESTNET);

  //NAVI
  // const naviAprFantom = parseFloat(formatAmount(processedData[SONIC_TESTNET]?.naviAprTotal, 2)) / 100;
  // const naviApyFantom = caculateAPYHourly2(naviAprFantom) * 100;

  // const naviAprOp = parseFloat(formatAmount(processedData[OP]?.naviAprTotal, 2)) / 100;
  // const naviApyOp = caculateAPYHourly2(naviAprOp) * 100;

  // const naviAprArb = parseFloat(formatAmount(processedData[ARBITRUM]?.naviAprTotal, 2)) / 100;
  // const naviApyArb = caculateAPYHourly2(naviAprArb) * 100;

  // const naviAprBase = parseFloat(formatAmount(processedData[BASE]?.naviAprTotal, 2)) / 100;
  // const naviApBase = caculateAPYHourly2(naviAprBase) * 100;

  //NLP
  const nlpAprFantom = parseFloat(formatAmount(processedData[SONIC_TESTNET]?.nlpAprTotal, 2)) / 100;
  const nlpApyFantom = caculateAPYHourly2(nlpAprFantom) * 100;

  // const nlpAprOp = parseFloat(formatAmount(processedData[OP]?.nlpAprTotal, 2)) / 100;
  // const nlpApyOp = caculateAPYHourly2(nlpAprOp) * 100;

  // const nlpAprArb = parseFloat(formatAmount(processedData[ARBITRUM]?.nlpAprTotal, 2)) / 100;
  // const nlpApyArb = caculateAPYHourly2(nlpAprArb) * 100;

  // const nlpAprBase = parseFloat(formatAmount(processedData[BASE]?.nlpAprTotal, 2)) / 100;
  // const nlpApBase = caculateAPYHourly2(nlpAprBase) * 100;

  return (
    <Wrapper className={lightThemeClassName}>
      <Title>
        <span>Buy </span>
      </Title>
      <TokenCardContainer>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={navi40Icon} alt="NAVI Token Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">NAVI</div>
                <div className="token-price">
                  {" "}
                  {!naviPrice ? (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                      }}
                    />
                  ) : (
                    <div>
                      Price:
                      <span className="txt-heighlight"> {"$" + formatAmount(naviPrice, USD_DECIMALS, 2, true)}</span>
                    </div>
                  )}
                  {/* {naviPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(naviPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(naviPriceFromFantom, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(naviPriceFromOP, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(naviPriceFromArbitrum, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Base`}
                            value={formatAmount(naviPriceFromBase, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )} */}
                </div>
              </div>
            </TokenInfo>
          </CardTitle>
          <div className="desc">
            NAVI is the utility and governance token. Accrues 30% of the platform's generated fees.
          </div>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={icToken} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APR
                  {processedData?.nlpAprTotal ? (
                    <span>{formatKeyAmount(processedData, "naviAprTotalWithBoost", 2, 2, true)}%</span>
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
              </div>
            </div>
            {/* <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(naviApyOp, 2)}%</span>
                </div>
              </div>
            </div>
            <div className="row arb">
              <div className="title">
                <img src={arb24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(naviApyArb, 2)}%</span>
                </div>
              </div>
            </div>
            <div className="row base">
              <div className="title">
                <img src={base24Icon} alt="base-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(naviApBase, 2)}%</span>
                </div>
              </div>
            </div> */}
          </TokenAPR>
          <div className="btns">
            <ExternalLink
              href=" https://equalizer.exchange/swap?fromToken=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&toToken=0x6881B80ea7C858E4aEEf63893e18a8A36f3682f3"
              target={"_blank"}
              className="default-btn full black"
            >
              Buy
            </ExternalLink>
            {/* <button disabled className="default-btn full">
              Read more
            </button> */}
            <ExternalLink
            href=" https://docs.navigator.exchange/"
              target={"_blank"}
              className="border-btn full read-more-btn"
            >
              Read more
            </ExternalLink>
          </div>
        </TokenCard>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={nlp40Icon} alt="nlp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">NLP</div>
                <div className="token-price">
                  {!nlpPrice ? (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                      }}
                    />
                  ) : (
                    <div>
                      {" "}
                      Price: <span>{"$" + formatAmount(nlpPrice, USD_DECIMALS, 2, true)}</span>
                    </div>
                  )}
                  {/* {nlpPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(nlpPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(nlpPriceFantom, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />

                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(nlpPriceOp, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Arbitrum`}
                            value={formatAmount(nlpPriceArb, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={t`Price on Base`}
                            value={formatAmount(nlpPriceBase, USD_DECIMALS, 2, true)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )} */}
                </div>
              </div>{" "}
            </TokenInfo>
          </CardTitle>
          <div className="desc">NLP is the liquidity provider token. Accrues 60% of the platform's generated fees.</div>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={icToken} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APR
                  {processedData?.nlpAprTotal ? (
                    <span>{formatKeyAmount(processedData, "nlpAprTotal", 2, 2, true)}%</span>
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
              </div>
            </div>
            {/* <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(nlpApyOp, 2)}%</span>
                </div>
              </div>
            </div>
            <div className="row arb">
              <div className="title">
                <img src={arb24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(nlpApyArb, 2)}%</span>
                </div>
              </div>
            </div>
            <div className="row base">
              <div className="title">
                <img src={base24Icon} alt="base-symbol" />
                <div className="apr-wrap">
                  APY
                  <span>{formatNumber(nlpApBase, 2)}%</span>
                </div>
              </div>
            </div> */}
          </TokenAPR>
          <div className="btns">
            <Link to="/earn/nlp" className="default-btn full">
              Buy
            </Link>
            <ExternalLink
              href="https://docs.navigator.exchange/earn/nlp"
              target={"_blank"}
              className="border-btn full read-more-btn"
            >
              Read more
            </ExternalLink>
          </div>
        </TokenCard>
        <TokenCard>
          <CardTitle>
            <TokenImg className="golden">
              <img src={nslp40Icon} alt="nslp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">NSLP</div>
                <div className="token-price">
                  {!nslpPrice ? (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                      }}
                    />
                  ) : (
                    <div>
                      {" "}
                      Price: <span>{"$" + formatAmount(nslpPrice, 30, 2)}</span>{" "}
                    </div>
                  )}
                  {/* {nslpPrice && <>{"$" + formatAmount(nslpPrice, 30, 2)}</>} */}
                  {/* {nslpPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(nslpPrice, 30, 2)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on Fantom`}
                            value={formatAmount(nslpPrices[SONIC_TESTNET], 30, 2)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(nslpPrices[OP], 30, 2)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )} */}
                </div>
              </div>{" "}
            </TokenInfo>
          </CardTitle>
          <div className="desc">
            NSLP is the liquidity provider token. Accrues 60% of the platform's generated fees.{" "}
          </div>
          <TokenAPR>
            <div className="row ftm">
              <div className="title">
                <img src={icToken} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APR
                  {processedData?.nslpAprTotal ? (
                    <span>{formatKeyAmount(processedData, "nslpAprTotal", 2, 2, true)}%</span>
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
              </div>
            </div>
            {/* <div className="row op">
              <div className="title">
                <img src={op24Icon} alt="ftm-symbol" />
                <div className="apr-wrap">
                  APR
                  <span>
                    {aprOp && aprOp.total !== undefined && !isNaN(aprOp.total) ? (
                      <>{aprOp.total === 0 ? "0.000" : `${formatNumber(aprOp.total, 2)}%`}</>
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    )}
                  </span>
                </div>
              </div>
            </div> */}
          </TokenAPR>
          <div className="btns">
            <Link to="/earn/nslp" className="default-btn full">
              Buy
            </Link>
            <ExternalLink
              href="https://docs.navigator.exchange/earn/nslp"
              target={"_blank"}
              className="border-btn full read-more-btn"
            >
              Read more
            </ExternalLink>
          </div>
        </TokenCard>
      </TokenCardContainer>
    </Wrapper>
  );
};

const TokenAPR = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;

  border-radius: 16px;
  overflow: hidden;

  .apr-wrap {
    display: flex;
    gap: 4px;
    align-items: center;

    color: var(--white-60, #828899);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    & > span {
      color: var(--main-primary, #90fb75);

      display: flex;
    }

    @media screen and (max-width: 767px) {
      flex-direction: row;
      gap: 4px;
    }
  }

  > .row {
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    background: #1a1d26;

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #90fb75;
      }

      @media screen and (max-width: 767px) {
        flex-direction: row;
      }
    }

    .btn-buy {
      display: flex;
      height: 40px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--stroke-10, rgba(255, 255, 255, 0.1));
      background-color: transparent;
      /* color: #080715; */
      text-decoration: none;

      color: var(--white-100, #fff);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      &:hover {
        color: #ffffff99 !important;
      }
    }

    @media (max-width: 767px) {
      flex-direction: row;

      .btn-buy {
        width: fit-content;
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const TokenInfo = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  .token-symbol {
    margin-bottom: 4px;
    font-size: 16px;

    font-weight: 700;
    line-height: 140%;
  }
  .token-price {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #828899;
    span {
      color: #90fb75;
    }
  }
`;

const TokenImg = styled.div`
  img {
    width: 40px;
    height: 40px;
  }
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TokenCard = styled.div`
  padding: 24px;
  background-color: #12141a;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .desc {
    color: var(--white-60, #828899);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 22.4px */

    margin: 24px 0;
  }

  .btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    &.no-chain {
      grid-template-columns: 1fr;
    }
  }

  .read-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    padding: 16px;
  }
`;

const TokenCardContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 767px) {
    gap: 16px;
  }

  .content-nlp {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
`;

const Wrapper = styled.div`
  margin-top: 48px;

  @media (max-width: 767px) {
    margin: 36px 0;

    div.Tooltip-popup:has(.customTooltip) {
      transform: translateX(-100px);
    }
  }
`;
export default TokenCards;
