import { LoadingOutlined } from "@ant-design/icons";
import SettingsManager from "abis/SettingsManager.json";
import { getContract } from "config/contracts";
import { BigNumber } from "ethers";
import { contractFetcher } from "lib/contracts";
import { getLiqPrice, getLiquidPriceV2, getPnlWithoutFee, importImage, USD_DECIMALS } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";
import { useState } from "react";
import styled from "styled-components";
import useSWR from "swr";
import cx from "classnames";
import { preventSpecialCharacters } from "utils/helpers";
import Checkbox from "../Checkbox/Checkbox";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import PercentageButtons from "./PercentageButtons";
import "./PositionSeller.css";
import RowsDropdown from "./RowsDropdown";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;
function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "" : "") + n;
  }
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return sign + z((secs / 3600) | 0) + "h:" + z(((secs % 3600) / 60) | 0) + "m:" + z((secs % 60).toFixed(0)) + "s";
}
export default function ClosePosition(props) {
  const {
    library,
    isVisible,
    selectedPosition,
    setIsVisible,
    chainId,
    handleClosePosition,
    marketTokensInfo,
    positions,
    isConfirm,
  } = props;
  const currentPos = positions.find((x) => x.posId === selectedPosition.posId) || {};
  const position = { ...selectedPosition, ...currentPos };

  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const [isKeepLeverage, setKeepLeverage] = useState("");
  const [closeAmount, setCloseAmount] = useState("");
  const [sizeAmount, setSizeAmount] = useState(0);
  const handleSelectPercentageCloseAmount = (percentage) => {
    const value = position.quantity.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    setCloseAmount(formatAmount(value, 30, 30, false));
  };

  const accruedPositionFee = parseValue(position.accruedPositionFee, 30);
  const accruedFundingFee = parseValue(position.accruedFundingFee, 30);
  const accruedBorrowFee = parseValue(position.accruedBorrowFee, 30);
  // console.log("???", position);

  const avgEntryPrice = parseValue(position.averagePrice, 30);
  const markPrice = BigNumber.from(position?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0);

  const posQty = parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.averagePrice, 30));
  const collateral = parseValue(position.collateral, 30);
  const pendingCollateral = parseValue(position.pendingCollateral, 30);
  const dateFormat = "YYYY-MM-DD";
  const paidPositionFee = parseValue(position.paidPositionFee, 30);
  const paidBorrowFee = parseValue(position.paidBorrowFee, 30);
  const paidFundingFee = parseValue(position.paidFundingFee, 30);
  const duration = secondsToHMS(Date.now() / 1000 - position.createdAt);
  const onClossePosition = () => {
    if (parseValue(closeAmount, 30).eq(position.quantity))
      handleClosePosition && handleClosePosition(position, parseValue(position.size, 30));
    else {
      handleClosePosition &&
        handleClosePosition(
          position,
          parseValue(closeAmount, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30))
        );
    }
  };
  const getPnl = (entryPrice, size, markPrice, isLong) => {
    const pnlWithoutFee = isLong
      ? markPrice.sub(entryPrice).mul(size).div(entryPrice)
      : entryPrice.sub(markPrice).mul(size).div(entryPrice);
    return pnlWithoutFee;
  };
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const closeAmountDelta = closeAmount
    ? parseValue(closeAmount, 30).eq(posQty)
      ? parseValue(position.size, 30)
      : parseValue(closeAmount, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30))
    : null;
  const { data: liquidateThreshold } = useSWR(
    closeAmount && [
      "ExChange:liquidateThreshold",
      chainId,
      settingsManagerAddress,
      "liquidateThreshold",
      position.tokenId,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: minProfitDurations } = useSWR(
    closeAmount && [
      "ExChange:minProfitDurations",
      chainId,
      settingsManagerAddress,
      "minProfitDurations",
      position.tokenId,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: maxCloseProfitPercents } = useSWR(
    closeAmount && [
      "ExChange:maxCloseProfitPercents",
      chainId,
      settingsManagerAddress,
      "maxCloseProfitPercents",
      position.tokenId,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );

  const { data: maxCloseProfits } = useSWR(
    closeAmount && ["ExChange:maxCloseProfits", chainId, settingsManagerAddress, "maxCloseProfits", position.tokenId],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );

  const newSize =
    closeAmount && parseValue(closeAmount, 30).gt(0)
      ? parseValue(position.size, 30)
          .sub(parseValue(closeAmount, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30)))
          .div(parseValue(1, 25))
      : null;
  const newCollateral = newSize ? newSize.mul(parseValue(1, 5)).div(parseValue(position.leverage, 5)) : null;

  const sizeDelta = newSize
    ? parseValue(closeAmount, 30).eq(position.quantity)
      ? parseValue(position.size, 30)
      : parseValue(closeAmount, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30))
    : null;
  const posQtyDelta = sizeDelta ? sizeDelta.mul(parseValue(1, 30)).div(parseValue(position.averagePrice, 30)) : null;
  const newColateral = sizeDelta
    ? parseValue(closeAmount, 30).eq(position.quantity)
      ? parseValue(position.collateral, 30)
      : sizeDelta.mul(parseValue(1, 30)).div(parseValue(position.leverage, 30))
    : null;
  const pnlWithoutFee = sizeDelta
    ? getPnlWithoutFee(position.isLong, markPrice, parseValue(position.averagePrice, 30), sizeDelta)
    : null;
  const accruedFees = parseValue(position.accruedPositionFee, 30)
    .add(parseValue(position.accruedFundingFee, 30))
    .add(parseValue(position.accruedBorrowFee, 30));
  const percentSizeDelta = closeAmount
    ? parseValue(closeAmount, 30).eq(position.quantity)
      ? parseValue(1, 30)
      : parseValue(closeAmount, 30).mul(parseValue(1, 30)).div(position.quantity)
    : null;
  const uPnL =
    pnlWithoutFee && percentSizeDelta
      ? pnlWithoutFee.sub(accruedFees.mul(percentSizeDelta).div(parseValue(1, 30)))
      : null;
  const pnlClose = newSize
    ? getPnl(parseValue(position.averagePrice, 30), sizeDelta, markPrice, position.isLong)
    : null;
  const estValueRecieve =
    percentSizeDelta && uPnL
      ? percentSizeDelta.mul(parseValue(position.collateral, 30)).div(parseValue(1, 30)).add(uPnL)
      : null;
  // const liquidPrice =
  //   newCollateral && newSize && newSize.gt(0) && liquidateThreshold
  //     ? getLiqPrice(
  //         liquidateThreshold,
  //         newCollateral,
  //         newSize,
  //         position.accruedFees
  //           .mul(parseValue(1, USD_DECIMALS))
  //           .sub(position.accruedFees.mul(percentSizeDelta))
  //           .div(parseValue(1, 25 + 30)),
  //         position.isLong,
  //         parseValue(position.averagePrice, 30)
  //       )
  //     : newSize && newSize.eq(0)
  //     ? 0
  //     : null;

  const liquidPrice =
    newCollateral && newSize && newSize.gt(0) && liquidateThreshold
      ? parseValue(
          getLiquidPriceV2(
            parseValue(position?.size, 30).sub(sizeDelta),
            newColateral.sub(parseValue(position.collateral, 30)),
            position.isLong,
            accruedFees.sub(accruedFees.mul(percentSizeDelta).div(parseValue(1, 30))),
            parseValue(position.averagePrice, 30),
            liquidateThreshold
          ),
          30
        )
      : newSize && newSize.eq(0)
      ? 0
      : null;

  const rPnL = position.realisedPnl;
  const ROI = uPnL ? uPnL.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30)) : null;

  const getClosePositionError = () => {
    if (!closeAmount || !Number(closeAmount)) {
      return "Enter an amount";
    }

    if (position.quantity && parseValue(closeAmount, 30).gt(position.quantity)) {
      return "Amount exceeds balance";
    }

    return null;
  };
  const getPrimaryText = () => {
    return getClosePositionError() ?? (isConfirm ? "Closing..." : "Close Position");
  };
  const isDisableCondition = () => {
    if (!minProfitDurations || !maxCloseProfitPercents || !maxCloseProfits) return true;
    const inTime = Date.now() / 1000 - position.createdAt < minProfitDurations.toNumber();
    if (inTime) {
      if (uPnL.gt(maxCloseProfits) || ROI.gt(parseValue(1, 27).mul(maxCloseProfitPercents))) return true;
      return false;
    }
    return false;
  };
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Close Position"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container">
              <div className="position-info">
                <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
                <div className="title">{position.symbol}</div>
                <div className="position-id">#{position?.posId}</div>
              </div>
              <div className="position-tags">
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
                <div className="leverage-box">{formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X</div>
              </div>
            </div>
          </div>
          <div className="content-container with-margin-mobile">
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "0px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <span>Close Amount</span>
                </div>
                <div className="right-balance" style={{ marginLeft: "auto" }}>
                  <span>Max: {formatAmount(position.quantity, 30, 4, true)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    onKeyDown={preventSpecialCharacters}
                    value={closeAmount}
                    onChange={(e) => setCloseAmount(e.target.value)}
                  />
                </div>
                <div className="PositionEditor-token-symbol">{position.symbol}</div>
              </div>
            </div>
            <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseAmount}
              balance={Number(formatAmount(position.quantity, 30, 30, false))}
              value={closeAmount}
            />
            <div className="order-details-container" style={{ marginTop: "16px" }}>
              <div className="square-container">
                <ExchangeInfoRow
                  label={`Keep Leverage At ${formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X`}
                  textStyled={{ marginBottom: "8px" }}
                >
                  {" "}
                  <Checkbox isChecked={true}>{/* <span></span> */}</Checkbox>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Leverage`}>
                  <LeverageTag>{formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X</LeverageTag>
                </ExchangeInfoRow>
                <Divider />
                <ExchangeInfoRow label={`Mark Price`}>
                  ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Entry Price`}>
                  ${formatAmount(avgEntryPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Liq. Price`} textStyled={{ marginBottom: "8px" }}>
                  {position?.liquidationPrice && liquidPrice ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#828899" }}>
                        $
                        {formatAmount(
                          parseValue(position?.liquidationPrice, 30),
                          30,
                          currentMarket?.decimals || 2,
                          true
                        )}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#828899"} />
                      </span>{" "}
                      ${liquidPrice ? formatAmount(liquidPrice, 30, currentMarket?.decimals || 2, true) : "--"}
                      {/* <span
                        className={cx("Exchange-list-info-label", {
                          positive: parseValue(position?.liquidationPrice, 30).sub(liquidPrice).lt(0),
                          negative: parseValue(position?.liquidationPrice, 30).sub(liquidPrice).gt(0),
                          muted: parseValue(position?.liquidationPrice, 30).sub(liquidPrice).eq(0),
                        })}
                        style={{ marginLeft: "4px", fontSize: "14px" }}
                      >
                        ({parseValue(position.liquidationPrice, 30).sub(liquidPrice).lte(0) ? "+" : "-"}$
                        {formatAmount(parseValue(position.liquidationPrice, 30).sub(liquidPrice), 30, 4, true).replace(
                          "-",
                          ""
                        )}
                        )
                      </span> */}
                    </span>
                  ) : (
                    "-"
                  )}
                </ExchangeInfoRow>
                <Divider />
                <ExchangeInfoRow
                  label={`Collateral`}
                  // isPositive={uPnL && uPnL.gt(0) ? true : uPnL && uPnL.lt(0) ? false : undefined}
                >
                  {newColateral ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#828899" }}>
                        ${formatAmount(parseValue(position?.collateral, 30), 30, 2, true)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#828899"} />
                      </span>{" "}
                      $
                      {newColateral
                        ? formatAmount(newColateral.sub(parseValue(position.collateral, 30)), 30, 2, true).replace(
                            "-",
                            ""
                          )
                        : "--"}
                      {/* <span
                        className={cx("Exchange-list-info-label", {
                          negative: true,
                        })}
                        style={{ marginLeft: "4px", fontSize: "14px" }}
                      >
                        (-$
                        {formatAmount(newColateral, 30, 2, true).replace("-", "")})
                      </span> */}
                    </span>
                  ) : (
                    <span>
                      {" "}
                      ${formatAmount(parseValue(position?.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
                    </span>
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>
                  {sizeDelta ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#828899" }}>
                        ${formatAmount(parseValue(position?.size, 30), 30, 2, true)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#828899"} />
                      </span>{" "}
                      ${formatAmount(parseValue(position?.size, 30).sub(sizeDelta), 30, 2, true)}
                      {/* <span
                        className={cx("Exchange-list-info-label", {
                          negative: true,
                        })}
                        style={{ marginLeft: "4px", fontSize: "14px" }}
                      >
                        (-$
                        {formatAmount(sizeDelta, 30, 2, true).replace("-", "")})
                      </span> */}
                    </span>
                  ) : (
                    <span> ${formatAmount(parseValue(position?.size, 30), 30, 2, true)}</span>
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Qty (${position.symbol})`}>
                  {posQtyDelta ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#828899" }}>
                        {formatAmount(position.quantity, 30, 2, true)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#828899"} />
                      </span>{" "}
                      {formatAmount(position.quantity.sub(posQtyDelta), 30, 2, true)}
                      {/* <span
                        className={cx("Exchange-list-info-label", {
                          negative: true,
                        })}
                        style={{ marginLeft: "4px", fontSize: "14px" }}
                      >
                        (-
                        {formatAmount(posQtyDelta, 30, 2, true).replace("-", "")})
                      </span> */}
                    </span>
                  ) : (
                    <span>${formatAmount(position.quantity, 30, 2, true)}</span>
                  )}
                </ExchangeInfoRow>
                <Divider />
                <ExchangeInfoRow label={`Fees`}>
                  <Tooltip
                    handle={`${accruedFees && percentSizeDelta ? (accruedFees.gte(0) ? "" : "-") : ""}${
                      percentSizeDelta
                        ? "$" +
                          formatAmount(accruedFees.mul(percentSizeDelta).div(parseValue(1, 30)), 30, 4, true).replace(
                            "-",
                            ""
                          )
                        : "-"
                    }`}
                    position="right-top"
                    handleClassName="plain"
                    renderContent={() => {
                      return (
                        <>
                          <StatsTooltipRow
                            label={`Closing Fee`}
                            showDollar={false}
                            value={`${accruedPositionFee.gte(0) ? "" : "-"}${
                              percentSizeDelta
                                ? "$" +
                                  formatAmount(
                                    accruedPositionFee.mul(percentSizeDelta).div(parseValue(1, 30)),
                                    30,
                                    4,
                                    true
                                  ).replace("-", "")
                                : "-"
                            }`}
                          />
                          <StatsTooltipRow
                            label={`Funding Fee`}
                            showDollar={false}
                            value={`${accruedFundingFee.gte(0) ? "" : "-"}${
                              percentSizeDelta
                                ? "$" +
                                  formatAmount(
                                    accruedFundingFee.mul(percentSizeDelta).div(parseValue(1, 30)),
                                    30,
                                    4,
                                    true
                                  ).replace("-", "")
                                : "-"
                            }`}
                          />
                          <StatsTooltipRow
                            label={`Borrow Fee`}
                            showDollar={false}
                            value={`${accruedBorrowFee.gte(0) ? "" : "-"}${
                              percentSizeDelta
                                ? "$" +
                                  formatAmount(
                                    parseValue(position.accruedBorrowFee, 30)
                                      .mul(percentSizeDelta)
                                      .div(parseValue(1, 30)),
                                    30,
                                    4,
                                    true
                                  ).replace("-", "")
                                : "-"
                            }`}
                          />
                        </>
                      );
                    }}
                  />
                </ExchangeInfoRow>
                <Divider />

                <ExchangeInfoRow
                  label={`PnL (ROI)`}
                  isPositive={uPnL && uPnL.gt(0) ? true : uPnL && uPnL.lt(0) ? false : undefined}
                >
                  {uPnL && uPnL.gt(0) ? "+" : uPnL && uPnL.lt(0) ? "-" : ""}
                  {uPnL ? "$" + formatAmount(uPnL, 30, 2, true).replace("-", "") : "-"} (
                  {ROI && ROI.gt(0) ? "+" : ROI && ROI.lt(0) ? "-" : ""}
                  {ROI ? formatAmount(ROI, 30, 2, true).replace("-", "") + "%" : "-"})
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Receive`}>
                  {estValueRecieve && estValueRecieve.lt(0) ? "-" : ""}
                  {estValueRecieve ? formatAmount(estValueRecieve, 30, 2, true).replace("-", "") + " USD" : "-"} (
                  {estValueRecieve && estValueRecieve.lt(0) ? "-" : ""}
                  {estValueRecieve ? "$" + formatAmount(estValueRecieve, 30, 2, true).replace("-", "") : "-"})
                </ExchangeInfoRow>
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button
              className="App-cta Exchange-swap-button"
              onClick={onClossePosition}
              disabled={!!getClosePositionError() || isConfirm || isDisableCondition()}
            >
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

const LeverageTag = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const Divider = styled.div`
  background: var(--Border, rgba(55, 63, 92, 0.5));
  height: 1px;
  width: 100%;
  margin: 8px 0 16px 0;
`;
