import { useWeb3React } from "@web3-react/core";
import SettingsManager from "abis/SettingsManager.json";
import cx from "classnames";
import { getContract } from "config/contracts";
import { THEME_KEY } from "config/localStorage";
import { BigNumber } from "ethers";
import usePositionActivities from "hooks/usePositionActivities";
import icHistory from "img/trade/ic-history.svg";
import { contractFetcher } from "lib/contracts";
import { renderRisk } from "lib/helper";
import { getPnlWithoutFee, importImage } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";
import moment from "moment";
import { useState } from "react";
import styled from "styled-components";
import useSWR from "swr";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import PositionHistory from "./PositionHistory";
import "./PositionSeller.css";
import RowsDropdown, { RowsDropdownHandler } from "./RowsDropdown";

export const MAPPED_ACTION_TYPES = {
  STOP_LOSS: "Stop Loss",
  TAKE_PROFIT: "Take Profit",
  TRAILING_STOP: "Trailing Stop",
};

export default function PositionDetails(props) {
  const {
    onConfirm,
    isVisible,
    positions,
    selectedPosition,
    setIsVisible,
    chainId,
    marketTokensInfo,
    isConfirm,
    hideClose,
  } = props;
  const { library } = useWeb3React();
  const currentMarket = marketTokensInfo?.find((x) => x.id === selectedPosition.tokenId);
  const currentPos = positions?.find((x) => x.posId === selectedPosition.posId) || {};
  const position = { ...selectedPosition, ...currentPos };
  const [isOpenPositionHistory, setIsOpenPositionHistory] = useState(false);
  const { positionNetValue } = position;
  const accruedPositionFee = parseValue(position.accruedPositionFee, 30);
  const accruedFundingFee = parseValue(position.accruedFundingFee, 30);
  const accruedBorrowFee = parseValue(position.accruedBorrowFee, 30);
  const accruedFees = accruedPositionFee.add(accruedFundingFee).add(accruedBorrowFee);
  const avgEntryPrice = parseValue(position.averagePrice, 30);
  const markPrice = BigNumber.from(position?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0);

  const collateral = parseValue(position.collateral, 30);
  const pnlWithoutFee = getPnlWithoutFee(
    position.isLong,
    markPrice,
    parseValue(position.averagePrice, 30),
    parseValue(position.size, 30)
  );
  const uPnL = pnlWithoutFee.sub(accruedFees);
  const pendingCollateral = parseValue(position.pendingCollateral, 30);
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const paidFee =
    parseValue(position.paidPositionFee, 30)
      .add(parseValue(position.paidBorrowFee, 30))
      .add(parseValue(position.paidFundingFee, 30)) || BigNumber.from(0);
  const paidPositionFee = parseValue(position.paidPositionFee, 30);
  const paidBorrowFee = parseValue(position.paidBorrowFee, 30);
  const paidFundingFee = parseValue(position.paidFundingFee, 30);
  const rPnL = parseValue(position.realisedPnl || 0, 30);
  const handleConfirm = () => {
    onConfirm && onConfirm(position, parseValue(position.size, 30));
  };
  const netValue = parseValue(position.collateral, 30).add(uPnL);
  const { data: positionActivities } = usePositionActivities(chainId, selectedPosition.posId);

  const mappedPosOrders = {};
  if (position.posOrders) {
    position.posOrders
      .map((o) => {
        if (o.type !== "TRIGGER") {
          return {
            ...o,
            actionType: "TRAILING_STOP",
          };
        }
        if (o.trigger.isTP) {
          return {
            ...o,
            actionType: "TAKE_PROFIT",
          };
        }
        return {
          ...o,
          actionType: "STOP_LOSS",
        };
      })
      .forEach((order) => {
        if (!Array.isArray(mappedPosOrders[order.actionType])) {
          mappedPosOrders[order.actionType] = [order];
        } else {
          mappedPosOrders[order.actionType].push(order);
        }
      });
  }
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: minProfitDurations } = useSWR(
    ["ExChange:minProfitDurations", chainId, settingsManagerAddress, "minProfitDurations", position.tokenId],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: maxCloseProfitPercents } = useSWR(
    ["ExChange:maxCloseProfitPercents", chainId, settingsManagerAddress, "maxCloseProfitPercents", position.tokenId],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );

  const { data: maxCloseProfits } = useSWR(
    ["ExChange:maxCloseProfits", chainId, settingsManagerAddress, "maxCloseProfits", position.tokenId],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const isDisableCondition = () => {
    if (!minProfitDurations || !maxCloseProfitPercents || !maxCloseProfits) return true;
    const inTime = Date.now() / 1000 - position.createdAt < minProfitDurations.toNumber();
    if (inTime) {
      if (uPnL.gt(maxCloseProfits) || ROI.gt(parseValue(1, 27).mul(maxCloseProfitPercents))) return true;
      return false;
    }
    return false;
  };
  const ROI = uPnL ? uPnL.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30)) : null;
  const theme = window.localStorage.getItem(`"${THEME_KEY}"`);

  const renderContent = (order, id) => {
    if (order.type === "TRIGGER") {
      const sizeDelta = parseValue(position.size, 30).mul(order.trigger.amountPercent).div(100000);
      return (
        <>
          {id >= 1 && <Divider className="medium" />}
          <ContentWrap>
            <ExchangeInfoRow label={"Size Delta"}> ${formatAmount(sizeDelta, 30, 2, true)}</ExchangeInfoRow>
            {/* <ExchangeInfoRow label={"Pos.Qty"}>
              {" "}
              {formatAmount(order.size.mul(parseValue(1, 30)).div(order.averagePrice), 30, 4, true)} {position.symbol}
            </ExchangeInfoRow> */}
            <ExchangeInfoRow label={"Trigger Price"}>
              {order.trigger.isTP ? (order.isLong ? "≥" : "≤") : !order.isLong ? "≥" : "≤"} $
              {formatAmount(order.trigger.price, 30, currentMarket?.decimals || 2, true)}
            </ExchangeInfoRow>
          </ContentWrap>
          {/*
          <ContentWrap>
            <ExchangeInfoRow label={"Pos. Size"}>--</ExchangeInfoRow>
            <ExchangeInfoRow label={"Pos.Qty"}>--</ExchangeInfoRow>
            <ExchangeInfoRow label={"Liq.Price"}>--</ExchangeInfoRow>
          </ContentWrap> */}
        </>
      );
    }
    if (order.type === "TRAILING") {
      const sizeDelta = BigNumber.from(order.sizeDelta).gte(parseValue(position.size, 30))
        ? parseValue(position.size, 30)
        : BigNumber.from(order.sizeDelta);
      return (
        <ContentWrap>
          <ExchangeInfoRow label={`Size Delta`}>${formatAmount(sizeDelta, 30, 2, true)}</ExchangeInfoRow>
          {/* <ExchangeInfoRow label={`Pos. Qty`}>
            {formatAmount(
              BigNumber.from(order.size).mul(parseValue(1, 30)).div(BigNumber.from(order.averagePrice)),
              30,
              4,
              true
            )}{" "}
            {position.symbol}
          </ExchangeInfoRow> */}
          <ExchangeInfoRow label={`Paramaters`}>
            <div>
              {order.stepType == 0
                ? "Trailing Amount = $" + formatAmount(BigNumber.from(order.stepAmount), 30, 2, true)
                : "Trailing Percentage = " + formatAmount(BigNumber.from(order.stepAmount), 3, 2, true) + "%"}
              {/* <div>
                {order.stepType == 0
                  ? formatAmount(BigNumber.from(order.stepAmount), 30, currentMarket?.decimals || 2, true)
                  : formatAmount(BigNumber.from(order.stepAmount), 3, 2, true)}{" "}
                <span className="muted">{order.stepType == 1 ? "T%" : "TA"}</span>
              </div> */}
              {/* <div>
                {" "}
                {BigNumber.from(order.size).eq(BigNumber.from(order.sizeDelta))
                  ? 100
                  : formatAmount(
                      BigNumber.from(order.sizeDelta).mul(parseValue(1, 30)).div(BigNumber.from(order.size)),
                      30,
                      2,
                      true
                    )}{" "}
                <span className="muted">%</span>
              </div> */}
            </div>
          </ExchangeInfoRow>
        </ContentWrap>
      );
    }
  };

  return (
    <Wrapper className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Open Position"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container" style={{ width: "100%" }}>
              {" "}
              <StyledWrap className="head">
                <div className="header">
                  <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
                  <div className="position-info">
                    <div className="title">{position.symbol}</div>
                    <div className="position-id">#{position?.posId}</div>
                  </div>
                </div>
                <div className="postion-history-ic" onClick={() => setIsOpenPositionHistory(true)}>
                  <img alt="history-ic" className="ic-history" src={icHistory} />
                </div>
              </StyledWrap>
              <StyledWrap>
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
                <div className="leverage-box">{formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X</div>
              </StyledWrap>
            </div>
          </div>{" "}
          <Wrap className="content-container">
            {" "}
            <Divider />
            <div className="order-details-container">
              <RowsDropdown
                handler={<ExchangeInfoRow label={<div className="title">Position Details</div>}></ExchangeInfoRow>}
                show
              >
                <ExchangeInfoRow label={`Unrealized PnL`}>
                  <div className={cx({ positive: uPnL.gt(0), negative: uPnL.lt(0) })}>
                    {uPnL.gt(0) ? "+" : "-"}${formatAmount(uPnL, 30, 2, 0).replace("-", "")}&nbsp;(
                    {positionNetValue.prefix}
                    {formatAmount(positionNetValue?.percentPnl, 30, 2, true).replace("-", "")}
                    %)
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Realized PnL`}>
                  <div className={cx({ positive: rPnL.gt(0), negative: rPnL.lt(0) })}>
                    {rPnL ? (rPnL.gt(0) ? "+" : "-") : ""}$
                    {rPnL ? formatAmount(rPnL, 30, 2, true).replace("-", "") : "--"}{" "}
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Net Value`}>{`${netValue.gt(0) ? "" : "-"}$${formatAmount(
                  netValue,
                  30,
                  2,
                  true
                ).replace("-", "")}`}</ExchangeInfoRow>{" "}
                <ExchangeInfoRow label={`Pos.Size`}>
                  ${formatAmount(parseValue(position.size, 30), 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos.Qty`}>
                  {formatAmount(position.quantity, 30, 4, true)} {position.symbol}
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Received Amount`}>{`${netValue.gt(0) ? "" : "-"}$${formatAmount(
                  netValue,
                  30,
                  2,
                  true
                ).replace("-", "")}`}</ExchangeInfoRow> */}
                <ExchangeInfoRow label={`Liq. Price`}>
                  ${formatAmount(parseValue(position.liquidationPrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Leverage`}>
                  <div className="position-list-leve">
                    {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                  </div>
                </ExchangeInfoRow> */}
                <ExchangeInfoRow label={`Mark Price`}>
                  ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Avg. Entry Price`}>
                  ${formatAmount(avgEntryPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Liq. Risk`}>
                  {position.LiqRisk && renderRisk(position.LiqRisk, false)}
                  {/* {formatAmount(position.LiqRisk, 30, position.LiqRisk.eq(0) ? 0 : 2, true).replace(
                    "-",
                    "",
                  )}
                  % */}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Collateral`}>${formatAmount(collateral, 30, 2, true)}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Pending Collateral`}>
                  ${formatAmount(pendingCollateral, 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Date/Time Opened`}>
                  {moment(new Date(position.createdAt * 1000)).format(dateFormat)}&nbsp;
                  {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                </ExchangeInfoRow>
              </RowsDropdown>

              {/* {Object.keys(mappedPosOrders).length > 0 && (
                <div className="square-container-yellow" style={{ margin: "4px 0" }}>
                  <div className="title title-linked-order">Linked Orders</div>
                  <div className="square-container fee-square">
                    {Object.keys(mappedPosOrders).map((type) => (
                      <RowsDropdown
                        key={type}
                        handler={
                          <RowsDropdownHandler>
                            {MAPPED_ACTION_TYPES[type]} ({mappedPosOrders[type].length})
                          </RowsDropdownHandler>
                        }
                      >
                        {mappedPosOrders[type].map((order) => (
                          <>
                            {order.type === "TRIGGER" && (
                              <div className="square-container">
                                <ExchangeInfoRow label={`Pos. Size`}>
                                  ${formatAmount(order.size, 30, 2, true)}
                                </ExchangeInfoRow>
                                <ExchangeInfoRow label={`Pos. Qty`}>
                                  {formatAmount(order.size.mul(parseValue(1, 30)).div(order.averagePrice), 30, 4, true)}{" "}
                                  {position.symbol}
                                </ExchangeInfoRow>
                                <ExchangeInfoRow label={`Trigger Price`}>
                                  {" "}
                                  ${formatAmount(order.trigger.price, 30, currentMarket?.decimals || 2, true)}
                                </ExchangeInfoRow>
                              </div>
                            )}
                            {order.type === "TRAILING" && (
                              <div className="square-container">
                                <ExchangeInfoRow label={`Pos. Size`}>
                                  ${formatAmount(order.size, 30, 2, true)}
                                </ExchangeInfoRow>
                                <ExchangeInfoRow label={`Pos. Qty`}>
                                  {formatAmount(
                                    BigNumber.from(order.size)
                                      .mul(parseValue(1, 30))
                                      .div(BigNumber.from(order.averagePrice)),
                                    30,
                                    4,
                                    true
                                  )}{" "}
                                  {position.symbol}
                                </ExchangeInfoRow>
                                <ExchangeInfoRow label={`Paramaters`}>
                                  <div>
                                    <div>
                                      {order.stepType == 0
                                        ? formatAmount(
                                            BigNumber.from(order.stepAmount),
                                            30,
                                            currentMarket?.decimals || 2,
                                            true
                                          )
                                        : formatAmount(BigNumber.from(order.stepAmount), 3, 2, true)}{" "}
                                      <span className="muted">{order.stepType == 1 ? "T%" : "TA"}</span>
                                    </div>
                                    <div>
                                      {" "}
                                      {BigNumber.from(order.size).eq(BigNumber.from(order.sizeDelta))
                                        ? 100
                                        : formatAmount(
                                            BigNumber.from(order.sizeDelta)
                                              .mul(parseValue(1, 30))
                                              .div(BigNumber.from(order.size)),
                                            30,
                                            2,
                                            true
                                          )}{" "}
                                      <span className="muted">%</span>
                                    </div>
                                  </div>
                                </ExchangeInfoRow>
                              </div>
                            )}
                          </>
                        ))}
                      </RowsDropdown>
                    ))}
                  </div>
                </div>
              )} */}
              {Object.keys(mappedPosOrders).length > 0 && (
                <>
                  <Divider className="toggle-margin " />
                  <RowsDropdown
                    handler={<ExchangeInfoRow label={<div className="title">Linked Orders</div>}></ExchangeInfoRow>}
                  >
                    {/* <div className="square-container square-fee-container"> */}
                    {Object.keys(mappedPosOrders).map((type) => (
                      <div className="square-container square-fee-container">
                        <RowsDropdown
                          key={type}
                          handler={
                            <RowsDropdownHandler>
                              {MAPPED_ACTION_TYPES[type]} ({mappedPosOrders[type].length})
                            </RowsDropdownHandler>
                          }
                        >
                          <div className="title">
                            {mappedPosOrders[type].map((order, id) => renderContent(order, id))}
                          </div>
                        </RowsDropdown>
                      </div>
                    ))}

                    {/* <RowsDropdown
                    handler={<ExchangeInfoRow label={<div className="title">Trailing Stop (1)</div>}></ExchangeInfoRow>}
                  >

                  </RowsDropdown> */}
                    {/* </div> */}
                    {/* <div className="square-container square-fee-container"></div>
                <div className="square-container square-fee-container"></div> */}
                  </RowsDropdown>
                </>
              )}
              <Divider className="toggle-margin " />
              <RowsDropdown
                handler={
                  <ExchangeInfoRow
                    label={<div className="title">Fees</div>}
                    isPositive={paidFee.gte(0)}
                  ></ExchangeInfoRow>
                }
              >
                <div className="square-container square-fee-container">
                  <ExchangeInfoRow label={`Paid`}>
                    {paidFee.lt(0) && "-"}${formatAmount(paidFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Position`}>
                    {paidPositionFee.lt(0) && "-"}${formatAmount(paidPositionFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Funding`}>
                    {paidFundingFee.lt(0) && "-"}${formatAmount(paidFundingFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Borrow`}>
                    {paidBorrowFee.lt(0) && "-"}${formatAmount(paidBorrowFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  {/* <ExchangeInfoRow label={`Fee Discount (--%)`}>$--</ExchangeInfoRow> */}
                </div>
                <div className="square-container square-fee-container">
                  <ExchangeInfoRow label={`Accrued`}>
                    {accruedFees.lt(0) && "-"}${formatAmount(accruedFees, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Close Position`}>
                    {accruedPositionFee.lt(0) && "-"}${formatAmount(accruedPositionFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Funding`}>
                    {accruedFundingFee.lt(0) && "-"}${formatAmount(accruedFundingFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Borrow`}>
                    {accruedBorrowFee.lt(0) && "-"}${formatAmount(accruedBorrowFee, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                </div>
              </RowsDropdown>
            </div>
          </Wrap>
          {!hideClose && (
            <div className="Exchange-swap-button-container">
              <button
                className="App-cta Exchange-swap-button"
                onClick={handleConfirm}
                disabled={(position && !position.liquidationPrice) || isConfirm || isDisableCondition()}
              >
                {isConfirm ? "Closing..." : "Close Position"}
              </button>
            </div>
          )}
          <PositionHistory
            isVisible={isOpenPositionHistory}
            setIsVisible={setIsOpenPositionHistory}
            rows={positionActivities}
            position={position}
            chainId={chainId}
            optionalHeader={
              <div className="order-title-line order-title-line-details">
                <div className="position-info-container">
                  <div className="position-info">
                    <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
                    <div className="title">{position.symbol}</div>
                    <div className="position-id">#{position?.posId}</div>
                    <div className="position-tags">
                      <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                        {position?.isLong ? "LONG" : "SHORT"}
                      </div>
                      <div className="side">
                        {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </Modal>
      )}
    </Wrapper>
  );
}

const StyledWrap = styled.div`
  &.head {
    display: flex;
    justify-content: space-between;
  }
  .header {
    display: flex;
    gap: 4px;
  }
  display: flex;
  gap: 4px;
  .position-info {
    gap: 4px;
  }

  .position-info .title {
    font-size: 14px;
  }
  .position-info .position-id {
    font-weight: 500;
  }
`;
const Wrap = styled.div`
  @media screen and (max-width: 700px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
`;
const Wrapper = styled.div`
  .postion-history-ic {
    cursor: pointer;
    &:hover {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(354deg) brightness(106%)
        contrast(101%);
    }
  }
  .Exchange-swap-button-container {
    margin-top: -8px;
  }
  .order-title-line .position-info-container {
    gap: 8px;
  }

  .content-container {
    padding-top: 0 !important;
    .title {
      font-weight: 700;
      color: #fff;
    }
  }
  .Exchange-info-label {
    margin-bottom: 0 !important;
  }

  .square-fee-container {
    border-radius: var(--Border-Radius-Medium, 12px);
    background: var(--Nature-1, #12141a);
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* &:not(:last-child) {
      margin-bottom: 8px;
    } */
  }
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 16px 0;
  background: var(--Border, rgba(55, 63, 92, 0.5));
  &.toggle-margin {
    margin: -8px 0 0 0;
  }
  &.medium {
    margin: 12px 0;
  }
`;
const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
