import { useEffect, useState } from "react";
import axios from "axios";
import { getServerBaseUrl } from "config/backend";
import { SONIC_TESTNET, FANTOM, FANTOM_TESTNET } from "config/chains";

export const configContest = {
  [FANTOM]: {
    START_TIMESTAMP: 1707264000,
    END_TIMESTAMP: 1709164799,
    REWARD_TOKEN: "FTM",
    REWARD_AMOUNT: 10000,
    TOP_REWARDS: [2000, 1000, 700, 650, 650],
    CONTEST_LINK: "https://medium.com/@navigator/navigatorblitz-is-back-let-the-race-to-the-pyramid-begin-0e4fca75a4b1",
  },
  [FANTOM_TESTNET]: {
    START_TIMESTAMP: 1707264000,
    END_TIMESTAMP: 1709164799,
    REWARD_TOKEN: "FTM",
    REWARD_AMOUNT: 10000,
    TOP_REWARDS: [2000, 1000, 700, 650, 650],
    CONTEST_LINK: "https://medium.com/@navigator/navigatorblitz-is-back-let-the-race-to-the-pyramid-begin-0e4fca75a4b1",
  },
  [SONIC_TESTNET]: {
    START_TIMESTAMP: 1723766400,
    END_TIMESTAMP: 1725062399,
    REWARD_TOKEN: "USDC.e",
    REWARD_AMOUNT: 5000,
    TOP_REWARDS: [1000, 700, 500, 200, 100],
    CONTEST_LINK: "",
  },
};
export const DEFAULT_SORT = "pnl DESC";

function useContest(chainId, options = {}) {
  const { toNow = false, from = configContest[chainId]?.START_TIMESTAMP } = options;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [sort, setSort] = useState(() => {
    const [field, type] = DEFAULT_SORT.split(" ");
    return {
      field,
      type,
    };
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    setTimeout(() => {}, 3000);

    setIsLoading(true);
    async function getContests() {
      try {
        const { data: response } = await axios(`https://api.navigator.exchange/stats/sonic/contests`, {
          params: {
            page: currentPage,
            pageSize: rowsPerPage,
            order: `${sort.field} ${sort.type}`,
            from,
            to: toNow ? Date.now() / 1000 : configContest[chainId]?.END_TIMESTAMP,
          },
          cancelToken: source.token,
        });

        setData(response.data || {});
      } catch (error) {
        if (axios.isCancel(error)) {
          console.error("Request canceled", error.message);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getContests();
    const timerId = setInterval(getContests, 30000);

    return () => {
      source.cancel();
      clearInterval(timerId);
    };
  }, [chainId, currentPage, rowsPerPage, sort, from, toNow]);

  const handleSort = (field, type = undefined) => {
    if (type) {
      setSort({ field, type });
    } else {
      setSort((prev) => {
        if (prev.field === field) {
          return {
            field,
            type: prev.type === "ASC" ? "DESC" : "ASC",
          };
        }

        return {
          field,
          type: "DESC",
        };
      });
    }
    setCurrentPage(1);
  };

  return {
    sort,
    handleSort,
    isLoading,
    data,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
  };
}

export default useContest;
