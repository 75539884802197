import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";

import SEO from "components/Common/SEO";
import Footer from "components/Footer/Footer";
import { ARBITRUM, FANTOM, MUMBAI } from "config/chains";
import { useChainId } from "lib/chains";
import { getPageTitle } from "lib/legacy";
import styled from "styled-components";
import Banner from "./components/Banner";
import Banner2 from "./components/Banner2";
import HowTo from "./components/Howto";
import StakeNWFTMain from "./components/StakeNFTInfo";
import StartBlock from "./components/StartBlock";
import "./style.scss";
import NewCollection from "./components/NewCollection";
import { useThemeContext } from "contexts/ThemeProvider";
import bg from "img/nft/Display.png";
function NFT({ connectWallet, setPendingTxns, pendingTxns }) {
  const { lightThemeClassName } = useThemeContext();
  const { chainId } = useChainId();
  // const { active, library, account, chainId: waletChainId } = useWeb3React();
  const [isVisible, setIsVisible] = useState(false);
  // const [activedTab, setActivedTab] = useState(chainId);
  // const handleClickTab = (value) => {
  //   setActivedTab(value);
  //   switchNetwork(value, true);
  // };
  return (
    <SEO title={getPageTitle("NFT")}>
      <NFTWrap className={lightThemeClassName}>
        {/* <ClubTabs>
          {CLUBS.map((item) => (
            <ClubItem
              className={`${item.value === activedTab ? "actived" : ""}`}
              onClick={() => {
                handleClickTab(item.value);
              }}
              key={item.name}
            >
              <img alt="img" src={item.icon} />
              {item.name}
            </ClubItem>
          ))}
        </ClubTabs> */}
        <Banner
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          connectWallet={connectWallet}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
        />

        <StakeNWFTMain
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          connectWallet={connectWallet}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
        />

        {/* <NewCollection /> */}

        {/* {chainId === ARBITRUM && <div className="nft-notavailable-outside"></div>} */}
      </NFTWrap>
      <Footer />
    </SEO>
  );
}

const NFTWrap = styled.div`
  margin-bottom: 284px;

  .container {
    width: 100%;
    max-width: 1368px;
    padding-right: 24px;
    padding-left: 24px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 767px) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  .Footer-wrapper {
    position: relative;
  }
`;

export default NFT;
