import { JsonRpcProvider, WebSocketProvider } from "@ethersproject/providers";
import {
  ARBITRUM,
  BASE,
  FALLBACK_PROVIDERS,
  FANTOM,
  OP,
  getRpcUrl,
} from "config/chains";
import { Signer, ethers } from "ethers";
import { useEffect, useState } from "react";
interface RpcLatencyResult {
  url: string;
  latency: number;
  status: "success" | "error";
  error?: string;
  name: string;
}
export function getProvider(signer: Signer | undefined, chainId: number) {
  let provider;

  // if (signer) {
  //   return signer;
  // }

  provider = getRpcUrl(chainId);

  return new ethers.providers.StaticJsonRpcProvider(
    provider,
    // @ts-ignore incorrect Network param types
    { chainId }
  );
}

export function getWsProvider(chainId: number): WebSocketProvider | JsonRpcProvider | undefined {
  if (chainId === FANTOM) {
    return new ethers.providers.WebSocketProvider("wss://fantom.publicnode.com");
  }

  if (chainId === ARBITRUM) {
    return new ethers.providers.WebSocketProvider("wss://arbitrum-one.publicnode.com");
  }

  if (chainId === OP) {
    return new ethers.providers.WebSocketProvider("wss://optimism.publicnode.com");
  }
  if (chainId === BASE) {
    return new ethers.providers.WebSocketProvider("wss://base.publicnode.com	");
  }
}

export function getFallbackProvider(chainId: number) {
  if (!FALLBACK_PROVIDERS[chainId]) {
    return;
  }

  const provider = getRpcUrl(chainId);

  return new ethers.providers.StaticJsonRpcProvider(
    provider,
    // @ts-ignore incorrect Network param types
    { chainId }
  );
}

export function useJsonRpcProvider(chainId: number) {
  const [provider, setProvider] = useState<JsonRpcProvider>();

  useEffect(() => {
    async function initializeProvider() {
      const rpcUrl = getRpcUrl(chainId);

      if (!rpcUrl) return;

      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

      await provider.ready;

      setProvider(provider);
    }

    initializeProvider();
  }, [chainId]);

  return { provider };
}

export function isWebsocketProvider(provider: any): provider is WebSocketProvider {
  return Boolean(provider?._websocket);
}

export enum WSReadyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

export function isProviderInClosedState(wsProvider: WebSocketProvider) {
  return [WSReadyState.CLOSED, WSReadyState.CLOSING].includes(wsProvider._websocket.readyState);
}

export function closeWsConnection(wsProvider: WebSocketProvider) {
  if (isProviderInClosedState(wsProvider)) {
    return;
  }

  wsProvider.removeAllListeners();
  wsProvider._websocket.close();
}
export async function checkRpcLatency(rpcUrl: string): Promise<number> {
  try {
    const startTime = Date.now();
    const payload = {
      jsonrpc: "2.0",
      method: "eth_blockNumber",
      params: [],
      id: 1,
    };

    const response = await fetch(rpcUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      return -1;
    }

    const endTime = Date.now();
    return endTime - startTime; // Return latency in milliseconds
  } catch (error) {
    // console.error("Error checking RPC latency:", error);
    return -1; // Return -1 to indicate error
  }
}
export async function checkMultipleRpcLatencies(rpcUrls: string[], names: string[]): Promise<RpcLatencyResult[]> {
  // Tạo mảng promises cho tất cả các requests
  const latencyPromises = rpcUrls.map(async (url, index): Promise<RpcLatencyResult> => {
    try {
      const latency = await checkRpcLatency(url);
      return {
        url,
        latency,
        status: "success",
        name: names[index],
      };
    } catch (error) {
      return {
        url,
        latency: Infinity,
        name: names[index],
        status: "error",
        error: error instanceof Error ? error.message : "Unknown error",
      };
    }
  });

  const results = await Promise.all(latencyPromises);
  return results;
}
