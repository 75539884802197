import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useNaviPrice } from "domain/legacy";
import { formatAmount } from "lib/numbers";
import { NAVI_DECIMALS, USD_DECIMALS, isActiveVnaviV2 } from "lib/legacy";
import { getContract } from "config/contracts";
import { DEFAULT_CHAIN_ID, FANTOM, OP } from "config/chains";

const useTokensPriceOP = (options) => {
  const { naviPrice = undefined, chainId = OP } = options || {};
  const [assets, setAssets] = useState({});
  const vNAVIAddress = getContract(OP, "vNAVI");

  useEffect(() => {
    const fetchIds = async () => {
      var url = "https://api.navigator.exchange/api/tokens/info?symbols=WETH,ETH,USDC&chain=OPTIMISM";
      const res = await axios.get(url, {});
      if (res && res.data) {
        const data = res.data || [];
        const result = {};

        Object.values(data).forEach((d) => {
          // if (d.symbol === "NAVI") {
          //   result["EsNAVI"] = {
          //     price: d.price,
          //     decimal: d.decimal,
          //   };
          // }
          result[d.symbol] = {
            price: d.price,
            decimal: d.decimal,
          };
          result[d.address] = {
            price: d.price,
            decimal: d.decimal,
          };
        });

        setAssets(result);
      }
    };

    fetchIds();
    const interval = setInterval(fetchIds, 7000);
    return () => clearInterval(interval);
  }, [chainId]);

  const parsedNaviPrice = naviPrice ? +formatAmount(naviPrice, USD_DECIMALS, USD_DECIMALS) : null;

  return {
    ...assets,
    ...(parsedNaviPrice && {
      vNAVI: {
        price: parsedNaviPrice,
        decimal: NAVI_DECIMALS,
      },
      [vNAVIAddress]: {
        price: parsedNaviPrice,
        decimal: NAVI_DECIMALS,
      },
    }),
  };
};

export default useTokensPriceOP;
