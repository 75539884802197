import { useMemo } from "react";
import moment from "moment";
import useSWR from "swr";
import { COINGECKO_NETWORK_ID } from "components/TVChartContainer/constants";
import { FANTOM, SONIC_TESTNET } from "config/chains";
import { getWrappedToken } from "config/tokens";

export enum LiveDataTimeframeEnum {
  HOUR = "1H",
  FOUR_HOURS = "4H",
  DAY = "1D",
  WEEK = "1W",
  MONTH = "1M",
  // SIX_MONTHS = '6M',
}

const getTimeFrameHours = (timeFrame: LiveDataTimeframeEnum): number => {
  switch (timeFrame) {
    case LiveDataTimeframeEnum.HOUR:
      return 1;
    case LiveDataTimeframeEnum.FOUR_HOURS:
      return 4;
    case LiveDataTimeframeEnum.DAY:
      return 24;
    case LiveDataTimeframeEnum.WEEK:
      return 7 * 24;
    case LiveDataTimeframeEnum.MONTH:
      return 30 * 24;
    // case LiveDataTimeframeEnum.SIX_MONTHS:
    //   return 180 * 24;
    default:
      return 7 * 24;
  }
};

export const getTimeFrameText = (timeFrame: LiveDataTimeframeEnum) => {
  switch (timeFrame) {
    case LiveDataTimeframeEnum.HOUR:
      return "Past hour";
    case LiveDataTimeframeEnum.FOUR_HOURS:
      return "Past 4 hours";
    case LiveDataTimeframeEnum.DAY:
      return "Past 24 hours";
    case LiveDataTimeframeEnum.WEEK:
      return "Past Week";
    case LiveDataTimeframeEnum.MONTH:
      return "Past Month";
    // case LiveDataTimeframeEnum.SIX_MONTHS:
    //   return 'Past 6 Months';
  }
};

const generateCoingeckoUrl = (chainId, address, timeFrame: LiveDataTimeframeEnum | "live"): string => {
  const timeTo = moment().unix();
  const timeFrom =
    timeFrame === "live" ? timeTo - 1000 : moment().subtract(getTimeFrameHours(timeFrame), "hours").unix();

  return `https://api.coingecko.com/api/v3/coins/${
    COINGECKO_NETWORK_ID[chainId || SONIC_TESTNET]
  }/contract/${address}/market_chart/range?vs_currency=usd&from=${timeFrom}&to=${timeTo}`;
};

const getClosestPrice = (prices: any[], time: number) => {
  let closestIndex = 0;
  prices.forEach((item, index) => {
    if (Math.abs(item[0] - time) < Math.abs(prices[closestIndex][0] - time)) {
      closestIndex = index;
    }
  });
  return prices[closestIndex][0] - time > 10000000 ? 0 : prices[closestIndex][1];
};

const fetchCoingeckoDataSWR = async (tokenAddresses: any, chainId: any, timeFrame: any): Promise<any> => {
  return await Promise.all(
    [tokenAddresses[0], tokenAddresses[1]].map(({ address }) =>
      fetch(generateCoingeckoUrl(chainId, address, timeFrame)).then((res) => {
        if (!res.ok) throw new Error();
        if (res.status === 204) {
          throw new Error("No content");
        }
        return res.json();
      })
    )
  );
};

export default function useLiveChartData(tokens, timeFrame, chainId) {
  const tokenAddresses = useMemo(
    () =>
      tokens.filter(Boolean).map((token) => {
        if (token.isNative) {
          return {
            ...token,
            address: getWrappedToken(chainId)?.address.toLowerCase(),
          };
        } else {
          return {
            ...token,
            address: token?.address?.toLowerCase(),
          };
        }
      }),
    [tokens, chainId]
  );
  const { data: coingeckoData, error: coingeckoError } = useSWR(
    [tokenAddresses, chainId, timeFrame],
    fetchCoingeckoDataSWR,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );

  // useEffect(() => {
  //   let timeout;
  //   if (coingeckoError?.message === 'Failed to fetch') {
  //     const params = isKyberDataNotValid ? [tokenAddresses, chainId, timeFrame] : null;
  //     timeout = setTimeout(() => fetchCoingeckoDataSWR.apply(null, params).catch(() => null), 15000);
  //   }
  //   return () => timeout && clearTimeout(timeout);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [coingeckoError]);

  const chartData = useMemo(() => {
    if (coingeckoData && coingeckoData[0]?.prices?.length > 0 && coingeckoData[1]?.prices?.length > 0) {
      const [data1, data2] = coingeckoData;
      return data1.prices.map((item: number[]) => {
        const closestPrice = getClosestPrice(data2.prices, item[0]);
        return {
          time: item[0],
          value: closestPrice > 0 ? parseFloat((item[1] / closestPrice).toPrecision(6)) : 0,
        };
      });
    } else return [];
  }, [coingeckoData]);

  const error = coingeckoError || (coingeckoData && chartData.length === 0);

  const { data: liveCoingeckoData } = useSWR(
    coingeckoData ? [tokenAddresses, chainId, "live"] : null,
    fetchCoingeckoDataSWR,
    {
      refreshInterval: 60000,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  );
  const latestData = useMemo(() => {
    if (liveCoingeckoData) {
      const [data1, data2] = liveCoingeckoData;
      if (data1.prices?.length > 0 && data2.prices?.length > 0) {
        const newValue = parseFloat(
          (data1.prices[data1.prices.length - 1][1] / data2.prices[data2.prices.length - 1][1]).toPrecision(6)
        );
        return { time: new Date().getTime(), value: newValue };
      }
    }
    return null;
  }, [liveCoingeckoData]);

  return {
    data: useMemo(() => (latestData ? [...chartData, latestData] : chartData), [latestData, chartData]),
    error: error,
    loading: chartData.length === 0 && !error,
  };
}
