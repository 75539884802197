import { getServerUrl } from "config/backend";
import { formatAmount, formatNumber } from "lib/numbers";
import { orderBy } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import useSWR from "swr";

import TooltipComponent from "components/Tooltip/Tooltip";
import { SONIC_TESTNET, FANTOM, OP, FANTOM_TESTNET } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider";
import { ACTIVE_CHAIN_IDS_V2, arrayURLFetcher } from "lib/legacy";

const now = Math.floor(new Date().getTime() / 1000.0);

const _29_DAYS_IN_SECOND = 29 * 24 * 60 * 60;

// const urlV2 = `https://api.navigator.exchange/v2/fantom/public/daily-infos?to=${now}&from=${now - _29_DAYS_IN_SECOND}`;
const getUrlV2 = (chainId) => {
  const _default = `https://api.navigator.exchange/v2/sonic/public/daily-infos?to=${now}&from=${
    now - _29_DAYS_IN_SECOND
  }`;
  return (
    {
      [OP]: `https://api.navigator.exchange/v2/optimism/public/daily-infos?to=${now}&from=${now - _29_DAYS_IN_SECOND}`,
      [FANTOM]: `https://api.navigator.exchange/v2/fantom/public/daily-infos?to=${now}&from=${
        now - _29_DAYS_IN_SECOND
      }`,
      [FANTOM_TESTNET]: `https://api.navigator.exchange/v2/fantom/public/daily-infos?to=${now}&from=${
        now - _29_DAYS_IN_SECOND
      }`,
      [SONIC_TESTNET]: `https://api.navigator.exchange/v2/sonic/public/daily-infos?to=${now}&from=${
        now - _29_DAYS_IN_SECOND
      }`,
      // [BASE]: `https://api.navigator.exchange/v2/base/public/daily-infos?to=${now}&from=${now - _29_DAYS_IN_SECOND}`
      // [ARBITRUM]: `https://api.navigator.exchange/v2/arbitrum/public/daily-infos?to=${now}&from=${now - _29_DAYS_IN_SECOND}`
    }[chainId] || _default
  );
};

const VolumeChart = ({ chainId }) => {
  const { isLightTheme, lightThemeClassName } = useThemeContext();
  const { data } = useSWR([getServerUrl(chainId, "/volume-chart"), getUrlV2(chainId)], {
    fetcher: arrayURLFetcher,
    refreshInterval: 10000,
  });

  const tradingVolumeData = useMemo(() => {
    if (data) {
      const [v1, v2 = { data: [] }] = data;

      const v2Result =
        ACTIVE_CHAIN_IDS_V2.includes(chainId) && v2?.data?.length > 0
          ? v2.data.map((d) => {
              return {
                timestamp: d.timestamp,
                daily: +d.volume,
                total: +d.volumeCumulative,
              };
            })
          : [];

      const v1Result =
        v1?.daily?.map(({ volume, timestamp }, index) => {
          return {
            daily: parseFloat(formatAmount(volume, 30)),
            total: parseFloat(formatAmount(v1?.total?.[index]?.volume, 30)),
            timestamp,
            // date: moment(timestamp * 1000).format("MM-DD"),
          };
        }) || [];

      const _result = orderBy([...v1Result.reverse(), ...v2Result], ["timestamp"], ["asc"]);

      const mappedByTimestamp = {};

      for (const [i, rs] of Object.entries(_result)) {
        if (!mappedByTimestamp[rs.timestamp]) {
          mappedByTimestamp[rs.timestamp] = {
            ...rs,
            date: moment(rs.timestamp * 1000).format("MM-DD"),
          };
        } else {
          mappedByTimestamp[rs.timestamp] = {
            date: moment(rs.timestamp * 1000).format("MM-DD"),
            daily: mappedByTimestamp[rs.timestamp].daily + rs.daily,
            total: mappedByTimestamp[rs.timestamp].total + rs.total,
          };
        }
      }

      return Object.values(mappedByTimestamp);
    }
    return [];
  }, [data]);

  const formatAxis = (tickItem) => {
    return `$${formatNumber(tickItem, 1)}`;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="stats-label"
          style={{
            backgroundColor: isLightTheme ? "#fff" : "#11121B",
            padding: "16px",
            borderRadius: "8px",
            boxShadow: isLightTheme ? "4px 0px 24px 0px rgba(0, 0, 0, 0.2)" : "none",
          }}
        >
          <div
            className="stats-row"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <div
              className="stats-label-color"
              style={{ width: "8px", height: "8px", borderRadius: "999px", backgroundColor: payload[0].color }}
            ></div>
            <div>24H Trading Volume: ${formatNumber(payload[0].payload.daily, 1)}</div>
          </div>
          <div
            className="stats-row"
            style={{
              marginTop: "8px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <div
              className="stats-label-color"
              style={{ width: "8px", height: "8px", borderRadius: "999px", backgroundColor: payload[1].color }}
            ></div>
            <div>Total Trading Volume: ${formatNumber(payload[0].payload.total, 1)}</div>
          </div>
          <div style={{ marginTop: "8px", color: isLightTheme ? "#0D1A16B2" : "#FFFFFFB2" }}>
            {payload[0].payload.date}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Wrapper className={lightThemeClassName}>
      <div className="chart-title">
        <TooltipComponent
          position="left-bottom"
          handle={`Trading Vol.`}
          renderContent={() => (
            <div style={{ fontWeight: 400 }}>
              The 24H trading volume is calculated from 0:00 UTC to 23:59 UTC each day.
              <br />
              Edit collateral volume is not included.
            </div>
          )}
        />
      </div>
      <div className="chart-content">
        <ResponsiveContainer width="100%" height={240}>
          {tradingVolumeData ? (
            <ComposedChart width={150} height={40} data={tradingVolumeData} cursor="pointer">
              <Tooltip
                cursor={{ fill: "#FFFFFF1A", cursor: "pointer" }}
                contentStyle={{ borderRadius: "8px", border: "none" }}
                itemStyle={{ color: "#90FB75" }}
                content={<CustomTooltip />}
              />
              <CartesianGrid
                strokeDasharray="4"
                stroke={isLightTheme ? "rgba(13, 26, 22, 0.07)" : "#FFFFFF1A"}
                vertical={false}
              />
              <XAxis
                stroke={isLightTheme ? "#0D1A16B2" : "#FFFFFF66"}
                dataKey="date"
                height={12}
                minTickGap={35}
                fontSize={12}
              />
              <YAxis
                yAxisId="left"
                axisLine={false}
                tickLine={false}
                stroke={isLightTheme ? "#0D1A16B2" : "#FFFFFF66"}
                width={45}
                tickFormatter={formatAxis}
                fontSize={12}
              />
              <YAxis
                yAxisId="right"
                axisLine={false}
                tickLine={false}
                stroke={isLightTheme ? "#0D1A16B2" : "#FFFFFF66"}
                width={40}
                tickFormatter={formatAxis}
                orientation="right"
                fontSize={12}
              />
              <Bar
                yAxisId="left"
                maxBarSize={18}
                dataKey="daily"
                width={20}
                fill={isLightTheme ? "#90FB75" : "#90FB75"}
                radius={100}
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="total"
                stroke={isLightTheme ? "#F3FF74" : "#F3FF74"}
                dot={false}
                strokeWidth={2}
              />
            </ComposedChart>
          ) : (
            <div className="skeleton-box" />
          )}
        </ResponsiveContainer>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 16px;
  background: var(--Nature-1, #12141a);
  padding: 24px;

  .chart-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 16px;
  }

  .chart-content {
    min-height: 240px;
  }

  &.theme--light {
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
  }

  .recharts-layer text {
    fill: #828899;
  }
`;

export default VolumeChart;
