import { getContract } from "config/contracts";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import Token from "abis/Token.json";

import { parseValue } from "lib/numbers";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import useVaultNaviContract from "./contracts/useVaultNaviContract";

function useInfoNslp(chainId) {
  const [totalSupply, setTotalSupply] = useState<BigNumber>();
  const [priceNslp, setPriceNslp] = useState<BigNumber>();
  // const urlApi = getServerBaseUrl(chainId);
  const nslpAddress = getContract(chainId, "NSLP");

  const { data: totalSupplyNslp } = useSWR<BigNumber>([`Nslp:getTotalSupply`, chainId, nslpAddress, "totalSupply"], {
    fetcher: contractFetcher(undefined, Token),
    refreshInterval: 10000,
  });
  const { vaultUSDBalance} = useVaultNaviContract(chainId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getNSLPInfo = async () => {
    try {
      if (totalSupplyNslp && !totalSupplyNslp.eq(0) && vaultUSDBalance) {
        const totalSupplyParsed = totalSupplyNslp.mul(parseValue("1", 12)!);
        setPriceNslp(vaultUSDBalance.mul(parseValue("1", 30)!).div(totalSupplyParsed));
        setTotalSupply(BigNumber.from(totalSupplyNslp));
      } else {
        setPriceNslp(BigNumber.from(0));
      }
    } catch (error) {
      console.error("Error fetching NSLP info:", error);
    }
  };

  useEffect(() => {
    if (totalSupplyNslp && !totalSupplyNslp.eq(0) && vaultUSDBalance) {
      getNSLPInfo();
    }

    const interval = setInterval(getNSLPInfo, 30000);

    return () => clearInterval(interval);
  }, [totalSupplyNslp, vaultUSDBalance]);

  return {
    totalSupply,
    price: priceNslp,
  };
}

export default useInfoNslp;
