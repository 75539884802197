import { ImageBgContact } from "assets";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import styled from "styled-components";
import { contentVariants, descVariant } from "./Banner";
import FooterLanding from "./FooterLanding";
import { PARTNERS } from "./configs";
import { useState } from "react";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Tooltip from "components/Tooltip/Tooltip";
import Partner from "./Partner";

const marqueeFirst = PARTNERS.slice(0, Math.floor(PARTNERS.length / 3));
const marqueeSecond = PARTNERS.slice(Math.floor(PARTNERS.length / 3), Math.floor((PARTNERS.length / 3) * 2));
const marqueeThird = PARTNERS.slice(Math.floor((PARTNERS.length / 3) * 2));

const Contact = () => {
  const [pauseId, setPauseId] = useState<number | undefined>(undefined);

  return (
    <div>
      <Wrapper>
        <div
          style={{
            background: `url(${ImageBgContact})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="container wrapper">
            <div className="left-contact">
              <motion.div variants={contentVariants} initial="hidden" whileInView="visible" className="title">
                Building with the best
              </motion.div>
              <motion.div variants={descVariant} initial="hidden" whileInView="visible" className="desc">
                We have joined forces with the best in Web3 to provide users with the most reliable and powerful
                on-chain trading platform.
              </motion.div>
              <motion.button
                onClick={() => window.open("https://discord.com/invite/23gmsJHmYx")}
                variants={descVariant}
                initial="hidden"
                whileInView="visible"
                className="default-btn btn"
              >
                Join Us
              </motion.button>
            </div>
            <div className="right-contact">
              <Marquee speed={6} autoFill direction="left" play={pauseId !== 1}>
                {marqueeFirst.map((item, idx) => (
                  // <ExternalLink href={item.link} className="wrap-coin" key={idx}>
                  //   <div
                  //     className="item-partner"
                  //     key={idx}
                  //     onMouseEnter={() => setPauseId(1)}
                  //     onMouseLeave={() => setPauseId(undefined)}
                  //   >
                  //     <img className="icon-partner" src={item.img} alt="" />
                  //   </div>
                  // </ExternalLink>
                  <Partner item={item} pauseId={1} idx={idx} setPauseId={setPauseId} key={idx} />
                ))}
              </Marquee>
              <Marquee speed={6} autoFill direction="right" play={pauseId !== 2}>
                {marqueeSecond.map((item, idx) => (
                  // <ExternalLink href={item.link} className="wrap-coin" key={idx}>
                  //   <div
                  //     className="item-partner"
                  //     key={idx}
                  //     onMouseEnter={() => setPauseId(2)}
                  //     onMouseLeave={() => setPauseId(undefined)}
                  //   >
                  //     <img className="icon-partner" src={item.img} alt="" />
                  //   </div>
                  // </ExternalLink>
                  <Partner item={item} idx={idx} pauseId={2} setPauseId={setPauseId} key={idx} />
                ))}
              </Marquee>
              <Marquee speed={6} autoFill direction="left" play={pauseId !== 3}>
                {marqueeThird.map((item, idx) => (
                  // <ExternalLink href={item.link} className="wrap-coin" key={idx}>
                  //   <div
                  //     className="item-partner"
                  //     key={idx}
                  //     onMouseEnter={() => setPauseId(3)}
                  //     onMouseLeave={() => setPauseId(undefined)}
                  //   >
                  //     <img className="icon-partner" src={item.img} alt="" />
                  //   </div>
                  // </ExternalLink>
                  <Partner item={item} pauseId={3} idx={idx} setPauseId={setPauseId} key={idx} />
                ))}
              </Marquee>
            </div>
          </div>
        </div>
      </Wrapper>
      <FooterLanding />
    </div>
  );
};

export default Contact;

const Wrapper = styled.div`
  overflow: hidden;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      gap: 20px;
    }
  }
  .left-contact {
    display: flex;
    padding: 120px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 19px;
    flex: 1 0 0;
    @media screen and (max-width: 768px) {
      padding: 60px 0px 20px;
      gap: 12px;
      align-items: center;
    }
    @media screen and (max-width: 1024px) {
      padding: 60px 0px 20px;
      gap: 12px;
      align-items: flex-start;
    }
  }
  .title {
    color: var(--Primary, #90fb75);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 67.2px */
    @media screen and (max-width: 768px) {
      font-size: 32px;
    }
  }
  .desc {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    @media screen and (max-width: 768px) {
      text-align: center;
    }
    @media screen and (max-width: 1024px) {
      text-align: left;
    }
  }
  .btn {
    width: 182px;
  }
  .right-contact {
    width: 480px;
    display: flex;
    justify-content: center;
    row-gap: 36px;
    flex-direction: column;
    rotate: 90deg;
    @media screen and (max-width: 768px) {
      rotate: 0deg;
      gap: 18px;
      padding-bottom: 40px;
    }
    @media screen and (max-width: 1024px) {
      width: 120%;
      rotate: 0deg;
      gap: 18px;
      padding-bottom: 40px;
    }
  }
  .icon-partner {
    width: 64px;
    height: 64px;
    @media screen and (max-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }
  .box-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
  }
  .item-partner {
    cursor: pointer;
    display: flex;
    padding: 36px;
    align-items: center;
    margin-right: 36px;
    gap: var(--Base-Unit-XS-8, 8px);
    border-radius: 1000px;
    background: #243324;
    rotate: 270deg;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      rotate: 0deg;
      margin-right: 18px;
      padding: 18px;
    }
  }
`;
