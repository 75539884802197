import cx from "classnames";
import React, { ReactNode, useEffect, useMemo, useState } from "react";

import { AppHeaderLinks } from "./AppHeaderLinks";
import { AppHeaderUser } from "./AppHeaderUser";

import { AnimatePresence as FramerAnimatePresence, motion } from "framer-motion";
import logoSmallImg from "img/icons/favicon.svg";

import { FaTimes } from "react-icons/fa";
import { RiMenuLine } from "react-icons/ri";

import { IconLogo, ImageLogo } from "assets";
import { useThemeContext } from "contexts/ThemeProvider";
import { BigNumber } from "ethers";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";
import "./HeaderTheme.scss";
import { HomeHeaderLinks } from "./HomeHeaderLinks";

// Fix framer-motion old React FC type (solved in react 18)
const AnimatePresence = (props: React.ComponentProps<typeof FramerAnimatePresence> & { children: ReactNode }) => (
  <FramerAnimatePresence {...props} />
);

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const slideVariants = {
  hidden: { x: "-100%" },
  visible: { x: 0 },
};

type Props = {
  disconnectAccountAndCloseSettings: () => void;
  openSettings: () => void;
  setWalletModalVisible: (visible: boolean) => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
  tradeVersion: any;
  account: string | null | undefined;
  library: any;
  usdcBalance: any;
  gusdBalance: BigNumber;
  savedSlippageAmount: any;
  savedSelectedDexes: string;
};

export function Header({
  disconnectAccountAndCloseSettings,
  openSettings,
  setWalletModalVisible,
  redirectPopupTimestamp,
  showRedirectModal,
  tradeVersion,
  account,
  library,
  usdcBalance,
  gusdBalance,
  savedSlippageAmount,
  savedSelectedDexes,
}: Props) {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isNativeSelectorModalVisible, setIsNativeSelectorModalVisible] = useState(false);
  const { lightThemeClassName, isLightTheme } = useThemeContext() as any;

  const history = useHistory();
  const isLanding = useMemo(() => {
    if (history.location.pathname === "/") {
      return true;
    } else {
      return false;
    }
  }, [history]);
  useEffect(() => {
    if (isDrawerVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isDrawerVisible]);

  useEffect(() => {
    setIsDrawerVisible(false);
  }, [window.location.hash]);

  const isPorfolio = window.location.href.includes("portfolio") || window.location.href.includes("analytics");
  const isTrade = window.location.href.includes("/trade");
  return (
    <>
      {isDrawerVisible && (
        <AnimatePresence>
          {isDrawerVisible && (
            <motion.div
              className="App-header-backdrop"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={fadeVariants}
              transition={{ duration: 0.2 }}
              onClick={() => setIsDrawerVisible(!isDrawerVisible)}
            ></motion.div>
          )}
        </AnimatePresence>
      )}
      {isNativeSelectorModalVisible && (
        <AnimatePresence>
          {isNativeSelectorModalVisible && (
            <motion.div
              className="selector-backdrop"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={fadeVariants}
              transition={{ duration: 0.2 }}
              onClick={() => setIsNativeSelectorModalVisible(!isNativeSelectorModalVisible)}
            ></motion.div>
          )}
        </AnimatePresence>
      )}
      <header>
        <div
          style={
            {
              // background: window.location.hash === "#/trade" && !isLightTheme ? "black" : "none",
            }
          }
          className={`App-header large ${isLanding && "landing-head"} ${lightThemeClassName} ${
            isPorfolio ? "porfolio" : ""
          } ${isTrade ? "trade" : ""}`}
        >
          <div className="App-header-container-left">
            <div
              className="App-header-link-main"
              onClick={() => (window.location.href = "https://navigator.exchange/#/")}
              style={{ cursor: "pointer" }}
            >
              <IconLogo />
            </div>
            {isLanding ? (
              <HomeHeaderLinks />
            ) : (
              <AppHeaderLinks
                redirectPopupTimestamp={redirectPopupTimestamp}
                showRedirectModal={showRedirectModal}
                account={account}
                library={library}
                usdcBalance={usdcBalance}
                gusdBalance={gusdBalance}
                savedSlippageAmount={savedSlippageAmount}
              />
            )}
          </div>
          <div className="App-header-container-right">
            {/* <Link to="trade" className="default-btn">
              <span style={{ color: "#000" }}>Trade</span>
            </Link> */}
            <AppHeaderUser
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
              openSettings={openSettings}
              setWalletModalVisible={setWalletModalVisible}
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
              tradeVersion={tradeVersion}
              usdcBalance={usdcBalance}
              gusdBalance={gusdBalance}
              savedSlippageAmount={savedSlippageAmount}
              savedSelectedDexes={savedSelectedDexes}
            />
          </div>
        </div>
        <div className={cx("App-header", "small", { active: isDrawerVisible, [lightThemeClassName]: isLightTheme })}>
          <div
            className={cx("App-header-link-container", "App-header-top", {
              active: isDrawerVisible,
            })}
          >
            <div className="App-header-container-left">
              <div className="App-header-menu-icon-block" onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
                {!isDrawerVisible && <RiMenuLine className="App-header-menu-icon" />}
                {isDrawerVisible && <FaTimes className="App-header-menu-icon" />}
              </div>
              <div className="App-header-link-main clickable" onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
                <img src={ImageLogo} className="small-logo" alt="GMY Logo" />
                {/* <img src={logoImg} className="big" alt="NAVI Logo" /> */}
                {/* <img src={isLightTheme && !isLanding ? logoImgV2Light : logoImgV2} className="big" alt="NAVI Logo" />
                <img src={logoSmallImg} className="small" alt="NAVI Logo" /> */}
                {/* <img src={logoSmallImg} className="small" alt="NAVI Logo" /> */}
                {/* <IconLogo /> */}
              </div>
            </div>
            <div className="App-header-container-right">
              <AppHeaderUser
                disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
                openSettings={openSettings}
                small
                setWalletModalVisible={setWalletModalVisible}
                redirectPopupTimestamp={redirectPopupTimestamp}
                showRedirectModal={showRedirectModal}
                tradeVersion={tradeVersion}
                usdcBalance={usdcBalance}
                gusdBalance={gusdBalance}
                savedSlippageAmount={savedSlippageAmount}
                savedSelectedDexes={savedSelectedDexes}
              />
            </div>
          </div>
        </div>
      </header>
      <AnimatePresence>
        {isDrawerVisible && (
          <motion.div
            // onClick={() => setIsDrawerVisible(false)}
            className={`App-header-links-container App-header-drawer ${lightThemeClassName}`}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={slideVariants}
            transition={{ duration: 0.2 }}
          >
            {isLanding ? (
              <HomeHeaderLinks small clickCloseIcon={() => setIsDrawerVisible(false)} />
            ) : (
              <AppHeaderLinks
                small
                openSettings={openSettings}
                clickCloseIcon={() => setIsDrawerVisible(false)}
                redirectPopupTimestamp={redirectPopupTimestamp}
                showRedirectModal={showRedirectModal}
                account={account}
                library={library}
                usdcBalance={usdcBalance}
                gusdBalance={gusdBalance}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
