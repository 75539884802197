import { useState, useEffect, useRef } from "react";
import Select from "react-dropdown-select";
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import styled from "styled-components";
const ALL_TIME_ID = 4;

export default function DateRangeSelect({ options, startDate, endDate, onChange }) {
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState();
  const [rangeState, setRangeState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    setRangeState([
      {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
      },
    ]);
  }, [startDate, endDate]);

  const onSelectItem = (option) => {
    if (option.id == ALL_TIME_ID) {
      const start = moment("2022-12-01").toDate();
      onChange([start, null]);
    }
    const end = new Date();
    const start = moment().subtract(option.id, "month").toDate();
    setSelectedDateRangeOption(option.id);
    if (option.id == ALL_TIME_ID) {
      const start = moment("2022-12-01").toDate();
      onChange([start, null]);
    } else {
      onChange([start, end]);
    }
  };

  useEffect(() => {
    let selected = false;
    for (const option of options) {
      if (option.isDefault) {
        selected = true;
        onSelectItem(option);
        break;
      }
    }
    if (!selected) {
      onSelectItem(options[0]);
    }
  }, []);

  const onDateRangeChange = (item) => {
    const localStartDate = new Date(item.selection.startDate);
    const localEndDate = new Date(item.selection.endDate);

    let utcStartDate = new Date(localStartDate.getTime() - localStartDate.getTimezoneOffset() * 60000);
    let utcEndDate = new Date(localEndDate.getTime() - localEndDate.getTimezoneOffset() * 60000);

    setRangeState([
      {
        startDate: utcStartDate,
        endDate: utcEndDate,
        key: "selection",
      },
    ]);
    if (item.selection.startDate == item.selection.endDate) {
      return;
    }
    onChange([utcStartDate, utcEndDate]);
    setSelectedDateRangeOption(-1);
  };

  const customContentRenderer = ({ props, state }) => {
    const start = startDate && startDate.toISOString().slice(0, 10);
    const end = endDate && endDate.toISOString().slice(0, 10);
    return (
      <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: "12px" }}
        >
          <path
            d="M7.75 2.5C7.75 2.08579 7.41421 1.75 7 1.75C6.58579 1.75 6.25 2.08579 6.25 2.5V4.07926C4.81067 4.19451 3.86577 4.47737 3.17157 5.17157C2.47737 5.86577 2.19451 6.81067 2.07926 8.25H21.9207C21.8055 6.81067 21.5226 5.86577 20.8284 5.17157C20.1342 4.47737 19.1893 4.19451 17.75 4.07926V2.5C17.75 2.08579 17.4142 1.75 17 1.75C16.5858 1.75 16.25 2.08579 16.25 2.5V4.0129C15.5847 4 14.839 4 14 4H10C9.16097 4 8.41527 4 7.75 4.0129V2.5Z"
            fill="#90FB75"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 12C2 11.161 2 10.4153 2.0129 9.75H21.9871C22 10.4153 22 11.161 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12ZM17 14C17.5523 14 18 13.5523 18 13C18 12.4477 17.5523 12 17 12C16.4477 12 16 12.4477 16 13C16 13.5523 16.4477 14 17 14ZM17 18C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18ZM13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17ZM7 14C7.55228 14 8 13.5523 8 13C8 12.4477 7.55228 12 7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14ZM7 18C7.55228 18 8 17.5523 8 17C8 16.4477 7.55228 16 7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18Z"
            fill="#90FB75"
          />
        </svg>

        {startDate && endDate && `${start} ~ ${end}`}
        {(!startDate || !endDate) && "All time"}
      </div>
    );
  };

  const customDropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search, "i");

    return (
      <div>
        <div className="date-range-items">
          {props.options
            .filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]))
            .map((option, index) => {
              if (!props.keepSelectedInList && methods.isSelected(option)) {
                return null;
              }

              return (
                <div
                  disabled={option.disabled}
                  key={index}
                  onClick={option.disabled ? null : () => onSelectItem(option)}
                  className={option.id === selectedDateRangeOption ? "date-range-item selected" : "date-range-item"}
                >
                  <div className="date-range-item__label">{option[props.labelField]}</div>
                  <div className="date-range-item__check" />
                </div>
              );
            })}
        </div>
        <div className="date-range-custom" color={props.color}>
          <DateRange
            editableDateInputs={true}
            onChange={onDateRangeChange}
            moveRangeOnFirstSelection={false}
            ranges={rangeState}
            showDateDisplay={false}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="date-range-selector-wrapper">
      <StyledSelect
        className="calendar"
        placeholder="Select"
        multi
        contentRenderer={customContentRenderer}
        dropdownRenderer={customDropdownRenderer}
        labelField="label"
        options={options}
        closeOnSelect={true}
        closeOnScroll={true}
        values={[selectedDateRangeOption]}
      />
    </div>
  );
}

const StyledSelect = styled(Select)`
  &.react-dropdown-select.calendar {
    border-radius: var(--Border-Radius-Medium, 12px) !important;
    border: 2px solid var(--Primary, #90fb75) !important;
    padding: 12px !important;
    &:focus {
      box-shadow: none;
    }
    @media screen and (max-width: 700px) {
      .react-dropdown-select-content {
        justify-content: center;
      }
    }
  }
  .react-dropdown-select-dropdown {
    border-radius: 16px !important;
    background: var(--Background-Modal_Background, #1a1d26) !important;
    border: none !important;
    padding: 16px;
    .rdrCalendarWrapper {
      background: #262933;
      border-radius: 16px;
    }
  }
`;
