import { ImageNavi, ImageNLP, ImageNSLP } from "assets";
import { SONIC_TESTNET } from "config/chains";
import { motion } from "framer-motion";
import useProcessedData from "hooks/useProcessedData";
import icToken from "img/ic_s_24.svg";
import { itemVariants, listVariants } from "lib/anim";
import { formatAmount, formatKeyAmount } from "lib/numbers";
import styled from "styled-components";
import { contentVariants } from "./Banner";
import { useNaviPrice } from "domain/legacy";

const Tokens = () => {
  const processedData = useProcessedData(SONIC_TESTNET);

  const { naviPrice } = useNaviPrice(SONIC_TESTNET, { arbitrum: undefined }, false);
  const listToken = [
    {
      name: "NAVI",
      price: formatAmount(naviPrice, 30, 2, true),
      img: ImageNavi,
      desc: "Navigator Exchange governance token",
      aprSonic: formatKeyAmount(processedData, "naviAprTotalWithBoost", 2, 2, true),
      href: "https://app.navigator.exchange/#/swap-x?toToken=0x6881B80ea7C858E4aEEf63893e18a8A36f3682f3",
      linkReadMore: "https://docs.navigator.exchange/tokenomics",
    },
    {
      name: "Crypto Pool - NLP",
      price: formatAmount(processedData.nlpPrice, 30, 2, true),
      img: ImageNLP,
      desc: "Crypto index pool (S, WS, ETH, USDC.e, SolvBTC)",
      aprSonic: formatKeyAmount(processedData, "nlpAprTotal", 2, 2, true),
      href: "https://app.navigator.exchange/#/earn/nlp",
      linkReadMore: "https://docs.navigator.exchange/earn/nlp",
      isDisable: false,
    },
    {
      name: "Stable Pool - NSLP",
      price: formatAmount(processedData.nslpPrice, 30, 2, true),
      img: ImageNSLP,
      desc: "Stable index pool (USDC.e, scUSD)",
      aprSonic: formatKeyAmount(processedData, "nslpAprTotal", 2, 2, true),
      href: "https://app.navigator.exchange/#/earn/nslp",
      linkReadMore: "https://docs.navigator.exchange/earn/nslp",
      isDisable: false,
    },
  ];
  return (
    <Wrapper>
      <div className="container wrapper">
        <motion.div variants={contentVariants} initial="hidden" whileInView="visible" className="title">
          Ecosystem tokens
        </motion.div>
        <motion.div variants={listVariants} initial="hidden" whileInView="visible" className="wrap-box">
          {listToken.map((item, idx) => (
            <motion.div variants={itemVariants} className="box-item" key={idx}>
              <div className="box-info">
                <img className="icon" src={item.img} alt="" />
                <div>
                  <div className="name">{item.name}</div>
                  <div className="price">
                    <span className="sub-price">Price:</span> ${item.price}
                  </div>
                </div>
              </div>
              <div className="desc">{item.desc}</div>
              <div className="box-apr">
                <div className="apr-item">
                  <img className="img-apr" src={icToken} alt="" />
                  <div className="apr-percent">
                    <span className="sub-apr">APR</span>
                    {item.aprSonic}%
                  </div>
                </div>
              </div>
              <div className="group-btn">
                <button
                  onClick={() => (window.location.href = item.href)}
                  className="default-btn btn-network"
                  disabled={item.isDisable}
                >
                  Buy
                </button>
                {/* <Link to="/buy_mmy" className="default-btn btn-network">

                </Link> */}
                <button
                  onClick={() => window.open(item.linkReadMore, "_blank")}
                  className="btn-outline-landing btn-network"
                >
                  Read More
                </button>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </Wrapper>
  );
};

export default Tokens;

const Wrapper = styled.div`
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
  }
  .title {
    color: #fff;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    @media screen and (max-width: 768px) {
      font-size: 28px;
    }
  }
  .box-item {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    border-radius: 16px;
    background: #0c0f0c;
    width: 100%;
  }
  .wrap-box {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  .box-info {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  .name {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
  }
  .price {
    color: var(--Primary, #90fb75);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-top: 4px;
  }
  .sub-price {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
  .desc {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  .box-apr {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 16px;
    background: #161a16;
  }
  .apr-item {
    display: flex;
    padding: 24px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    flex: 1 0 0;
    align-self: stretch;
  }
  /* .apr-item:first-child {
    border-right: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
  } */
  .img-apr {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
  }
  .apr-percent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: var(--Primary, #90fb75);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    gap: 4px;
  }
  .sub-apr {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }
  .group-btn {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }
  .btn-network {
    flex: 1;
  }
`;
